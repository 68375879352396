import React, {useEffect, useState} from 'react';
import axios from 'axios';

import {useSelector} from 'react-redux';
import {UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import {Upload, Button, message} from 'antd';

import classes from './style.module.scss';
import {RootStateType} from '../../../Stores';
import {ApiUrls} from '../../../Utils/Api/constants';
import {OrderForAdminType} from '../../../Types/adminTypes';
import {CustomerOrderType} from '../../../Types/userTypes';
import {appApi} from '../../../Globals/App/services';

import {uploadFileStore} from '../../../Stores/upload';

function beforeUpload(file: any) {
  const isExcelPDFWord =
    file.type ===
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/pdf' ||
    file.type ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    file.type === 'application/vnd.ms-excel' ||
    file.type === 'application/msword';

  if (!isExcelPDFWord) {
    message.error('You can only upload Excel/Word/PDF file!');
    return isExcelPDFWord ? true : Upload.LIST_IGNORE;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('File must smaller than 2MB!');
    return isLt2M ? true : Upload.LIST_IGNORE;
  }
  //console.log(isExcelPDFWord);
  return isExcelPDFWord && isLt2M;
}

type UploadComponentPropsType = {
  order: OrderForAdminType | CustomerOrderType;
  userRole: string;
};
export const UploadComponent: React.FC<UploadComponentPropsType> = ({
                                                                      order,
                                                                    }) => {
  const [formData, setFormData] = useState<FormData>();

  // cl-742 bugfix
  uploadFileStore.dispatch({
    type: 'SET_ORDER',
    order: order
  });
  // cl-742 bugfix

  const userRole = useSelector(
    (state: RootStateType) => state.auth.user?.user.role.type
  );

  const token = useSelector((state: RootStateType) => state.auth.user?.jwt);

  const selectedAdminOrder = useSelector(
    (state: RootStateType) => state.admin.order
  );

  const sendFiles = ({onError, onSuccess}: any) => {
    return axios
      .put(`${ApiUrls.BASE_URL}/orders/file-upload/${order?.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => onSuccess())
      .catch((resp) => onError(resp));
  };

  const uploadInvoice = ({fileList, file}: any) => {
    const formData: FormData = new FormData();
    formData.append('files.invoice', fileList[0]?.originFileObj);
    formData.append('data', '{}');
    setFormData(formData);
    file.status = 'done';
  };

  // anti-pattern psychodelics for cl-742

  const pingNothing = ({onError, onSuccess}: any) => {
    const emptyFormData = new FormData();
    emptyFormData.append('data', '{}');
    return axios
      .put(`${ApiUrls.BASE_URL}/orders/file-upload/${order?.id}`, emptyFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => onSuccess())
      .catch((resp) => onError(resp));
  }

  const setDocs = ({fileList, file}: any) => {
    console.log(fileList, "appending...")
    try {
      uploadFileStore.dispatch({
        type: 'SET_NOTES',
        fileList: fileList
      });
    } catch (e) {
      console.log(e);
    }
  };

  // anti-pattern psychodelics for cl-742

  const onDownloadFile = (extra_note: { url: string; name: string }) => {
    appApi.downloadFile(`${extra_note.url}`, extra_note.name);
  };

  const invoiceProps = {
    customRequest: sendFiles,
    beforeUpload: beforeUpload,
    onChange: uploadInvoice,
    maxCount: 1,
  };

  const docProps = {
    customRequest: pingNothing,
    beforeUpload: beforeUpload,
    onChange: setDocs,
  };

  return (
    <div className={classes.container}>
      {userRole === 'authenticated_workshop' || userRole === 'authenticated_admin' ? (
        order.status !== 'finished' ? (
          <div className={classes.buttonsWrapper}>
            <Upload {...invoiceProps}>
              {
                <Button
                  icon={<UploadOutlined/>}
                  shape="round"
                  style={{width: '150px'}}
                  disabled={!order.job_start_date}
                >
                  Upload Invoice
                </Button>
              }
            </Upload>
            <Upload {...docProps}>
              {
                <Button
                  icon={<UploadOutlined/>}
                  shape="round"
                  style={{width: '150px'}}
                  disabled={!order.job_start_date}
                >
                  Upload doc
                </Button>
              }
            </Upload>
          </div>
        ) : (
          !!order.extra_notes?.length &&
          order.extra_notes.map((file) => {
            return (
              <Button
                icon={<DownloadOutlined/>}
                style={{
                  borderRadius: '16px',
                  marginBottom: '16px',
                  marginRight: '16px',
                }}
                onClick={() => {
                  onDownloadFile(file);
                }}
                key={file.id}
              >
                Download doc
              </Button>
            );
          })
        )
      ) : (
        !!order.extra_notes.length &&
        order.extra_notes.map((file) => {
          return (
            <Button
              icon={<DownloadOutlined/>}
              style={{
                borderRadius: '16px',
                marginBottom: '16px',
                marginRight: '16px',
              }}
              onClick={() => {
                onDownloadFile(file);
              }}
              key={file.id}
            >
              Download doc
            </Button>
          );
        })
      )}
    </div>
  );
};
