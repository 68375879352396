import React, { ReactNode } from 'react';
import classes from './styles.module.scss';

type CardPropsType = {
  icon: any;
  children: ReactNode;
};
export const Card: React.FC<CardPropsType> = ({ icon, children }) => {
  return (
    <div className={classes.container}>
      <img src={icon} alt="icon" />
      <div className={classes.title}>{children}</div>
    </div>
  );
};
