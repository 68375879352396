import React from 'react';
import classes from './style.module.scss';
import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../Stores';

interface AlertComponentPropsType {}

export const AlertComponent: React.FC<AlertComponentPropsType> = () => {
  const alerts = useSelector((state: RootStateType) => state.app.alerts);

  return alerts.length ? (
    <Alert
      message={alerts[alerts.length - 1].message}
      type={alerts[alerts.length - 1].type}
      className={classes.alert}
      showIcon
    />
  ) : null;
};
