import { Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { Segmented } from 'antd'
import { useSelector } from 'react-redux';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { RootStateType } from '../../../../../Stores';
import classes from './styles.module.scss';
//import { WorkshopForLandingType } from '../../../../../../Types/appTypes';

type CitySelectorPropsType = {
  selectedCity: any;
  sortByCities: (city:any) => void;
};

export const CitySelector: React.FC<CitySelectorPropsType> = ({ selectedCity, sortByCities = (city) => {}}) => {
    const cities = useSelector((state: RootStateType) => state.user.cities);
    const selectSity = (e:any) => {
        sortByCities(e)
    }
    const [options, setOptions] = useState<Array<Object>>([{label: "All Cities", value: ""}]);
    
    const options1 = [{
        label: "All Cities", value: ""
    },
    cities.map((city) => {
        return(
            {
                label: city.title,
                value: city.id
            }
        )})
    ].flat()
    
    return (
    <div className={classes.cityList}>
        {/* <div className={(selectedCity == null ? classes.selected : classes.disabled)} onChange={(e) => console.log(e)} key={null}><span>All Cities</span></div> */}
        {/* {cities.map((city) => {
            return(
                <button className={(selectedCity == city.id ? classes.selected : classes.disabled)} onClick={(e) => console.log(e)} key={city.id}><span>{city.title}</span></button>
                //<div key={city.id} onClick={Console} className={(selectedCity == city.id ? classes.selected : classes.disabled)}><span>{city.title}</span></div>
            )})
        } */}
        <Segmented
            defaultValue={1}
            options={options1}
            onChange={selectSity}
            className={classes.segmented}
        >
            
        </Segmented>
    </div>
  );
};
