import {
  WorkShopType,
  WorkShopRequestType,
  ScheduleRequest,
  WorkshopPrices,
  WorkshopPricesResponse,
  CitiesTypeRequest
} from './../../Types/authTypes';
import axios from 'axios';
import * as constants from '../../Utils/Api/constants';
import {
  OrderForWorkshopType,
  OrderService,
  OrderServicesForJob,
  OrdersRequestType,
  PaymentDetailsRequest,
  PaymentPreferencesRequestType,
  PaymentsDetailsResponse,
  RequestInvoiceType,
  SpecialistType,
  StripeInfoType,
  WorkshopAppointmentRequestType,
} from '../../Types/workshopTypes';

export const workshopApi = {
  getWorkshopData: async (token: string, id: number) => {
    return axios
      .get<WorkShopType>(`${constants.ApiUrls.BASE_URL}/workshops/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  updateWorkshop: async (
    token: string,
    id: number,
    payload: WorkShopRequestType
  ) => {
    return axios
      .put<WorkShopType>(
        `${constants.ApiUrls.BASE_URL}/workshops/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getWorkshopSchedules: async (id: number) => {
    return axios
      .get<ScheduleRequest>(`${constants.ApiUrls.BASE_URL}/schedules/${id}`)
      .then((resp) => resp.data);
  },
  updateWorkshopSchedules: async (
    token: string,
    id: number,
    payload: ScheduleRequest
  ) => {
    return axios
      .put<ScheduleRequest>(
        `${constants.ApiUrls.BASE_URL}/schedules/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getWorkshopPrices: async (token: string, id: number) => {
    return axios
      .get<WorkshopPricesResponse>(
        `${constants.ApiUrls.BASE_URL}/prices/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  updateWorkshopPrices: async (
    token: string,
    id: number,
    payload: WorkshopPrices
  ) => {
    return axios
      .put<WorkshopPricesResponse>(
        `${constants.ApiUrls.BASE_URL}/prices/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getOrders: async (token: string, pageQuery: string) => {
    return axios
      .get<OrdersRequestType<Array<OrderService>>>(
        `${constants.ApiUrls.BASE_URL}/workshop-orders${pageQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  changeStatus: async (
    token: string,
    id: number,
    status: 'accepted' | 'declined' | 'processing' | 'finished'
  ) => {
    return axios
      .put<OrderForWorkshopType<OrderService>>(
        `${constants.ApiUrls.BASE_URL}/order-job-status/${id}`,
        {
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getOrderById: async (token: string, id: number) => {
    return axios
      .get<OrderForWorkshopType<OrderServicesForJob>>(
        `${constants.ApiUrls.BASE_URL}/workshop-orders/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  markIsDone: async (
    token: string,
    payload: {
      id: number;
      note?: string;
      status?: boolean;
    }
  ) => {
    const { id, ...data } = payload;
    return axios
      .put<OrderForWorkshopType<OrderServicesForJob>>(
        `${constants.ApiUrls.BASE_URL}/order-services/${payload.id}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  markIsAllDone: async (
    token: string,
    payload: {
      order_id: number;
      note?: string;
      status?: boolean;
    }
  ) => {
    const {...data } = payload;
    return axios
      .put<OrderForWorkshopType<OrderServicesForJob>>(
        `${constants.ApiUrls.BASE_URL}/order-services`,
        {
          ...data
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  sendNote: async (
    token: string,
    payload: {
      id: number;
      workshop_note: string;
    }
  ) => {
    const { id, ...data } = payload;
    return axios
      .put<OrderForWorkshopType<OrderServicesForJob>>(
        `${constants.ApiUrls.BASE_URL}/orders/note/${payload.id}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getCities: async (
    ) => {
      return axios
        .get<Array<CitiesTypeRequest>>(
          `${constants.ApiUrls.BASE_URL}/cities`
        )
        .then((resp) => resp.data);
    },
  getSpecialists: async (token: string, id?: number) => {
    return axios
      .get<Array<SpecialistType>>(
        `${constants.ApiUrls.BASE_URL}/workshop-specialists/${id ? id : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  updateSpecialists: async (
    token: string,
    payload: Array<{
      id: number;
      status: boolean;
    }>,
    workshopId?: number
  ) => {
    return axios
      .put<Array<SpecialistType>>(
        `${constants.ApiUrls.BASE_URL}/workshop-specialists/${
          workshopId ? workshopId : ''
        }`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getPromotions: async (token: string, workshopId?: number) => {
    return axios
      .get<Array<SpecialistType>>(
        `${constants.ApiUrls.BASE_URL}/workshop-promotions/${
          workshopId ? workshopId : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  updatePromotions: async (
    token: string,
    payload: Array<{
      id: number;
      status: boolean;
    }>,
    workshopId?: number
  ) => {
    return axios
      .put<Array<SpecialistType>>(
        `${constants.ApiUrls.BASE_URL}/workshop-promotions/${
          workshopId ? workshopId : ''
        }`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getWorkshopInvoices: async (token: string, query: string) => {
    return axios
      .get<RequestInvoiceType>(
        `${constants.ApiUrls.BASE_URL}/workshop-invoices${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getWorkshopAppointments: async (token: string, query: string) => {
    return axios
      .get<WorkshopAppointmentRequestType>(
        `${constants.ApiUrls.BASE_URL}/workshop-appointments${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getWorkshopInvoiceLink: async (token: string, id: number) => {
    return axios
      .get<{ url: string }>(
        `${constants.ApiUrls.BASE_URL}/load-invoice/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getWorkshopMonthlyRevenue: async (token: string) => {
    return axios
      .get<{ total_price: number }>(
        `${constants.ApiUrls.BASE_URL}/month-revenue`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getWorkshopPaymentsLink: async (token: string) => {
    return axios
      .get<PaymentPreferencesRequestType>(
        `${constants.ApiUrls.BASE_URL}/account-link`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  addNewPhone: async (token: string, workshopId: number, phone: string) => {
    return axios
      .post<WorkShopType>(
        `${constants.ApiUrls.BASE_URL}/workshops/${workshopId}/workshop-phones`,
        { phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  editPhone: async (
    token: string,
    workshopId: number,
    phone: string,
    phoneId: number
  ) => {
    return axios
      .put<WorkShopType>(
        `${constants.ApiUrls.BASE_URL}/workshops/${workshopId}/workshop-phones/${phoneId}`,
        { phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  deletePhone: async (token: string, workshopId: number, phoneId: number) => {
    return axios
      .delete<WorkShopType>(
        `${constants.ApiUrls.BASE_URL}/workshops/${workshopId}/workshop-phones/${phoneId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getStripeInfo: async (token: string) => {
    return axios
      .get<StripeInfoType>(`${constants.ApiUrls.BASE_URL}/stripe-info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  getPaymentsDetails: async (token: string, id: number) => {
    return await axios.get<{data: PaymentsDetailsResponse}>(
      `${constants.ApiUrls.BASE_URL}/workshop-payments/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  updatePaymentsDetails: async (
    token: string,
    id: number,
    payload: PaymentDetailsRequest
  ) => {
    return await axios.put<PaymentsDetailsResponse>(
      `${constants.ApiUrls.BASE_URL}/workshop-payments/${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
};
