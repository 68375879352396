import classes from './style.module.scss';
import { CardElement } from '@stripe/react-stripe-js';
import classNames from 'classnames';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      borderColor: 'red',
      border: '1px solid red',
      borderRadius: '5px',
      fontFamily: 'LatoRegular, sans-serif',
      fontWeight: 'normal',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: 'rgba(0, 0, 0, 0.25)',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};


function CardSection({ fromSettings = false }) {
  return (
    <div
      className={classNames(classes.card, {
        [classes.customerCard]: fromSettings,
      })}
    >
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
}
export default CardSection;
