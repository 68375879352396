import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert } from 'antd';

import { CarAndServices } from '../../../../Components/CarAndServices/index';
import classes from './style.module.scss';
import { BookForm, FormType } from './Components/Form';
import DrawerComponent from '../../../../Components/Drawer';
import { RootStateType } from '../../../../Stores';
import { CreateOrderRequestType } from '../../../../Types/authTypes';
import { authActions } from '../../../../Globals/Auth/actions';
import { userActions } from '../../../../Globals/User/actions';
import { constants } from '../../../../Utils/Api';

//import { TabbyCard } from '../../../../Components/Tabby/TabbyCard'
//import { TabbyPromo } from '../../../../Components/Tabby/TabbyPromo'

type BookStepPropsType = {
  goToNextStep: () => void;
  goToPrevStep: () => void;
  setClientSecret: (value: string | null) => void;
  setOrderSavedForConfirm: (value: string | null) => void;
  setTabbyUrl: (value: string | null) => void;
};

export const BookStep: React.FC<BookStepPropsType> = ({
  goToNextStep,
  goToPrevStep,
  setClientSecret,
  setOrderSavedForConfirm,
  setTabbyUrl
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // const tabby = useSelector(
  //   (state: any) => state.user?.responseTabby
  // );

//const tabbySession = tabby?.payload?.tabbySession;

  //console.log('tabbySession',tabbySession)

  const user = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  const selectedWorkshop = useSelector(
    (state: RootStateType) => state.user.selectedWorkshop
  );
  const [isPaymentStep, setIsPaymentStep] = useState<boolean>(
    user?.role.name === 'Authenticated Client'
  );
  
  const formValues = useSelector(
    (state: RootStateType) => state.user.formValues
  );
  const isBasic = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );
  const selectedWorkshopId = useSelector(
    (state: RootStateType) => state.user.selectedWorkshop?.id
  );
  const location = useSelector((state: RootStateType) => state.user.location);
  const client_address = useSelector(
    (state: RootStateType) => state.user.client_address
  );
  const otherServices = useSelector(
    (state: RootStateType) => state.user.selectedOtherServicesId
  );
  const carRepair = useSelector(
    (state: RootStateType) => state.user.selectedCarRepairId
  );
  const carRepairText = useSelector(
    (state: RootStateType) => state.user.carRepairText
  );
  const selectedDate = useSelector(
    (state: RootStateType) => state.user.selectedDate
  );

  const selectedCar = useSelector(
    (state: RootStateType) => state.user.selectedCar
  );

  const delivery = useSelector(
    (state: RootStateType) => state.user.deliveryCar
  );

  const savedSummaryData = useSelector(
    (state: RootStateType) => state.auth.summaryData
  );

  const customer_note = useSelector(
    (state: RootStateType) => state.user.customer_note
  );

  const tabby = useSelector(
    (state: RootStateType) => state.user.responseTabby
  );

  const orderData = useSelector((state: RootStateType) => state.auth.orderData);

  const dataLayer = window.dataLayer || []
  const createRequestObject = useCallback(
    (
      save_card: boolean,
      phone?: string,
      payment_id?: string | null
    ): CreateOrderRequestType => {
      return {
        make: formValues?.brand?.id!,
        model: formValues?.trim?.model!,
        trim: formValues?.trim?.id!,
        phone: phone ? phone : '',
        maintenance_interval: formValues?.milliage?.id || null,
        log_book: isBasic === false,
        basic: isBasic === true,
        workshop: selectedWorkshopId!,
        at_location: location === 'at_location',
        at_workshop: true,
        start_date: selectedDate?.startDate!,
        start_time: moment(selectedDate?.startTime, 'HH:mm a').format(
          'HH:mm:ss ZZ'
        ),
        //latitude: addressPoint?.lat!,
        //longitude: addressPoint?.lng!,
        address: client_address,
        tabby_payment_id: tabby.payload?.tabby_payment_id,
        //address_url: addressPoint?.link,
        additional_tasks: otherServices.map(String),
        car_repair_ids: carRepair.map(String),
        car_repair_note: carRepairText,
        redirect_url: null,
        client_secret: null,
        payment_id: payment_id || null,
        save_card,
        emailSuccess: `${constants.ApiUrls.WEB_URL}/createorder/congratulation`,
        emailCancel: `${constants.ApiUrls.WEB_URL}/createorder/payment`,
        emailFailure: `${constants.ApiUrls.WEB_URL}/createorder/payment`,
        car: selectedCar?.id,
        pick_up: delivery.pick_up_slot,
        drop_off: delivery.drop_off_slot,
        // drop_off_time_slot: delivery.time.drop_off_time_slot
        //   ? moment(delivery.time.drop_off_time_slot).format('HH:mm:ss')
        //   : null,
        drop_off_time_slot: null,
        year: formValues?.year?.year,
        customer_note,
        username: user?.username || ''
      };
    },
    [
      formValues,
      client_address,
      delivery,
      isBasic,
      location,
      otherServices,
      selectedCar,
      carRepair,
      carRepairText,
      selectedDate,
      selectedWorkshopId,
      customer_note,
      user
    ]
  );
  
  const goNext = async (values: FormType) => {
    if (!isPaymentStep) {
      dispatch(
        authActions.signUp({
          email: values.email,
          password: values.password,
          username: values.name,
          phone: values.phone,
        })
      );
      if (user?.role.name) {
        setIsPaymentStep(true);
      }
    } else {
      const orderData = createRequestObject(values.save_card, values.phone);
      //GTM
      //Start
      dataLayer.push({
        "event": "step_5_book",
        "user_id":`${user?.id}`,
        "email":`${user?.email}`,
        "phone":`+${user?.phone}`
        });
      //End
      //return orderData;
    }
    if (!user?.role.name) {
      const orderData = createRequestObject(true, '', null);
      dispatch(authActions.setOrderData(orderData));
    }
    const orderData = createRequestObject(values.save_card, values.phone);
      return orderData;
  };

    useEffect(() => {
      // if (user) {
      //   dispatch(userActions.createTabbySession({
      //     name: user?.username,
      //     email: user?.email,
      //     phone: user?.phone,
      //     created_at: user?.created_at,
      //     amount: selectedWorkshop?.total_vat_price}));
      // }

      window.onbeforeunload = (event) => {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
            e.returnValue = '';
        }
        return '';
        };
      return () => {
        window.onbeforeunload = null;
      }
    }, [dispatch]);
    
    const signUpGoogle = () => {
      window.onbeforeunload = null;
      const orderData = createRequestObject(true, '', null);
      dispatch(authActions.setOrderData(orderData));
    };

  const signIn = () => {
    const orderData = createRequestObject(true, '', null);
    dispatch(authActions.setOrderData(orderData));
    dispatch(userActions.setOrderData(orderData));
    history.push('/auth');
  };

  useEffect(() => {
    if (savedSummaryData && user?.role.name && !formValues) {
      dispatch(userActions.setSavedSummaryData(savedSummaryData));
    }
    // if (isPaymentStep == true) {
    //  goNext();
    // } 
  }, [dispatch, savedSummaryData]);

  const error = useSelector((state: RootStateType) => state.app.error);

  return (
    <div className={classes.contentContainer}>
      <DrawerComponent runAnimate={{}}>
        <CarAndServices canRemove={false} step={5} isShow={false}/>
      </DrawerComponent>
      <div className={classes.leftContainerWrapper}>
      {error && (
        <Alert type="error" message={error} className={classes.alert} />
      )}
        {!user?.role.name ? (
          <div>
            <h2 className={classes.header}>Your contact information</h2>    
            <span className={classes.subtitle}>
              Already have an account?{' '}
              <span
                style={{ cursor: 'pointer', color: '#5B8DB4' }}
                onClick={signIn}
              >
                Log in{' '}
              </span>{' '}
              If not, please sign up below{' '}
            </span>
          </div>
        ) : (<h2 className={classes.header}>Please confirm your phone number</h2>)}
        <BookForm
          goToPrevStep={goToPrevStep}
          goToNextStep={goNext}
          goCardStep={goToNextStep}
          isPaymentStep={isPaymentStep}
          setClientSecret={setClientSecret}
          setOrderSavedForConfirm={setOrderSavedForConfirm}
          signUpGoogle={signUpGoogle}
          setTabbyUrl={setTabbyUrl}
        />
        {/* <TabbyPromo
          price={'200'}
        /> */}
        {/* <TabbyCard
          price={'200'}
        /> */}
      </div>
      <div className={classes.hide}>
        <CarAndServices canRemove={false} step={5} />
      </div>
    </div>
  );
};
