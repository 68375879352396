import React, { useEffect, useState } from 'react';
import { Select, Checkbox, Pagination, Spin, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import ReactGA from 'react-ga';

import classes from './style.module.scss';
import { ButtonComponent } from '../../../../Components/Button';
import { ModalComponent } from '../../../../Components/Modal';
import Workshop from '../../../../Components/Workshop';
import { CarAndServices } from '../../../../Components/CarAndServices/index';
import { WorkshopInfoModal } from '../../../../Components/WorkshopInfoModal';
import DrawerComponent from '../../../../Components/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../../Stores';
import { userActions } from '../../../../Globals/User/actions';
import { appActions } from '../../../../Globals/App/actions';
import * as constants from '../../../../Utils/Api/constants';
import TabbyLogo from '../../../../Assets/tabby-button-logo.svg'
import { workshopActions } from '../../../../Globals/Workshop/actions';
import { WorkShopType } from '../../../../Types/authTypes';
import { CitySelector } from './CitySelector';

const { Option } = Select;

type SelectWorkshopStepPropsType = {
  goToNextStep: () => void;
  goToPrevStep: () => void;
  orderId?: number;
  isSpinning?: boolean;
};

export const SelectWorkshopStep: React.FC<SelectWorkshopStepPropsType> = ({
  goToNextStep,
  goToPrevStep,
  orderId,
  isSpinning
}) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPartnerShow, setIsPartnerShow] = useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState<WorkShopType>();
  const [animateGo, setAnimateGo] = useState<{}>();
  const [selectedSity, setSelectedSity] = useState<string>("");

  const workshops = useSelector((state: RootStateType) => state.user.workshops);
  const cities = useSelector((state: RootStateType) => state.user.cities);
  const delivery = useSelector(
    (state: RootStateType) => state.user.deliveryCar
  );

  const addressPoint = useSelector(
    (state: RootStateType) => state.user.addressPoint
  );
  const trimId = useSelector(
    (state: RootStateType) => state.user.formValues?.trim?.id
  );
  const intervalId = useSelector(
    (state: RootStateType) => state.user.formValues?.milliage?.id
  );
  const modelId = useSelector(
    (state: RootStateType) => state.user.formValues?.trim?.model
  );
  const isBasic = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );
  const additionalTasks = useSelector(
    (state: RootStateType) => state.user.selectedOtherServicesId
  );

  const order = useSelector((state: RootStateType) => state.user.order);

  const location = useSelector((state: RootStateType) => state.user.location);

  const isLoading = useSelector((state: RootStateType) => state.app.loading);

  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  
  const [city_id, setCityID] = useState<any>(1);

  const dataLayer = window.dataLayer || []

  const dispatch = useDispatch();

  const sortByCity = (city: any) => {
    const city_id = city;
    setCityID(city_id)
    setCurrentPage(1)
    if (trimId && modelId) {
      dispatch(
        userActions.getWorkshops(
          {
            trimId,
            intervalId,
            modelId,
            isBasic,
            additionalTasks,
            // pick_up: delivery.type.includes('pickUp'),
            // drop_off: delivery.type.includes('dropOff'),
            // coord: { lat: addressPoint!.lat, lng: addressPoint!.lng },
            // mobile_mechanic: location === 'at_location',
          },
          isPartnerShow,
          currentPage,
          city_id,
        )
      );
    }
  };
  
  const onSearchPartners = (e: CheckboxChangeEvent) => {
    setIsPartnerShow(e.target.checked);
    sortByCity(city_id)
  };
  
  const closeModal = () => {
    setIsModal(false);
  };

  const openModal = (id: number, workshopId: number) => {
    dispatch(workshopActions.getWorkshopSchedules(id));
    const selectedWorkshop = workshops.rows.find(
      (item) => item.id === workshopId
    );
    setSelectedWorkshop(selectedWorkshop);
    setIsModal(true);
    ReactGA.event({
      category: 'Create order',
      label: 'workshop modal was open',
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } opened modal with ${selectedWorkshop?.name}`,
    });
  };

  const onSelectWorkshop = (id: number) => {
    const selectedWorkshop = workshops.rows.find(
      (workshop) => workshop.id === id
    );
    if (selectedWorkshop) {
      //GTM
      //Start
      dataLayer.push({
        "event": "step_3_booknow",
        "workshop":`${selectedWorkshop.name}`,
        "price":`${selectedWorkshop.total_price}`
      });
      //End
      setAnimateGo({});
      ReactGA.event({
        category: 'Create order',
        label: isModal ? 'from modal window' : 'book now button',
        action: `User ${
          currentUser?.username ? currentUser?.username : 'not registered'
        } selected workshop ${selectedWorkshop.name}`,
      });
      dispatch(userActions.setOrderSummary(selectedWorkshop.total_vat_price));
      dispatch(userActions.setSelectedWorkshop(selectedWorkshop));
      goToNextStep();
    }
  };

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/createorder/workshops",
      "page_type":"booknowStep",
      "event":"page_view"
    });
    //End
  }, []);
  
  useEffect(() => {
    const beforeUnloadWindow = (e:any) => {
      var e = e || window.event;
        if (e) {
            e.returnValue = 'Leaving the page?';
        }
        // For Safari
        return "Leaving the page?";
     }
    window.addEventListener('beforeunload', beforeUnloadWindow)
    return () => { 
      window.removeEventListener('beforeunload', beforeUnloadWindow)
    }
  }, []);

  useEffect(() => {
    // cities.map((city,index) => {
    //   setAllCities(
    //     allCities {
    //       label: city.title,
    //       key: city.id
    //     }
    //   )
    // })
  },[])

  useEffect(() => {
    dispatch(userActions.setOrderSummary(null))
    if (currentUser?.role.type === 'authenticated' && orderId) {
      dispatch(userActions.getDeclinedOrder(orderId));
    }
  }, [currentUser?.role.type, dispatch, orderId]);

  useEffect(() => {
    dispatch(userActions.resetSelectedWorkshop());
    dispatch(userActions.removeDeliveryCar());
    // dispatch(
    //   userActions.getCities()
    // );
    if (trimId && modelId && !order) {
      dispatch(
        userActions.getWorkshops(
          {
            trimId,
            intervalId,
            modelId,
            isBasic,
            additionalTasks,
            // pick_up: delivery.type.includes('pickUp'),
            // drop_off: delivery.type.includes('dropOff'),
            // coord: { lat: addressPoint.lat, lng: addressPoint.lng },
            // mobile_mechanic: location === 'at_location',
          },
          isPartnerShow,
          currentPage,
          city_id
          //undefined
        )
      );
    }
  }, [
    currentPage,
    isPartnerShow,
    dispatch,
    addressPoint,
    trimId,
    intervalId,
    modelId,
    isBasic,
    city_id,
    additionalTasks,
  ]);

  return (
    <Spin spinning={isSpinning && isLoading} size="large" tip="loading...">
      <div className={classes.contentContainer}>
        <DrawerComponent runAnimate={animateGo}>
          <CarAndServices canRemove={false} isShowTabbyPromo={false}/>
        </DrawerComponent>
        <div className={classes.leftWrapper}>
        <div className={classes.tabbyPromo}>
          <p>4 interest-free payments with</p>
          <img src={TabbyLogo} alt="tabby logo" />
        </div>
          <h2 style={{ marginBottom: '24px', marginTop: '10px' }}>
            {workshops.count
              ? (workshops.count == 1 ? `We found ${workshops.count} workshop!` : `We found ${workshops.count} workshops!`)
              : (isLoading
              ? `Search for workshops`
              : `No available workshops!`)}
          </h2>

          <div className={classes.sortContainer}>
            <div className={classes.cityContainer}>
              <CitySelector selectedCity={city_id} sortByCities={sortByCity}/>
            </div>
          </div>

          <div className={classes.workshop}>
            {workshops.rows.map((workshop) => (
              <Workshop
                key={workshop.id}
                openModal={() => openModal(+workshop.schedule, workshop.id)}
                availableData={moment(workshop.available_from).format('DD MMM')}
                goToNextStep={onSelectWorkshop}
                workshop={workshop}
              />
            ))}
          </div>
          {workshops.count > constants.LIMIT_ON_PAGE && (
            <Pagination
              current={currentPage}
              onChange={setCurrentPage}
              total={workshops.count}
              size="default"
              className={classes.pagination}
              pageSize={constants.LIMIT_ON_PAGE}
            />
          )}
          <div className={classes.buttons}>
            <ButtonComponent
              style={{ width: '172px' }}
              title="Back"
              type="cancel"
              onClick={goToPrevStep}
            />
          </div>
        </div>
        <div className={classes.hide}>
          <CarAndServices canRemove={false} />
        </div>

        <ModalComponent
          isModal={isModal}
          toggleModal={closeModal}
          title={`More about ${selectedWorkshop?.name}`}
          className={classes.workshopModal}
        >
          <WorkshopInfoModal
            goToNextStep={onSelectWorkshop}
            selectedWorkshop={selectedWorkshop!}
          />
        </ModalComponent>
      </div>
    </Spin>
  );
};
