import React, { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './style.scss';
import { RootStateType } from '../../../../Stores';
import axios from 'axios';
import { ApiUrls } from '../../../../Utils/Api/constants';

function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

type AvatarPropsType = {
  setAvatar: (file: any) => void;
  workshopId: number;
};

export const Avatar: React.FC<AvatarPropsType> = ({
  setAvatar,
  workshopId,
}) => {
  const image = useSelector(
    (state: RootStateType) => state.workshop.workshopData?.avatar?.url
  );

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>();
  const [formData, setFormData] = useState<FormData>();

  const token = useSelector((state: RootStateType) => state.auth.user?.jwt);

  const sendFiles = ({ onError, onSuccess }: any) => {
    return axios
      .put(`${ApiUrls.BASE_URL}/workshops/${workshopId}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => onSuccess())
      .catch((resp) => onError(resp));
  };

  const handleChange = ({ file, fileList }: any) => {
    if (file.status === 'uploading') {
      setLoading(true);
      uploadImage(file);
      return;
    }
    if (file.status === 'done') {
      getBase64(file.originFileObj, (imageUrl: string) => {
        setImageUrl(imageUrl);
        setLoading(false);
      });
    }
  };

  const uploadImage = (file: any) => {
    const formData: FormData = new FormData();
    formData.append('files.avatar', file.originFileObj);
    formData.append('data', '{}');
    setFormData(formData);
  };

  const uploadProps = {
    customRequest: sendFiles,
    beforeUpload: beforeUpload,
    onChange: handleChange,
    maxCount: 1,
  };

  useEffect(() => {
    setImageUrl(image);
  }, [image]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      {...uploadProps}
      className="avatar-uploader"
      listType="picture-card"
      showUploadList={false}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{ width: '100%' }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};
