import {
  AddCarRequestType,
  CreateOrderRequestType,
  CreateOrderResponseType,
  IntervalResponseType,
  MakesResponseType,
  ModelResponseType,
  OfferedServicesResponseType,
  TrimResponseType,
  WorkShopType,
  YearResponseType,
  PaymentsResponseType,
  WorkShopTypeRequest,
  UpdateUserProfile,
  CarResponseType,
  CarsResponseType,
  CitiesTypeRequest,
  TabbySessionRequestType,
  TabbySessionResponseType,
} from './../../Types/authTypes';
import axios from 'axios';
import * as constants from '../../Utils/Api/constants';
import {
  OrderService,
  OrdersRequestType,
  ReviewResponseType,
} from '../../Types/workshopTypes';
import {
  CancelOrderRequest,
  CarDeliveriesType,
  CarUpdateRequestType,
  CheckCancelRescheduleOrderResponse,
  CreditCardType,
  CustomerOrderType,
  GetAvailableDaysParamsType,
  RescheduleRequest,
  ReviewRequestType,
  ScheduledMaintenanceRequestType,
  ServiceHistoryRequestType,
  UserProfileType,
} from '../../Types/userTypes';

export const userApi = {
  getMakes: async () => {
    return axios
      .get<Array<MakesResponseType>>(`${constants.ApiUrls.BASE_URL}/makes?_sort=title:ASC`)
      .then((resp) => resp.data);
  },
  getYears: async (make_id: number) => {
    return axios
      .get<Array<YearResponseType>>(
        `${constants.ApiUrls.BASE_URL}/years-by-one?_where[0][make]=${make_id}&_sort=year_from:ASC`
      )
      .then((resp) => resp.data);
  },
  getModel: async (years_ids: Array<number>) => {
    const getParams = () => {
      const querys = years_ids.map((item, index) => {
        if (index === 0) {
          return `?year=${item}`;
        } else {
          return `&year=${item}`;
        }
      });
      return querys.join('');
    };
    return axios
      .get<Array<ModelResponseType>>(
        `${constants.ApiUrls.BASE_URL}/models-by-one${getParams()}`
      )
      .then((resp) => resp.data);
  },
  getTrim: async (models_ids: Array<number>) => {
    const getParams = () => {
      const querys = models_ids.map((item, index) => {
        if (index === 0) {
          return `?model=${item}`;
        } else {
          return `&model=${item}`;
        }
      });
      return querys.join('');
    };
    return axios
      .get<Array<TrimResponseType>>(
        `${constants.ApiUrls.BASE_URL}/trims${getParams()}`
      )
      .then((resp) => resp.data);
  },
  getIntervals: async (trim_id: number) => {
    return axios
      .get<Array<IntervalResponseType>>(
        `${constants.ApiUrls.BASE_URL}/maintenance-intervals?_where[0][services.trim]=${trim_id}&_sort=value:ASC`
      )
      .then((resp) => resp.data);
  },
  getIntervalsForAllCars: async (trims_id: number[]): Promise<{
    trimId: number;
    rows: Array<IntervalResponseType>;
}[]> => {
    const getIntervals = async (
      id: number
    ): Promise<{ trimId: number; rows: Array<IntervalResponseType> }> => {
      const res = await axios.get<Array<IntervalResponseType>>(
        `${constants.ApiUrls.BASE_URL}/maintenance-intervals?_where[0][services.trim]=${id}&_sort=value:ASC`
      );
      return { trimId: id, rows: res.data };
    };
    const res = await Promise.all(
      trims_id.map(async (t) => {
        const res = await getIntervals(t);
        return res;
      })
    );
    return res;
  },
  getServices: async (trim_id: number, maint_id?: number) => {
    return axios
      .get<OfferedServicesResponseType>(
        `${constants.ApiUrls.BASE_URL}/services-by-maintenance?${
          maint_id
            ? '_where[0][maintenance_interval]=' + maint_id + '&_where[1]'
            : '_where[0]'
        }[trim]=${trim_id}`
      )
      .then((resp) => resp.data);
  },
  getCities: async (
  ) => {
    return axios
      .get<Array<CitiesTypeRequest>>(
        `${constants.ApiUrls.BASE_URL}/cities`
      )
      .then((resp) => resp.data);
  },
  getWorkshops: async (
    page: number,
    trim_id: number,
    model_id: number,
    additionalTask: Array<number>,
    isBasic: boolean | null,
    interval_id?: number,
    isPartner?: boolean,
    city_id?: number,
    workshop_id?: number
  ) => {
    const start = page === 1 ? 0 : constants.LIMIT_ON_PAGE * (page - 1);
    return axios
      .get<WorkShopTypeRequest>(
        `${constants.ApiUrls.BASE_URL}/workshops-for-order?additional_tasks=${JSON.stringify(
          additionalTask
        )}&trim=${trim_id}${
          !!interval_id ? '&maintenance_interval=' + interval_id : ''
        }&model=${model_id}&log_book=${isBasic === false}&basic=${
          isBasic === true
        }&_start=${start}&_limit=${constants.LIMIT_ON_PAGE}${
          !!isPartner ? '&partner=' + isPartner : ''
        }${
          !!city_id ? '&city_id=' + city_id : ''}${
          !!workshop_id ? '&workshop_id=' + workshop_id : ''
        }
        `
      )
      .then((resp) => resp.data);
  },
  getAppointment: async (
    date: string,
    trim_id: number,
    additionalTask: Array<number>,
    isBasic: boolean | null,
    workshop_id: number,
    interval_id?: number,
    order_id?: number
  ) => {
    return axios
      .get<Array<WorkShopType>>(
        `${constants.ApiUrls.BASE_URL}/appointments?date=${date}${
          !!interval_id ? '&maintenance_interval=' + interval_id : ''
        }&trim=${trim_id}&log_book=${isBasic === false}&basic=${
          isBasic === true
        }&workshop=${workshop_id}${
          !!additionalTask.length
            ? '&additional_tasks=' + JSON.stringify(additionalTask)
            : ''
        }${order_id ? '&order_id=' + order_id : ''}`
      )
      .then((resp) => resp.data);
  },
  addCar: async (token: string, payload: AddCarRequestType) => {
    return axios
      .post<CarResponseType>(
        `${constants.ApiUrls.BASE_URL}/customer-cars`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getCarList: async (token: string) => {
    return axios
      .get<CarsResponseType>(`${constants.ApiUrls.BASE_URL}/customer-cars`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  deleteCar: async (token: string, id: number) => {
    return axios
      .delete<CarResponseType>(
        `${constants.ApiUrls.BASE_URL}/customer-cars/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getServicesForCustomCar: async (token: string, id: number) => {
    return axios
      .get<OfferedServicesResponseType>(
        `${constants.ApiUrls.BASE_URL}/services-by-car/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  createOrder: async (token: string, payload: CreateOrderRequestType) => {
    return axios
      .post<CreateOrderResponseType>(
        `${constants.ApiUrls.BASE_URL}/orders`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getPayment: async (token: string) => {
    return axios
      .get<PaymentsResponseType>(
        `${constants.ApiUrls.BASE_URL}/payment-method`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getOrders: async (token: string, pageQuery: string) => {
    return axios
      .get<OrdersRequestType<Array<OrderService>>>(
        `${constants.ApiUrls.BASE_URL}/customer-orders${
          pageQuery ? pageQuery : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getCar: async (token: string, id: number) => {
    return axios
      .get<CarResponseType>(
        `${constants.ApiUrls.BASE_URL}/customer-cars/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  updateCar: async (
    token: string,
    payload: CarUpdateRequestType,
    id: number
  ) => {
    return axios
      .put<CarResponseType>(
        `${constants.ApiUrls.BASE_URL}/customer-cars/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getUserProfile: async (token: string) => {
    const resp = await axios.get<UserProfileType>(
      `${constants.ApiUrls.BASE_URL}/users/me`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  },
  updateProfile: async (
    token: string,
    payload: UpdateUserProfile,
    id: number
  ) => {
    const resp = await axios.put<UserProfileType>(
      `${constants.ApiUrls.BASE_URL}/users/${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  },
  getServiceHistoryForCar: async (token: string, id: number, query: string) => {
    const resp = await axios.get<ServiceHistoryRequestType>(
      `${constants.ApiUrls.BASE_URL}/car-service-history/${id}${
        query?.length ? query : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  },
  getScheduledMaintenance: async (token: string, id: number) => {
    const resp = await axios.get<ScheduledMaintenanceRequestType>(
      `${constants.ApiUrls.BASE_URL}/scheduled-maintenance/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  },
  getInvoiceLink: async (token: string, id: number) => {
    return axios
      .get<{ url: string }>(
        `${constants.ApiUrls.BASE_URL}/load-customer-invoice/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  sendReview: async (token: string, payload: ReviewRequestType, id: number) => {
    return axios
      .post<ReviewResponseType>(
        `${constants.ApiUrls.BASE_URL}/reviews/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getOrder: async (token: string, id: number) => {
    return axios
      .get<CustomerOrderType>(
        `${constants.ApiUrls.BASE_URL}/customer-orders/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  sendCard: async (token: string, payload: any) => {
    return axios
      .post<any>(`${constants.ApiUrls.BASE_URL}/card`,
       payload, 
       {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  deleteCard: async (token: string, payload: string) => {
    return axios
      .delete<CreditCardType>(`${constants.ApiUrls.BASE_URL}/card/${payload}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  updateCard: async (
    token: string,
    payload: { exp_month: number; exp_year: number }
  ) => {
    return axios
      .put<CreditCardType>(`${constants.ApiUrls.BASE_URL}/card`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  getCancelStatus: async (token: string, payload: number) => {
    return axios
      .get<CheckCancelRescheduleOrderResponse>(
        `${constants.ApiUrls.BASE_URL}/orders/try-cancel/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getRescheduleStatus: async (token: string, payload: number) => {
    const response = await axios.get<CheckCancelRescheduleOrderResponse>(
      `${constants.ApiUrls.BASE_URL}/orders/try-reschedule/${payload}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  cancelOrder: async (token: string, id: number, data: CancelOrderRequest) => {
    const response: any = await axios.post<any>(
      `${constants.ApiUrls.BASE_URL}/orders/cancel/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  rescheduleOrder: async (
    token: string,
    id: number,
    data: RescheduleRequest
  ) => {
    const response = await axios.post<any>(
      `${constants.ApiUrls.BASE_URL}/orders/reschedule/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  getDeclinedOrder: async (token: string, id: number) => {
    return axios
      .get<CustomerOrderType>(`${constants.ApiUrls.BASE_URL}/declined/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  getCarDeliveries: async () => {
    return axios
      .get<CarDeliveriesType[]>(`${constants.ApiUrls.BASE_URL}/car-deliveries`)
      .then((resp) => resp.data);
  },
  getAvailableDays: async (
    token: string,
    params: GetAvailableDaysParamsType
  ) => {
    return axios
      .get<CarDeliveriesType[]>(
        `${constants.ApiUrls.BASE_URL}/available-days`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      )
      .then((resp) => resp.data);
  },
  createTabbySession: async (token: string, payload: any) => {   //TabbySessionRequestType
    const data = payload;
    data['emailSuccess'] = `${constants.ApiUrls.WEB_URL}/createorder/congratulation`;
    data['emailCancel'] = `${constants.ApiUrls.WEB_URL}/createorder/payment`;
    data['emailFailure'] = `${constants.ApiUrls.WEB_URL}/createorder/payment`;
    return axios
      .post<any>(                            //TabbySessionResponseType
        `${constants.ApiUrls.BASE_URL}/tabby-session`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  reloadOrder: async (token: string, payload: any) => {
    const data = payload;
    data['emailSuccess'] = `${constants.ApiUrls.WEB_URL}/createorder/congratulation`;
    data['emailCancel'] = `${constants.ApiUrls.WEB_URL}/createorder/payment`;
    data['emailFailure'] = `${constants.ApiUrls.WEB_URL}/createorder/payment`;
    return axios
      .post<any>(                           
        `${constants.ApiUrls.BASE_URL}/order-reload`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
};

