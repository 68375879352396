import { Tag } from 'antd';
import cn from 'classnames';
import { UserType } from '../../../../Types/authTypes';
import classes from './style.module.scss';

type TopBarPropsType = {
  user: UserType;
};

export const TopBar: React.FC<TopBarPropsType> = ({ user }) => {
  return (
    <div className={cn(classes.container, classes.boxShadow)}>
      <div className={classes.fullName}>{user.username}</div>
      <div className={classes.personInfoContainer}>
        <div className={classes.email}>{user.email}</div>
        <div className={classes.phone}>{user.phone && '+971'}{user.phone}</div>
        <div className={classes.tagDotsWrapper}>
          <Tag color={user.blocked ? 'red' : 'green'}>
            {user.blocked ? 'Blocked' : 'Active'}
          </Tag>
          {/* <div className={classes.verticalDots}>&#8942;</div> */}
        </div>
      </div>
    </div>
  );
};
