import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from '../../Components/MainLayout';
import { adminActions } from '../../Globals/Admin/actions';
import { RootStateType } from '../../Stores';
import { TableComponent } from '../../Components/Table/Table';
import { UserType } from '../../Types/authTypes';
import { TableConfigItem } from '../../Types/appTypes';
import moment from 'moment';
import { useHistory } from 'react-router';

const tableConfig: Array<TableConfigItem> = [
  { title: 'Full Name', key: 'username', isSort: true, isClickable: true },
  { title: 'Email', key: 'email', isSort: false },
  { title: 'Phone number', key: 'phone', isSort: false, align: 'right' },
  {
    title: 'No of orders',
    key: 'num_of_orders',
    isSort: true,
    align: 'right',
  },
  { title: 'Member since', key: 'created_at', isSort: true },
  { title: 'Last order date', key: 'last_order', isSort: true },
  { title: 'Total amount', key: 'total_price', isSort: true },
  { title: 'Status', key: 'status', isSort: true },
];

export const AdminClients = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(20);
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([]);

  const users = useSelector((state: RootStateType) => state.admin.usersList);

  const usersWithoutDeleted = users.rows.filter((user) => !user.deleted);

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const redirectTo = (id: number) => {
    dispatch(adminActions.getUser(id));
    history.push(`/admin/clients/${id}`, );
  };

  const onSort = (fieldKey: string, order?: 'ascend' | 'descend') => {
    if (fieldKey === 'status') {
      if (order === 'ascend') {
        setSortValue([{ status: 'ASC' }]);
      } else {
        setSortValue([{ status: 'ASC' }]);
      }
    } else {
      setSortValue(
        order ? [{ [fieldKey]: order === 'ascend' ? 'ASC' : 'DESC' }] : []
      );
    }
  };

  const getActions = (
    status: 'blocked' | 'active' | 'inactive'
  ): Array<'Approve' | 'Reject' | 'Delete' | 'Block' | 'Unblock'> => {
    switch (status) {
      case 'active':
        return ['Block', 'Delete'];
      case 'blocked':
        return ['Unblock', 'Delete'];
      case 'inactive':
        return [];
      default:
        return [];
    }
  };

  const createDataList = () => {
    if (usersWithoutDeleted.length) {
      return usersWithoutDeleted.map((user: UserType) => {
        return {
          key: user.id,
          username: user.username,
          phone: user.phone,
          email: user.email,
          num_of_orders: user.num_of_orders,
          created_at: user.created_at && moment(user.created_at).format('MMM DD, YYYY'),
          last_order:
            user.last_order && moment(user.last_order).format('MMM DD, YYYY'),
          total_price: user.total_price,
          status: user.status,
          actions: getActions(user.status),
        };
      });
    } else {
      return [];
    }
  };

  const onAction = (data: { id: number; action: string }) => {
    if (data.action === 'Block' || data.action === 'Unblock') {
      dispatch(
        adminActions.blockUnblockUser(
          { blocked: data.action === 'Block', type: 'customer' },
          data.id
        )
      );
    } else if (data.action === 'Delete') {
      dispatch(adminActions.deleteCustomer(data.id));
    }
  };

  useEffect(() => {
    dispatch(
      adminActions.getUsersList({
        page: { page: currentPage, limit: limitOnPage },
        sort: sortValue,
      })
    );
  }, [currentPage, limitOnPage, dispatch, sortValue]);

  return (
    <MainLayout headerTitle="Clients">
      <div>
        <TableComponent
          dataForTableList={createDataList()}
          tableConfig={tableConfig}
          defaultPageSize={limitOnPage}
          totalItems={users.count}
          onChangePage={onChangePage}
          goTo={redirectTo}
          onSort={onSort}
          onAction={onAction}
        />
      </div>
    </MainLayout>
  );
};
