import { Tabs } from 'antd';
import ReactGA from 'react-ga';
import { useEffect, useState } from 'react';
import { MainLayout } from '../../Components/MainLayout';
import { AccountInfo } from './AccountInfo';
import { PaymentPreferences } from './PaymentPreferences';
import classes from './style.module.scss';

const { TabPane } = Tabs;

export const UserSettings = () => {

  const [activeTab, setActiveTab] = useState('1');
  return (
    <MainLayout headerTitle="Mister Smith" isHorizontalPadding={false}>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={setActiveTab}
        style={{ border: 'none', position: 'relative' }}
      >
        <TabPane tab="Account info" key="1">
          <AccountInfo />
        </TabPane>
        <TabPane tab="Payment preferences" key="2">
          <PaymentPreferences />
        </TabPane>
      </Tabs>
    </MainLayout>
  );
};
