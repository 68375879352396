import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import TabbyIcon from '../../../../../../Assets/tabby-button-logo.svg'
import { RootStateType } from '../../../../../../Stores';
import moment from 'moment';

import { message, Modal } from 'antd';

import classes from './style.module.scss';
import { appActions } from '../../../../../../Globals/App/actions';
import { userActions } from '../../../../../../Globals/User/actions';
import useConfirm from '../../../../../../Hooks/useConfirm';
import { SavedCardElement } from '../../../../../../Components/SavedCardElement';
import { ButtonComponent } from '../../../../../../Components/Button';
import EditIcon from '../../../../../../Assets/orderIcon/editIcon.svg'

import * as constants from '../../../../../../Utils/Api/constants';
import { authActions } from '../../../../../../Globals/Auth/actions';
import { CreateOrderRequestType } from '../../../../../../Types/authTypes';
import { SavedDataObjectType } from '../../../../../../Types/userTypes';

//import { TabbyCard } from '../../../../../../Components/Tabby/TabbyCard'
//import { TabbyPromo } from '../../../../../../Components/Tabby/TabbyPromo'

type PayStepPropsType = {
  goToNextStep: (value: FormType) => void;
  goToPrevStep: () => void;
  clientSecret: string | null;
  tabbyUrl: string | null;
  orderSavedForConfirm: string | null;
};

export type FormType = {
  email: string;
  name: string;
  phone: string;
  password: string;
  payment_id: string;
  save_card: boolean;
};

export const PayForm: React.FC<PayStepPropsType> = ({
  goToNextStep,
  goToPrevStep,
  clientSecret,
  tabbyUrl,
  orderSavedForConfirm,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [elements, setElements] = useState<any>();
  const [isShowPayForm, setIsShowPayForm] = useState<boolean>(false);
  const [isShowErrorWindow, setIsShowErrorWindow] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [isTabby, setIsTabby] = useState<boolean>(false);
  const ref: any = useRef();
  const history = useHistory();
  //const dispatch = useDispatch();
  //const { confirm } = useConfirm();
  const payment = useSelector((state: RootStateType) => state.user.payment);

  const token = useSelector((state: RootStateType) => state.auth.user?.jwt);

  const dataLayer = window.dataLayer || []
  
  const user = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  const summaryPrice = useSelector(
    (state: RootStateType) => state.user.finalSummary
  );

  const selectedOtherServicesId = useSelector(
    (state: RootStateType) => state.user.selectedOtherServicesId
  );
  const otherServices = useSelector(
    (state: RootStateType) => state.user.services?.additional
  );
  const mainServices = useSelector(
    (state: RootStateType) => state.user.services?.log_book
  );
  const formValues = useSelector(
    (state: RootStateType) => state.user.formValues
  );
  const isBasic = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );
  const parts = useSelector(
    (state: RootStateType) => state.user.services?.check
  );

  const selectedOtherServices =
    otherServices?.filter((service) =>
      selectedOtherServicesId.find((id) => service.id === id)
    ) || [];

  const appointment = useSelector(
    (state: RootStateType) => state.user.appointment
  );

  const finalSummary = useSelector(
    (state: RootStateType) => state.user.finalSummary
  );

  const deliveryCar = useSelector(
    (state: RootStateType) => state.user.deliveryCar
  );

  const car_deliveries = useSelector(
    (state: RootStateType) => state.user.car_deliveries
  );

  const selectedWorkshop = useSelector(
    (state: RootStateType) => state.user.selectedWorkshop
  );

  const selectedDate = useSelector(
    (state: RootStateType) => state.user.selectedDate
  );

  const selectedCar = useSelector(
    (state: RootStateType) => state.user.selectedCar
  );

  const addressPoint = useSelector(
    (state: RootStateType) => state.user.addressPoint!
  );

  const customer_note = useSelector(
    (state: RootStateType) => state.user.customer_note
  );

  const location = useSelector((state: RootStateType) => state.user.location);

  const fetchReloadOrderInfoFunction = async (orderDataInfo: any) => {
    dispatch(appActions.openLoader());
    const data = orderDataInfo;
    data['emailSuccess'] = `${constants.ApiUrls.WEB_URL}/createorder/congratulation`;
    data['emailCancel'] = `${constants.ApiUrls.WEB_URL}/createorder/payment`;
    data['emailFailure'] = `${constants.ApiUrls.WEB_URL}/createorder/payment`;
    
    const order = await fetch(`${constants.ApiUrls.BASE_URL}/order-reload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const order_loaded = await order.json();
    dispatch(appActions.closeLoader());
    return order_loaded;
  };

  const createDataObject = useCallback(() => {
    return {
      selectedOtherServicesId,
      otherServices,
      formValues,
      isBasic,
      parts,
      selectedOtherServices,
      appointment,
      deliveryCar,
      selectedWorkshop,
      selectedDate,
      selectedCar,
      location,
      addressPoint,
      customer_note,
      car_deliveries,
      finalSummary
    };
  }, [
    appointment,
    formValues,
    isBasic,
    otherServices,
    parts,
    selectedDate,
    selectedCar,
    deliveryCar,
    selectedOtherServicesId,
    selectedWorkshop,
    car_deliveries,
    finalSummary
  ]);

  const dataObject: SavedDataObjectType = createDataObject();

  const togglePayForm = () => {
    if(isShowPayForm){
      setIsShowPayForm(false)
    }
    else{
      setIsShowPayForm(true)
    }
  }

  useEffect(() => {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
          e.returnValue = '';
      }
      return '';
      };
    return () => {
      window.onbeforeunload = null;
    }
  }, []);

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/createorder/payment",
      "page_type":"paymentStep",
      "event":"page_view"
    });
    //End
  }, []);

  useEffect(() => {
    if (ref.current) {
      const options = {
        clientSecret: clientSecret || undefined,
        // Fully customizable with appearance API.
        appearance: {
          colorPrimary: '#0570de',
          colorBackground: '#ffffff',
          colorText: '#30313d',
          colorDanger: '#df1b41',
          fontFamily: 'LatoRegular, sans-serif',
          borderRadius: '4px',
        },
        wallets: {
          applePay: 'auto',
          googlePay: 'auto',
        },
        locale: 'en' as StripeElementLocale,
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
      const elements = stripe?.elements(options);
      setElements(elements);

      // Create and mount the Payment Element
      const paymentElement = elements?.create('payment');
      paymentElement?.mount('#payment-element');
    }
  }, [isShowPayForm, clientSecret]);

  const tabbyPayment = async () =>{
    window.onbeforeunload = null;
    const order = await fetchReloadOrderInfoFunction({id_order: orderSavedForConfirm})
    dispatch(authActions.setSavedSummaryData(dataObject));
    dispatch(authActions.setOrderID(Number(orderSavedForConfirm)))
    order?.tabbyUrl && 
      window.location.assign(order?.tabbyUrl)

  }
  
  useEffect(() => {
    dispatch(authActions.setOrderID(null))
    // if (isPaymentStep == true) {
    //  goNext();
    // } 
  }, [dispatch]);

  // useEffect(()=>{
  //   if(isTabby) {
  //     const orderData = createRequestObject(true, '', null);
  //     dispatch(authActions.setOrderData(orderData));
  //     window.location.assign(tabbyUrl)
  //   }

  // },[isTabby])

  const warning = () => {
    Modal.warning({
      title: 'Payment failed',
      content: errorMessage,
      className: classes.modalWarning,
      afterClose: () => {setIsShowErrorWindow(false)}
    });
  };
  const onFinish = async () => {
    window.onbeforeunload = null;
    if (payment && orderSavedForConfirm && clientSecret && !isShowPayForm) {
      // stripe?.confirmCardPayment(clientSecret, { payment_method: payment.id, return_url: `${constants.ApiUrls.WEB_URL}/createorder/congratulation` } ).then(function(response) {
      //   if (response.error) {
      //     // Handle error here
      //   } else if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
      //     history.push('/createorder/congratulation')
      //   }
      // });
      stripe?.confirmCardPayment(clientSecret, {
        payment_method: payment.id,
        return_url: `${constants.ApiUrls.WEB_URL}/createorder/congratulation`
      })
      .then(function(result) {
        if (result?.error) {
          setErrorMessage(result.error.message)
          setIsShowErrorWindow(true)
          // Inform the customer that there was an error.
        }
        else{
          //GTM
          //Start

          dataLayer.push({
            "event": "step_6_payment_success",
            "transaction_id": `${orderSavedForConfirm}`,
            "total": `${summaryPrice}`,
            "tax": `${selectedWorkshop?.total_vat_price! - selectedWorkshop?.total_price!}`,
            "payment_type": 'Stripe',
            "user_id":`${user?.id}`,
            "email":`${user?.email}`,
            "phone":`+971${user?.phone}`
            });
          //End
          history.push('/createorder/congratulation')
        }
        // Handle result.error or result.paymentIntent
      });  
      // const res = await confirmOrder(payment.id);
      // if (!res?.error) {
      //   history.push('/createorder/congratulation');
      // }
    } else if (clientSecret && elements) {
      stripe?.confirmPayment({
        elements,
        confirmParams: {
          // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
          return_url: `${constants.ApiUrls.WEB_URL}/createorder/congratulation`,
        },
        redirect: "if_required"
      })
      .then(function(result) {
        if (result?.error) {
          setErrorMessage(result.error.message)
          setIsShowErrorWindow(true)
          // Inform the customer that there was an error.
        }
        else{
          //GTM
          //Start
          dataLayer.push({
            "event": "step_6_payment_success",
            "transaction_id": `${orderSavedForConfirm}`,
            "total": `${summaryPrice}`,
            "tax": `${selectedWorkshop?.total_vat_price! - selectedWorkshop?.total_price!}`,
            "payment_type": 'Stripe',
            "user_id":`${user?.id}`,
            "email":`${user?.email}`,
            "phone":`+971${user?.phone}`
            });
          //End
          history.push('/createorder/congratulation')
        }
      });
    }

  };

  return (
    <div>
      <div className={classes.paymentcontainer}>
        <h2>Payment details</h2>
        {/* <div className={classes.subtitle}>
          This is the last step to book your service
        </div> */}
        <div className={classes.withCardBook}>Book and pay with your credit card</div>
        {isShowErrorWindow ?
          warning()
          :
          null
        }
        {summaryPrice && (
            <div className={classes.subtitle}>
              Total Amount: AED {summaryPrice?.toLocaleString('en-US', {maximumFractionDigits:0})}
              {/* <div className={classes.price}>AED {selectedWorkshop?.labor_price?.toLocaleString('en-US', {maximumFractionDigits:0})}</div> */}
            </div>
          )}
        <div className={classes.warrantyOEM}>All parts are OEM with warranty</div>
        {!payment ? (
          <div ref={ref} id="payment-element"></div>
        ) : (
          <div>
          {!isShowPayForm ?
            <SavedCardElement payment={payment} />
            :
            <div ref={ref} id="payment-element"></div>
          }
          <div className={classes.paymentSaved} onClick={() => {togglePayForm()}}>
            <img src={EditIcon} alt="Change payment method" />
            <p>Change payment method</p>
          </div>
          </div>
        )}
      </div>
      {tabbyUrl && (
        <div className={classes.tabbyInfo} onClick={tabbyPayment}>
          <img src={TabbyIcon} alt="" />
          <p>Buy now - Pay later</p>
        </div>
      )}
      {/* <Row justify="end" gutter={[16, 16]}>
        <ButtonComponent type="cancel" title="Back" onClick={goToPrevStep} />
        <ButtonComponent
          type="save"
          title="Continue"
          onClick={onFinish}
          style={{ width: '172px' }}
        />
      </Row> */}
      <div className={classes.buttons}>
        <ButtonComponent
          style={{ width: '172px' }}
          title="Back"
          type="cancel"
          onClick={goToPrevStep}
        />
        <ButtonComponent
          style={{ width: '172px' }}
          title="Continue"
          type="save"
          onClick={onFinish}
        />
      </div>
    </div>
  );
};
