import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor } from './Stores/index';

import 'antd/dist/antd.min.css';
import './App.scss';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import GoogleAuthCallback from './Views/GoogleAuthCallback';
import { SignUpPage } from './Views/SignUp';
import { NotFound } from './Views/NotFound';

import { Car } from './Views/UserCar';
import { UserMyCars } from './Views/UserMyCars';
import { UserAppointments } from './Views/UserAppointments';
import { UserSettings } from './Views/UserSettings';
import { AddCar } from './Views/UserAddCar';

import { WorkshopJobs } from './Views/WorkshopJobs';
import { WorkshopAppointments } from './Views/WorkshopAppointments';
import { WorkshopFinance } from './Views/WorkshopFinance';
import { WorkShopSettings } from './Views/WorkShopSettings';

import { AdminClients } from './Views/AdminClients';
import { AdminServices } from './Views/AdminServices';
import { AdminWorkshops } from './Views/AdminWorkshops';
import { AdminInvoices } from './Views/AdminInvoices';
import { AdminOrders } from './Views/AdminOrders';
import { CreateOrder } from './Views/CreateOrder';
import { CongratulationPage } from './Views/Congratulations';
import { LandingPage } from './Views/LandingPage';
import { StartJob } from './Views/WorkshopStartJob';
import { AdminClientProfile } from './Views/AdminClientProfile';
import { CommunityPage } from './Views/LandingPage/Views/CommunityView';
import { HelpPage } from './Views/LandingPage/Views/HelpView';
import { PrivacyPolicy } from './Views/LandingPage/Views/PrivacyPolicy';
import { TermsConditions } from './Views/LandingPage/Views/TermsConditions';
import { AdminNotifications } from './Views/AdminNotifications';
import { AdminReviews } from './Views/AdminReviews';
import { ModalComponent } from './Components/Modal';
import useConfirm from './Hooks/useConfirm';
import { AboutUs } from './Views/LandingPage/Views/AboutUs';
import PageTracking from './Components/PageTracking/PageTracking';
import PrivateRoute from './Components/AuthHOC';

function App() {
  const { onConfirm, onCancel, confirmState } = useConfirm();
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <PersistGate loading={null} persistor={persistor}>
        <Switch>
          <Route path={'/'} exact component={LandingPage} />
          <Route path={'/community'} component={CommunityPage} />
          <Route path={'/faq'} component={HelpPage} />
          <Route path={'/privacy-policy'} component={PrivacyPolicy} />
          <Route path={'/terms-conditions'} component={TermsConditions} />
          <Route path={'/about-carlogik'} component={AboutUs} />
          <Route path={'/auth/callback'} component={GoogleAuthCallback} />
          <Route path={'/auth'} component={SignUpPage} />
          <Route path={'/auth/forgot-password'} component={SignUpPage} />
          <PrivateRoute
            path="/user/my-cars/:id"
            render={({ match }) => <Car carId={+match?.params.id!} />}
          />
          <PrivateRoute path="/user/my-cars" component={UserMyCars} />
          <PrivateRoute path="/user/add-car" component={AddCar} />
          <PrivateRoute
            path={'/user/appointments/:id'}
            render={({match}) => <StartJob orderId={+match.params.id!} />}
          />
          <PrivateRoute
            path="/user/appointments"
            component={UserAppointments}
          />
          <PrivateRoute path="/user/account" component={UserSettings} />
          <PrivateRoute
            path={'/user/get-quote'}
            component={CreateOrder}
          />

          <PrivateRoute
            path={'/workshop/jobs/:id'}
            render={({ match }) => <StartJob orderId={+match.params.id!} />}
          />
          <PrivateRoute
            path={'/workshop/jobs/:id'}
            render={({ match }) => <StartJob orderId={+match.params.id!} />}
          />
          <PrivateRoute path={'/workshop/jobs'} component={WorkshopJobs} />
          <PrivateRoute
            path={'/workshop/appointments'}
            component={WorkshopAppointments}
          />
          <PrivateRoute
            path={'/workshop/finance'}
            component={WorkshopFinance}
          />
          <PrivateRoute
            path={'/workshop/settings'}
            component={WorkShopSettings}
          />

          <PrivateRoute
            path={'/admin/workshops/workshop/settings'}
            component={WorkShopSettings}
          />
          <PrivateRoute path={'/admin/workshops'} component={AdminWorkshops} />
          <PrivateRoute path={'/admin/services'} component={AdminServices} />
          <PrivateRoute
            path={'/admin/orders/:id'}
            render={({ match }) => <StartJob orderId={+match.params.id!} />}
          />
          <PrivateRoute path={'/admin/orders'} component={AdminOrders} />
          <PrivateRoute
            path={'/admin/clients/:id'}
            render={({ match }) => (
              <AdminClientProfile userId={+match.params.id!} />
            )}
          />
          <PrivateRoute
            path={'/admin/clients'}
            exact
            component={AdminClients}
          />
          <PrivateRoute
            path={'/admin/notifications'}
            exact
            component={AdminNotifications}
          />
          {/* <PrivateRoute
            path={'/admin/reviews'}
            exact
            component={AdminReviews}
          /> */}

          <PrivateRoute path={'/admin/invoices'} component={AdminInvoices} />
          <Route
            path={'/createorder/congratulation'}
            component={CongratulationPage}
          />
          <Route
            path={'/createorder/reschedule-order'}
            component={CongratulationPage}
          />
          <Route
            path={'/createorder/cancel-order'}
            component={CongratulationPage}
          />
        {/* <Route
            path={'/user/get-quote/:id'}
            render={({ match }) => <CreateOrder orderId={+match.params.id} />}
          />  */}
          <Route path={'/createorder'} component={CreateOrder} />
          <Route path="/404" render={() => <NotFound />} />
          <Redirect from="*" to="/404" />
        </Switch>
        <ModalComponent
          isModal={confirmState.isShow}
          toggleModal={onCancel}
          isAlert={true}
          buttonConfig={[
            { type: 'cancel', title: confirmState.buttonText[0] },
            { type: 'save', title: confirmState.buttonText[1] },
          ]}
          onSubmit={onConfirm}
        >
          <div style={classes.alertBody}>
            <ExclamationCircleOutlined
              style={{ fontSize: '22px', color: '#FAAD14' }}
            />
            <div>{confirmState.text}</div>
          </div>
        </ModalComponent>
        <PageTracking />
      </PersistGate>
    </BrowserRouter>
  );
}

const classes = {
  alertBody: {
    paddingTop: '32px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    display: 'flex',
    gap: '16px',
  },
};

export default App;
