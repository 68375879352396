import React from 'react';
import classes from './style.module.scss';
import './styles.scss';
import { OfferedServiceType } from '../../../../../../Types/authTypes';

type OptionsListPropsType = {
  title: string;
  options: Array<OfferedServiceType>;
};

export const OptionsList: React.FC<OptionsListPropsType> = ({
  options,
  title,
}) => {
  return (
    <div className={classes.container}>
        {options.map((option) => {
          return (
              <div className={classes.optionItem} key={option.id}>
                <span className={classes.optionTitle}>{option.title}</span>
              </div>
          );
        })}
    </div>
  );
};
