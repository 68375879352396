export const staticReviews = [
  {
    id: 1,
    description:
      'Needed brakes & discs replaced at very short notice & took less than 2 hours to complete at less than half the price of main dealer.',
    rateCount: 4,
    workshopName: 'Intensive Car Care (ICC)',
    userName: 'Patrick Boughoson',
  },
  {
    id: 2,
    description:
      'It was a wonderful experience with Jamal Garage, they did my work on time and was absolutely flawless.',
    rateCount: 4,
    workshopName: 'Jamal Garage',
    userName: 'Rahat M.',
  },
  {
    id: 3,
    description:
      'Royal Swiss Auto is great. Honest people fair prices and great service. Highly recommend.',
    rateCount: 5,
    workshopName: 'Royal Swiss Auto',
    userName: 'Caroline Nandi',
  },
  {
    id: 4,
    description:
      "Excellent service. Cayenne mechanical work and some minor body repairs. I accept and admit I am very fussy.",
    rateCount: 4.5,
    workshopName: 'OFJ Automotive',
    userName: 'Wozza Fan',
  },
  {
    id: 5,
    description:
      'Highly recommended garage, they are very good and realiable and trustworthy people.',
    rateCount: 4,
    workshopName: 'Intensive Car Care (ICC)',
    userName: 'Emad M.',
  },
  {
    id: 6,
    description:
      'Special thanks to Mr. Loay for helping me out with my car. So satisfied with their services. They even gave me a discount on my final invoice.',
    rateCount: 4.5,
    workshopName: 'The Gearbox',
    userName: 'Hamood D.',
  },
  {
    id: 7,
    description:
      'I went to Jamal Garage after seeing reviews on Google.. Trust me, the reviews were so accurate.. They were very professional.',
    rateCount: 4,
    workshopName: 'Jamal Garage',
    userName: 'Akila Hassan',
  },
  {
    id: 8,
    description:
      'I was greeted with a big smile and very friendly manner. The receptionist offered me a bottle of water and drove my car into the shop. Highly recommended!',
    rateCount: 5,
    workshopName: 'DME Auto Repairing',
    userName: 'Saif Qries',
  },
  {
    id: 9,
    description:
      'A great service like I have never experienced before with any workshop in UAE even better than the agency... ICC professional car care services',
    rateCount: 5,
    workshopName: 'Intensive Car Care (ICC)',
    userName: 'Roger Mitri',
  },
  {
    id: 10,
    description:
      'The quality of service from the gearbox team is the best across Dubai. I have used they services many times from basic servicing to general checks.',
    rateCount: 4.5,
    workshopName: 'The Gearbox',
    userName: 'Richard Luis',
  },
  {
    id: 11,
    description:
      "I do not often leave a review unless it is either very bad or very good. In this case I am happy to give a 5 star review for OFJ Automotive. ",
    rateCount: 5,
    workshopName: 'OFJ Automotive',
    userName: 'Geof Leo',
  },
  {
    id: 12,
    description:
      'Quick, Thorough and fast service. Honest and price. Solution oriented and polite. Also special thanks to Swiss Team superb service.',
    rateCount: 5,
    workshopName: 'Royal Swiss Auto',
    userName: 'Adarsh P.',
  },
  {
    id: 13,
    description:
      'I went there for oil change twice, helpful and trained staff. Checked my car properly for any fault. I recommend this place',
    rateCount: 5,
    workshopName: 'DME Auto Repairing',
    userName: 'Umar Khan',
  },
  {
    id: 14,
    description:
      'Mini cooper electrical problem. Immediate diagnosis, identified the exact problem, and fixed in a timely and professional manner.',
    rateCount: 4,
    workshopName: 'Jamal Garage',
    userName: 'Kavya N.',
  },
  {
    id: 15,
    description:
      'Good experience. The customer service representative was very kind and attentive and gave regular updates including videos of the work being done.',
    rateCount: 5,
    workshopName: 'The Gearbox',
    userName: 'Veselin Tilev',
  },
  {
    id: 16,
    description:
      "By far the best garage I have ever come across in the City. The professionalism, price, service and most importantly timely updates left me in awe.",
    rateCount: 4.5,
    workshopName: 'OFJ Automotive',
    userName: 'Jaffar Bin',
  },
  {
    id: 17,
    description:
      'I took my vehicle in when it seized up. They took a gander at it in a nice time period and provided me a good cost estimate for a whole modified motor trade.',
    rateCount: 5,
    workshopName: 'Royal Swiss Auto',
    userName: 'Joseph Summer',
  },
  {
    id: 18,
    description:
      'Someone hit my car in a parking lot... 100% would recommend. Great work, friendly and they were so helpful. Communicated with me the whole time.',
    rateCount: 4,
    workshopName: 'DME Auto Repairing',
    userName: 'Rachael M.',
  },
];
