import React, { useEffect } from 'react';
import { Form, Input, Row } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import classes from './style.module.scss';
import { ButtonComponent } from '../../../../../Components/Button';

type PaymentPreferencesPropsType = {
  onSubmit: (payload: {
    password: string;
    newPassword: string;
    confirmPassword: string;
  }) => void;
  onCancel: () => void,
  isResetMode?: boolean
};

export const ManagePasswordForm: React.FC<PaymentPreferencesPropsType> = ({
  onSubmit,
  onCancel,
  isResetMode = false
}) => {
  const [form] = Form.useForm();

  const onChange = (value: any) => {
    
  };

  const onFinish = (values: {
    password: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    onSubmit(values);
  };

  return (
    <Form
      form={form}
      name="manage_password"
      onFinish={onFinish}
      size="large"
      onChange={onChange}
      className={classes.form}
    >
      {!isResetMode ? <div className={classes.header}>Manage Password</div> : 
      <div className={classes.header}>Enter New Password</div>
      }
      {!isResetMode && <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your old password' }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          className={classes.input}
          placeholder="Old password"
        />
      </Form.Item>}
      <Form.Item
        name="newPassword"
        rules={[{ required: true, message: 'Please input new password' }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          className={classes.input}
          placeholder="New Password"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          className={classes.input}
          placeholder="Confirm password"
        />
      </Form.Item>

      <Form.Item>
        <Row justify="end">
          <ButtonComponent
            type="cancel"
            title="Cancel"
            onClick={onCancel}
          ></ButtonComponent>
          <ButtonComponent type="save" title="Confirm" />
        </Row>
      </Form.Item>
    </Form>
  );
};
