import React, { useEffect, useState } from 'react';
import { Form, Row, InputNumber } from 'antd';
import classes from './style.module.scss';
import { ButtonComponent } from '../../../Components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { workshopActions } from '../../../Globals/Workshop/actions';
import { RootStateType } from '../../../Stores';

type ServicesPropsType = {
  wsId?: number;
};

export const Services: React.FC<ServicesPropsType> = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const prices = useSelector(
    (state: RootStateType) => state.workshop.workshopPrices
  );
  
  const onFinish = (values: any) => {
    dispatch(workshopActions.updateWorkshopPrices(values, priceId));
    //dispatch(workshopActions.getWorkshopPrices(priceId)
    form.setFieldsValue({
      price_per_hour: prices?.price_per_hour,
      parts_margin: prices?.parts_margin,
      multiplier: prices?.multiplier,
      car_repair: prices?.car_repair,
      commission: prices?.commission,
    });
    setIsDisabled(true);
  };

  const priceId = useSelector(
    (state: RootStateType) => state.workshop.workshopData?.price
  );

  const commission = useSelector(
    (state: RootStateType) => state.workshop.workshopPrices?.workshop.commission
  );

  const userRole = useSelector(
    (state: RootStateType) => state.auth.user?.user.role.type
  );

  const onCancel = () => {
    form.setFieldsValue({
      price_per_hour: prices?.price_per_hour,
      parts_margin: prices?.parts_margin,
      multiplier: prices?.multiplier,
      car_repair: prices?.car_repair,
      commission: prices?.commission,
    });
    // console.log(123)
    // dispatch(workshopActions.getWorkshopPrices(priceId))
    setIsDisabled(true);
  };

  const onChange = (e: any) => {
    setIsDisabled(false);
  };

  useEffect(() => {
    dispatch(workshopActions.getWorkshopPrices(priceId));
  }, [dispatch, priceId]);

  useEffect(() => {
    form.setFieldsValue({
      price_per_hour: prices?.price_per_hour,
      parts_margin: prices?.parts_margin,
      multiplier: prices?.multiplier,
      car_repair: prices?.car_repair,
      commission: prices?.commission,
    });
    //dispatch(workshopActions.getWorkshopPrices(priceId))
  }, [prices, form, commission]);

  return (
    <>
      <div className={classes.labelSpan}>At workshop service only</div>
      <Form
        form={form}
        name="work-account"
        onFinish={onFinish}
        onValuesChange={onChange}
      >
        <div className={classes.topFormWrapper}>
          <div className={classes.inputContainer}>
            <span className={classes.labelText}>Labour price per hour</span>
            <Form.Item
              label=""
              name="price_per_hour"
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNumber className={classes.input} placeholder="AED 100" />
            </Form.Item>
            <div className={classes.labelSuffixText}>AED</div>
          </div>
          <div className={classes.inputContainer}>
            <span className={classes.labelText}>
              Parts margin
              <span className={classes.tooltipText}>&#x24D8;:</span>
              <span className={[classes.tooltip, classes.hide].join(' ')}>
                Optional, additional charge on top of your cost of Labor
              </span>
            </span>
            <Form.Item
              label=""
              name="parts_margin"
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNumber
                min={0}
                max={100}
                className={classes.input}
                placeholder="10%"
              />
            </Form.Item>
            <div className={classes.labelSuffixText}>%</div>
          </div>
          <div className={classes.inputContainer}>
            <span className={classes.labelText}>
              Multiplier
              <span className={classes.tooltipText}>&#x24D8;:</span>
              <span className={[classes.tooltip, classes.hide].join(' ')}>
                Optional, additional charge on top of your cost of Labor
              </span>
            </span>
            <Form.Item
              label=""
              name="multiplier"
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNumber
                min={-100}
                max={100}
                className={classes.input}
                placeholder="10%"
              />
            </Form.Item>
            <div className={classes.labelSuffixText}>%</div>
          </div>
          {userRole === 'authenticated_workshop' && (
            <div>
              <div className={classes.inputContainer}>
                <span className={classes.labelText}>
                  Car repair price
                  <span className={classes.tooltipText}>&#x24D8;:</span>
                  <span className={[classes.tooltip2, classes.hide].join(' ')}>
                    To change the price, contact the administrator
                  </span>
                </span>
                <Form.Item
                  label=""
                  validateTrigger={['onChange', 'onBlur']}
                  name="car_repair"
                >
                  <InputNumber
                    disabled={true}
                    className={classes.input}
                    placeholder="AED 100"
                  />
                </Form.Item>
                <div className={classes.labelSuffixText}>AED</div>
              </div>
            </div>
          )}
          {userRole === 'authenticated_admin' && (
            <div>
              <div className={classes.inputContainer}>
              <span className={classes.labelText}>
                Car repair price
                <span className={classes.tooltipText}>&#x24D8;:</span>
                <span className={[classes.tooltip, classes.hide].join(' ')}>
                  Optional, additional charge on top of your cost of Labor 
                </span>
              </span>
              <Form.Item
                label=""
                validateTrigger={['onChange', 'onBlur']}
                name="car_repair"
                rules={[
                  { required: true, message: 'required' },
                  {
                    validator(_, value) {
                      if (value ?? 1 > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Car repair price can't be less than zero")
                      );
                    },
                  },
                ]}
              >
                <InputNumber
                  className={classes.input}
                  placeholder="AED 100"
                />
              </Form.Item>
              <div className={classes.labelSuffixText}>AED</div>
            </div>

            <div className={classes.inputContainer}>
            <span className={classes.labelText}>
              CL Commission
              <span className={classes.tooltipText}>&#x24D8;:</span>
              <span className={[classes.tooltip, classes.hide].join(' ')}>
                Optional, additional charge on top of your cost of Labor
              </span>
            </span>
            <Form.Item
              label=""
              validateTrigger={['onChange', 'onBlur']}
              name="commission"
              rules={[
                { required: true, message: 'required' },
                // {
                //   validator(_, value) {
                //     if (value ?? 1 > 0) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject(
                //       new Error("commission can't be less than zero")
                //     );
                //   },
                // },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                className={classes.input}
                placeholder="10%"
              />
            </Form.Item>
            <div className={classes.labelSuffixText}>%</div>
          </div>
            </div>
          )}
          <Form.Item>
            <Row
              style={{
                gap: 14,
              }}
            >
              <ButtonComponent
                type="cancel"
                title="Cancel"
                onClick={() => onCancel()}
              />
              <ButtonComponent
                type="save"
                title="Save"
                disabled={isDisabled}
                breakpointOff
                style={{ marginLeft: 0 }}
              />
            </Row>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
