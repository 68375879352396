import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../Globals/Auth/actions';
import { signUpResponseType } from '../../Types/authTypes';
import { RootStateType } from '../../Stores';
import { ApiUrls } from '../../Utils/Api/constants';



const URL = ApiUrls.BASE_URL

function GoogleAuthCallback() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const orderData = useSelector((state: RootStateType) => state.auth.orderData);
  const dataLayer = window.dataLayer || [] 
  
  useEffect(() => {
    if (!location) {
      return;
    }
    const { search } = location;
    axios({
      method: 'GET',
      url: `${URL}/auth/google/callback${search}`,
    })
      .then((res) => res.data)
      .then((res: signUpResponseType) =>
        {dispatch(authActions.setProfileData(res))
          console.log(res)
          dataLayer.push({
            "event": `${res.existing ? 'login' : 'signup'}`,
            "user_id": `${res.user.id}`,
            "email": `${res.user.email}`,
          });
        }
      )
      .then(() => {
        if (orderData) {
          history.push('/user/get-quote');
        } else {
          history.push('/user/my-cars');
        }
      }).catch((err) => history.push('/auth/customer/signup', err));
  }, [dispatch, history, location, orderData]);

  return (
    <div>
      Google auth Page
      {/* {auth && (
        <>
          <div>Jwt: {auth.jwt}</div>
          <div>User Id: {auth.user.id}</div>
          <div>Provider: {auth.user.provider}</div>
        </>
      )} */}
    </div>
  );
}

export default GoogleAuthCallback;
