import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { MainLayout } from '../../Components/MainLayout';
import classes from './style.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateType } from '../../Stores';
import { useStripe } from '@stripe/react-stripe-js';
import { userActions } from '../../Globals/User/actions';
import { useLocation, useHistory } from 'react-router-dom';
import { Button } from 'antd';
import Logo from '../../Assets/congat-logo.svg';
import { authActions } from '../../Globals/Auth/actions';
//import { ButtonComponent } from '../../Components/Button';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const CongratulationPage = () => {
  const client_secret_from_state = useSelector(
    (state: RootStateType) => state.user.client_secret
  );
  const savedSummaryData = useSelector(
    (state: RootStateType) => state.auth.summaryData
  );
  const savedOrderNumber = useSelector(
    (state: RootStateType) => state.auth.orderID
  );
  const user = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  let query = useQuery();
  const dispatch = useDispatch();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [done, setDone] = useState(false);
  const stripe = useStripe();
  const history = useHistory();
  const dataLayer = window.dataLayer || []

  useEffect(() => {
    const client_secret = query.get('setup_intent_client_secret');
    const type = query.get('type');
    if (
      client_secret_from_state &&
      (type === 'cancel' || type === 'reshedule')
    ) {
      stripe?.retrievePaymentIntent(client_secret_from_state).then((res) => {
        if (res.paymentIntent?.status === 'succeeded') {
          // Handle successful payment here
          setSuccess(true);
        } else {
          // Handle unsuccessful, processing, or canceled payments and API errors here
          setSuccess(false);
        }
        setDone(true);
        // dispatch(userActions.resetRedirects());
      });
    } else if (client_secret) {
      stripe?.retrieveSetupIntent(client_secret).then(({ setupIntent }) => {
        if (setupIntent?.status === 'succeeded') {
          // Handle successful payment here
          setSuccess(true);
        } else {
          // Handle unsuccessful, processing, or canceled payments and API errors here
        }
        setDone(true);
        // dispatch(userActions.resetRedirects());
      });
    }
    {
      setSuccess(true);
      setDone(true);
    }
  }, [client_secret_from_state, dispatch, query, stripe]);

  useEffect(() => {
    if (location.pathname === '/createorder/congratulation') {
      if(savedOrderNumber){
        dataLayer.push({
          "event": "step_6_payment_success",
          "transaction_id": `${savedOrderNumber}`,
          "total": `${savedSummaryData?.finalSummary}`,
          "tax": `${savedSummaryData?.selectedWorkshop?.total_vat_price! - savedSummaryData?.selectedWorkshop?.total_price!}`,
          "payment_type": 'Tabby',
          "user_id":`${user?.id}`,
          "email":`${user?.email}`,
          "phone":`+971${user?.phone}`
        });
        dispatch(authActions.setOrderID(null))
      }
      dispatch(authActions.resetOrderData());
      dispatch(userActions.resetOrderData());
    }
  }, [dataLayer]);

  useEffect(() => {
    if (location.pathname === '/createorder/congratulation') {
      dataLayer.push({
        "path": "/createorder/congratulation",
        "page_type":"paymentSuccess",
        "event":"page_view"
      });
    }
  }, []);

  

  const renderSuccess = () => (
    <MainLayout headerTitle="">
      <div className={classes.container}>
        <div className={classes.contentWrapper}>
          {/* <h1>
            Congratulations <br></br>You have {!location.state?.isReschedule ? 'booked' : 're-scheduled'} a service!
          </h1> */}
          <img src={Logo} alt="logo" style={{ marginBottom: '10px' }} />
          {location.pathname === '/createorder/cancel-order' && (
            <h1>Your service was canceled!</h1>
          )}
          {location.pathname === '/createorder/reschedule-order' && (
            <h1>
              Congratulations! <br></br> You have re-scheduled a service!
            </h1>
          )}
          {location.pathname === '/createorder/congratulation' && (
            <div>
              <h1>
                Mabrook! <br></br> Your service is booked!
              </h1>
              <Button
              type="primary"
              htmlType="submit"
              style={{
                borderRadius: '40px',
                width: '100%',
                height: '44px',
                background: '#026BB5',
                borderColor: '#026BB5',
                fontSize: '16px',
                fontWeight: 700,
              }}
              onClick={() => {
                history.push('/user/appointments')
                //window.location.reload();
              }}
            >
              View your booking
            </Button>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );

  const renderFail = () => (
    <MainLayout headerTitle="">
      <div className={classes.container}>
        <div className={classes.contentWrapper}>
          <h1>
            Failed <br></br>Payment failed
          </h1>
          <div className={classes.subTitle}>Check your payment data</div>
        </div>
      </div>
    </MainLayout>
  );

  return (
    <div>
      {done && success && renderSuccess()}
      {done && !success && renderFail()}
    </div>
  );
};
