import React, {
  ChangeEvent,
  useState,
} from 'react';
import cn from 'classnames';
import classes from './style.module.scss';

import { Checkbox, Input } from 'antd';
import { ReactComponent as EditIcon } from '../../../../../Assets/edit.svg';

import { OrderServiceForJob } from '../../../../../Types/workshopTypes';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type CheckboxComponentType = {
  service: OrderServiceForJob;
  onChange: (e: CheckboxChangeEvent) => void;
  isDisable?: boolean;
  isInspect?: boolean;
  onInputChange?: (id: number, e: ChangeEvent<HTMLTextAreaElement>) => void;
};

export const CheckboxComponent: React.FC<CheckboxComponentType> = ({
  service,
  onChange,
  isDisable = false,
  isInspect = false,
  onInputChange,
}) => {
  const [isShowInput, setIsShowInput] = useState(false);
  const onEdit = (e: any) => {
    setIsShowInput((prev) => !prev);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
      <Checkbox
        key={service.id}
        className={cn({
          [classes.checkbox]: !isInspect,
          [classes.checkboxInspect]: isInspect,
          [classes.withInput]: isInspect,
        })}
        value={service.id}
        onChange={onChange}
        //defaultChecked={service.status != null ? service.status : false}
      >
        <div className={cn(classes.title, {
          [classes.notDisable]: isDisable
        })}>{service.title}</div>
        {isInspect && isShowInput && (
          <Input.TextArea
            placeholder="Findings"
            defaultValue={service.note || ''}
            onChange={(e) => {
              onInputChange!(service.id, e);
            }}
            disabled={isDisable}
          />
        )}
      </Checkbox>
      {isInspect && (
        <EditIcon
          className={classes.editIcon}
          fill={isDisable ? '#D9D9D9' : '#5B8DB4'}
          onClick={onEdit}

        />
      )}
    </div>
  );
};
