import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../Globals/User/actions';
import { RootStateType } from '../../../../Stores';
import { CarResponseType } from '../../../../Types/authTypes';
import classes from './style.module.scss';

type MaintenancePropsType = {
  car: CarResponseType | null;
};

export const Maintenance: React.FC<MaintenancePropsType> = ({ car }) => {
  const dispatch = useDispatch();
  const scheduleMaintenance = useSelector((state: RootStateType) => state.user.scheduledMaintenance)

  useEffect(() => {
    if (car?.id) {
      dispatch(userActions.getScheduledMaintenance(car.id));
    }
  }, [car, dispatch]);
  return (
    <div className={classes.container}>
      <p>Your car’s next scheduled maintenance is at {scheduleMaintenance?.next_maintenance_interval.value.toLocaleString()} km.</p>
      <p className={classes.par}>
        And that maintenance would involve, the below, plus any necessary checks
        and fluid top-ups:
      </p>
      <ol>
        {scheduleMaintenance?.log_book ? scheduleMaintenance?.log_book.map((item) => {
          return (
            <li>{item.title}</li>
          )
        }) : ''}
      </ol>
    </div>
  );
};
