import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DoubleRightOutlined } from '@ant-design/icons'
import { Drawer } from 'antd';
import classes from './style.module.scss';
import { CarOutlined } from '@ant-design/icons';
import { RootStateType } from '../../Stores';
import WhiteBG from '../../Assets/orderIcon/car-details-white-bg.svg'
import classNames from 'classnames';
import { isBasic } from '../../Globals/User/selectors';

type DrawerComponentPropsType = {
  runAnimate?: {};
  children: ReactNode;
};

const DrawerComponent = ({
  children,
  runAnimate,
}: DrawerComponentPropsType) => {
  const formValues = useSelector(
    (state: RootStateType) => state.user.formValues
  );

  const otherServices = useSelector(
    (state: RootStateType) => state.user.selectedOtherServicesId
  );

  const services = useSelector(
    (state: RootStateType) => state.user.services?.log_book
  );

  const finalSummary = useSelector(
    (state: RootStateType) => state.user.finalSummary
  );

  const isBasic = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );

  const selectedWorkshop = useSelector(
    (state: RootStateType) => state.user.selectedWorkshop
  );

  const [visible, setVisible] = useState(false);
  const [run, setRun] = useState(false);
  
  const onAnimationPlay = () => {
    if (run) {
      setRun((prev) => !prev);
    }
    setRun((prev) => !prev);
    setTimeout(() => {
      setRun(false);
    }, 800);
  };

  const showDrawer = () => {
    setVisible(!visible);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (runAnimate) {
      onAnimationPlay();
    }
  }, [runAnimate]);

  return (
    <>
      <div className={classes.mobileContainer}>
      {/* <div
        className={classNames(classes.buttonDrawer, {
          [classes.animationCircle]: run,
        })}
        onClick={() => {
          showDrawer();
        }}
      > */}
      <div className={classes.leftWrapper}>
      <div className={classes.carWrapper}>
        <div className={classes.whiteBackground}>
        <img
            src={WhiteBG}
          />
        </div>
        <div className={classes.carImage}>
          {formValues?.model?.image_url && <img
            src={formValues?.model?.image_url}
            alt="car"
            className={classes.carImageSrc}
            // onError={addDefaultSrc}
          />}
        </div>
      </div>
      <div className={classes.headInfo}>
          <h4>
            {formValues?.brand?.title} {formValues?.model?.title}{' '}
            {/* <span style={{ display: 'inline-block', marginLeft: '12px' }}> */}
              {typeof formValues?.year === 'number'
                ? formValues?.year
                : formValues?.year?.year}
            {/* </span> */}
          </h4>
          <div className={classes.textArea}>
            {/* <span className={classes.engineMilliage}>Engine</span>{' '} */}
            <b>{formValues?.trim?.title}</b>
          </div>
          {formValues?.milliage && (
            <div className={classes.textArea}>
              {/* <span className={classes.engineMilliage}>Mileage</span>{' '} */}
              <b>
                {formValues?.milliage?.value
                  ? `${formValues?.milliage?.value.toLocaleString()} km`
                  : ''}
              </b>
            </div>
          )}
        </div>
      </div>
        <div className={classes.summary} onClick={showDrawer}>
          <div className={classes.titles}>
            {finalSummary? 
            <h4>AED {finalSummary?.toLocaleString('en-US', {maximumFractionDigits:0})}</h4>
            :
            <h4>Summary</h4>
            }
            {/* {
              isBasic == null || isBasic? 
                otherServices.length
                : 
                services?.length

            } */}
            <h3>
              {isBasic == null ? otherServices.length : (otherServices.length + 1)} services
            </h3>
          </div>
          <DoubleRightOutlined className={classes.doubleArrow} />
        </div>
        {/* <div className={classes.imageBackground}>          
          <CarOutlined className={classes.icon} />
        </div> */}
      </div>
      <Drawer
        title=""
        placement="right"
        width="350px"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: '0px' }}
      >
        {children}
      </Drawer>
    </>
  );
};

export default DrawerComponent;
