import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import { Provider } from 'react-redux';
import { store } from './Stores/index';
import './index.scss';
import App from './App';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ConfirmContextProvider } from './Context/ConfirmContext';
import { StripeKeys, TRACKING_ID } from './Utils/Api/constants';

const stripePromise = loadStripe(StripeKeys.KEY);

function AppInit() {
  useEffect(() => {

   ReactGA.initialize(TRACKING_ID);
   ReactGA.set({
    'dimension1': 'user',
  });
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <ConfirmContextProvider>
          <App />
        </ConfirmContextProvider>
      </Provider>
    </Elements>
  );
}

ReactDOM.render(<AppInit />, document.getElementById('root'));
