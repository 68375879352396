import React from 'react';
import classes from './style.module.scss';
import { Divider } from 'antd';
import { WorkShopType } from '../../../../Types/authTypes';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../../Stores';

export interface PopoverPropsType {
  workshop: WorkShopType;
}

export const PopoverPrices: React.FC<PopoverPropsType> = ({ workshop }) => {

  const otherServices = useSelector(
    (state: RootStateType) => state.user.services?.additional
  );
  const isBasicSelected = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );
  const deliveryCar = useSelector(
    (state: RootStateType) => state.user.deliveryCar
  );
  const selectedOtherServices =
    otherServices?.filter((service) =>
      Object.keys(workshop.additional_prices).find((id) => +id === service.id)
    ) || [];

  return (
    <div className={classes.container}>
      {isBasicSelected !== null && (
        <div style={{marginBottom: "20px"}}>
          <div className={classes.priceItem}>
          <div>
            {isBasicSelected ? 'Minor service' : 'Major service'}
          </div>
          {/* <div>AED {workshop.main_total_price?.toLocaleString('en-US', {maximumFractionDigits:0})}</div> */}
        </div>
        {workshop.main_services_titles.map((item:any) => {
          return (
            <div className={classes.chieldPrices} key={item.id}>
              <div>{item.title}</div>
              <div className={classes.price}>{(workshop.main_prices[item.id] == 0) ? 'FREE' : 'AED ' +  workshop.main_prices[item.id]?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
            </div>
          );
        })}
        </div>
      )}
      {selectedOtherServices.map((item) => {
        return (
          <div className={classes.priceItem} key={item.id}>
            <div>{item.title}</div>
            <div>{'AED ' + workshop.additional_prices[item.id]?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
          </div>
        );
      })}
     {/*  {deliveryCar.type.map((item, index) => {
        return (
          <div className={classes.priceItem} key={index}>
            <div>
              {item === 'dropOff'
                ? 'Car Pick-up service'
                : 'Car Drop-off service'}
            </div>
            <div>{item === 'dropOff'
                ? 'AED ' + workshop.drop_off
                : 'AED ' + workshop.pick_up}</div>
          </div>
        );
      })} */}
      <div>
        <div className={classes.priceItem} style={{marginTop: "20px"}}>
          <div>
            Labor
          </div>
          <div>AED {workshop.labor_price?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.totalWrapper}>
        <div>Total</div>
        <div>{'AED ' + workshop.sub_total_price?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
      </div>
    </div>
  );
};
