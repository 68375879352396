import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { ButtonComponent } from '../../../../Components/Button';
import { authActions } from '../../../../Globals/Auth/actions';
import { userActions } from '../../../../Globals/User/actions';
import { RootStateType } from '../../../../Stores';
import { AddCarForm } from '../../../../Components/AddCar';
import { CarDetailsFormType } from '../../../../Types/authTypes';
import classes from './styles.module.scss';
import classNames from 'classnames';
import Title from './Title/Title'
import { TabbyCard } from '../../../../Components/Tabby/TabbyCard';
import Image1 from '../../../../Assets/Landing/banner-image1.png'
import Image2 from '../../../../Assets/Landing/banner-image2.png'
import Image1Mob from '../../../../Assets/Landing/banner-image-mob1.png'
import Image2Mob from '../../../../Assets/Landing/banner-image-mob2.png'


type BannerPropsType = {
  goTo: (path: string) => void;
};

export const Banner: React.FC<BannerPropsType> = ({ goTo }) => {

  const [imageView,isImageView] = useState(false)
  
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  const dispatch = useDispatch();

  const sendGAEvent = () => {
    ReactGA.event({
      category: 'Get Started',
      label: 'From banner',
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } get started`,
    });
  };

  const onRedirect = () => {
    if (currentUser?.role.type === 'authenticated') {
      goTo('user/my-cars');
    } else if (currentUser?.role.type === 'authenticated_admin') {
      goTo('admin/clients');
    } else if (currentUser?.role.type === 'authenticated_workshop') {
      goTo('workshop/jobs');
    } else {
      dispatch(authActions.resetOrderData());
      dispatch(userActions.resetOrderData());
      goTo('createorder');
      sendGAEvent();
    }
  };

  const createNewCar = (formValues: CarDetailsFormType) => {
    const myCar = {
      make: formValues?.brand?.id!,
      model: formValues?.trim?.model!,
      trim: formValues?.trim?.id!,
      maintenance_interval: formValues?.milliage?.id!,
      year: formValues.year?.year
    }
    dispatch(userActions.addNewCar(myCar))
}

  const makes = useSelector((state: RootStateType) => state.user.makes);
  useEffect(() => {
    dispatch(userActions.getMakes());
  }, [dispatch]);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth} = window;
    return innerWidth;
  }
  const SwitchTitleWidth = 520;
  return (
    <div className={classes.mainWrapper} id="find-service">
      <div className={classes.container} id="top">
        <div className={classes.title}>
              {
                (windowSize > SwitchTitleWidth) ?
                  <Title setImageVisible={isImageView}/>
                :
                <ul className={classes.titleBox}>
                  <li>{'Car Service, Simplified!'}</li>
                  <li>{'Easy Online Booking'}</li>
                  <li>{'Honest Upfront Prices'}</li>
                  <li>{'Compare and Save'}</li>
                  <li>{(windowSize > SwitchTitleWidth) ? 'UAE’s 1st Auto Repair Marketplace' : 'Auto Repair Platform '}</li>
                </ul>
              }
            <div className={classes.subTitle}>
              {(windowSize > SwitchTitleWidth) ? 'Book Car Maintenance & Repairs at Top Rated Workshops' : 'Book Car Maintenance & Repairs'}
            </div>
          </div>
          <div className={classes.main}>
             {
              windowSize > SwitchTitleWidth ? 
              <div className={classes.imagesContainer}>
                <img src={Image1} alt="models" className={imageView ? classes.imageView : classes.imageNoView}/>
                <img src={Image2} alt="models"  className={!imageView ? classes.imageView : classes.imageNoView}/>
              </div>
              :
              <ul className={classes.imageBox}>
                <li><img src={Image1Mob} alt="models"/></li>
                <li><img src={Image2Mob} alt="models"/></li>
              </ul>
             }
            {
              currentUser?.role.type === 'authenticated' 
              ? 
              <div className={classes.form}>
                <AddCarForm createNewCar={createNewCar} makes={makes} fromLanding={true}/>
              </div>
              :
              <div className={classes.form}>
                <AddCarForm makes={makes} fromLanding={true}/>
              </div>
            }
          </div>
      </div>
    </div>
  );
};

