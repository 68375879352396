import React, { ChangeEvent, useEffect, useState } from 'react';
import { Divider, Checkbox , Input , message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import classes from './style.module.scss'
import ReactGA from 'react-ga';
import OtherServiceIcon from '../../../../../../Assets/orderIcon/other-service-icon.svg';
import SparkPlugs from '../../../../../../Assets/orderIcon/spark_icon.svg';
import Battery from '../../../../../../Assets/orderIcon/battery_icon.svg';
import ACFilter from '../../../../../../Assets/orderIcon/ac_filter_icon.svg';
import AirFilter from '../../../../../../Assets/orderIcon/air_filter_icon.svg';
import Wipes from '../../../../../../Assets/orderIcon/replace_wipes_icon.svg';
import Inspection from '../../../../../../Assets/orderIcon/inspection_icon.svg';
import RearBrake from '../../../../../../Assets/orderIcon/rear_break_icon.svg';
import FrontBrake from '../../../../../../Assets/orderIcon/front_break_icon.svg';
import OtherServicesModal from '../../../../../../Assets/orderIcon/Icon_other_services.svg';
import SparkPlugsMob from '../../../../../../Assets/orderIcon/spark_icon-mob.svg';
import BatteryMob from '../../../../../../Assets/orderIcon/battery_icon-mob.svg';
import ACFilterMob from '../../../../../../Assets/orderIcon/ac_filter_icon-mob.svg';
import AirFilterMob from '../../../../../../Assets/orderIcon/air_filter_icon-mob.svg';
import WipesMob from '../../../../../../Assets/orderIcon/replace_wipes_icon-mob.svg';
import InspectionMob from '../../../../../../Assets/orderIcon/inspection_icon-mob.svg';
import RearBrakeMob from '../../../../../../Assets/orderIcon/rear_break_icon-mob.svg';
import FrontBrakeMob from '../../../../../../Assets/orderIcon/front_break_icon-mob.svg';
import OtherServiceIconMob from '../../../../../../Assets/orderIcon/other-service-icon2-mob.svg';
import { ServiceCard } from '../ServiceCard';
import { ButtonComponent } from '../../../../../../Components/Button';
import { OfferedServiceType } from '../../../../../../Types/authTypes';
import { ModalComponent } from '../../../../../../Components/Modal';
import TextArea from 'antd/lib/input/TextArea';

import { RootStateType } from '../../../../../../Stores';
import { userActions } from '../../../../../../Globals/User/actions';

type OtherServicesPropsType = {
  openModel: (title: string, modalType: 'main' | 'logbook') => void;
  otherServices: Array<OfferedServiceType> | undefined;
  toggleOtherServices: (id: number) => void
  selectedOtherServices: Array<number>
  isBasic: boolean | null
};

export const OtherServices: React.FC<OtherServicesPropsType> = (
  {
  openModel,
  otherServices = [],
  toggleOtherServices,
  selectedOtherServices,
  isBasic
}) => {
  const getIconName = (id: number) => {
    switch (id) {
      case 1:
        return ((windowSize > SwitchIconsWidth) ? SparkPlugs : SparkPlugsMob);
      case 2:
        return ((windowSize > SwitchIconsWidth) ? Battery : BatteryMob);
      case 3:
        return ((windowSize > SwitchIconsWidth) ? ACFilter : ACFilterMob);
      case 4:
        return ((windowSize > SwitchIconsWidth) ? AirFilter : AirFilterMob);
      case 5:
        return ((windowSize > SwitchIconsWidth) ? Wipes : WipesMob);
      case 6:
        return ((windowSize > SwitchIconsWidth) ? Inspection : InspectionMob);
      case 7:
        return ((windowSize > SwitchIconsWidth) ? FrontBrake : FrontBrakeMob);
      case 8:
        return ((windowSize > SwitchIconsWidth) ? RearBrake : RearBrakeMob);
      default:
        return ((windowSize > SwitchIconsWidth) ? OtherServiceIcon : OtherServiceIconMob);
    }
  };
 
  const [ArrayOfCarService, setArrayOfCarService] = useState<Array<any>>([])
  
  const selectService = (id?: number) => {
    id && toggleOtherServices(id);
  }

  const [isModal, setIsModal] = useState<boolean>(false);
  const [CarRepairID, setCarRepairID] = useState(6);
  const [CarRepairSelected, setCarRepairSelected] = useState<boolean>(false);
  //Потомкам этого проекта расписываю, строка выше говнокод изза  нехватки времени, число это id CarRepair из API OtherServices. Создано для работы кнопки назад в моадльном окне.

  const closeModal = () => {
    setIsModal(false)
  }

  const disableModal = () => {
    closeModal();
    clearCarRepairInfo();
  };

  const acceptModal = () => {
    if (selectedCarRepairId.length) {
      closeModal();
      setCarRepairSelected(true)
      selectService(CarRepairID);
    } else {
      message.error('Please select a service!');
    }
  }

  const openModalOther = () => {
    if(selectedCarRepairId.length != 0){
      selectService(CarRepairID)
      clearCarRepairInfo();
      setCarRepairSelected(false)
    }
    else{
      setIsModal(true)
    } 
  };

  const dispatch = useDispatch();
  const selectedCarRepairId = useSelector(
    (state: RootStateType) => state.user.selectedCarRepairId
  );
  const carRepairText = useSelector(
    (state: RootStateType) => state.user.carRepairText
  );

  const toggleCarRepairId = (id: number) => {
    if (selectedCarRepairId.findIndex((item) => item === id) >= 0) {
      dispatch(userActions.removeCarRepair(id));
    } else {
      dispatch(userActions.addCarRepair(id));
    }
  };

  const clearCarRepairInfo = () => {
    dispatch(userActions.removeAllCarRepair())
  }

  const setCarRepairText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(userActions.setCarRepairText(e.target.value))
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth} = window;
    return innerWidth;
  }
  
  const SwitchIconsWidth = 570;
  
  useEffect(() => {
    if(otherServices[0]?.car_repair_theses)
    setArrayOfCarService(otherServices[0].car_repair_theses)
  }, [otherServices]);

  return (
    <div className={classes.container}>
      <Divider orientation="left" className={classes.devider}>
        Other services
      </Divider>
      <div className={classes.cardContainer}>
        {otherServices.map((item,index) => {
          if(index == 0){
            // setArrayOfCarService(item.car_repair_theses)
            return <ServiceCard
            key={item.id}
            id={item.id}
            title={item.title}
            isDisable={(item.disabled && (isBasic === false || isBasic == true))}
            isSelected={!!selectedOtherServices.find(i => i === item.id)}
            icon={getIconName(item.id)}
            column={false}
            subtitle={'Let us check your car problems'}
            onSelect={openModalOther}
            car_repair={true}
            //isShowTooltip={item.disabled && isBasic === false}
          />
          }
          else{
            return <ServiceCard
            key={item.id}
            id={item.id}
            title={item.title}
            isDisable={(item.disabled && isBasic === false)}
            isSelected={!!selectedOtherServices.find(i => i === item.id)}
            icon={getIconName(item.id)}
            column={false}
            onSelect={selectService}
            isShowTooltip={item.disabled && isBasic === false}
          />}
      })}
        <ModalComponent
          isModal={isModal}
          //onSubmit={selectService}
          toggleModal={disableModal}
          title="Car Repair  - Diagnose and repair a fault in my car"
          //onCancel={disableModal}
          icon={OtherServicesModal}
        >
          <div className={classes.partsList}>
            <div className={classes.header}>
              <h4>What best describes your problem?</h4>
              <p> Please note, this is only a detailed inspection and a separate quote shall be provided for any recommended repairs.</p>
            </div>
            <div className={classes.content}>
              <div className={classes.leftBlock}>
                {ArrayOfCarService.map(
                  (part,index) =>

                      <div className={classes.row}>

                        <Checkbox
                        key={index}
                        checked={(selectedCarRepairId.includes(part.id))}
                        onChange={() => {toggleCarRepairId(part.id)}}
                          // style={{ fontSize: '15px', color: '#5B8DB4' }}
                        />
                        {part.title}
                      </div>
                    )
                }
              </div>
              {/* <div className={classes.rightBlock}>
                {parts?.map(
                  (part, index) =>
                    index % 2 !== 0 && (
                      <div className={classes.row} key={part.id}>
                        <CheckCircleOutlined
                          style={{ fontSize: '15px', color: '#5B8DB4' }}
                        />
                        {part.title}
                      </div>
                    )
                )} */}
              {/* </div> */}
            </div>
            <TextArea rows={4} placeholder="Please let us know here, if you have anything or any issue you want us to inspect" value={carRepairText} onChange={setCarRepairText} style={{marginTop: '28px'}}></TextArea>
              <div className={classes.buttons}>
                <ButtonComponent type="cancel" title="Cancel" onClick={disableModal} />
                <ButtonComponent type="save" title={"Continue"} onClick={acceptModal}/>
              </div>
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};
