import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import classes from './style.module.scss';
import { MainLayout } from '../../Components/MainLayout';
import { AddCarForm } from '../../Components/AddCar';
import { NestedTreeList } from '../../Components/Tree';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../Stores';
import { userActions } from '../../Globals/User/actions';
import { adminActions } from '../../Globals/Admin/actions';

const { TabPane } = Tabs;

const defaultActiveTab = '1';

export const AdminServices: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>(defaultActiveTab);

  const dispatch = useDispatch();
  const makes = useSelector((state: RootStateType) => state.user.makes);

  const selectedModelTitle = useSelector(
    (state: RootStateType) => state.user.formId?.model
  );
  
  const models = useSelector(
    (state: RootStateType) => state.user.model
  );

  const getModelIds = useCallback(() => {
    if (selectedModelTitle && models) 
    return  models.find(model => model.title === selectedModelTitle)
  },[selectedModelTitle, models])


  const onChangeTab = (tab: string) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    dispatch(userActions.getMakes());
  }, [dispatch]);

  useEffect(() => {
    if (getModelIds()) {
      dispatch(adminActions.getParts(getModelIds()?.models_id[0]!));
    }
  }, [selectedModelTitle, dispatch, getModelIds]);

  return (
    <MainLayout headerTitle="Services">
      <div className={classes.contentContainer}>
        <AddCarForm makes={makes} />
        {getModelIds()?.image_url ? <img src={getModelIds()?.image_url} width="260px" height="169px" alt="car" /> : <div style={{width:"260px", height:"169px"}}></div>}
      </div>
      <div className={classes.containerTree}>
        <Tabs
          defaultActiveKey={defaultActiveTab}
          onChange={onChangeTab}
          style={{ border: 'none' }}
        >
          <TabPane tab="Services" key="1">
            <NestedTreeList activeTab={activeTab} />
          </TabPane>
          <TabPane tab="Spares" key="2">
            <NestedTreeList activeTab={activeTab} />
          </TabPane>
        </Tabs>
      </div>
    </MainLayout>
  );
};
