import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import classes from './style.module.scss';
import { TableComponent } from '../../Components/Table/Table';
import { MainLayout } from '../../Components/MainLayout';
import { adminActions } from '../../Globals/Admin/actions';
import { RootStateType } from '../../Stores';
import { NotificationType } from '../../Types/adminTypes';

import GarageIcon from '../../Assets/garageNotif.svg';
import TeamIcon from '../../Assets/team.svg';

const LIMIT_ON_PAGE = 20;

const tableConfig = [
  { title: 'Date & Time', key: 'created_at', isSort: true },
  { title: 'Recipient', key: 'recipient', isSort: true },
  { title: 'User Name', key: 'recipient_name', isSort: true },
  { title: 'Order ID', key: 'order_id', isSort: false, isClickable: true },
  { title: 'Notification', key: 'notification', isSort: false },
];

export const AdminNotifications = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(LIMIT_ON_PAGE);
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([{ created_at: 'DESC' }]);

  const dispatch = useDispatch();
  const history = useHistory();

  const notificationsList = useSelector(
    (state: RootStateType) => state.admin.notifications
  );

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const redirectTo = (id: number) => {
    history.push(`/admin/orders/${id}`);
  };

  const onSort = (fieldKey: string, order?: 'ascend' | 'descend') => {
    if (false) {
      if (order === 'ascend') {
        setSortValue([{ approved: 'ASC' }]);
      } else {
        setSortValue(order ? [{ approved: 'DESC' }] : []);
      }
    } else {
      setSortValue(
        order
          ? [{ [fieldKey]: order === 'ascend' ? 'ASC' : 'DESC' }]
          : fieldKey === 'created_at'
          ? [{ created_at: 'DESC' }]
          : []
      );
    }
  };

  const getRecipient = (recipient: 'customer' | 'workshop') => {
    if (recipient === 'customer') {
      return (
        <div className={classes.recipientIcon}>
          <img src={TeamIcon} alt="customer" />
        </div>
      );
    } else if (recipient === 'workshop') {
      return (
        <div className={classes.recipientIcon}>
          <img src={GarageIcon} alt="workshop" />
        </div>
      );
    } else {
      return;
    }
  };

  const createDataList = () => {
    if (notificationsList?.rows.length) {
      return notificationsList.rows.map((notification: NotificationType) => {
        return {
          key: notification.id,
          order_id: notification.order_id,
          recipient_name: notification.recipient_name,
          created_at:
            notification.created_at &&
            moment(notification.created_at).format('MMM DD YYYY, HH:mm'),
          recipient: getRecipient(notification.recipient),
          notification: notification.text,
        };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    dispatch(
      adminActions.getNotifications({
        page: { page: currentPage, limit: limitOnPage },
        sort: sortValue,
      })
    );
  }, [currentPage, dispatch, limitOnPage, sortValue]);

  return (
    <MainLayout headerTitle="Notifications">
      <div className={classes.contentContainer}>
        <TableComponent
          dataForTableList={createDataList()}
          tableConfig={tableConfig}
          totalItems={notificationsList?.count}
          onChangePage={onChangePage}
          goTo={redirectTo}
          onSort={onSort}
          isLastColumn={false}
        />
      </div>
    </MainLayout>
  );
};
