import { call, takeLatest, put, select } from 'redux-saga/effects';
import { appApi } from './services';
import { appActions } from '../App/actions';
import * as appConstants from './constants';
import * as selectors from '../Auth/selectors';
import {
  ReviewForWorkshopRequestType,
  WorkshopForLandingRequestType,
} from '../../Types/appTypes';
import { WorkShopType } from '../../Types/authTypes';

export function* getWorkshopList() {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const confirmResult: WorkshopForLandingRequestType = yield call(
      appApi.getWorkshops
    );
    yield put(appActions.setWorkshopList(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopById(payload: { payload: number; type: string }) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const confirmResult: WorkShopType = yield call(
      appApi.getWorkshop,
      payload.payload
    );
    yield put(appActions.setSelectedWorkshop(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getReviewsForWorkshop(payload: {
  payload: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const confirmResult: ReviewForWorkshopRequestType = yield call(
      appApi.getReviewsForWorkshop,
      payload.payload
    );
    yield put(appActions.setReviewsForWorkshop(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getReceiptInvoice(payload: {
  payload: { address: string; id: number };
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: { url: string } = yield call(
      appApi.getInvoiceLink,
      token,
      payload.payload.address,
      payload.payload.id
    );
    yield call(appApi.downloadFile, confirmResult.url, 'Receipt Invoice');
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export default function* WatcherSaga() {
  yield takeLatest(appConstants.GET_WORKSHOPS_FOR_LENDING, getWorkshopList);
  yield takeLatest(appConstants.GET_SELECTED_WORKSHOP_BY_ID, getWorkshopById);
  yield takeLatest(
    appConstants.GET_REVIEWS_FOR_WORKSHOP,
    getReviewsForWorkshop
  );
  yield takeLatest(appConstants.GET_RECEIPT_INVOICE, getReceiptInvoice);
}
