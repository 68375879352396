import axios from "axios";
import {createStore} from "redux";
import {ApiUrls} from "../Utils/Api/constants";

function sendFiles(order, formData, token) {
    return axios
        .put(`${ApiUrls.BASE_URL}/orders/file-upload/${order?.id}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((resp) => console.log('Successfully transfered docs'))
        .catch((resp) => console.error(resp));
};

function uploadDocs({fileList, file}, order, token) {
    const formData: FormData = new FormData();
    formData.append('data', '{}');
    fileList.forEach((file: any) => {
        formData.append('files.extra_notes', file?.originFileObj);
    });
    sendFiles(order, formData, token);
};

function uploadFilesAtFinishTheJob(state = {fileList: [], order: null}, action) {
    switch (action.type) {
        case 'SET_ORDER':
            return {
                ...state,
                order: action.order,
            };
        case 'SET_NOTES':
            return {
                ...state,
                fileList: action.fileList
            };
        case 'CLEAR_NOTES':
            return {...state, fileList: [], file: null};
        case 'UPLOAD_NOTES':
            uploadDocs({fileList: state.fileList}, state.order, action.token);
            return {}; // no need to store this trash after upload, isn't it?
    }
}

const uploadFileStore = createStore(uploadFilesAtFinishTheJob, {fileList: [], file: null, order: null});

export {uploadFileStore};

// created to fix cl-742