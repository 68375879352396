import { QueryObjectType } from '../Types/authTypes';

export const encodedOrderQuery = (data: QueryObjectType): string => {
  const keys = Object.keys(data);
  const queryArray = keys.reduce(
    (acc, key): any => {
      switch (key) {
        case 'page':
          const start =
            data.page.page === 1
              ? 0
              : data.page.limit * (data.page.page - 1);
          return [...acc, `?_start=${start}&_limit=${data.page.limit}`];
        case 'status':
          return [
            ...acc,
            data
              .status!.map((status) => {
                return `&status=${status}`;
              })
              .join(''),
          ];
        case 'date':
          return [
            ...acc,
            `&appointment_date_gte=${
              data.date![0]
            }T00:00:00.000Z&appointment_date_lt=${data.date![1]}T00:00:00.000Z`,
          ];
        case 'role':
          return [...acc, `&_where[0][role.type]=authenticated_${data.role}`];
        case 'asc':
          return [...acc, `&_sort=appointment_date:ASC`];
        case 'sort':
          return [
            ...acc,
            ...data.sort!.map((item, index) => {
              if (!index) {
                return `&_sort=${Object.keys(item)[0]}:${
                  item[Object.keys(item)[0]]
                }`;
              } else {
                return `,${Object.keys(item)[0]}:${item[Object.keys(item)[0]]}`;
              }
            }),
          ];
        case 'filter':
          return [
            ...acc,
            ...data.filter!.map(
              (item) => `&${Object.keys(item)[0]}=${item[Object.keys(item)[0]]}`
            ),
          ];
      }
    },
    ['']
  );
  return queryArray.join('');
};
