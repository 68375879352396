import { SavedDataObjectType } from '../../Types/userTypes';
import {
  CreateOrderRequestType,
  signUpResponseType,
} from './../../Types/authTypes';
import { ActionTypes } from './actions';
import * as authConstants from './constants';


const initialState = {
  userRole: '' as 'customer' | 'admin' | 'workshop' | '',
  isLogin: false,
  user: null as null | signUpResponseType,
  route: null as null | string,
  orderData: null as null | CreateOrderRequestType,
  orderID: null as null | number,
  summaryData: null as null | SavedDataObjectType,
  isSendingConfirmed: false,
};

export type InitialStateType = typeof initialState;

const authReducer = (
  state: InitialStateType = initialState,
  action: ActionTypes
): InitialStateType => {
  switch (action.type) {
    case authConstants.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };
    case authConstants.TOGGLE_IS_LOGIN:
      return {
        ...state,
        isLogin: !state.isLogin,
      };
    case authConstants.SET_PROFILE_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case authConstants.SIGN_OUT:
      return {
        ...state,
        user: null,
      };
    case authConstants.SET_ROUTE:
      return {
        ...state,
        route: action.payload,
      };
    case authConstants.SET_ORDER_DATA:
      return {
        ...state,
        orderData: action.payload,
      };
    case authConstants.SET_ORDER_ID:
      return {
        ...state,
        orderID: action.payload,
      };
    case authConstants.RESET_ORDER_DATA:
      return {
        ...state,
        orderData: null,
        summaryData: null,
      };
    case authConstants.SET_SAVED_SUMMARY_DATA:
      return {
        ...state,
        summaryData: action.payload,
      };
    case authConstants.IS_SENDING_CONFIRMED:
      return {
        ...state,
        isSendingConfirmed: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
