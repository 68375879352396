import React, {useEffect, useState} from 'react';
import { Carousel } from 'antd';
import { Review } from './Components/Review';
import { staticReviews } from './data';
import classes from './styles.module.scss';
import cn from 'classnames';

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cn(className, classes.arrow)}
      style={{
        ...style,
        left: '-70px',
      }}
      onClick={onClick}
    />
  );
}
function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cn(className, classes.arrow, classes.arrowRight)}
      style={{
        ...style,
        right: '-70px',
      }}
      onClick={onClick}
    />
  );
}

var settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 5000,
  slidesToShow: 4,
  slidesToScroll: 2,
  initialSlide: 1,
  draggable: true,
  arrows: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  responsive: [
    {
      breakpoint: 1750,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
      },
    },
  ],
};

export const Testimonials: React.FC = () => {
  const foo = (
    description: string,
    rateCount: number,
    workshopName: string,
    userName: string,
    id: number
  ) => {
    return (
      <div key={id}>
        <Review
          description={description}
          rateCount={rateCount}
          workshopName={workshopName}
          userName={userName}
        />
      </div>
    );
  };

  const [NumberOfReview,setNumberOfReview] = useState(1) 
  function ReviewCounter(props: any) {
    setNumberOfReview(props + 1)
  }
  return (
    <div className={classes.container}>
      <h2>Customers love our partners</h2>
      <Carousel {...settings} autoplay className={classes.carousel} afterChange={ReviewCounter}>
        {staticReviews.map((review) => {
          return foo(
            review.description,
            review.rateCount,
            review.workshopName,
            review.userName,
            review.id
          );
        })}
      </Carousel>
      <div className={classes.counterPages}><span>{NumberOfReview}\</span>{staticReviews.length}</div>
    </div>
  );
};
