import React from 'react';
import classes from './styles.module.scss';

export const TermsList: React.FC = () => {
  return (
    <div className={classes.contentWrapper}>
      <p>Current as of 15 December 2021</p>
      <p>
        By using this website, for any purpose including making a booking for
        service or repairs to your vehicle you confirm that you have read and
        understood and agree to abide by these Terms and Conditions of Service (
        T&Cs).
      </p>
      <p>
        These T&Cs apply to your use of this website (Website) operated by Car
        Logik FZCO (referred to below as "CarLogik" or "us" or "we" or "our")
        and also applies to any information provided by us on this Website, and
        any messages sent to or from us electronically.
      </p>
      <p>
        By using this Website, the user (you) confirms their agreement to all of
        the T&Cs. We may vary our T&Cs at any time without notice and, if we do,
        we will provide an updated version of our T&Cs on this Website.
      </p>
      <p>
        If you do not agree to any of our T&Cs, you must not use any of the
        services offered on our Website.
      </p>
      <p>
        <b>
          The content on or accessible through{' '}
          <a href="https://carlogik.com"> www.carlogik.com</a> is for informational
          purposes only and does not constitute technical advice related to your
          vehicle.
        </b>
      </p>
      <p>
        The Website is owned and operated by Car Logik FZCO, 8th Floor, The
        Offices 4, One Central, Dubai, United Arab Emirates
      </p>
      <h2>Agreement</h2>
      <h2>1. Our Service</h2>
      <ol type="a">
        <li>
          CarLogik is a booking service matching users requests for service and
          repairs to vehicles with service providers (Suppliers) that wish to
          perform the requested services.
          <ol type="1">
            <li>
              CarLogik facilitates booking requests from its users with third
              party Suppliers registered with CarLogik.
            </li>
            <li>
              CarLogik is not a party to, or responsible for, any service or
              repair work provided by a Supplier to you.
            </li>
            <li>
              CarLogik will not be a party to any disputes between Suppliers and
              you.
            </li>
            <li>
              CarLogik is not liable in any way for any service delivered or
              service failure by any Supplier for any reason.
            </li>
            <li>
              CarLogik is not liable in any way for any service delivered or
              service failure by any Supplier for any reason.
            </li>
          </ol>
        </li>
        <li>
          To register to make a booking to have your vehicle serviced or
          repaired, you must
          <ol type="i">
            <li>
              Be at least 18 years of age and legally capable to agree to these
              T&Cs.
            </li>
            <li>
              Accurately complete a booking request form on our Website which
              includes your personal contact and other information and your
              vehicle details such as (but not limited to):
              <ol type="1">
                <li>make;</li>
                <li>model;</li>
                <li>
                  scheduled service interval per the manufacturer's log book and
                  / or vehicle odometer reading; and preferred location
                </li>
                <li>
                  selecting the required (Service) from available choices;
                </li>
                <li>selecting a preferred Supplier; and</li>
                <li>
                  selecting a preferred day and time of arrival for service at
                  selected Supplier.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          When you make a booking request with CarLogik, CarLogik will notify
          the Supplier selected by you. The Supplier has agreed with us that it
          will then confirm with CarLogik if it can provide the service or
          required repairs requested and if the day and time selected for
          service or repair are agreed by Supplier.
        </li>
        <li>
          If your booking request is accepted by a Supplier, CarLogik will
          confirm the booking with you in writing (usually by email or
          whatsapp).
        </li>
        <li>
          If any issues arise with your proposed booking with the Supplier,
          CarLogik may, at its discretion, work with the Supplier and with you
          to resolve them, if possible. You agree that it may not be possible to
          fulfil all booking requests you attempt to make with our service.
        </li>
        <li>
          CarLogik gives no guarantee that a booking will be honoured by the
          Supplier and in such event, you should take up the matter with the
          Supplier.
        </li>
        <li>
          A booking through our service is intended to create a legal agreement
          between you and the Supplier to provide and to pay for services
          described in the booking.
        </li>
        <li>
          CarLogik is not a party to or responsible for either party in any such
          agreement between you and a Supplier. Apart from facilitating the
          booking, CarLogik does not provide any vehicle service or repairs and
          is not responsible for representations made by the Supplier, the
          quality of the vehicle service or repairs provided to you by a
          Supplier or any warranties offered by the Supplier or which you are
          entitled to from the Supplier.
        </li>
        <li>
          The Supplier you select has agreed with you (using us as an
          intermediary) to provide a service or repair for you and you will be
          responsible for payment through the CarLogik website or directly to
          the Supplier if you choose additional services or repairs over the
          agreed services on the website; any price indication (by way of quote
          or estimate) is supplied by the Supplier and merely communicated to
          the Customer by CarLogik and any variation from a price indication is
          a matter for the customer and the supplier to resolve between
          themselves.
        </li>
        <li>
          CarLogik is not responsible for any transactions and disputes between
          Suppliers and you.
        </li>
        <li>
          CarLogik does not represent that it has any skills or expertise
          pertaining to the service or repair of motor vehicles. We act simply
          as an intermediary between you and the Supplier.
        </li>
        <li>
          CarLogik acts as an intermediary between the customer and supplier;
          the contract for performance of any repair work is between the
          customer and the supplier,
        </li>
        <li>
          CarLogik is not liable as to the nature or suitability of the service
          or repairs provided by a Supplier to you.
        </li>
        <li>
          CarLogik is not liable as to the nature or suitability of the service
          or repairs provided by a Supplier to you. To the extent you permit a
          Supplier to use, operate, repair, service or otherwise take possession
          of your motor vehicle, you release and discharge CarLogik from and
          indemnify CarLogik against any loss or liability CarLogik may suffer
          or incur of any description whatsoever arising as a result of
          negligence, or the acts or omissions of the Supplier.
        </li>
        <li>
          In no event will CarLogik be liable to you for any direct, indirect,
          incidental or consequential damages or losses whatsoever arising from:
          <ol type="a">
            <li>defective service or repair work carried out by a Supplier;</li>
            <li>
              damage caused to (or the loss of) any property belonging to you or
              any personal injury or loss of life suffered by you relating to
              goods and services provided by a supplier, including without
              limitation, services performed on your motor vehicle or the
              supplier’s use and operation of that motor vehicle;
            </li>
            <li>
              misleading or deceptive conduct of a Supplier in its dealings with
              you;
            </li>
            <li>
              he failure of a Supplier to comply with any law, including any UAE
              consumer laws, product safety laws and workplace health and safety
              laws and such damages or losses you may suffer include, without
              limitation, damages for loss of profits and business interruption.
              For the avoidance of doubt and in all cases the supplier’s
              liability shall not exceed 100% of the value of the booking made
              by the customer through the CarLogik website.
            </li>
          </ol>
        </li>
        <li>
          Payments
          <ol type="a">
            <li>
              CarLogik may determine in its own discretion, from time to time,
              the methods of payment available on www.carlogik.com including
              Credit Card (Visa, MasterCard) and or cash payment. We reserve the
              right to not offer all payment methods. By submitting a credit or
              debit card number, you:
            </li>
            <li>
              confirm that your use of the particular card is authorized and
              that all information that you submit is true and accurate; and
            </li>
            <li>
              authorize us to charge to the card you tendered all amounts
              payable by you to us (including VAT and any other applicable
              taxes) based on the Items you order.
            </li>
            <li>
              you authorise us to make authenticity checks we consider
              appropriate regarding your method of payment and your right to
              make payment in that manner and if we suspect that any order is
              fraudulent or involves stolen identity or payment information, we
              reserve the right to involve any law enforcement and credit
              agencies we deem fit.
            </li>
            <li>
              Where payment is made by credit card you must satisfy yourself
              with the terms and conditions upon which the relevant credit
              provider offers you credit. Once a payment is made to us by your
              credit provider, any queries or disputes concerning payment terms,
              interest (where applicable) or other credit related matters should
              be addressed to your chosen credit provider.
            </li>
          </ol>
        </li>
        <li>
          Please note, except to the extent required by law, that the Supplier,
          and not CarLogik is:
          <ol type="a">
            <li>the seller, supplier and/or provider of the booked Service</li>
            <li>
              the party who enters into a contract with you when the booked
              service is performed; and
            </li>
            <li>
              solely responsible for providing the you with the Booking Service
              and for the Service itself
            </li>
          </ol>
        </li>
        <li>
          Under no circumstances is CarLogik acting as the partner or joint
          venturer of the Supplier.
        </li>
        <li>
          Prepayments are taken by CarLogik as agent for the Supplier that
          actually provides the products or services described in the booking.
        </li>
        <li>
          In the case of services described, CarLogik is not the provider of
          such services and has no responsibility for the services performed.
        </li>
        <li>
          CarLogik makes no warranty or representation regarding the standard of
          any services provided by a Supplier.
        </li>
        <li>
          Except to the extent required by law CarLogik has no liability to you
          for products or services described in a Booking.
        </li>
        <li>
          Parts - Instantly priced quotes provided through CarLogik are based on
          the prices of genuine parts or quality aftermarket parts that meet or
          exceed manufacturer recommendations. If these genuine parts are
          unavailable at the time of your booking, quality aftermarket parts
          mentioned above, will be used.
        </li>
        <li>
          Payment for inspections to your vehicle, roadworthy inspections or
          pre-purchase inspection is due regardless of the outcome of the
          inspection. If additional repairs are needed a quote will be provided
          by the supplier and will be at an additional cost.
        </li>
      </ol>

      <h2>2. License to Use Website</h2>
      <ol type="a">
        <li>
          CarLogik grants to users of its web service a limited, revocable right
          to access and use the Website as set out in these T&Cs and to print
          pages from the Website only for your personal and informational
          purposes.
        </li>
        <li>
          Except as expressly permitted in these T&Cs and as allowed by
          applicable laws, no part of our Website may be reproduced, adapted or
          transmitted in any form or by any process, without the specific
          written consent of CarLogik.
        </li>
        <li>
          To use this Website, you agree to receive communications from us and
          from our third party service providers and you agree to accept
          "cookies" and other similar devices used by this Website to perform
          our services. You also agree that we may call or email you about any
          incomplete or unconfirmed enquiry you make through our Website.
        </li>
        <li>
          CarLogik may terminate your license to use this Website at any time
          for any reason at CarLogik's sole discretion, including but not
          limited to:
          <ol type="i">
            <li>
              Failure by you to accept services pursuant to any confirmed
              booking made via this Website;
            </li>
            <li>
              Failure by you to pay any invoice rendered to you by a Supplier
              pursuant to booking you make via the Website;
            </li>
            <li>
              Supply by you of inaccurate information to CarLogik or to a
              Supplier or another service provider of ours;
            </li>
            <li>Harassment by you of other users of the Website;</li>
            <li>
              Violation by you of any law or rights of a third party, including
              but not limited to third party intellectual property rights;
            </li>
            <li>
              Posting by you of material on the Website which promotes non
              CarLogik authorized commercial activity;
            </li>
            <li>
              Misuse by you of the Website including using it to send spam or
              other unsolicited electronic communications or distributing
              viruses or malware to us or to other Website users;
            </li>
            <li>
              Misuse by you of our content or of third party content posted on
              the Website, including unauthorized reproduction of all or
              portions of this Website or for posting false, misleading or
              harassing content or any content that you are not authorized to
              post; or
            </li>
            <li>
              Conduct by you that we consider detrimental to our business and
              our other Website users.
            </li>
          </ol>
        </li>
        <li>
          CarLogik may immediately terminate, without any liability to you, any
          confirmed Booking request at any time, without giving reasons.
        </li>
      </ol>
      <h2>3. Warranties and indemnification obligations</h2>
      <ol type="a">
        <li>
          To the maximum extent permitted by law, the service on this Website is
          provided by CarLogik on an "AS IS, WHERE IS" basis and without any
          warranty as to accuracy or suitability for any purpose.
        </li>
        <li>
          by CarLogik and that, without limiting the foregoing, CarLogik gives
          no warranty or representation as to:
          <ol type="i">
            <li>
              the results that may be obtained through the use of the Website;
            </li>
            <li>
              the accuracy, reliability or otherwise of any information obtained
              from or displayed on this Website;
            </li>
            <li>the Website being available at any time or at all;</li>
            <li>the Website being error free;</li>
            <li>
              the nature or quality of any services provided by any Supplier to
              you as a result of a booking through the Website; and
            </li>

            <li>
              the goods and or services purchased or obtained through the
              Website being of merchantable quality or otherwise suitable for
              your purposes.
            </li>
          </ol>
        </li>
        <li>
          To the maximum extent permitted by law, you agree to indemnify, keep
          indemnified and hold harmless CarLogik and its officers, employees,
          agents, subsidiaries and affiliates against all claims (including
          third party claims), demands, damages, costs (including legal costs),
          penalties or suits arising out of or consequential upon:
          <ol type="i">
            <li>the use of the Website;</li>
            <li>any dispute between you and a Supplier;</li>
            <li>
              any negligence by a Supplier in the provision of any service to
              you;
            </li>
            <li>
              any loss or damage suffered by or through you arising from or
              related to the provision of any service by a Supplier; and
            </li>
            <li>
              any loss or damage suffered by or through you arising from or
              related to the use of the Website.
            </li>
          </ol>
        </li>
        <li>
          (d) CarLogik accepts no liability in respect of any loss or damage
          arising from any third party content displayed on the Website,
          including any information provided by or relating to Suppliers and
          other information which appears on the Website. Information displayed
          on the Website may be:
          <ol type="i">
            <li>out of date;</li>
            <li>inaccurate in whole or in part; or</li>
            <li>
              the result of duplication or confusion between Suppliers where two
              or more Suppliers have the same or similar name.
            </li>
          </ol>
        </li>
        <li>
          CarLogik will determine, in its sole discretion, the method by which
          it confirms to you the results of your use of our booking system.
        </li>
        <li>
          Our confirmation of the booking you have made with a Supplier is the
          only service CarLogik provides to you via the Website.
        </li>
        <li>
          CarLogik is not liable for liability, costs or damages suffered or
          incurred by you resulting from any communication directly between you
          and the Supplier.
        </li>
        <li>
          Except where otherwise indicated in these terms and conditions,
          CarLogik is not an agent or partner of any of the Suppliers included
          with or on this Website and CarLogik has no responsibility for and no
          liability whatsoever in respect of the conduct of an appointment by
          the Supplier, timing of or attendance at an appointment or quality of
          service provided by a Supplier.
        </li>
        <li>
          A Supplier has no authority to make representations on our behalf or
          bind us in any way.
        </li>
        <li>
          How do you file a claim under this Limited Warranty?You need to send
          an email to customer@carlogik.com with the following information:{' '}
          <br />
          Name: <br />
          Repair Order/Invoice #: <br />
          Workshop Name: <br />
          Time of Appointment: <br />
          Repair Service: <br />
          Mileage when the Service was done: <br />
          Current Mileage: <br />
          Nature of problem: <br />
          Documentation: Please attach all relevant documentation supporting
          your claim including photos or video of the problem occurring with
          your vehicle. Please note, however, that attempting to fix the problem
          or touching the work performed by a Service Provider booked through
          CarLogik will immediately void your Limited Warranty. In case the car
          has been in any accident, collision, or subject to natural disasters
          (flooding, earthquakes, lightning, fire, hurricane, sandstorm,
          hailstorm, or any other natural disasters) after the Service is done,
          that would also void your Limited Warranty. <br />
          Required Photos/Documents:
          <ol>
            <li>Proof of Purchase (email or payment receipt)</li>
            <li>License Plate</li>
            <li>Car Registration</li>
            <li>Damaged or defective vehicle area</li>
          </ol>
        </li>
        <li>
          Who is covered by this Limited Warranty? <br />
          This Limited Warranty, which is provided by Car Logik FZCO
          (“CarLogik,” “we” or “us”), extends to you as the original purchaser
          of auto repair or maintenance services (“Vehicle Services”) provided
          by a workshop (the “Workshop” or “Supplier”) that you have requested
          and scheduled through the CarLogik Platform. Capitalized terms not
          otherwise defined herein shall have the meanings given them in our
          T&Cs
        </li>
        <li>
          What does this Limited Warranty cover? <br />
          Subject to the exclusions, limitations and conditions set forth below,
          we warrant that the Vehicle Services will be free from defects in:
          materials (i.e., that the parts replaced in the performance of the
          Vehicle Services are not defective and perform as expected under
          normal usage conditions); and workmanship (i.e., that the Vehicle
          Services were performed correctly).
        </li>
        <li>
          How long will coverage under this Limited Warranty last? <br />
          This Limited Warranty lasts for 6 (six) months, measured from the date
          the Vehicle Services are provided on the applicable invoice/payment" 
          receipt (the “Warranty Period”). Note that any
          remedial work performed under this Limited Warranty will not restart
          the Warranty Period. Some materials provided by Workshops may carry a
          separate warranty that extends beyond the Warranty Period (e.g., some
          car batteries are covered by a manufacturer’s two-year warranty). In
          those instances, the term of the manufacturer’s warranty will apply to
          the materials (but workmanship will remain subject to the Warranty
          Period).
        </li>
        <li>
          What will we do in the event of a defect in the Services during the
          Warranty Period? <br />
          If there is a materials or workmanship defect within the Warranty
          Period, we will, at our option and at no additional cost to you: (i)
          engage the same Workshop without charge to you to perform remedial
          service work and/or replace defective part(s) installed in connection
          with the Vehicle Services; or (ii) refund any amount actually paid by
          you to us for the Vehicle Services. (Note that remedial service work
          carried or replacement of parts done will be limited to the amount
          actually paid by you to us for the Vehicle Service and that any
          discounts or promotions you applied towards payment of the Vehicle
          Services will not count as amounts you paid for purposes of any refund
          under this Limited Warranty.) In the event that a refund is to be
          issued due to defective materials, we may require the return of the
          defective part or parts at issue prior to processing a refund. We will
          not be responsible for any core fee charges incurred by you associated
          with returning the defective part or parts to us. We neither assume
          nor authorize anyone to assume for us any other obligation or
          liability in connection with the Vehicle Services.
        </li>
        <li>
          What is not covered by this Limited Warranty? <br />
          This Limited Warranty does not cover: <br />
          <ol type="i">
            <li>
              Any damage to your car caused by abnormal use (such as use in a
              commercial venture), misuse, neglect (such as not performing
              maintenance services in accordance with the instructions in the
              owner’s manual for your car), alteration or tampering, or by
              external causes (such as accidents, fire, water and freezing);
            </li>
            <li>
              Any work for which you have paid the mechanic directly, rather
              than making your payment through the CarLogik Platform;
            </li>
            <li>
              Any diagnostic or inspection service including pre-purchase
              inspection services provided through the CarLogik Platform;
            </li>
            <li>
              Any work not scheduled and processed through the CarLogik
              Platform, including any work performed by a Workshop originally
              introduced to you through the CarLogik Platform to the extent such
              work was not scheduled and processed through the CarLogik
              Platform;
            </li>
            <li>
              Any pre-existing condition with your car that is not known by the
              Workshop prior to starting the Vehicle Services and that is
              discovered during or after the Vehicle Services are performed
              (e.g., if you engage the Workshop to replace valve cover gaskets,
              but during the removal of the valve covers, worn plastic hoses
              break because they are old and brittle; repairing and/or replacing
              the plastic hoses under that scenario would not be covered by this
              Limited Warranty);
            </li>
            <li>
              Any repairs that you request the Workshop to perform beyond those
              included in the Workshop’s diagnosis of the problem, and which the
              Workshop performs, even if those additional repairs are needed to
              fix the issue you asked the Workshop to diagnose; provided,
              however, that if you authorize and pay for additional repairs
              through CarLogik, such additional repairs would be included in the
              Vehicle Services and covered by this Limited Warranty; and
            </li>
            <li>
              Any consequential effects of the Vehicle Services performed,
              unrelated to any of the parts replaced being defective or any of
              the repair work being done incorrectly (e.g., if you request a
              transmission fluid service, but because you have not been
              replacing the transmission fluid regularly, when the Workshop
              replaces the fluid, with the right fluid installed properly, your
              transmission stops shifting properly because it cannot handle the
              new fluid—this is a “consequential effect” not covered by this
              Limited Warranty). <br />
              This Limited Warranty is entirely void when:
              <ol>
                <li>
                  Any Vehicle Service request is made by you that deviates from
                  a manufacturer’s recommendation (e.g., conventional oil is
                  requested by you for an oil change but the owner’s manual for
                  your vehicle calls for synthetic oil); or
                </li>
                <li>
                  After receiving the Vehicle Services, you take your car to a
                  person not scheduled and processed through the CarLogik
                  Platform to diagnose or repair a problem believed to be
                  associated with such Vehicle Services, without giving CarLogik
                  the first opportunity to inspect and remedy the problem (or to
                  consent to an alternative person or shop from doing so).
                </li>
                <li>
                  Any determination of whether this Limited Warranty does not
                  apply or is otherwise void as to a particular warranty claim
                  shall be made by us in our sole discretion.
                </li>
                <li>
                  You will be charged for one hour of labor fees if a service
                  call is made under this Limited Warranty for an alleged defect
                  determined not to be the result of a defect in materials or
                  workmanship of the Vehicle Services. You must pay for any
                  non-warranty service ordered at the same time as any warranty
                  service.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          What is the process for a claim filed under this Limited Warranty?{' '}
          <br />
          Once you file a claim with us, our team will evaluate the claim to
          determine preliminary eligibility for coverage of Vehicle Services
          under this Limited Warranty. If eligible, we will arrange and book an
          appointment for the same Workshop to inspect the problem. The cost of
          bringing the car over to the Workshop will be borne by you at your own
          cost. If the Workshop determines that there is a defect in materials
          or workmanship of the Vehicle Services and we elect to perform
          remedial service work and/or replace defective part(s) installed
          (instead of providing a refund), we will create a new appointment to
          perform such work and/or replace such part(s) at no cost to you. If
          the Workshop determines that the claimed defect is not the result of a
          defect in materials or workmanship of the Vehicle Services, you will
          be charged for one hour of labor fees.
        </li>
        <li>
          May you keep any defective parts replaced under this Limited Warranty? <br/>
          No. All defective parts replaced under this Limited Warranty must be
          returned to us.
        </li>
      </ol>
      <h2>
        4. Review of content and Linked Third Party Websites and Information
      </h2>
      <ol type="a">
        <li>
          CarLogik may from time to time review and remove content posted on our
          Website by you and by other Website users in its sole discretion.
        </li>
        <li>
          CarLogik reserves the right to cooperate with any law enforcement
          authority in any jurisdiction in respect of any lawful direction or
          request to disclose the identity or other information in respect of
          anyone posting any materials which violate any applicable or relevant
          law on the Website.
        </li>
        <li>
          If you would like CarLogik to review any material on our Website,
          please contact us by email at the address set out on this Website.
        </li>
        <li>
          The Website may contain links to third party information and products.
          Such third party content and links are not owned, operated or
          maintained by CarLogik and they are not endorsed by or affiliated with
          CarLogik in any way, unless otherwise indicated on the Website.
        </li>
        <li>
          CarLogik is not responsible for the content of any third party
          material appearing on the Website or any third party sites linked to
          the Website. Such information, advertisements or links are purely for
          information purposes only and are not endorsements by CarLogik as to
          the quality, accuracy or completeness of the third party information
          or third party websites.
        </li>
        <li>
          If you decide to access any of the third party services or websites
          advertised or linked to our Website, you do so entirely at your own
          risk and, as far as lawfully possible, CarLogik accepts no liability
          or responsibility for your actions or omissions on the linked sites.
        </li>
      </ol>
      <h2>5. User Supplied Content</h2>
      <p>Subject to CarLogik's legal obligations with respect to privacy:</p>
      <ol type="a">
        <li>
          Any information or material submitted by you through any facility on
          our Website (User Material) will be considered as having been
          voluntarily submitted by you for use by CarLogik and is not
          confidential. By providing CarLogik with User Material via the
          Website, you:
          <ol type="i">
            <li>
              grant CarLogik a non-exclusive, royalty-free, perpetual,
              irrevocable and fully sublicensable and transferable right to use,
              reproduce, modify, adapt, publish, translate, create derivative
              works from, distribute and display, such User Material throughout
              the world in any media and irrevocably grant CarLogik the right to
              publish the name you use to submit the User Material;
            </li>
            <li>
              You represent and warrant that you own or otherwise have the
              exclusive right to grant us the license in (i) and that the User
              Material is accurate, does not violate these terms and conditions
              or our policies and that it will not cause injury to any person or
              entity; and
            </li>
            <li>
              agree to indemnify and hold CarLogik and its directors, officers,
              agents and employees (for whom CarLogik accepts the benefit of
              this clause as trustee and agent) from and against losses,
              damages, costs and claims (collectively, Claims) resulting from
              publication on our Website of User Material you post, including
              but not limited to any Claims by you or against you. Without
              limiting the foregoing, you irrevocably agree and undertake in
              favour of CarLogik never to bring any claim against CarLogik
              related to any User Material or your name that you post on the
              Website.
            </li>
          </ol>
        </li>
        <li>
          You represent and warrant in favour of CarLogik that you are
          authorized to submit any User Material you submit to CarLogik and that
          CarLogik is free to use any such User Material without any restriction
          or prior notice to you, whether for CarLogik commercial or
          non-commercial purposes and without any compensation to any person or
          entity.
        </li>
        <li>
          If you post any User material, such as reviews or comments, on our
          Website, you represent to us and we rely on that representation in
          publishing such material, that the User Material is not illegal,
          obscene, threatening, defamatory, invasive of privacy, infringing of
          intellectual property rights, or otherwise injurious to third parties
          or objectionable and does not consist of or contain software viruses,
          political campaigning, commercial solicitation, chain letters, mass
          mailings, or any form of "spam".
        </li>
        <li>
          You acknowledge your understanding that if you post User Material
          about your experience with a Supplier on our Website, we may also
          publish that Supplier's response to your post on our Website and that
          such response will be visible to other users of our Website.
        </li>
        <li>
          Without limiting any other provision of these terms and conditions,
          CarLogik may at its sole election and at any time, remove or edit User
          Material, but has no obligation to review User Material or responses
          posted by Suppliers and other persons.
        </li>
      </ol>
      <h2>6. Copyright in Website Content</h2>
      <p>
        Copyright in all content contained on the Website, including but not
        limited to text, drawings, photographs, page layout and look and feel,
        subsists under and is protected by, the relevant UAE law and other
        international copyright laws and is, unless stated otherwise, owned by
        CarLogik or used by CarLogik under license.
      </p>
      <h2>7. CarLogik Trade Name</h2>
      <ol type="a">
        <li>
          Unless otherwise indicated, CarLogik and www.carlogik.com are trade
          names belonging to CarLogik.
        </li>
        <li>
          Any use of these trade names without our prior written consent is
          expressly prohibited. Please contact us if you would like to use our
          trade names.
        </li>
        <li>
          Third party trade marks may appear on this Website and all rights
          therein are reserved by the registered owners of those trade marks.
        </li>
      </ol>
      <h2>8. Linking to the Website</h2>
      <ol type="a">
        <li>
          You may not make any part of the Website available as part of another
          website by use of inline imaging, framing technologies or any other
          method of incorporating parts of the Website into another site,
          without the prior written consent of CarLogik.
        </li>
        <li>
          You will be liable for any direct or indirect damages to or loss
          suffered by CarLogik due to unauthorised hyperlinking to or framing of
          our Website and you agree that you will fully indemnify CarLogik in
          respect of any loss or damage suffered by CarLogik due to such
          actions.
        </li>
      </ol>
      <h2>9. Third party incentives and promotions</h2>
      <ol type="a">
        <li>
          From time to time, you may be given the option to participate in
          reward or similar programs to obtain certain products or services
          provided by third parties (Program Partners) that may be promoted on
          the Website. Participation in those programs will be governed by
          separate terms and conditions that you must agree to prior to your
          decision to participate.
        </li>
        <li>
          CarLogik will integrate Program Partners into the Website at its
          discretion.
        </li>
        <li>
          CarLogik reserves the right to change the terms of any promotion
          offered with or through any Program Partners at any time. CarLogik is
          not responsible for changes to or discontinuance of any program.
        </li>
        <li>
          Unless stated otherwise, the products and services promoted on the
          Website as being provided by a Program Partner are supplied by that
          Program Partner and not by CarLogik and the Program Partner's standard
          terms and conditions (governing for example delivery, refunds etc.)
          will govern your agreement to acquire those goods or services.
        </li>
        <li>
          Any offers or representations made by Program Partners are made by the
          Program Partner only and CarLogik does not give any warranties,
          guarantees or representations regarding the quality of any
          merchandise, products or services offered or provided by any Program
          Partner. CarLogik is not responsible or liable to you for any losses
          you may suffer in the event that the Program Partner fails to comply
          with its obligations to you.
        </li>
      </ol>
      <h2>10. Limitation of Liability</h2>
      <ol type="a">
        <li>
          CarLogik does not charge fees to you for use of its service to make a
          booking with a Supplier using this Website. To the maximum extent
          permitted by law, CarLogik will not be liable to you for any direct,
          indirect consequential or any other damage you may incur as a result
          of your use of this Website, which is entirely at your own risk.
        </li>
        <li>
          While CarLogik takes all reasonable care to include accurate and
          up-to-date information on the Website, the information provided should
          not be relied upon as being error free or accurate and CarLogik makes
          no representation or warranty as to the timeliness, accuracy,
          suitability or completeness of any information, content, services,
          materials and products provided on or via the Website, nor does it
          accept any responsibility arising in any way for errors or omissions.
        </li>
        <li>
          To maximum extent permitted by law, CarLogik accepts no liability or
          responsibility for the actions or omissions of any Supplier goods or
          services received by you as a result of your use of this Website
          service.
        </li>
        <li>
          As far as lawfully possible, CarLogik accepts no liability or
          responsibility for the actions or omissions of any other users of the
          Website in relation to the content contained herein.
        </li>
        <li>
          All information provided on the Website is general in nature and does
          not constitute technical or any other form of advice and is provided
          'as is' without warranty of any kind, express or implied, to the
          fullest extent possible under law.
        </li>
        <li>
          In no event will CarLogik be liable to any party for any direct,
          indirect, incidental or consequential damages or losses whatsoever
          arising from access to, reliance on, or use of or downloading of
          information from the Website, (or any associated or linked online
          service), including, without limitation, damages for loss of profits,
          business interruption, loss or information or damage to systems due to
          viruses or other harmful components.
        </li>
        <li>
          CarLogik is not responsible for the deletion, failure to store,
          mis-delivery or untimely delivery of any material accessed through the
          Website or external sites and will not be liable for any damage or
          loss whatsoever resulting from any users of the website downloading or
          accessing any information or material through the Website.
        </li>
        <li>
          CarLogik does not accept any responsibility or liability whatsoever
          for any interruption or discontinuance of any or all functionality of
          the Website (including hypertext links to external sites if any) for
          any reason whatsoever.
        </li>
        <li>
          CarLogik does not guarantee that files or programs executed and/or
          downloaded from this Website or provided via this Website are free
          from viruses and other unauthorised or malicious code or corruption
          that could damage or interfere with data, hardware or software and
          CarLogik is not liable for any such damage or interference which may
          be suffered in connection with use of our online services.
        </li>
        <li>
          CarLogik does not guarantee the security of any information sent to
          the Website via the internet and such information is sent at the
          transmitter's own risk.
        </li>
        <li>
          CarLogik does not endorse the content of advertisements appearing on
          the Website and has no liability in respect thereof.
        </li>
      </ol>
      <h2>11. Changes to the Website</h2>
      <ol type="a">
        <li>
          CarLogik reserves the right to revise, change, modify or delete the
          content of any part of the Website, including any product or service
          available through the Website, at any time in its sole discretion and
          without prior written notice.
        </li>
        <li>
          CarLogik reserves the right to terminate or restrict access to the
          Website or modify, suspend or cancel any of the online services
          provided via the Website at any time without notice.
        </li>
      </ol>
      <h2>12. Updates</h2>
      <p>
        CarLogik reserves the right to revise, change or modify these T&Cs at
        any time by posting new T&Cs on the Website. You are bound by any such
        revisions and should therefore visit the T&Cs each time you visit the
        Website to review the current T&Cs by which you must abide.
      </p>
      <h2>13. Intellectual Property</h2>
      <ol type="a">
        <li>
          You acknowledge that all intellectual property contained on or within
          the Website is and remains at all times the property of CarLogik or
          the other parties identified. You agree not to use the information or
          other material on the Website for any purpose or in any manner that
          would, in any way, infringe CarLogik's intellectual property rights or
          those of the owners of the rights. Except for the temporary copy held
          in your computer's cache, the material referred to above may not
          otherwise be used, copied, reproduced, published, stored in a
          retrieval system, altered or transmitted in any form or by any means
          in whole or part without prior written approval from CarLogik or the
          approval of the relevant copyright owner.
        </li>
        <li>
          Nothing displayed on the Website grants any licence or right of use of
          any logo, or trade mark displayed without the express written
          permission of the relevant owner.
        </li>
      </ol>
      <h2>14. Referral Program</h2>
      <p>
        You may refer other persons to the Website at any time. From time to
        time CarLogik may offer a reward based referral scheme to users{' '}
        <b>(Referral Scheme).</b> However there is no obligation to give you
        rewards unless:
      </p>
      <ol type="i">
        <li>
          User referrals are made specifically in conjunction with an authorised
          Referral Scheme;
        </li>
        <li>
          Users have complied with the rules and conditions of the Referral
          Scheme (which will be published on the Website);
        </li>
        <li>User referrals are eligible to use of the Website;</li>
        <li>
          a User referral joins as a User prior to termination of the Referral
          Scheme; and
        </li>
        <li>
          a User has not used spam or spamming techniques or other improper
          means to obtain referrals.
        </li>
      </ol>
      <h2>15. Dispute Resolution</h2>
      <ol type="i">
        <li>
          All questions or disputes relating to the Website including these
          terms should be submitted to CarLogik to resolve directly with you.
        </li>
        <li>CarLogik will endeavour to resolve any disputes expeditiously.</li>
        <li>
          The User acknowledges that any complaints or enquiries in relation to
          any good or services supplied by any Supplier will be directed to the
          Supplier in question.
        </li>
      </ol>
      <h2>16. Cancellation and Refunds</h2>
      <p>
        Subject to any contrary provisions outlined below, if you make a booking
        through our website:-
      </p>
      <ol type="a">
        <li>
          we will give you a full refund, if you cancel the scheduled provision
          of any products and services by a Supplier, latest by 12 hours before
          the appointment upon the products and services were to be delivered or
          supplied;{' '}
        </li>
        <li>
          we will give you a full refund, less AED 100 cancellation fee, if you
          cancel the scheduled provision of any products and services by a
          Supplier, less than12 hours before the appointment upon the products
          and services were to be delivered or supplied;{' '}
        </li>
        <li>
          rescheduling prior to 2 hours of appointment is permissible with no
          charge or fees, however, for any rescheduling less than 2 hours before
          appointment, an AED 100 rescheduling fee which will be added to your
          final invoice.{' '}
        </li>
      </ol>
      <p>
        however, in the event you miss your appointment or fail to cancel in
        accordance with the preceding sub-paragraphs, we will give you a full
        refund, less AED 200 fail to show fee.
      </p>
      <p>
        You agree that all fees mentioned above are reasonable and fair fees
        which covers administration, data and suppliers fees.
      </p>
      <h2>17. Privacy</h2>
      <ol type="a">
        <li>
          We will use and share personal information we have collected about you
          to provide our service and for our business purposes.
        </li>
        <li>
          We may contact you about your use of our service from time to time as
          required to provide our service and to improve our service.
        </li>
        <li>
          Our third party service providers, including Suppliers, may contact
          you from time to time as required to provide our service and to
          improve our service.
        </li>
        <li>
          Our Privacy Policy (posted on the Website) governs how we use
          information we collect about you. If you do not agree to our Privacy
          Policy, you may not use our service.
        </li>
        <li>
          You warrant that you have obtained the consent of any third person
          whose personal information you post to or via the Website.
        </li>
        <li>
          The Website contains links to other sites that are not affiliated or
          associated with, or controlled by CarLogik and CarLogik has no
          responsibility for their privacy practices and our Privacy Policy
          applies only to those parts of the Website within the control of
          CarLogik.
        </li>
        <li>
          CarLogik may change its Privacy Policy from time to time at its
          discretion and without notice. If any such changes are made, CarLogik
          will make all reasonable efforts to bring those changes to the
          attention of users of its website services by posting the revised
          policy on the Website.
        </li>
        <li>
          Until such time as you unsubscribe, you authorise CarLogik to send
          marketing and promotional materials regarding the Website, to you.
        </li>
        <li>
          CarLogik's handling and use of your personal information is governed
          by our Privacy Policy.
        </li>
      </ol>
      <h2>18. Applicable Laws</h2>
      <p>
        These T&Cs are governed by the laws of the Dubai, United Arab Emirates.
      </p>
      <h2>19. CarLogik Policies</h2>
      <p>
        These T&Cs must be read with any policy we post on the Website, which
        policies form part of these T&Cs.
      </p>
      <h2>20. Severance</h2>
      <p>
        If any provision contained in these T&Cs is or becomes legally
        ineffective, under the general law or by force of legislation, the
        ineffective provision shall be severed from the T&Cs which otherwise
        continue to be valid and operative.
      </p>
      <h2>21. Jurisdiction</h2>
      <p>
        These T&Cs shall be governed by and construed in accordance with the
        laws of Dubai as applicable in the United Arab Emirates and the parties
        hereby submit to the exclusive jurisdiction of Dubai courts and any
        courts which have jurisdiction to hear appeals from any of those courts.
      </p>
      <h2>22. Entire Agreement</h2>
      <p>These T&Cs:</p>
      <ol>
        <li>
          exclude all conditions, warranties and terms implied by custom,
          general law or statute (except ones that by law may not be excluded);
        </li>
        <li>
          is the entire agreement between the parties about its subject matter;
        </li>
        <li>in relation to that subject matter, supersedes any prior:</li>
        <ol type="i">
          <li>understanding or agreement between the parties; and</li>
          <li>
            condition, warranty, indemnity or representation imposed, given or
            made by a party;
          </li>
        </ol>
      </ol>
      <h2>23. Interpretation</h2>
      <p>
        In this Agreement, unless the subject or context otherwise requires:
      </p>
      <ol type="a">
        <li>
          a reference to any legislation or legislative provision includes any
          statutory modification or re-enactment of, or legislative provision
          substituted for, and any subordinate legislation issued under, that
          legislation or legislative provision;
        </li>
        <li>the singular includes the plural and vice versa;</li>
        <li>
          a reference to an individual or person includes a corporation, firm
          partnership, joint venture, association, authority, trust, state or
          government and vice versa;
        </li>
        <li>a reference to any gender includes all genders;</li>
        <li>
          a reference to a recital, clause, schedule, annexure or exhibit is to
          a recital, clause, schedule, annexure or exhibit of or to this
          Agreement;
        </li>
        <li>
          a recital, schedule, annexure or a description of the parties forms
          part of this Agreement;
        </li>
        <li>
          a reference to any agreement or document is to that agreement or
          document (and, where applicable, any of its provisions) as amended,
          novated, supplemented or replaced from time to time;
        </li>
        <li>
          a reference to any party to this document or any other document or
          arrangement includes that party's executors, administrators,
          substitutes, successors and permitted assigns;
        </li>
        <li>
          where an expression is defined, another part of speech or grammatical
          form of that expression has a corresponding meaning;
        </li>
        <li>
          a reference to a matter being written includes that matter being in
          any mode of representing or reproducing words, figures or symbols in
          written form;
        </li>
        <li>
          a reference to a time is that time in Dubai, Untied Arab Emirates
        </li>
        <li>
          except as otherwise provided, a reference to a period of time
          (including without limitation, a year or a month) is to calendar
          period;
        </li>
        <li>
          a reference to an "associate" of a party is to a person that has a
          relationship for the time being with the relevant party whereby either
          that party or such person directly or indirectly controls, is
          controlled by, or is under common control with, the other. For this
          purpose, "control" means the power, whether direct or indirect, of one
          person to direct or cause the direction of the management or policies
          of another, whether by contract, through voting securities or
          otherwise;
        </li>
        <li>
          a “booking” refers to a booking made by you with us for the purchase
          of any products or services to be supplied by a Supplier.
        </li>
      </ol>
      <h2>24. Pre-purchase Inspection Disclaimers</h2>
      <ol type="a">
        <li>
          Inspection
          <ol type="i">
            <li>
              A Pre-Purchase Inspection service can only take into account the
              visible condition and the operation of the vehicle at the time of
              the inspection. CarLogik nor the inspector will be responsible for
              any defects that are discovered which were not apparent during the
              inspection.
            </li>
            <li>
              The Pre-Purchase Inspection will cover the vehicle’s engine bay,
              wheels & tyres, brakes, exterior and interior, underbody and a
              road test (if possible).
            </li>
            <li>
              Despite all efforts by inspectors, there is no guarantee that this
              inspection will identify every fault or defect with the vehicle.
            </li>
            <li>
              The Pre-Purchase Inspection report is not a certificate of
              roadworthiness.
            </li>
            <li>
              In some cases, vehicles may only be inspected in a workshop due to
              difficulty, size, safety and location requirements.
            </li>
            <li>
              There are some vehicles that are unable to be inspected, or can
              only have a limited inspection completed. This may be due to
              limited access, complexity, or the need for specialised tools and
              equipment. Should this be the case with your vehicle, we will
              attempt to inform you prior to the inspection being done, and it
              will be recorded in the Pre-Purchase Inspection report.
            </li>
            <li>
              The Pre-Purchase Inspection is based off the external view of your
              vehicle and its components. It is not possible to conduct in-depth
              checks that involve removing, inspecting and re-installing parts
              due to the time and cost involved.{' '}
            </li>
            <li>
              A short road test to assess steering, suspension, brake operation,
              engine and driveline operation will be conducted.
            </li>
            <li>
              Some issues do not become apparent unless higher speeds are tested
              or achieved (e.g. wheel bearings).
            </li>
            <li>
              Subject to all applicable laws, CarLogik does not provide any
              guarantee, warranty or representation regarding the nature,
              reliability, accuracy or completeness of any information contained
              in the Pre-Purchase Inspection report or the fitness of the
              information contained in the report for any purpose intended by
              you.
            </li>
          </ol>
        </li>
        <li>
          General Exclusions
          <ol type="i">
            <li>
              This Pre-Purchase Inspection will not tell you everything you need
              to know about the vehicle.
            </li>
            <li>
              The Pre-Purchase Inspection will not tell you about defects that
              have been hidden or concealed. Additionally, problems that only
              occur intermittently and that are not apparent during the
              inspection, or problems which cannot be identified by a visual
              inspection will not be reported.
            </li>
            <li>
              During the inspection, the engine may not be tested through the
              full operating temperature range. As a result, issues that only
              become apparent when the engine is cold may not be detected if the
              engine is presented hot.
            </li>
            <li>
              Documents that accompany the vehicle (vehicle service records,
              vehicle manual, damage reports and repair records) will not be
              inspected. Preparing these documents is not part of your
              Pre-Purchase Inspection and their accuracy will not be verified.
            </li>
            <li>
              The Pre-Purchase Inspection does not check for recall notices
              issued by the manufacturer of the vehicle.
            </li>
            <li>
              To avoid damaging the vehicle's drive train, if your vehicle is
              only equipped with a part time 4WD system, and not designed for
              full time 4WD, it may not be driven in 4WD during the inspection.
            </li>
          </ol>
        </li>
        <li>
          The Pre-Purchase Inspection report
          <ol type="i">
            <li>
              Only items in the Pre-Purchase Inspection checklist are assessed
              along with any additions requested by you
            </li>
            <li>
              We must be notified of any additional items that you request to be
              inspected at the time of booking.
            </li>
            <li>
              The inspector will only report on defects apparent at the time of
              inspection.
            </li>
            <li>
              Inspectors will provide their professional opinion of the overall
              visual and operating condition of the vehicle. Any professional
              opinion provided is a matter of subjective opinion based on the
              inspectors’ knowledge and experience, with an aim to assist you in
              making informed decisions.
            </li>
            <li>
              Although the Pre-Purchase Inspection has been requested to help
              you make the decision to purchase a vehicle, you must be aware
              that the Pre-Purchase Inspection report is not a recommendation to
              either purchase or not purchase the vehicle. You are solely
              responsible for making that decision, and you must consider
              whichever factors you see relevant.
            </li>
          </ol>
        </li>
        <li>
          Risk to Purchaser
          <ol>
            <li>
              As stated, this Pre-Purchase Inspection is a brief visual and
              operational check of the components on the vehicle. As there is
              limited ability to detect hidden or intermittent faults,
              purchasing a vehicle without warranty from the seller may expose
              the buyer to risks that could become quite significant.
            </li>
            <li>
              As it is defined, this inspection will only consider the vehicle
              in the condition and at the time it is presented. Defects or
              issues may become apparent, which were not identifiable at the
              time of inspection.
            </li>
            <li>
              Standard cancellation fees may apply as per the CarLogik T&Cs.
            </li>
          </ol>
        </li>
      </ol>
      <h2>25. Use of a Buy Now and Pay Later payment option Disclaimer</h2>
      <p>
        In case you use the services of any of the Buy now and Pay later (BNPL) 
        options available through our website. The terms and conditions, for those 
        third party BNPL service providers shall apply.
        Please refer to the following Terms and Conditions in case you use 
        Tabby payment option: <a href='https://tabby.ai/en-AE/toc' target='_blank'>Tabby T&Cs</a>
      </p>
      <h2>26. Use of the Valet driver service Disclaimer</h2>
      <p>
        In case you select the valet driver option available through our website. The terms and conditions, 
        of the third party provider that we utilize to perform the valet driver service shall apply. 
        Currently we are utilizing the services of ZOFEUR please refer to the following Terms and Conditions: <a href='https://www.zofeur.com/terms-and-conditions' target='_blank'>Zofeur T&Cs.</a>
        In addition please note that in case of any accident or damage to your vehicle, other driver's vehicles, 
        or any public or privately owned property, your car's insurance policy shall apply. In the case that the accident was the fault of 
        the valet driver of our third part provider, CarLogik will only cover for the excess amount (maximum of AED 5,000), and shall not 
        be held liable for any other compensation. If the accident was the fault of the other car driver, CarLogik shall not be held liable for any compensation.
      </p>
    </div>
  );
};
