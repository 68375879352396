import React from 'react';
import classes from './styles.module.scss';

export const PrivacyList: React.FC = () => {
  return (
    <div className={classes.contentWrapper}>
      <h3>1. Protecting your privacy</h3>
      <p>
        Car Logik FZCO ("us, we, our" or "CarLogik") is committed to protecting
        the information about you that we collect, store and use when you
        provide it to us at our web and mobile sites:
        <a href="www.carlogik.com"> www.carlogik.com.</a> By accessing our
        Website, you accept the terms and conditions of its use and consent to
        the collection, use and disclosure of your personal information in
        accordance with this privacy policy and the terms and conditions of use
        of the Website.
      </p>
      <p>
        If you have questions or complaints regarding our privacy policy or
        practices, or believe that your privacy has been breached, please
        contact us at{' '}
        <a href="mailto:customer@carlogik.com">customer@carlogik.com</a> and
        provide details of the incident so that we can investigate it. We
        request that all complaints be made in writing, so that we can be sure
        about the details of the complaint. We will treat your complaint
        confidentially, investigate your complaint, and contact you to resolve
        your complaint within a reasonable time.
      </p>
      <h2>2. Collection & use of personal information</h2>
      <h3>
        2.1 What personal information about me does CarLogik hold, and how is it
        collected during the registration process.
      </h3>
      <div className={classes.listWrapper}>
        If you register on the Website, or seek a quotation for services via the
        Website, you will be asked to complete an online form to submit
        information about you to us. Such information includes:
        <ol style={{ marginBottom: 0 }}>
          <li>
            your name, if an individual or your company name (as appropriate);
          </li>
          <li>
            your contact details, such as your telephone number, physical
            address and email address;
          </li>
          <li>
            in case of Suppliers, information that allows us to process payments
            to us; Trade License number, a brief summary of your business and
            other additional information we need from time to time to be able to
            provide services to you. If you do not provide us with the necessary
            information, you will not be able to use our services.
          </li>
        </ol>
        <span style={{ marginTop: '-16px' }}>
          If you do not provide us with the necessary information, you will not
          be able to use our services.
        </span>
      </div>
      <h3>User submitted content</h3>
      <p>
        The Website allows you to post and submit content and when you do, we
        collect the information that you submit. If you post any content,
        pursuant to our terms and conditions of use, you authorise us to publish
        that content, to publish the name under which you submit the content and
        you indemnify us in respect of claims or losses resulting from our
        publication of that content.
      </p>
      <h3>Geo-Location Data</h3>
      <p>
        We may ask to track location-based information from your mobile device
        while using our website or the services we provide, in order to tailor
        your experience. If you don't grant permission then we will not collect
        any location-based data.
      </p>

      <h3>2.2 What does CarLogik do with my personal information?</h3>
      <div className={classes.listWrapper}>
        We collect, hold and use your personal information for a number of
        purposes, as outlined below: <br />
        In general, we use the information that we collect to:
        <ol>
          <li>provide services to you and operate the Website;</li>
          <li>
            contact you via phone, writing or email in relation to your requests
            for quotations for motor vehicle services and incomplete bookings or
            to respond to your queries;
          </li>
          <li>display, update and maintain the content of the Website;</li>
          <li>
            tailor your experience of the Website and our communications to you;
          </li>
          <li>
            and send information about future events, email communications or
            newsletters (both electronic and hardcopy) outlining news, services
            or events.
          </li>
        </ol>
      </div>

      <h3>Promotions</h3>
      <p>
        If we collect information from you as part of any promotions, we may use
        the information that we collect in profiling your use of our services
        and in promoting our services.
      </p>

      <h3>General uses</h3>
      <div className={classes.listWrapper}>
        We may also use the information that we collect from you:
        <ol>
          <li>
            for our internal administrative, marketing and planning
            requirements;
          </li>
          <li>
            to compile and report statistics, with all the relevant personal
            and/or proprietary information de-identified prior to the completion
            of such statistics;
          </li>
          <li>
            for purposes that are expressly permitted under any agreement with
            you, including the terms under which we agree to provide any service
            to you.
          </li>
        </ol>
      </div>

      <h3>2.3 To whom will CarLogik disclose my personal information?</h3>
      <p>
        We do not disclose contact information about you publicly or to other
        third parties, unless:
      </p>
      <ol>
        <li>
          it is authorised or required by law (or when we believe that
          disclosure is necessary to protect our rights and/or to comply with a
          judicial proceeding, court order, or legal process served on us in
          relation to the Website);
        </li>
        <li>pursuant to an agreement with you; or</li>
        <li>as otherwise outlined in this policy.</li>
      </ol>
      <p>
        We may display your submitted content regarding your reviews and
        comments about Suppliers for whom bookings can be made via the Website,
        as part of the Website. The Website may include functionalities that
        allow you to control whether or not the public or other users of the
        Website are able to view your user submitted content. We will use
        reasonable endeavours to implement your selection.
      </p>
      <p>
        When necessary to provide a service to you, we will also disclose your
        personal information (on a confidential basis) to Suppliers with whom
        you make service bookings, agents and others that we use in the ordinary
        operation of our business, user experience research and surveys, website
        hosting and support and maintenance. We will only disclose your
        information to the extent required for the limited purpose of the third
        party providing services to you (in the case of a participating
        Supplier) or as contracted to us so that we may provide our services.
      </p>
      <p>
        If CarLogik is involved in a merger, acquisition, or sale of all or a
        portion of its assets, your personal information or other information
        may be part of the transferred assets.
      </p>
      <p>
        We may also disclose aggregated data (in a form that does not identify
        you individually) to our advisors for the purposes of conducting market
        and user experience analysis.
      </p>
      <p>
        While we store the majority of the personal information that we collect
        from you in the UAE, we may from time to time store some of the
        information in a computer server located in the United States or
        elsewhere. We will take such steps as are reasonable in the
        circumstances to ensure that the overseas recipient does not breach the
        UAE privacy principles.
      </p>
      <h3>2.4 Testimonials</h3>
      <p>
        We may publish customer testimonials on our website, in social media
        profiles and within advertising creative material. These testimonials
        may contain personally identifiable information. We will obtain your
        written consent at the time the testimonial is recorded. If you want
        your testimonial removed please contact us at{' '}
        <a href="mailto:customer@carlogik.com">customer@carlogik.com</a>.
      </p>
      <h3>2.5 Manner of collection</h3>
      <p>
        We will use reasonable endeavours to only collect information directly
        from you. If you are disclosing details of another person, it is your
        sole responsibility to ensure that that person understands and consents
        to the collection, use and disclosure of their personal information in
        the manner set out in this policy.
      </p>
      <h2>3. Information collected by our website</h2>
      <h3>3.1 Tracking of user experience</h3>
      <p>
        As is true of most web sites and mobile applications, we and, in some
        instances, our third party tracking-utility partners gather certain
        information automatically and store it in log files. This information
        includes internet protocol (IP) addresses, browser type, internet
        service provider (ISP), referring/exit pages, operating system,
        date/time stamp, and clickstream data.
      </p>
      <p>
        We use this information, which does not identify individual users, to
        analyse trends, to administer the Website, to track users' movements
        around the Website and to gather demographic information about our user
        base as a whole.
      </p>
      <p>
        Technologies such as cookies, beacons, tags and scripts may be used by
        CarLogik and our partners (marketing and development partners),
        affiliates, or analytics or service providers (online customer support
        provider, email marketing system). These technologies are used in
        analysing trends, administering the Website, tracking users' movements
        around the site and to gather demographic information about our user
        base. We may receive reports based on the use of these technologies by
        these companies on an individual as well as aggregated basis.
      </p>
      <p>
        We may use cookies for authentication purposes and to tailor the Website
        to user preferences. Users can control the use of cookies at the
        individual browser level. If you reject cookies, you may still use our
        Website, but your ability to use some features or areas of our site may
        be limited.
      </p>
      <p>
        We may use Google Analytics Advertising products: Remarketing with
        Google Analytics and Google Analytics Demographics and Interest
        Reporting. Google Analytics collects data about our site traffic via
        Google Advertising cookies and anonymous identifiers. Data collected via
        these Google products is not linked with any personally identifiable
        information you submit while on our Website. If you wish to opt out of
        the Google Analytics data collection, you may do so on Google's website.
      </p>
      <p>
        Some components of the website utilises Google Maps mapping services.
        Your use of Google Maps is subject to Google's terms of use and Google's
        privacy policy, and may be amended by Google from time to time.
      </p>
      <h3>3.2 Advertisements</h3>
      <p>
        We may partner with a third party ad network to either display
        advertising on our Website or to manage our advertising on other sites.
        Our ad network partner may use cookies and Web beacons to collect
        non-personally identifiable information about your activities on the
        Website and other websites that you may visit to provide you targeted
        advertising based upon your interests.
      </p>
      <h3>3.3 Social Media Features</h3>
      <p>
        The Website may include Social Media Features, such as a Like button.
        These features may collect your IP address, which page you are visiting
        on our Website and may set a cookie to enable the feature to function
        properly. Social media features and widgets are either hosted by a third
        party or hosted directly on our Website. Your interactions with these
        features are governed by the privacy policy of the company providing
        them.
      </p>
      <h2>4. Marketing material</h2>
      <p>
        We may use your personal information to provide you with material,
        promotions and communications from time to time about services and
        products available to you from us, or from other service providers in
        partnership with us, such as participating Suppliers or to invite you to
        continue to use our services. This information will only come from us.
        We will not on-sell your personal information to other providers without
        your consent.
      </p>
      <p>
        You may opt out of receiving promotional materials from us at any time
        by following the 'unsubscribe' link on the relevant promotional emails.
      </p>
      <h2>5. Protecting and maintaining personal information</h2>
      <p>
        We will take all reasonable steps to protect the information we hold
        about you from unauthorised access, use and disclosure. However, we
        cannot guarantee that our systems and stored data will be completely
        free from third party interception or are free from corruption.
      </p>
      <p>
        No method of transmission over the Internet, or method of electronic
        storage, is 100% secure. In light of this, we cannot ensure or warrant,
        and do not warrant, the security or privacy of your personal
        information, including payment and account details. You transmit this
        information at your own risk. If you have any questions regarding
        security you can contact us at{' '}
        <a href="mailto:customer@carlogik.com">customer@carlogik.com</a>.
      </p>
      <p>
        You are solely responsible for maintaining the security of your
        passwords and/or any account information. We will destroy or de-identify
        your personal information when it is no longer needed or required to be
        kept by law or to provide services to you. We will also preserve the
        content of any email or information you submit if we believe we have a
        legal requirement to do so, or if any form of email abuse is suspected.
      </p>
      <p>
        If you use our Website to link to another site, we encourage you to view
        their terms and conditions of use, privacy policies and security
        statements before disclosing any of your personal information on those
        sites.
      </p>
      <h2>6. Accessing personal information</h2>
      <p>
        It is your responsibility to advise us of any changes to your personal
        details to ensure we can keep our records accurate and up to date. If
        you wish to access the information we hold about you, please submit a
        request to{' '}
        <a href="mailto:customer@carlogik.com">customer@carlogik.com</a> or by
        logging into your account (if you have one) and making the appropriate
        changes. If you believe that the personal information we hold about you
        is incorrect, you are entitled to request amendment of such information.
        If we do not agree that there are grounds for amendment, then we will
        add a note to the personal information stating that you disagree with
        it.
      </p>
      <p>
        If you know the information we hold about you is not accurate or
        complete, please notify us. We will respond to requests for access
        within 30 days.
      </p>
      <p>
        We will retain your information for as long as your account (if you have
        one) is active or as needed to provide you services. We will retain and
        use your information as necessary to comply with our legal obligations,
        resolve disputes, and enforce our agreements.
      </p>
      <h2>7. Privacy concerns</h2>
      <p>
        If you have any concerns about how we handle your personal information
        or require further information, please contact us at
        <a href="mailto:customer@carlogik.com"> customer@carlogik.com.</a> Also
        use this contact if you no longer consent to receiving marketing
        material from us, or to the posting of your personal information on the
        Website.
      </p>
      <p>
        This document represents our policy as at 15th December 2021. We may
        change this document from time to time to reflect changes to our
        information practices. Although we intend to observe this policy at all
        times, it is not legally binding on us in any way. From time to time we
        may regard it as necessary or desirable to act outside the policy. We
        encourage you to regularly review this statement to inform yourself of
        any amendments.
      </p>
      <h2>8. Contact us</h2>
      <p>
        Phone: 800LOGIK <br />
        <a href="mailto:customer@carlogik.com">
          Email: customer@carlogik.com
        </a>
        <br />
        Office: 8th floor, The Offices 4, One Central, Dubai, United Arab
        Emirates
      </p>
    </div>
  );
};
