import React, { HTMLAttributes, DetailedHTMLProps } from 'react';
import classes from './style.module.scss';
import { Modal } from 'antd';
import { ButtonComponent } from '../Button';

interface ModalPropsType extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>  {
  isModal: boolean;
  toggleModal: () => void;
  title?: string;
  children: React.ReactNode;
  icon?: any;
  buttonConfig?: Array<{ type: 'cancel' | 'save' | 'customer' | 'save-cancel'; title: string }>;
  onConfirm?: (type: 'cancel' | 'save') => void;
  isAlert?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  afterClose?: () => void;
};

export const ModalComponent: React.FC<ModalPropsType> = ({
  isModal,
  toggleModal,
  onSubmit,
  title,
  icon,
  children,
  buttonConfig,
  isAlert = false,
  onCancel,
  afterClose
}) => {
  return (
    <Modal
      title={!isAlert &&
        <div className={classes.modalHeader}>
          {icon && <img src={icon} alt="icon" />}
          <div className={classes.textTitle}><span>{title}</span></div>
        </div>
      }
      visible={isModal}
      onCancel={toggleModal}
      footer={null}
      width={isAlert ? 416 : 680}
      // wrapClassName={classes.modal}
      closable={!isAlert}
      afterClose={afterClose}
    >
      <div className={classes.modal}>
        <div className={classes.stepsInfo}>{children}</div>
        {buttonConfig && (
          <div className={classes.buttons}>
            {buttonConfig.map((button, index) => (
              <ButtonComponent
                type={button.type}
                title={button.title}
                onClick={button.type === 'save' || button.type === 'customer' ? (onSubmit || toggleModal): (onCancel || toggleModal)}
                fontSize14={isAlert}
                key={index}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
