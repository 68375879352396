import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import classes from './style.module.scss';
import './styles.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

type ServicesListPropsType = {
  services: Array<{ id: number; title: string }> | undefined;
  removeOtherService: (id: number, type?: string) => void;
  canRemove?: boolean;
  price?: string;
  otherServices?: Record<number, number>;
};

export const ServicesList: React.FC<ServicesListPropsType> = ({
  services,
  removeOtherService,
  canRemove = true,
  price,
  otherServices,
}) => {
  const getTitle = (title: string) => {
    switch (title) {
      case 'pickUp':
        return 'Car Pick-up';
      case 'dropOff':
        return 'Car Drop-off';
      default:
        return title;
    }
  };
  return (
    <div className={classes.logBookWrapper}>
      <TransitionGroup className={classes.serviceList}>
        {services?.map((item) => {
          return (
            <CSSTransition
              classNames={'noteAnimate'}
              key={item.id}
              timeout={800}
            >
              <div className={classes.itemWrapper} key={item.id}>
                <span className={classes.serviceName}>
                  {getTitle(item.title)}
                  {price && <span className={classes.price}>{price}</span>}
                </span>
                {canRemove ? (
                  <DeleteOutlined
                    className={classes.deleteIcon}
                    onClick={() => removeOtherService(item.id, item.title)}
                  />
                ) : (
                  <div className={classes.emptyIcon}>
                    {otherServices
                      ? 'AED ' + otherServices[item.id]?.toLocaleString('en-US', {maximumFractionDigits:0})
                      : ''}
                  </div>
                )}
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};
