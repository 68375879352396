import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Rate, Input } from 'antd';
import classes from './style.module.scss';
import workshopLogo from '../../../../../Assets/workshopImage2.jpeg';
import { ButtonComponent } from '../../../../../Components/Button';
import { ReviewRequestType } from '../../../../../Types/userTypes';
import { ResponseWorkshopType } from '../../../../../Types/authTypes';
import classNames from 'classnames';

const { TextArea } = Input;

type WorkshopReviewPropsType = {
  onSubmit: (review: ReviewRequestType) => void;
  workshop?: ResponseWorkshopType;
  onCancel: () => void;
};

export const WorkshopReview: React.FC<WorkshopReviewPropsType> = ({
  onSubmit,
  workshop,
  onCancel,
}) => {
  const [rate, setRate] = useState<number>(0);
  const [description, setDescription] = useState('');
  const [pulse, setPulse] = useState(false);

  const onChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onFinish = () => {
    if (rate === 0) {
      onAnimateStars();
    } else {
      onSubmit({ rate, description });
      setDescription('');
    }
  };

  const onAnimateStars = () => {
    setPulse(true);
    setRate(5);
    setTimeout(() => {
      setPulse(false);
      setRate(0);
    }, 500);
  };

  const addDefaultSrc = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    //@ts-ignore
    e.target.attributes.src.value = workshopLogo;
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span>Some words to help you:</span>
        <span> Just great, Just OK, or Just horrible!</span>
      </div>
      <div className={classes.wrapper}>
        <img
          src={workshop?.avatar?.url}
          alt="logo"
          className={classes.workshopImage}
          onError={addDefaultSrc}
          width={176}
        />
        <div style={{ width: '100%', paddingRight: '12px' }}>
          <Rate
            disabled={false}
            onChange={setRate}
            value={rate}
            className={classNames(classes.rate, {
              [classes.pulse]: pulse,
            })}
          />
          <TextArea
            className={classes.textArea}
            placeholder="Please ensure your comments adhere to CarLogik’s T&Cs and Community guidelines"
            onChange={onChangeDescription}
            value={description}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <ButtonComponent
          type="cancel"
          title="Cancel"
          onClick={onCancel}
          style={{ marginTop: '16px', marginBottom: '16px' }}
        />
        <ButtonComponent
          type="save"
          title="Send"
          onClick={onFinish}
          style={{ marginTop: '16px', marginBottom: '16px' }}
        />
      </div>
    </div>
  );
};
