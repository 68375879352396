import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import classes from './styles.module.scss';
import miniLogo from '../../../../Assets/Logo-icon.svg';
import watsappLogo from '../../../../Assets/whatsapp.svg'
import { ButtonComponent } from '../../../../Components/Button';
import { SideMenu } from './SideMenu';
import { RootStateType } from '../../../../Stores';
import { SignOut } from '../../../../Components/SignOut';
import { MenuItem } from './MenuItem';
import { authActions } from '../../../../Globals/Auth/actions';
import { userActions } from '../../../../Globals/User/actions';

type HeaderPropsType = {
  goTo: (path: string) => void;
};

export const Header: React.FC<HeaderPropsType> = ({ goTo }) => {

  const [visible, setVisible] = useState(false);
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const sendGAEvent = () => {
    ReactGA.event({
      category: 'Get Started',
      label: 'From header',
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } get started`,
    });
  };

  const dispatch = useDispatch();

  const onRedirect = () => {
    if (currentUser?.role.type === 'authenticated') {
      goTo('user/my-cars');
    } else if (currentUser?.role.type === 'authenticated_admin') {
      goTo('admin/clients');
    } else if (currentUser?.role.type === 'authenticated_workshop') {
      goTo('workshop/jobs');
    } else {
      // dispatch(authActions.resetOrderData());
      // dispatch(userActions.resetOrderData());
      // sendGAEvent();
      goTo('auth/customer/signup');
    }
  };

  return (
    <header className={classes.mainBlock} id="header">
      <div className={classes.container}>
        <div className={classes.forWidth}>
          <MenuOutlined
            onClick={() => {
              setVisible(true);
            }}
            className={classes.burgerButton}
          />

          <HashLink className={classes.carLogicIcon} smooth to="/#">
            <img src={miniLogo} alt="logo" />
          </HashLink>

          <SideMenu visible={visible} setVisible={setVisible} />
          <div className={classes.leftWrapper}>
            <ul className={classes.menu}>
              <li style={{margin: '0'}}>
                <MenuItem title="" path="find-service" />
              </li>
              <li>
                <MenuItem title="Our workshops" path="our-workshops" />
              </li>
              <li>
                <MenuItem title="How we work" path="how-work" />
              </li>
              <li>
                <MenuItem
                  title="Looking for other services?"
                  path="services-for-offer"
                />
              </li>
              <li>
                <MenuItem title="About Us " path="footer" />
              </li>
            </ul>
          </div>

          {currentUser ? (
              <div className={classes.rightWrapper}>
                <SignOut name={currentUser.workshop?.name || currentUser.username || ''} />
                <ButtonComponent
                  type="dark"
                  title="Get Started"
                  onClick={onRedirect}
                  className={classes.buttonSignUp}
                />
              </div>
            ) : (
              <div className={classes.rightWrapper}>
                <ul className={classes.menu}>
                  <li>
                    <Link to="/auth/workshop/signup">Be a Partner Workshop</Link>
                  </li>
                  <li>
                    <Link to="/auth/customer/login">Log In</Link>
                  </li>
                </ul>
                <ButtonComponent
                  type="dark"
                  title="Sign up"
                  onClick={onRedirect}
                  className={classes.buttonSignUp}
                />
              </div>
            )}
            <a className={classes.whatsapp} href="https://wa.me/97180056445" target="_blank">
                  <img src={watsappLogo} alt="whatsapp logo" />
                </a>
        </div>
      </div>
    </header>
  );
};
