import { ConfirmContext } from './../Context/ConfirmContext';
import { useContext } from 'react';

let resolveCallback: (value: boolean) => void;

function useConfirm() {
  const { confirmState, showConfirm, hideConfirm } = useContext(ConfirmContext);
  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = (text: string, textButtons?: [string, string]): Promise<boolean> => {
    showConfirm(text, textButtons);
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    hideConfirm();
  };

  return { confirm, onConfirm, onCancel, confirmState };
}

export default useConfirm;
