import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga';

import checkCircle from '../../Assets/orderIcon/check-circle.svg';
import TabbyLogo from '../../Assets/tabby-button-logo.svg'
import { ServicesList } from '../../Views/CreateOrder/Steps/ServicesStep/Components/ServicesList';
import classes from './style.module.scss';
import { RootStateType } from '../../Stores';
import { userActions } from '../../Globals/User/actions';
import { ModalComponent } from '../Modal';
import GarageIcon from '../../Assets/orderIcon/icon_at_workshop.svg';
import EmptyGarage from '../../Assets/orderIcon/icon_at_location.svg';
import { authActions } from '../../Globals/Auth/actions';
import { SavedDataObjectType } from '../../Types/userTypes';
import { PICK_UP_PRICE } from '../../Utils/Api/constants';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { TabbyPromo } from '../Tabby/TabbyPromo';

type CarAndServicesPropsType = {
  canRemove?: boolean;
  openAlert?: () => void;
  step?: number;
  isShow?: boolean;
  isShowTabbyPromo?: boolean;
};

export const CarAndServices: React.FC<CarAndServicesPropsType> = ({
  canRemove = true,
  openAlert,
  step,
  isShow = true,
  isShowTabbyPromo = true
}) => {
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState<boolean>(false);

  const closeModal = () => {
    setIsModal(false);
  };

  const location = useSelector((state: RootStateType) => state.user.location);

  const userRole = useSelector(
    (state: RootStateType) => state.auth.user?.user.role.name
  );
  
  const summaryPrice = useSelector(
    (state: RootStateType) => state.user.finalSummary
  );

  const selectedOtherServicesId = useSelector(
    (state: RootStateType) => state.user.selectedOtherServicesId
  );
  const otherServices = useSelector(
    (state: RootStateType) => state.user.services?.additional
  );
  const mainServices = useSelector(
    (state: RootStateType) => state.user.services?.log_book
  );
  const formValues = useSelector(
    (state: RootStateType) => state.user.formValues
  );
  const isBasic = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );
  const parts = useSelector(
    (state: RootStateType) => state.user.services?.check
  );

  const selectedOtherServices =
    otherServices?.filter((service) =>
      selectedOtherServicesId.find((id) => service.id === id)
    ) || [];

  const appointment = useSelector(
    (state: RootStateType) => state.user.appointment
  );

  const finalSummary = useSelector(
    (state: RootStateType) => state.user.finalSummary
  );

  const deliveryCar = useSelector(
    (state: RootStateType) => state.user.deliveryCar
  );

  const car_deliveries = useSelector(
    (state: RootStateType) => state.user.car_deliveries
  );

  const selectedWorkshop = useSelector(
    (state: RootStateType) => state.user.selectedWorkshop
  );

  const selectedDate = useSelector(
    (state: RootStateType) => state.user.selectedDate
  );

  const selectedCar = useSelector(
    (state: RootStateType) => state.user.selectedCar
  );

  const addressPoint = useSelector(
    (state: RootStateType) => state.user.addressPoint!
  );

  const customer_note = useSelector(
    (state: RootStateType) => state.user.customer_note
  );

  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );


  const createDataObject = useCallback(() => {
    return {
      selectedOtherServicesId,
      otherServices,
      formValues,
      isBasic,
      parts,
      selectedOtherServices,
      appointment,
      deliveryCar,
      selectedWorkshop,
      selectedDate,
      selectedCar,
      location,
      addressPoint,
      customer_note,
      car_deliveries,
      finalSummary
    };
  }, [
    appointment,
    formValues,
    isBasic,
    otherServices,
    parts,
    selectedDate,
    selectedCar,
    deliveryCar,
    selectedOtherServicesId,
    selectedWorkshop,
    car_deliveries,
    finalSummary
  ]);

  const dataObject: SavedDataObjectType = createDataObject();

  const removeOtherService = (id: number) => {
    if(id == 6){
      dispatch(userActions.removeAllCarRepair());
    }
    dispatch(userActions.removeOtherService(id));
  };

  const openModal = () => {
    ReactGA.event({
      category: 'Create order',
      label: 'Checks done opened',
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } opened checks done window`,
    });
    setIsModal(true);
  };

  const removeServiceType = () => {
    if (openAlert) {
      openAlert();
    } else {
      dispatch(userActions.setSelectedServiceType(null));
    }
  };

  const getTitle = (title: string) => {
    switch (title) {
      case 'pickUp':
        return 'Car Pick-up';
      case 'dropOff':
        return 'Car Drop-off';
      default:
        if(title.substring(0,4) == 'Free'){
          return title.substr(5,1).toUpperCase() + title.substring(6)
        }
        return title;
    }
  };

  // const removeDelivery = (_: number, type?: string) => {
  //   if (type === 'dropOff' || type === 'pickUp') {
  //     dispatch(userActions.removeDeliveryCar(type));
  //     if (type === 'pickUp') {
  //       dispatch(userActions.setDeliveryTime({ pick_up_time_slot: null }));
  //     } else if (type === 'dropOff') {
  //       dispatch(userActions.setDeliveryTime({ drop_off_time_slot: null }));
  //     }
  //   }
  // };

  const openServiceModal = () => {
    dispatch(userActions.toggleModalIsSidebar());
    dispatch(userActions.setIsModal(true));
  };

  const addDefaultSrc = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    //@ts-ignore
    e.target.attributes.src.value = defaultImage;
  };

  useEffect(() => {
    if ((step === 5 && !userRole)) {
      dispatch(authActions.setSavedSummaryData(dataObject));
    }
  }, [dispatch, step, userRole]);

  useEffect(() => {
    
  }, [isShow,isShowTabbyPromo]);
  return (
    <div className={classes.rightBlockContainer}>
        <div className={classes.carImage}>
          {formValues?.model?.image_url && <img
            src={formValues?.model?.image_url}
            alt="car"
            // onError={addDefaultSrc}
          />}
          <div className={classes.headInfo}>
            <h4>
              {formValues?.brand?.title} {formValues?.model?.title}{' '}
              <span style={{ display: 'inline-block', marginLeft: '12px' }}>
                {typeof formValues?.year === 'number'
                  ? formValues?.year
                  : formValues?.year?.year}
              </span>
            </h4>
            <div>
              <span className={classes.engineMilliage}>Engine</span>{' '}
              <b>{formValues?.trim?.title}</b>
            </div>
            {formValues?.milliage && (
              <div>
                <span className={classes.engineMilliage}>Mileage</span>{' '}
                <b>
                  {formValues?.milliage?.value
                    ? `${formValues?.milliage?.value.toLocaleString()} km`
                    : ''}
                </b>
              </div>
            )}
          </div>
        </div>
      <div className={classes.rightBlockInfo}>
        <div className={classes.servicesList}>
          <div className={classes.summari}>Summary</div>

          <div className={classes.summariHeader}>
          {selectedWorkshop && (
            <div className={classes.workshopName}>Service at {selectedWorkshop.name}</div>
          )}

          {selectedDate && (
            <div className={classes.itemWrapperDate}>
              <span className={classes.title}>Date</span>
              <span className={classes.time}>
                {moment(selectedDate.startDate, 'YYYY MM DD').format(
                  'DD MMM YYYY'
                )}
                ,{' '}
                {moment(selectedDate.startTime, 'hh:mm a').format(
                  'hh:mm A'
                )}
              </span>
            </div>
          )}
          {appointment?.estimate && selectedDate && (
            <div className={classes.itemWrapperDate}>
              <span className={classes.title}>Time estimate</span>
                  <span className={classes.time}>
                {appointment.estimate.hours}h {appointment.estimate.min}m
              </span>
            </div>
          )}
          </div>
              
          <div className={classes.priceBreakdown}>
            <span>Price Breakdown</span>
            <p>Clear pricing with no surprises</p>
          </div>

          <div className={classes.services}>
            {isBasic !== null && (
              <div className={`${classes.devider} ${selectedWorkshop ? `${classes.basicService}` : ''} `}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <div className={classes.servicesTitle} onClick={openServiceModal}>
                    <span>{!isBasic ? 'Major Service' : 'Minor Service'}</span>
                  </div>
                  {canRemove &&
                    <DeleteOutlined
                      className={classes.deliteIcon}
                      onClick={removeServiceType}
                    />
                  }
                </div>
                {!selectedWorkshop?.main_services_titles ? (
                  <div>
                    <div className={classes.followingParts}>Includes the  following parts:</div>
                    <div className={classNames(classes.emptyIcon, classes.topPad)}>
                      <div className={classes.subServiceTitle}>
                        {mainServices?.map((item:any) => {
                           if(!isBasic || !item.optional){
                            return (
                              <div className={classes.chieldPrices} key={item.id}>
                              <div className={classes.mainServiceTitle}>{item.title}</div>
                              {/* <div className={classes.price}>{(selectedWorkshop?.main_prices[item.id] == 0) ? 'FREE' : 'AED ' +  selectedWorkshop?.main_prices[item.id]?.toLocaleString('en-US', {maximumFractionDigits:0})}</div> */}
                            </div>
                          );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={classes.followingParts}>Includes the  following parts:</div>
                    <div className={classNames(classes.emptyIcon, classes.topPad)}>
                      <div className={classes.subServiceTitle}>
                        {selectedWorkshop?.main_services_titles.map((item:any) => {
                          return (
                            <div className={classes.chieldPrices} key={item.id}>
                              <div className={classes.mainServiceTitle}>{item.title}</div>
                              {selectedWorkshop?.main_prices[item.id] == 0 ?
                                <div className={classes.freePrice}>{'FREE'}</div>
                                :
                                <div className={classes.price}>{'AED ' +  selectedWorkshop?.main_prices[item.id]?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
                              }
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {(!!selectedOtherServices.length  || selectedWorkshop?.additional_services_titles) && (
              <div className={classes.devider}>
                <div className={classes.servicesTitle}>Other services</div>
                <div className={classes.itemContainer}>
                <ServicesList
                    services={selectedOtherServices.length ? selectedOtherServices : selectedWorkshop?.additional_services_titles}
                    removeOtherService={removeOtherService}
                    canRemove={canRemove}
                    otherServices={selectedWorkshop?.additional_prices}
                  />   
                </div> 
              </div>
            )}
          </div>

          <div className={classes.workshopPrices}>
            {selectedWorkshop && (
              <div className={classes.deviderPrice}>
                <div className={classes.servicesTitle}>Labor Charges</div>
                <div className={classes.priceBold}>AED {selectedWorkshop?.labor_price?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
              </div>
            )}
            {selectedWorkshop && (
              <div className={classes.deviderPriceTotal}>
                <div className={classes.servicesTitle} style={{marginBottom: "0"}}>Total Price</div>
                <div className={classes.priceTotal}>AED {selectedWorkshop?.total_price?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
              </div>
            )}
            {selectedWorkshop?.vat && (
              <div className={classes.deviderPrice} style={{marginBottom: "6px"}}>
                <div className={classes.servicesTitle}>VAT 5%</div>
                <div className={classes.priceBold}>AED {(
                    selectedWorkshop.total_vat_price -
                    selectedWorkshop.total_price
                  )?.toLocaleString('en-US', {maximumFractionDigits:0})}</div>
              </div>
            )}
          </div>
          
          <div className={classes.promotions}>
            {deliveryCar.pick_up_slot && (
                <div className={classes.deviderPrice} style={{marginTop: "8px"}}>
                  <div className={classes.servicesTitle}>Car Pick-up</div>
                </div>
            )}
            {deliveryCar.pick_up_slot && (
            <TransitionGroup>
              <CSSTransition
                classNames={'noteAnimate'}
                timeout={800}
              > 
                <div className={classes.itemWrapper} style={{marginLeft: '24px'}}>
                    <span className={classes.name}>{car_deliveries[0].delivery_types[deliveryCar.pick_up_slot - 1].title}</span>
                  <div className={classes.rightPart}>
                    <span className={classes.valued}>{
                      car_deliveries[0].delivery_types[deliveryCar.pick_up_slot - 1].price == null ? 
                      <></>
                      :
                      car_deliveries[0].delivery_types[deliveryCar.pick_up_slot - 1].price == 0 ?
                        'Valued at AED 50'
                        :
                        <></>
                    }
                    </span>
                    <span className={classes.price}>{
                      car_deliveries[0].delivery_types[deliveryCar.pick_up_slot - 1].price == null ?
                        <></>
                        :
                        car_deliveries[0].delivery_types[deliveryCar.pick_up_slot - 1].price == 0 ?
                          'FREE'
                          :
                          `AED ${car_deliveries[0].delivery_types[deliveryCar.pick_up_slot - 1].price}`
                    }
                    </span>
                  </div>
                </div>
              </CSSTransition>
            </TransitionGroup>
            )}
            {deliveryCar.drop_off_slot && (
                <div className={classes.deviderPrice} style={{marginTop: "4px"}}>
                  <div className={classes.servicesTitle}>Car Drop-off</div>
                </div>
            )}
            <TransitionGroup>
              {deliveryCar.drop_off_slot && (
              <CSSTransition
                classNames={'noteAnimate'}
                timeout={800}
              >
                <div className={classes.itemWrapper} style={{marginLeft: '24px'}}>
                  <span className={classes.name}>{car_deliveries[1].delivery_types[deliveryCar.drop_off_slot - 1].title}</span>
                  <div className={classes.rightPart}>
                  <span className={classes.valued}>{
                      car_deliveries[1].delivery_types[deliveryCar.drop_off_slot - 1].price == null ? 
                      <></>
                      :
                      car_deliveries[1].delivery_types[deliveryCar.drop_off_slot - 1].price == 0 ?
                        'Valued at AED 50'
                        :
                        <></>
                    }
                    </span>
                    <span className={classes.price}>{
                      car_deliveries[1].delivery_types[deliveryCar.drop_off_slot - 1].price == null ?
                        <></>
                        :
                        car_deliveries[1].delivery_types[deliveryCar.drop_off_slot - 1].price == 0 ?
                          'FREE'
                          :
                          `AED ${car_deliveries[1].delivery_types[deliveryCar.drop_off_slot  - 1].price}`
                    }
                    </span>
                  </div>
                </div>
              </CSSTransition>
              )}
            </TransitionGroup>
            <TransitionGroup>
              {selectedWorkshop?.workshop_promotions.map((item,index) => {
                return (
                  <CSSTransition
                    classNames={'noteAnimate'}
                    key={index}
                    timeout={800}
                  >
                    <div className={classes.itemWrapper} key={index}>
                      <span className={classes.name}>{getTitle(item.title)}</span>
                      <div className={classes.rightPart}>
                        {/* style={{marginRight: '27px'} */}
                        {item.price == 0 ?
                          <></>
                          :
                          <span className={classes.valued}>Valued at AED {item.price}</span>
                        }
                        <span className={classes.price}>FREE</span>  
                      </div>

                      {/* {step !== 6 && (
                        <DeleteOutlined
                          className={classes.deleteIcon}
                          onClick={() => removeDelivery(index, item)}
                        />
                      ) 
                      } */}
                    </div>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
            {selectedWorkshop?.total_price && (
              <div>
                {/* <div className={classes.total}>Total</div>
                <div className={classes.price}>AED {selectedWorkshop?.total_price?.toLocaleString('en-US', {maximumFractionDigits:0})}</div> */}
                {summaryPrice && (
                  <div className={classes.amount}>
                    <span className={classes.title}>Total Order</span>
                    <span className={classes.price}>
                      AED{' '}
                      {summaryPrice.toLocaleString('en-US', {
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                )}
              </div>
            )}
            {/* <button data-tabby-info="installments|payLater">ⓘ</button> */}
            {/* {console.log(<TabbyPromo price={`${selectedWorkshop?.total_vat_price}`}/>)} */}
            {isShowTabbyPromo ?
              <div className={classes.tabbyPromo}>
                <p>4 interest-free payments with</p>
                <img src={TabbyLogo} alt="tabby logo" />
              </div>
              // selectedWorkshop?.total_vat_price ?
              //   <TabbyPromo price={`${selectedWorkshop?.total_vat_price}`}/>
              //   // <TabbyPromo price={`${selectedWorkshop?.total_price}`}/>
              //   :
              //   <div className={classes.tabbyPromo}>
              //     <p>4 interest-free payments with</p>
              //     <img src={TabbyLogo} alt="tabby logo" />
              //   </div>
              :
              <></>
            }
          </div>
        </div>
      </div>
      <ModalComponent
        isModal={isModal}
        toggleModal={closeModal}
        title="Checks done"
        icon={checkCircle}
      >
        {/* <div className={classes.partsList}>
          <div className={classes.header}>
            Logbook services are unique to your car, based on your car’s
            manufacturer recommendations, and are to the highest industry
            standards.
          </div>
          <div className={classes.content}>
            <div className={classes.leftBlock}>
              {parts?.map(
                (part, index) =>
                  index % 2 === 0 && (
                    <div className={classes.row} key={part.id}>
                      <CheckCircleOutlined
                        style={{ fontSize: '15px', color: '#5B8DB4' }}
                      />
                      {part.title}
                    </div>
                  )
              )}
            </div>
            <div className={classes.rightBlock}>
              {parts?.map(
                (part, index) =>
                  index % 2 !== 0 && (
                    <div className={classes.row} key={part.id}>
                      <CheckCircleOutlined
                        style={{ fontSize: '15px', color: '#5B8DB4' }}
                      />
                      {part.title}
                    </div>
                  )
              )}
            </div>
          </div>
        </div> */}
      </ModalComponent>
    </div>
  );
};
