import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';

import sandClock from '../../../Assets/sandClock.png';

import moment from 'moment';

export type TimerPropsType = {
  createdAt: string;
  timer?: number;
};

export const Timer: React.FC<TimerPropsType> = ({
  createdAt,
  timer = 1800,
}) => {
  const created = moment(createdAt);
  const passedSeconds = moment().diff(created, 'seconds');
  const leftSeconds = timer - passedSeconds;

  const [passedTime, setPassedTime] = useState(leftSeconds);

  useEffect(() => {
    if (passedTime > 0) {
      const counter = setInterval(() => {
        setPassedTime((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(counter);
    }
  }, []);

  const getTime = () => {
    if (passedTime < 0) {
      return null;
    }
    const hours = Math.floor(passedTime / 60 / 60);
    const minutes = Math.floor((passedTime - hours * 3600) / 60);
    const seconds = passedTime - minutes * 60 - hours * 3600;
    let secondsTwo;
    if (seconds < 10) {
      secondsTwo = `0${seconds}`;
    } else {
      secondsTwo = seconds;
    }
    return {
      minutes,
      secondsTwo,
      hours,
    };
  };

  return (
    <div className={classes.time}>
      <img src={sandClock} alt="clock" />
      <p
        className={[passedTime < 300 ? classes.danger : classes.null].join(' ')}
      >
        {passedTime > 0
          ? `${getTime()!.hours > 0 ? getTime()!.hours+':' : ''}${
              getTime()!.minutes
            }:${getTime()!.secondsTwo}`
          : '30 min passed'}
      </p>
    </div>
  );
};
