import React, { HTMLAttributes, DetailedHTMLProps } from 'react';
import cn from 'classnames';
import classes from './style.module.scss';

export interface ButtonPropsType
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  onClick?: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  type?: 'save' | 'cancel' | 'decline' | 'customer' | 'dark' | 'save-cancel';
  size?: 'sm' | 'md' | 'lg' | 'max';
  style?: any;
  fontSize14?: boolean;
  breakpointOff?: boolean;
}

export const ButtonComponent: React.FC<ButtonPropsType> = ({
  title,
  onClick,
  onSubmit,
  disabled = false,
  type = 'save',
  size = 'sm',
  breakpointOff = false,
  style,
  fontSize14,
  children,
  className,
}) => (
  <button
    onClick={onClick}
    onSubmit={onSubmit}
    type={type === 'save' ? 'submit' : 'button'}
    style={{ ...style }}
    className={cn(classes.button, className, {
      [classes.saveButton]: type === 'save' || type === 'save-cancel',
      [classes.saveDark]: type === 'dark',
      [classes.decline]: type === 'decline',
      [classes.cancelButton]: type === 'cancel',
      [classes.withoutBackground]: type === 'customer',
      [classes.sm]: size === 'sm',
      [classes.md]: size === 'md',
      [classes.lg]: size === 'lg',
      [classes.max]: size === 'max',
      [classes.alertButtons]: fontSize14,
      [classes.disabled]: disabled,
      [classes.fixWidth]: breakpointOff,
    })}
    disabled={disabled}
  >
    {children ? children : <span>{title}</span>}
  </button>
);
