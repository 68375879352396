import React from 'react';
import {
  UserAddOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  DownCircleOutlined,
  MinusCircleOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import classes from './style.module.scss';

type ActionContentPropsType = {
  actions: Array<string>;
  id: number;
  onAction: (data: { id: number; action: string }) => void;
  isWorkshops: boolean;
};

export const ActionContent = ({
  actions,
  id,
  onAction,
  isWorkshops,
}: ActionContentPropsType) => {
  const handleSubmit = (id: number, action: string) => {
    const data = { id, action };
    onAction(data);
  };

  const getActionsValues = (
    action: string
  ): {
    icon: any;
    color: string;
  } => {
    switch (action) {
      case 'Approve':
        return {
          icon: <UserAddOutlined />,
          color: 'black',
        };
      case 'Reject':
        return {
          icon: <UserDeleteOutlined />,
          color: 'black',
        };
      case 'Delete':
        return {
          icon: <DeleteOutlined />,
          color: 'red',
        };
      case 'Block':
        return {
          icon: <UserDeleteOutlined />,
          color: 'black',
        };
      case 'Unblock':
        return {
          icon: <UserAddOutlined />,
          color: 'black',
        };
      case 'Accept order':
        return {
          icon: <DownCircleOutlined />,
          color: 'black',
        };
      case 'Decline order':
        return {
          icon: <MinusCircleOutlined />,
          color: 'black',
        };
      default:
        return {
          icon: <CheckCircleOutlined />,
          color: 'black',
        };
    }
  };

  return (
    <div>
      {actions?.map((item: string, index) => (
        <div
          key={index}
          className={[
            classes.menuItem,
            classes[getActionsValues(item).color],
          ].join(' ')}
          onClick={() => {
            handleSubmit(id, item);
          }}
        >
          {getActionsValues(item).icon}
          <p>{item}</p>
        </div>
      ))}
    </div>
  );
};
