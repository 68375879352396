import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Moment } from 'moment';
import moment from 'moment';
import { Tabs, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import classes from './style.module.scss';

import { TableComponent } from '../../Components/Table/Table';
import { MainLayout } from '../../Components/MainLayout';

import { workshopActions } from '../../Globals/Workshop/actions';

import { RootStateType } from '../../Stores';
import { InvoiceType } from '../../Types/workshopTypes';
import { QueryObjectType } from '../../Types/authTypes';
import { TableConfigItem } from '../../Types/appTypes';
import { appApi } from '../../Globals/App/services';
import classNames from 'classnames';
import { BankAccount } from '../../Components/BankAccount';

const { TabPane } = Tabs;

const tableConfig: Array<TableConfigItem> = [
  {
    title: 'Invoice ID',
    key: 'invoice_id',
    isSort: false,
    suffixIcon: 'download',
    align: 'right',
  },
  {
    title: 'Date & Time',
    key: 'date_time',
    isSort: false,
    suffixIcon: 'download',
    align: 'right',
  },
  {
    title: 'Invoice amount',
    key: 'invoice_amount',
    isSort: false,
    suffixIcon: 'download',
    align: 'right',
  },
  {
    title: 'Payments',
    key: 'payments',
    isSort: false,
    suffixIcon: 'download',
    align: 'right',
  },
  {
    title: 'Payment status',
    key: 'status',
    isSort: false,
    suffixIcon: 'download',
    align: 'center',
  },
  {
    title: 'Workshop invoice',
    key: 'workshop_invoice',
    isSort: false,
    align: 'center',
  },
];

const LIMIT_ON_PAGE = 20;

export const WorkshopFinance = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(LIMIT_ON_PAGE);
  const [sortDate, setSortDate] = useState<Moment | null>(null);
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([{ created_at: 'DESC' }]);

  const dispatch = useDispatch();

  const monthlyRevenue = useSelector(
    (state: RootStateType) => state.workshop.monthlyRevenue
  );

  const currentWorkshop = useSelector(
    (state: RootStateType) => state.auth.user?.user.workshop
  );

  const [sortStatus, setSortStatus] = useState<
    'paid' | 'not_paid' | 'pending' | null
  >('paid');

  const invoicesList = useSelector(
    (state: RootStateType) => state.workshop.invoices
  );

  const dataLayer = window.dataLayer || []

  const onChangeSortStatus = (e: 'paid' | 'not_paid' | 'pending' | '') => {
    if (e) {
      setSortStatus(e);
    } else {
      setSortStatus(null);
    }
  };

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const getInvoice = (key: number) => {
    const selectedInvoice = invoicesList.rows.find(
      (invoice) => invoice.id === key
    );
    if(!selectedInvoice?.invoice?.url) {
      return
    }
    appApi.downloadFile(
      `${selectedInvoice.invoice.url}`,
      selectedInvoice.invoice.name
    );
  };

  const generateQuery = useCallback(
    (nextPage: number): QueryObjectType => {
      const obj = {
        page: {
          page: nextPage,
          limit: limitOnPage,
        },
        filter: sortStatus ? [{ payment_status: sortStatus }] : [],
        sort: sortValue,
      };
      return {
        ...obj,
        ...(sortDate && {
          filter: [
            ...obj.filter,
            {
              job_end_date_gte:
                sortDate?.startOf('month').format('YYYY-MM-DD') +
                'T00:00:00.000Z',
            },
            {
              job_end_date_lt:
                sortDate
                  ?.clone()
                  .endOf('month')
                  .add(1, 'day')
                  .format('YYYY-MM-DD') + 'T00:00:00.000Z',
            },
          ],
        }),
      };
    },
    [sortDate, sortStatus, limitOnPage]
  );

  const onDataChange = (value: Moment | null, dateString: string) => {
    setSortDate(value);
  };

  const createDataList = () => {
    if (invoicesList.rows.length) {
      return invoicesList.rows.map((invoice: InvoiceType) => {
        return {
          key: invoice.id,
          invoice_id: invoice.id,
          date_time:
            invoice.job_end_date &&
            moment(invoice.job_end_date).format('MMM DD, YYYY hh:mm A'),
          invoice_amount: invoice.total_price.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          }),
          payments: invoice.workshop_amount.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          }),
          status: invoice.workshop_payment_status,
          workshop_invoice: (
            <DownloadOutlined
              className={classNames(classes.downloadIcon, {
                [classes.disabledIcon]: !invoice.invoice?.url,
              })}
              onClick={() => {
                getInvoice(invoice.id);
              }}
            />
          ),
          //link: invoice.invoice?.url,
        };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/workshop/finance",
      "page_type":"workshopFinancePage",
      "event":"page_view"
    });
    //End
  }, []);

  useEffect(() => {
    dispatch(workshopActions.getWorkshopInvoices(generateQuery(currentPage)));
  }, [dispatch, currentPage, generateQuery]);

  useEffect(() => {
    dispatch(workshopActions.getWorkshopMonthlyRevenue());
  }, [dispatch]);

  return (
    <MainLayout headerTitle="Finance">
      <Tabs defaultActiveKey="1" onChange={() => {}} className={classes.tabs}>
        <TabPane tab="Invoices" key="1">
          <div className={classes.contentContainer}>
            <div className={classes.headerContainer}>
              <div className={classes.filtersContainer}>
                <div className={classes.statusContainer}>
                  <div className={classes.label}>Filter by</div>
                  {/* <Select
                    placeholder="Status"
                    className={classes.statusFilter}
                    size="large"
                    onChange={onChangeSortStatus}
                  >
                    <Option value="paid">Paid</Option>
                    <Option value="pending">Pending</Option>
                    <Option value="not_paid">Not Paid</Option>
                    <Option value="">All</Option>
                  </Select> */}
                </div>
                <DatePicker
                  onChange={onDataChange}
                  picker="month"
                  size="large"
                  className={classes.dataPicker}
                />
                {/* <img src={excelIcon} alt="icon microsoft excel" /> */}
              </div>
              <div className={classes.totalMonth}>
                <span>Current Month Total: </span>
                <span>
                  AED{' '}
                  {monthlyRevenue
                    ? monthlyRevenue.toLocaleString('en-US', {
                        maximumFractionDigits: 0,
                      })
                    : 0}
                </span>
              </div>
            </div>
            <TableComponent
              dataForTableList={createDataList()}
              tableConfig={tableConfig}
              totalItems={invoicesList.count}
              onChangePage={onChangePage}
              onDownloadClick={getInvoice}
              isLastColumn={false}
            />
          </div>
        </TabPane>
        <TabPane tab="Bank account details" key="2">
          {currentWorkshop && (
            <BankAccount wsId={currentWorkshop.workshop_payment} />
          )}
        </TabPane>
      </Tabs>
    </MainLayout>
  );
};
