import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import classes from './styles.module.scss';
import miniLogo from '../../../Assets/Logo-icon.svg';
import watsappLogo from '../../../Assets/whatsapp.svg'
import { RootStateType } from '../../../Stores';

type HeaderPropsType = {
  goTo: (path: string) => void;
};

export const Header: React.FC<HeaderPropsType> = ({ goTo }) => {

  const [visible, setVisible] = useState(false);
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const sendGAEvent = () => {
    ReactGA.event({
      category: 'Get Started',
      label: 'From header',
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } get started`,
    });
  };

  const dispatch = useDispatch();

  const onRedirect = () => {
    if (currentUser?.role.type === 'authenticated') {
      goTo('user/my-cars');
    } else if (currentUser?.role.type === 'authenticated_admin') {
      goTo('admin/clients');
    } else if (currentUser?.role.type === 'authenticated_workshop') {
      goTo('workshop/jobs');
    } else {
      // dispatch(authActions.resetOrderData());
      // dispatch(userActions.resetOrderData());
      // sendGAEvent();
      goTo('auth/customer/signup');
    }
  };

  return (
    <header className={classes.mainBlock} id="header">
      <div className={classes.container}>
        <div className={classes.forWidth}>
          {/* <MenuOutlined
            onClick={() => {
              setVisible(true);
            }}
            className={classes.burgerButton}wwwwww
          /> */}

          <HashLink className={classes.carLogicIcon} smooth to="/#">
            <img src={miniLogo} alt="logo" />
          </HashLink>

            <a className={classes.whatsapp} href="https://wa.me/97180056445" target="_blank">
                  <img src={watsappLogo} alt="whatsapp logo" />
                </a>
        </div>
      </div>
    </header>
  );
};
