import { all } from 'redux-saga/effects';
import WatcherAuthSaga from '../Globals/Auth/sagas';
import WatcherAdminSaga from '../Globals/Admin/sagas';
import WatcherWorkshopSaga from '../Globals/Workshop/sagas';
import WatcherUserSaga from '../Globals/User/sagas';
import WatcherAppSaga from '../Globals/App/sagas';

export default function* rootSaga() {
  yield all([
    WatcherAuthSaga(),
    WatcherAdminSaga(),
    WatcherWorkshopSaga(),
    WatcherUserSaga(),
    WatcherAppSaga(),
  ]);
}
