import {
  PaymentsDetailsResponse,
  StripeInfoType,
} from './../../Types/workshopTypes';
import {
  OrderForWorkshopType,
  OrderService,
  OrderServicesForJob,
  OrdersRequestType,
  PaymentPreferencesRequestType,
  RequestInvoiceType,
  SpecialistType,
  WorkshopAppointmentRequestType,
} from '../../Types/workshopTypes';
import {
  ScheduleRequest,
  WorkshopPricesResponse,
  WorkShopType,
  CitiesTypeRequest
} from './../../Types/authTypes';
import { ActionTypes } from './actions';
import * as workshopConstants from './constants';

export type InitialStateType = {
  workshopData: WorkShopType | null;
  workshopSchedules: ScheduleRequest | null;
  workshopPrices: WorkshopPricesResponse | null;
  orders: OrdersRequestType<Array<OrderService> | OrderServicesForJob>;
  isHasMore: boolean;
  order: OrderForWorkshopType<OrderServicesForJob> | null;
  cities: Array<CitiesTypeRequest>;
  specialists: Array<SpecialistType>;
  promotions: Array<SpecialistType>;
  invoices: RequestInvoiceType;
  appointments: WorkshopAppointmentRequestType;
  monthlyRevenue: number | null;
  paymentsLink: PaymentPreferencesRequestType | null;
  stripeInfo: StripeInfoType | null;
  paymentsDetails: PaymentsDetailsResponse | null;
};
const initialState: InitialStateType = {
  workshopData: null,
  workshopSchedules: null,
  workshopPrices: null,
  orders: { count: 0, rows: [] },
  isHasMore: true,
  order: null,
  cities: [],
  specialists: [],
  promotions: [],
  invoices: { count: 0, rows: [] },
  appointments: { count: 0, rows: [] },
  monthlyRevenue: null,
  paymentsLink: null,
  stripeInfo: null,
  paymentsDetails: null,
};

const workshopReducer = (
  state: InitialStateType = initialState,
  action: ActionTypes
): InitialStateType => {
  // @ts-ignore
  switch (action.type) {
    case workshopConstants.SET_WORKSHOP_DATA:
      return {
        ...state,
        workshopData: action.payload,
      };
    case workshopConstants.SET_WORKSHOP_SCHEDULES:
      return {
        ...state,
        workshopSchedules: action.payload,
      };
    case workshopConstants.SET_WORKSHOP_PRICES:
      return {
        ...state,
        workshopPrices: action.payload,
      };
    case workshopConstants.SET_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          rows: [...state.orders.rows, ...action.payload.rows],
        },
      };
    case workshopConstants.RESET_ORDERS:
      return {
        ...state,
        orders: { count: 0, rows: [] },
      };
    case workshopConstants.UPDATE_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          rows: [
            ...state.orders.rows.filter(
              (order) => order.id !== action.payload.id
            ),
            action.payload,
          ],
        },
      };
    case workshopConstants.SET_CITIES:
      return {
        ...state,
        cities: action.cities,
      };
    case workshopConstants.IS_HAS_MORE:
      return {
        ...state,
        isHasMore: action.payload,
      };
    case workshopConstants.SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case workshopConstants.RESET_ORDER:
      return {
        ...state,
        order: null,
      };
    case workshopConstants.SET_WORKSHOP_SPECIALISTS:
      return {
        ...state,
        specialists: action.payload,
      };
    case workshopConstants.SET_WORKSHOP_PROMOTIONS:
      return {
        ...state,
        promotions: action.payload,
      };
    case workshopConstants.SET_WORKSHOP_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case workshopConstants.SET_WORKSHOP_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case workshopConstants.SET_WORKSHOP_MONTHLY_REVENUE:
      return {
        ...state,
        monthlyRevenue: action.payload.total_price,
      };
    case workshopConstants.SET_WORKSHOP_PAYMENTS_LINK:
      return {
        ...state,
        paymentsLink: action.payload,
      };
    case workshopConstants.SET_UPDATED_PHONES:
      return {
        ...state,
        workshopData: {
          ...state.workshopData!,
          workshop_phones: action.payload,
        },
      };
    case workshopConstants.SET_STRIPE_INFO:
      return {
        ...state,
        stripeInfo: action.payload,
      };
    case workshopConstants.SET_WORKSHOP_PAYMENTS_DATA:
      return {
        ...state,
        paymentsDetails: action.payload,
      };
    default:
      return state;
  }
};

export default workshopReducer;
