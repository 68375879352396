import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { MainLayout } from '../../Components/MainLayout';
import { Additional } from './Additional';
import { Appointments } from './Appointments';
import classes from './style.module.scss';
import { Cars } from './Cars';
import { TopBar } from './Components/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from '../../Globals/Admin/actions';
import { RootStateType } from '../../Stores';
import { UserInfo } from './UserInfo';

const { TabPane } = Tabs;

type AdminClientProfilePropsType = {
  userId: number;
};

export const AdminClientProfile: React.FC<AdminClientProfilePropsType> = ({
  userId,
}) => {
  //const location = useLocation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');

  const customerCars = useSelector((state: RootStateType) => state.admin.cars);
  const userProfile = useSelector((state: RootStateType) => state.admin.user);

  useEffect(() => {
    if (userId) {
      dispatch(
        adminActions.getCars(userId, {
          page: { page: 1, limit: 100 },
        })
      );
    }
  }, [dispatch, userId]);

  return (
    <MainLayout isHorizontalPadding={false}>
      <div className={classes.topBarWrapper}>
        {userProfile && <TopBar user={userProfile} />}
      </div>

      <Tabs
        defaultActiveKey={activeTab}
        onChange={setActiveTab}
        style={{ border: 'none', position: 'relative' }}
      >
        <TabPane tab="Cars" key="1">
          <Cars customerCars={customerCars} />
        </TabPane>
        <TabPane tab="Appointments" key="2">
          <Appointments userId={userId} />
        </TabPane>
        <TabPane tab="User info" key="3">
          <UserInfo />
        </TabPane>
        <TabPane tab="Additional info" key="4">
          <Additional />
        </TabPane>
      </Tabs>
    </MainLayout>
  );
};
