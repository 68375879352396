import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../Stores';
//ыimport TagManager from 'react-gtm-module'

import classes from './styles.module.scss';
import { Header } from './Components/Header';
import { Banner } from './Components/Banner';
import { Tailored } from './Components/Tailored';
import { Partners } from './Components/Partners';
import { TabbyPromo } from './Components/TabbyPromo';
import { Testimonials } from './Components/Testimonials';
import { HowItWorks } from './Components/HowItWorks';
import { ServiceForOffer } from './Components/ServiceForOffer';
import { Footer } from './Components/Footer';
import { userActions } from '../../Globals/User/actions';
import { appActions } from '../../Globals/App/actions';
import { authActions } from '../../Globals/Auth/actions';

export const LandingPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateType) => state.auth.user);
  const dataLayer = window.dataLayer || []
  const goTo = (path: string) => {
    history.push(path);
  };

  dispatch(userActions.removeFormID())

  useEffect(() => {
    if (~location.search.indexOf('?code=')) {
      history.push('auth/customer/forgot-password', { code: location.search });
    }
  }, [history, location.search]);

  useEffect(() =>
  {
    //GTM
    dataLayer.push({
      "title": "CarLogik Homepage",
      "url": "https://carlogik.com/",
      "path": "/",
      "language":"en",
      "page_type":"homepage",
      "currencyCode":"AED",
      "is_login":`${!!user}`,
      "user_Id":`${user?.user.id}`,
      "email":`${user?.user.email}`,
      "search": "",
      "event":"page_view"
    });
    dispatch(authActions.resetOrderData());
  },[])

  useEffect(() => {
    dispatch(appActions.getWorkshopList());
  }, [dispatch]);

 
  useEffect(() => {
    const el = document.getElementById('banner');
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          history.push('/');
          return;
        }
      },
      {
        root: null,
        threshold: 0.2,
      }
    );
      if(el) {
        observer.observe(el);
      }
    
  }, [history]);

  return (
    <div className={classes.container} id="main">
      <Header goTo={goTo}/>
      <Banner goTo={goTo} />
      <Tailored />
      <Partners />
      <HowItWorks />
      <TabbyPromo />
      <ServiceForOffer goTo={goTo} />
      <Testimonials />
      <Footer />
    </div>
  );
};
