import React, { FormEvent, useEffect, useState } from 'react';
import { Form, Input, Checkbox, Button, Row, Modal } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import GoogleIcon from '../../../../../../Assets/google.svg';
// import FacebookIcon from '../../../../../../Assets/facebook.svg';
import classes from './style.module.scss';
import { ButtonComponent } from '../../../../../../Components/Button';

import { RootStateType } from '../../../../../../Stores';
import { userActions } from '../../../../../../Globals/User/actions';
import { appActions } from '../../../../../../Globals/App/actions';

import { ApiUrls } from '../../../../../../Utils/Api/constants';
import * as constants from '../../../../../../Utils/Api/constants';
import { TermsList } from '../../../../../LandingPage/Views/TermsConditions/TermsList';
import { PrivacyList } from '../../../../../LandingPage/Views/PrivacyPolicy/PrivacyPolisyList';

type BookStepPropsType = {
  goToNextStep: (value: FormType) => void;
  goToPrevStep: () => void;
  goCardStep: () => void;
  isPaymentStep: boolean;
  setClientSecret: (value: string | null) => void;
  setTabbyUrl: (value: string | null) => void;
  setOrderSavedForConfirm: (value: string | null) => void;
  signUpGoogle: () => void;
};

export type FormType = {
  email: string;
  name: string;
  phone: string;
  password: string;
  payment_id: string;
  save_card: boolean;
};

export const BookForm: React.FC<BookStepPropsType> = ({
  goToNextStep,
  goToPrevStep,
  isPaymentStep,
  goCardStep,
  setClientSecret,
  setTabbyUrl,
  setOrderSavedForConfirm,
  signUpGoogle,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isAccept, setIsAccept] = useState(false);
  const [isError, setIsError] = useState(false);

  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  const payment = useSelector((state: RootStateType) => state.user.payment);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);


  const token = useSelector((state: RootStateType) => state.auth.user?.jwt);

  const tabby = useSelector(
    (state: RootStateType) => state.user.responseTabby
  );

  const onChange = (e: FormEvent<HTMLFormElement>) => {
    //@ts-ignore
    if (e.target?.id! === 'register_email') {
      dispatch(appActions.clearError());
    }
  };

  const onAccept = (value: CheckboxChangeEvent) => {
    setIsAccept(value.target.checked);
    if (value.target.checked) {
      setIsError(false);
    }
  };

  const fetchOrderFunction = async (orderData: any) => {
    dispatch(appActions.openLoader());
    const order = await fetch(`${constants.ApiUrls.BASE_URL}/orders`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(orderData),
    });
    const order_loaded = await order.json();
    dispatch(appActions.closeLoader());
    return order_loaded;
  };

  const onFinish = async (values: FormType) => {
    if (isAccept || currentUser?.id) {
      
      if (isPaymentStep && !payment) {
        const orderData: any = await goToNextStep({ ...values });
        const order = await fetchOrderFunction(orderData);
        setClientSecret(order.client_secret);
        setTabbyUrl(order.tabbyUrl)
        setOrderSavedForConfirm(order.order_id);
        goCardStep();
      } else if (isPaymentStep && payment) {
        const orderData: any = await goToNextStep({ ...values });
        const order = await fetchOrderFunction(orderData);
        setClientSecret(order.client_secret);
        setTabbyUrl(order.tabbyUrl)
        setOrderSavedForConfirm(order.order_id);
        goCardStep();
      } else {
        goToNextStep(values);
        //goCardStep();
      }
    } else {
      setIsError(true);
    }
  };
  

  const signGoogle = () => {
    signUpGoogle();
    // @ts-ignore
    window.location = `${ApiUrls.BASE_URL}/connect/google`;
  };

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        name: currentUser.username,
        email: currentUser.email,
        phone: currentUser.phone,
        save_card: true,
      });
      dispatch(userActions.getPayment());
    }
  }, [form, dispatch, currentUser]);

  return (
    <Form
      form={form}
      name="register"
      className={classes.loginForm}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      size="large"
      onChange={onChange}
    >
      <Form.Item
        name="name"
        rules={[{ required: true, message: 'Please input your name!' }]}
      >
        <Input
          className={classes.input}
          prefix={<UserOutlined />}
          disabled={!isPaymentStep ? false : true}
          placeholder="Name"
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email' },
        ]}
      >
        <Input
          className={classes.input}
          prefix={<MailOutlined />}
          disabled={!isPaymentStep ? false : true}
          placeholder="Email"
        />
      </Form.Item>

      <Form.Item
        name="phone"
        rules={[
          { required: true, message: 'Please input your phone number!' },
          {
            validator: (_, value: number) => {
              if (
                value.toString().length !== 9 &&
                value.toString().length !== 0
              ) {
                return Promise.reject(new Error('must be 9 characters'));
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input
          onWheel={(event) => event.currentTarget.blur()}
          type="number"
          className={classNames(classes.input, classes.phoneInput)}
          prefix={<PhoneOutlined style={{ color: '#5B8DB4' }} />}
          placeholder="5x xxx xxxx"
          addonBefore="+971"
          maxLength={9}
        />
      </Form.Item>

      {!isPaymentStep && (
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please input your Password!' },
            {
              type: 'string',
              min: 6,
              max: 20,
              message: 'password must be at least 6 characters',
            },
          ]}
        >
          <Input
            className={classes.input}
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
      )}

      {!isPaymentStep && (
        <>
          <div style={{ textAlign: 'center', margin: '5%' }}>or</div>
          <Button
            type="default"
            shape="round"
            className={classes.buttonSocial}
            onClick={() => {
              signGoogle();
            }}
          >
            <img src={GoogleIcon} alt="Google Logo" />
            <span className={classes.btnText}>Sign up with Google</span>
          </Button>
          {/* <Button type="default" shape="round" className={classes.buttonSocial}>
            <img src={FacebookIcon} alt="Facebook Logo" />
            <span className={classes.btnText}>Sign up with Facebook</span>
          </Button> */}
        </>
      )}

      {!isPaymentStep && (
        <div className={classes.acceptContainer}>
          {isError && (
            <div className={classes.errorMessage}>
              You should accept our terms and conditions
            </div>
          )}
          <Form.Item name="accept" valuePropName="checked" noStyle>
            <Checkbox className={classes.checkBoxText} onChange={onAccept}>
              I have read (I promise I did!) and I accept{' '}
              <span onClick={() => setIsModalVisible((prev) => !prev)}>
                CarLogik’s Terms of use{' '}
              </span>
              and{' '}
              <span onClick={() => setIsModalVisible((prev) => !prev)}>
                Privacy Policy
              </span>
            </Checkbox>
          </Form.Item>
        </div>
      )}

      <Form.Item>
        {/* <Row justify="end" gutter={[16, 16]}>
          <ButtonComponent
            type="cancel"
            title="Back"
            onClick={goToPrevStep}
          ></ButtonComponent>
          <ButtonComponent
            type="save"
            title="Continue"
            style={{ width: '172px' }}
          ></ButtonComponent>
        </Row> */}
        <div className={classes.buttons}>
          <ButtonComponent
            style={{ width: '172px' }}
            title="Back"
            type="cancel"
            onClick={goToPrevStep}
          />
          <ButtonComponent
            style={{ width: '172px' }}
            title="Continue"
            type="save"
            onClick={() => {}}
          />
        </div>
      </Form.Item>
      <Modal
        title="Terms of use and Privacy Policy"
        visible={isModalVisible}
        onOk={() => setIsModalVisible((prev) => !prev)}
        onCancel={() => setIsModalVisible((prev) => !prev)}
      >
        <div style={{ height: '500px', overflowY: 'scroll' }}>
          <TermsList />
          <PrivacyList />
        </div>
      </Modal>
    </Form>
  );
};
