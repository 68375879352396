import { InferActionsTypes } from '../../Stores';
import {
  AlertType,
  ReviewForWorkshopRequestType,
  WorkshopForLandingRequestType,
} from '../../Types/appTypes';
import { WorkShopType } from '../../Types/authTypes';
import * as appConstants from './constants';

export const appActions = {
  openLoader: () =>
    ({
      type: appConstants.OPEN_LOADER,
    } as const),
  closeLoader: () =>
    ({
      type: appConstants.CLOSE_LOADER,
    } as const),
  setError: (payload: any) =>
    ({
      type: appConstants.SET_ERROR,
      payload,
    } as const),
  clearError: () =>
    ({
      type: appConstants.CLEAR_ERROR,
    } as const),
  addAlertMessage: (payload: AlertType) =>
    ({
      type: appConstants.ADD_ALERT_MESSAGE,
      payload,
    } as const),
  deleteAlertMessage: (payload: string) =>
    ({
      type: appConstants.DELETE_ALERT_MESSAGE,
      payload,
    } as const),
  resetAlerts: () =>
    ({
      type: appConstants.RESET_ALERTS,
    } as const),
  showConfirm: (payload: string, textButtons?: [string, string]) =>
    ({
      type: appConstants.SHOW_CONFIRM_WINDOW,
      payload,
      textButtons,
    } as const),
  hideConfirm: () =>
    ({
      type: appConstants.HIDE_CONFIRM_WINDOW,
    } as const),
  getWorkshopList: () =>
    ({
      type: appConstants.GET_WORKSHOPS_FOR_LENDING,
    } as const),
  setWorkshopList: (payload: WorkshopForLandingRequestType) =>
    ({
      type: appConstants.SET_WORKSHOPS_FOR_LENDING,
      payload,
    } as const),
  getSelectedWorkshop: (payload: number) =>
    ({
      type: appConstants.GET_SELECTED_WORKSHOP_BY_ID,
      payload,
    } as const),
  setSelectedWorkshop: (payload: WorkShopType) =>
    ({
      type: appConstants.SET_SELECTED_WORKSHOP,
      payload,
    } as const),
  resetSelectedWorkshop: () =>
    ({
      type: appConstants.RESET_SELECTED_WORKSHOP,
    } as const),

  getReviewsForWorkshop: (payload: number) =>
    ({
      type: appConstants.GET_REVIEWS_FOR_WORKSHOP,
      payload,
    } as const),
  setReviewsForWorkshop: (payload: ReviewForWorkshopRequestType) =>
    ({
      type: appConstants.SET_REVIEWS_FOR_WORKSHOP,
      payload,
    } as const),
  getReceiptInvoice: (payload: { address: string; id: number }) =>
    ({
      type: appConstants.GET_RECEIPT_INVOICE,
      payload,
    } as const),
};

export type ActionTypes = InferActionsTypes<typeof appActions>;
