import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';
import { Input, Form, Checkbox, Row, Col, TimePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { workshopActions } from '../../../Globals/Workshop/actions';
import { RootStateType } from '../../../Stores';
import { ButtonComponent } from '../../../Components/Button';
import moment from 'moment';

export type WeekDay = 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun' | 'mon';
const weekDays: Array<WeekDay> = [
  'sun',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
];

type SchedulePropsType = {
  wsId?: number;
};

export const Schedule: React.FC<SchedulePropsType> = () => {
  const [selectedDays, setSelectedDays] = useState<Array<WeekDay>>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const schedules = useSelector(
    (state: RootStateType) => state.workshop.workshopSchedules
  );

  const scheduleId = useSelector(
    (state: RootStateType) => state.workshop.workshopData?.schedule
  );

  const getWorkedDays = () => {
    if (schedules) {
      const workDays = weekDays.filter(
        (day) => schedules[day] !== 'closed-closed'
      );
      if (workDays) {
        return workDays;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const createObject = (values: any) => {
    const obj = weekDays.reduce((acc: any, curr: any) => {
      let start = values[`start_at_${curr}`]?.format('LT');
      let finish = values[`finish_at_${curr}`]?.format('LT');
      return {
        ...acc,
        [curr]: start ? `${start}-${finish}` : 'closed-closed',
      };
    }, {});
    return obj;
  };

  const onChange = (e: { day: Array<WeekDay> }) => {
    if (e.day) {
      const daysOff = weekDays.filter((f) => !e.day.includes(f));
      if (daysOff.length) {
        daysOff.forEach((day) => {
          const start = `start_at_${day}`;
          const finish = `finish_at_${day}`;
          form.setFieldsValue({ [start]: null, [finish]: null });
        });
      }
      setSelectedDays(e.day);
    }
    setIsDisabled(false);
  };

  const onFinish = (values: any) => {
    const formValues = createObject(values);
    dispatch(
      workshopActions.updateWorkshopSchedules(formValues, schedules?.id)
    );
    setIsDisabled(true);
  };

  const isActiveInput = (day: WeekDay) => {
    return !selectedDays.find((item) => item === day);
  };

  const getWorkedTime = (day: WeekDay) => {
    if (schedules && schedules[day] !== 'closed-closed') {
      return [
        moment(schedules[day]?.split('-')[0], 'HH:mm a'),
        moment(schedules[day]?.split('-')[1], 'HH:mm a'),
      ];
    } else {
      return [null, null];
    }
  };

  useEffect(() => {
    //@ts-ignore
    if (!scheduleId?.id) {
      //@ts-ignore
      dispatch(workshopActions.getWorkshopSchedules(+scheduleId));
    } else {
      //@ts-ignore
      dispatch(workshopActions.getWorkshopSchedules(+scheduleId.id));
    }
  }, [dispatch, scheduleId]);

  const setDefaultValue = () => {
    form.setFieldsValue({
      day: getWorkedDays(),
      finish_at_fri: getWorkedTime('fri')[1],
      finish_at_mon: getWorkedTime('mon')[1],
      finish_at_sat: getWorkedTime('sat')[1],
      finish_at_sun: getWorkedTime('sun')[1],
      finish_at_thu: getWorkedTime('thu')[1],
      finish_at_tue: getWorkedTime('tue')[1],
      finish_at_wed: getWorkedTime('wed')[1],
      start_at_fri: getWorkedTime('fri')[0],
      start_at_mon: getWorkedTime('mon')[0],
      start_at_sat: getWorkedTime('sat')[0],
      start_at_sun: getWorkedTime('sun')[0],
      start_at_thu: getWorkedTime('thu')[0],
      start_at_tue: getWorkedTime('tue')[0],
      start_at_wed: getWorkedTime('wed')[0],
    });
  };

  useEffect(() => {
    setDefaultValue();
    setSelectedDays(getWorkedDays());
  }, [schedules]);

  return (
    <Form
      form={form}
      name="work-account"
      onFinish={onFinish}
      onValuesChange={onChange}
      style={{ maxWidth: '360px' }}
    >
      <Row
        style={{ display: 'flex', justifyContent: 'flex-start' }}
        gutter={16}
      >
        <Col span={8} style={{}}>
        <span className={classes.label}>Day</span>
          <Form.Item
            name="day"
            // label={<span className={classes.label}>Day</span>}
            colon={false}
          >
            <Checkbox.Group>
              <Checkbox value="sun" className={classes.checkBox}>
                Sunday
              </Checkbox>
              <Checkbox value="mon" className={classes.checkBox}>
                Monday
              </Checkbox>
              <Checkbox value="tue" className={classes.checkBox}>
                Tuesday
              </Checkbox>
              <Checkbox value="wed" className={classes.checkBox}>
                Wednesday
              </Checkbox>
              <Checkbox value="thu" className={classes.checkBox}>
                Thursday
              </Checkbox>
              <Checkbox value="fri" className={classes.checkBox}>
                Friday
              </Checkbox>
              <Checkbox value="sat" className={classes.checkBox}>
                Saturday
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col span={8}>
          <span className={classes.label}>Starts at</span>
          {weekDays.map((day) => {
            return (
              <Form.Item
                name={`start_at_${day}`}
                key={day}
                className={classes.formItem}
                rules={[{ required: !isActiveInput(day), message: 'required' }]}
              >
                {!isActiveInput(day) ? (
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    minuteStep={30}
                    showNow={false}
                    style={{ borderRadius: '6px' }}
                  />
                ) : (
                  <Input disabled={true} placeholder="closed" />
                )}
              </Form.Item>
            );
          })}
        </Col>
        <Col span={8}>
          <span className={classes.label}>Finishes at</span>
          {weekDays.map((day) => {
            return (
              <Form.Item
                name={`finish_at_${day}`}
                key={day}
                className={classes.formItem}
                rules={[{ required: !isActiveInput(day), message: 'required' }]}
              >
                {!isActiveInput(day) ? (
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    minuteStep={30}
                    showNow={false}
                    style={{ borderRadius: '6px' }}
                  />
                ) : (
                  <Input disabled={true} placeholder="closed" />
                )}
              </Form.Item>
            );
          })}
        </Col>
      </Row>
      <Form.Item>
        <div
          style={{
            gap: 14,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonComponent
            type="cancel"
            title="Cancel"
            onClick={setDefaultValue}
          />
          <ButtonComponent
            type="save"
            title="Save"
            disabled={isDisabled}
            breakpointOff
            style={{ marginLeft: 0 }}
          />
        </div>
      </Form.Item>
    </Form>
  );
};
