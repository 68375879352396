import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Form, Select, Input, Row, Radio } from 'antd';
import cn from 'classnames';
import classes from './style.module.scss';
import { ButtonComponent } from '../../../../../Components/Button';
import { CarForAdminType } from '../../../../../Types/adminTypes';
import { useDispatch } from 'react-redux';
import { adminActions } from '../../../../../Globals/Admin/actions';
import { IntervalResponseType } from '../../../../../Types/authTypes';

const { Option } = Select;

type CarsCardPropsType = {
  car: CarForAdminType;
  millageData?: IntervalResponseType[];
};

export const CarCard: React.FC<CarsCardPropsType> = ({ car, millageData }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [form] = Form.useForm();


  const onFinish = (e: any) => {
    if (!isEdit) return setIsEdit(true);
    const { maintenance_interval, ...values } = e;
    const maintenanceId =
      millageData?.find((item) => item.value === maintenance_interval)?.id ||
      car.maintenance_interval?.id;
    dispatch(
      adminActions.updateCar(
        {
          ...{
            make: car!.make.id,
            year: car!.year,
            model: car!.model.id,
            trim: car!.trim.id,
            maintenance_interval: maintenanceId,
          },
          ...values,
        },
        car!.id
      )
    );
    setIsEdit(false);
  };

  const onDeleteCar = (id: number) => {
    dispatch(adminActions.deleteCar(id));
  };

  const onReset = () => {
    setIsEdit(false);
    form.setFieldsValue({
      maintenance_interval: car.maintenance_interval?.value?.toLocaleString(
        'en-US',
        { maximumFractionDigits: 0 }
      ),
      vin_chassis: car.vin_chassis,
      av_daily_mileage: car.av_daily_mileage,
      gears_change: car.gears_change,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      maintenance_interval: car.maintenance_interval?.value.toLocaleString(),
      vin_chassis: car.vin_chassis,
      av_daily_mileage: car.av_daily_mileage,
      gears_change: car.gears_change,
    });
  }, [car, form, dispatch]);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
  };

  return (
    <div className={cn(classes.container, classes.boxShadow)}>
      <DeleteOutlined
        className={classes.binIcon}
        onClick={() => {
          onDeleteCar(car.id);
        }}
      />
      <div className={classes.imageWithNameContainer}>
        <img src={car.model.image_url} alt="your car" />
        <div className={classes.carNameContainer}>
          <div className={classes.carInfo}>{car.year}</div>
          <div className={classes.carInfo}>
            {car.make.title} {car.model.title}
          </div>
          <div className={classes.carInfo}>{car.trim.title}</div>
          <div>
            <span className={classes.millage}>Total mileage</span>
            {car.maintenance_interval?.value.toLocaleString()} km
          </div>
        </div>
      </div>

      <div className={classes.formContainer}>
        <Form {...layout} form={form} name="car-info" onFinish={onFinish}>
          <div style={{ maxWidth: '440px', margin: '0 auto' }}>
            <Form.Item name="maintenance_interval" label="Current Mileage">
              <Select
                placeholder=""
                disabled={!isEdit}
              >
                {millageData?.map((data, index) => {
                  return (
                    <Option key={data.id} value={data.value} title="mileage">
                      {data.value.toLocaleString()}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="vin_chassis" label="VIN/Chassis#">
              <Input disabled={!isEdit} />
            </Form.Item>
            <Form.Item
              name="av_daily_mileage"
              label="Average daily mileage"
              style={{ marginBottom: 0 }}
            >
              <Input disabled={true} />
            </Form.Item>
            <div className={classes.millagePrompt}>
              We deduce this from the age of your car and the current mileage 😉{' '}
            </div>
            <Form.Item
              name="gears_change"
              label={
                <span className={classes.labelGears}>
                  Do you change gears or <br /> does the car do it for you?
                </span>
              }
            >
              <Radio.Group disabled={!isEdit}>
                <Radio value={'manual'}>Manual</Radio>
                <Radio value={'automatic'}>Automatic</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Form.Item>
            <Row justify="end">
              {isEdit && (
                <ButtonComponent type="cancel" onClick={onReset} title="Cancel">
                  Cancel
                </ButtonComponent>
              )}
              <ButtonComponent title={isEdit ? 'Save Changes' : 'Edit'} />
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
