import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';
import { Checkbox, Row, Col } from 'antd';
import { ButtonComponent } from '../../../Components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { workshopActions } from '../../../Globals/Workshop/actions';
import { RootStateType } from '../../../Stores';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type PromotionsPropsType = {
  wsId?: number;
};

export const Promotions: React.FC<PromotionsPropsType> = ({ wsId }) => {

  const dispatch = useDispatch();

  const [promo, setPromo] = useState<Array<{ id: number; status: boolean }>>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const promotions = useSelector(
    (state: RootStateType) => state.workshop.promotions
  );

  const onFinish = () => {
    if (promo?.length) {
      dispatch(workshopActions.updateWorkshopPromotions(promo, wsId));
      setIsDisabled(true)
    }
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setPromo((prev) =>
      prev?.map((item) => {
        if (e.target.value === item.id) {
          return { ...item, status: e.target.checked };
        } else {
          return item;
        }
      })
    );
    setIsDisabled(false)
  };

  useEffect(() => {
    dispatch(workshopActions.getWorkshopPromotions(wsId));
  }, [dispatch, wsId]);

  useEffect(() => {
    setPromo(promotions.map((item) => ({ id: item.id, status: item.status })));
  }, [promotions]);

  return (
    <>
      <div className={classes.title}>
        How many "Frees" will you give today?
      </div>

      <div className={classes.checkboxContainer}>
        <Row
          className={classes.rowContainer}
          gutter={24}
        >
          <Col span={24} style={{}}>
            {promotions.map((prom) => (
              <div key={prom.id} className={classes.promoItem}>
                <Checkbox
                  value={prom.id}
                  defaultChecked={prom.status}
                  onChange={onChange}
                >
                  {prom.title}
                </Checkbox>
              </div>
            ))}
          </Col>
        </Row>

        <Row justify="end" className={classes.buttonContainer}>
          <ButtonComponent type="save" title="Save" onClick={onFinish} disabled={isDisabled} />
        </Row>
      </div>
    </>
  );
};
