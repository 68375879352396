import React, { useState } from 'react';
import { Form, Input, Row, Checkbox, Divider, InputNumber } from 'antd';
import cn from 'classnames';
import classes from './style.module.scss';
import { ButtonComponent } from '../Button';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../Stores';
import { ApproveWorkshopRequestType } from '../../Types/workshopTypes';

type WorkshopFormPropsType = {
  submitModal: (payload: ApproveWorkshopRequestType) => void;
  closeModal: () => void;
  data: {
    id: number;
    action: string;
  };
};
export const WorkshopForm: React.FC<WorkshopFormPropsType> = ({
  submitModal,
  closeModal,
  data,
}) => {
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);

  const workshopList = useSelector(
    (state: RootStateType) => state.admin.workshopList.rows
  );

  const currentWorkshop = workshopList.find(
    (workshop) => workshop.id === data.id
  );

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 24 },
  };

  form.setFieldsValue({
    name: currentWorkshop?.name,
    contact_person: currentWorkshop?.contact_person,
    email: currentWorkshop?.user.email,
    phone: currentWorkshop?.phone,
    address: currentWorkshop?.address,
    trade_license: currentWorkshop?.trade_license,
    mobile_mechanic: currentWorkshop?.mobile_mechanic,
    commission: currentWorkshop?.commission,
    partner: currentWorkshop?.partner,
    is_workshop: currentWorkshop?.is_workshop,
    verified: currentWorkshop?.verified,
  });

  const onFinish = (values: any) => {
    submitModal({ ...values, approved: true });
    closeModal();
  };

  const onReset = () => {
    form.resetFields();
    closeModal();
  };
  return (
    <div className={classes.container}>
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item
          name="name"
          label={
            <span
              className={cn({
                [classes.disabled]: !isEditable,
              })}
            >
              Workshop name
            </span>
          }
        >
          <Input disabled={!isEditable} />
        </Form.Item>
        <Form.Item
          name="contact_person"
          label={
            <span
              className={cn({
                [classes.disabled]: !isEditable,
              })}
            >
              Owner name
            </span>
          }
        >
          <Input disabled={!isEditable} />
        </Form.Item>
        <Form.Item
          name="email"
          label={
            <span
              className={cn({
                [classes.disabled]: !isEditable,
              })}
            >
              Email
            </span>
          }
        >
          <Input disabled={!isEditable} />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              validator: (_, value: number) => {
                if (value.toString().length !== 9) {
                  return Promise.reject(new Error('must be 9 characters'));
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
          label={
            <span
              className={cn({
                [classes.disabled]: !isEditable,
              })}
            >
              Phone
            </span>
          }
        >
          <Input
            onWheel={(event) => event.currentTarget.blur()}
            type="number"
            disabled={!isEditable}
            placeholder="5x xxx xxxx"
            addonBefore="+971"
            maxLength={9}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label={
            <span
              className={cn({
                [classes.disabled]: !isEditable,
              })}
            >
              Address
            </span>
          }
        >
          <Input disabled={!isEditable} />
        </Form.Item>
        <Form.Item
          name="trade_license"
          label={
            <span
              className={cn({
                [classes.disabled]: !isEditable,
              })}
            >
              Trade License
            </span>
          }
        >
          <Input disabled={!isEditable} />
        </Form.Item>
        <Form.Item
          name="mobile_mechanic"
          valuePropName="checked"
          label={
            <span
              className={cn({
                [classes.disabled]: !isEditable,
              })}
            >
              A mobile Mechanic
            </span>
          }
          style={{ marginBottom: 0 }}
        >
          <Checkbox disabled={!isEditable} />
        </Form.Item>
        <Form.Item
          name="is_workshop"
          valuePropName="checked"
          label={
            <span
              className={cn({
                [classes.disabled]: !isEditable,
              })}
            >
              A Workshop
            </span>
          }
          style={{ marginBottom: 0 }}
        >
          <Checkbox disabled={!isEditable} />
        </Form.Item>
        <Row justify="end">
          <div
            onClick={() => setIsEditable((prev) => !prev)}
            className={classes.editButton}
          >
            {isEditable ? 'Disable' : 'Edit'}
          </div>
        </Row>
        <Divider orientation="left" plain>
          Admin Settings
        </Divider>
        <Form.Item
          name="commission"
          label="Commission"
          style={{ marginBottom: '12px' }}
        >
          <InputNumber
            min={0}
            max={100}
            placeholder="%"
            style={{ maxWidth: '84px', borderRadius: '6px' }}
          />
        </Form.Item>
        <Form.Item
          name="partner"
          label="Partner"
          style={{ marginBottom: '12px' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="verified"
          label="Verified"
          style={{ marginBottom: '12px' }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <ButtonComponent
              //htmlType="button"
              type="cancel"
              onClick={onReset}
              title="Cancel"
              //shape="round"
              //className={classes.button}
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent title="Approve" />
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};
