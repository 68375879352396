import React, { useEffect, useMemo, useState } from 'react';
import { Form, Select, Input, Row, Radio } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import countryList from 'react-select-country-list';
import PasswordIcon from '../../../Assets/icon_password.svg';
import classes from './style.module.scss';
import { ButtonComponent } from '../../../Components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../Stores';
import { ModalComponent } from '../../../Components/Modal';
import { authActions } from '../../../Globals/Auth/actions';
import { UpdateUserProfile } from '../../../Types/authTypes';
import { userActions } from '../../../Globals/User/actions';
import { ManagePasswordForm } from '../../UserSettings/PaymentPreferences/Components/ManagePasswordForm';

const { Option } = Select;

const ageRage = [
  '18 - 25',
  '25 - 30',
  '30 - 35',
  '35 - 40',
  '40 - 45',
  '45 - 50',
  '50 - 55',
  '55 - 60',
  '65 - 70',
];

interface formType extends UpdateUserProfile {
  age_range: string;
}

type AccountInfoPropsType = {};

export const UserInfo: React.FC<AccountInfoPropsType> = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const countries = useMemo(() => countryList().getData(), []);

  const userProfile = useSelector((state: RootStateType) => state.admin.user);

  const closeModal = () => {
    setIsModal(false);
  };

  const onResetPassword = (payload: {
    password: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (userProfile) {
      dispatch(
        authActions.updatePassword({
          ...payload,
          identifier: userProfile.email,
        })
      );
      setIsModal(false);
    }
  };

  const onChange = () => {
    if (!isChanged) {
      setIsChanged(true);
    }
  };

  const onFinish = (values: formType) => {
    const { age_range, ...withoutRange } = values;
    const rangeFromTo = age_range.split(' - ');
    if (userProfile?.id) {
      dispatch(
        userActions.updateUserProfile(
          {
            ...withoutRange,
            age_range_from: +rangeFromTo[0],
            age_range_to: +rangeFromTo[1],
          },
          +userProfile?.id
        )
      );
    }
    setIsChanged(false);
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (userProfile) {
      const age_range = () => {
        if (userProfile.age_range_from && userProfile.age_range_to) {
          return `${userProfile.age_range_from} - ${userProfile.age_range_to}`;
        } else {
          return '';
        }
      };
      form.setFieldsValue({
        username: userProfile.username,
        email: userProfile.email,
        phone: userProfile.phone,
        nationality: userProfile.nationality,
        age_range: age_range(),
        gender: userProfile.gender,
      });
    }
  }, [userProfile, form]);

  useEffect(() => {
    dispatch(userActions.getUserProfile());
  }, [dispatch]);

  const layout = {
    labelCol: { span: 6 },
  };

  return (
    <div className={classes.container}>
      <Form
        {...layout}
        form={form}
        name="customer-info"
        onFinish={onFinish}
        onChange={onChange}
        className={classes.form}
      >
        <div className={classes.leftForm}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input
              className={classes.input}
              prefix={<UserOutlined />}
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email' },
            ]}
          >
            <Input
              className={classes.input}
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            name="phone"
            rules={[
              { required: true, message: 'Please input your phone number!' },
              {
                validator: (_, value: number) => {
                  if (
                    value.toString().length !== 9 &&
                    value.toString().length !== 0
                  ) {
                    return Promise.reject(new Error('must be 9 characters'));
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input
              onWheel={(event) => event.currentTarget.blur()}
              type="number"
              className={classes.input}
              prefix={<PhoneOutlined />}
              placeholder="5x xxx xxxx"
              addonBefore="+971"
              maxLength={9}
            />
          </Form.Item>
          <div
            className={classes.managePassword}
            onClick={() => {
              setIsModal(true);
            }}
          >
            Manage password
          </div>
        </div>
        <div className={classes.rightForm}>
          <div className={classes.header}>Personal info</div>
          <Form.Item name="nationality" label="Nationality">
            <Select placeholder="" onChange={onChange}>
              {countries.map((country, index) => {
                return (
                  <Option key={index} value={country.label} title="country">
                    {country.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="age_range" label="Age range">
            <Select placeholder="" onChange={onChange}>
              {ageRage.map((age, index) => {
                return (
                  <Option key={index} value={age} title="age">
                    {age}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="gender" label="Gender">
            <Radio.Group>
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
              <Radio value="neutral">Neutral</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Row justify="end" className={classes.buttons}>
              {true && (
                <ButtonComponent type="cancel" onClick={onReset} title="Cancel">
                  Cancel
                </ButtonComponent>
              )}
              <ButtonComponent title="Save Changes" disabled={!isChanged} />
            </Row>
          </Form.Item>
        </div>
      </Form>
      <ModalComponent
        isModal={isModal}
        toggleModal={closeModal}
        title="Manage password"
        icon={PasswordIcon}
      >
        <ManagePasswordForm onSubmit={onResetPassword} onCancel={closeModal} />
      </ModalComponent>
    </div>
  );
};
