import { Checkbox, Radio, Modal} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../../../../Stores';
import classes from './style.module.scss';
import { TermsList } from '../../../../../LandingPage/Views/TermsConditions/TermsList';
import { DROP_OFF_PRICE, PICK_UP_PRICE } from '../../../../../../Utils/Api/constants';
import { CarDeliveriesType } from '../../../../../../Types/userTypes';

type PickUpPropsType = {
  onChange: (value: number, type: 'pickUp' | 'dropOff') => void;
  deliverySlots: CarDeliveriesType;
  type: 'pickUp' | 'dropOff';
};

export const PickUp: React.FC<PickUpPropsType> = ({ onChange, type, deliverySlots }) => {

  // const checked = useSelector((state: RootStateType) => state.user.deliveryCar.type).indexOf(type) >= 0

  const onPickUpOpen = (e: CheckboxChangeEvent) => {
    onChange(e.target.value, type);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {deliverySlots.title}
      </div>
      <Radio.Group defaultValue={1} className={classes.deliveryContainer} onChange={onPickUpOpen} >
        {
          deliverySlots.delivery_types.map((deliveryType) => {
            return(
              <Radio value={deliveryType.id} className={classes.deliveryItem}>
                <div className={classes.item}>
                  {deliveryType.title}{deliveryType.price !== null ? ` - ${deliveryType.price === 0 ? 'FREE' : `AED ${deliveryType.price}`}` : ''}
                  {
                    deliveryType.price === 0 && (
                      <p>*Your car's insurance applies, refer to <span onClick={() => setIsModalVisible((prev) => !prev)}>T&Cs</span></p>
                    )
                  }
                </div>
              </Radio>
            )
          })
        }
      </Radio.Group>
      <Modal
        title="Terms of use"
        visible={isModalVisible}
        onOk={() => setIsModalVisible((prev) => !prev)}
        onCancel={() => setIsModalVisible((prev) => !prev)}
      >
        <div style={{ height: '500px', overflowY: 'scroll' }}>
          <TermsList />

        </div>
      </Modal>
      {/* <Checkbox style={{ alignItems: 'center' }} onChange={onPickUpOpen} checked={checked}>
        <div className={classes.checkboxContainer}>
          <div className={classes.header}>
            {type === 'pickUp'
              ? 'Car Pick-up - ' + PICK_UP_PRICE
              : 'Car Drop-off - ' + DROP_OFF_PRICE}
          </div>
          <div className={classes.subTitle}>
            {type === 'pickUp'
              ? 'Your car will be picked up at your appointment time'
              : 'We shall contact you to arrange drop off at your location'}
          </div>
        </div>
      </Checkbox> */}
    </div>
  );
};
