import React, { useEffect, useState } from 'react';
import OtherServiceIcon from '../../../../Assets/orderIcon/other-service-icon2.svg';
import SparkPlugs from '../../../../Assets/orderIcon/spark_icon.svg';
import Battery from '../../../../Assets/orderIcon/battery_icon.svg';
import LogbookIcon from '../../../../Assets/orderIcon/logbook-icon.svg';
import ACFilter from '../../../../Assets/orderIcon/ac_filter_icon.svg';
import AirFilter from '../../../../Assets/orderIcon/air_filter_icon.svg';
import Wipes from '../../../../Assets/orderIcon/replace_wipes_icon.svg';
import Inspection from '../../../../Assets/orderIcon/inspection_icon.svg';
import RearBrake from '../../../../Assets/orderIcon/rear_break_icon.svg';
import FrontBrake from '../../../../Assets/orderIcon/front_break_icon.svg';
import CarRepairModal from '../../../../Assets/Landing/car-repair-modal.jpg';
import classes from './styles.module.scss';
import { ButtonComponent } from '../../../../Components/Button';

type StaticModalPropsType = {
  onRedirect: (id: number | boolean | null) => void;
  setModal: (flag: boolean) => void;
  serviceNumber: number;
  buttonState: boolean;
};

export const StaticModals: React.FC<StaticModalPropsType> = ({
  onRedirect,
  setModal,
  serviceNumber,
  buttonState,
}) => {
  const dateModals = [
    {
      id: 1,
      title: 'Major Service',
      serviceID: null,
      isBasic: false,
      icon: LogbookIcon,
      buttonTitle: 'Book a Major/ Periodic Service',
      description:
        <div className={classes.description}>
          <p>Its NOT an oil and oil filter change only!</p>
          <p>A typical periodic/major maintenance includes few inspection points and the replacement of
            few   spare   parts   necessary   for   continued   smooth   operating   of  your   vehicle   at   a   specific
            mileage (at specific intervals only and not every time your service your car). Don’t waste
            your money by changing parts and fluids when you don’t need! </p>
          <p>Here at CarLogik, we provide you with the exact parts and checks recommended by your car
            manufacturer for your car at a given mileage. Just input your car’s details and mileage <span className={classes.blueLinkText} onClick={() => onRedirect(dateModals[serviceNumber].serviceID == null ? dateModals[serviceNumber].isBasic : dateModals[serviceNumber].serviceID)}>HERE </span>
            and we can tell you the exact parts and checks you need for your car! </p>
          <div className={classes.list}>
            <ul className={classes.mainList}>
              <li>
                Parts included in a major service could vary for every car and every mileage but
                typically are as follows:
                <ul className={classes.subList}>
                  <li>Engine Oil</li>
                  <li>Oil Filter</li>
                  <li>Air Filter</li>
                  <li>AC Filter</li>
                  <li>Spark plugs (subject to mileage)</li>
                  <li>Engine V belts (subject to mileage)</li>
                  <li>Transmission Oil (subject to mileage)</li>
                  <li>Flushing of Brake fluid (subject to mileage)</li>
                  <li>Flushing of coolant and replacing it (subject to mileage)</li>
                </ul>
              </li>
              <li>
                Checks done part of major / periodic service  include but not limited to:
                <ul className={classes.subList}>
                  <li>Check Brake fluid & top up if necessary</li>
                  <li>Check the condition & thickness of Brake Discs (not pads)</li>
                  <li>Check the thickness of Brake pads and replace if necessary</li>
                  <li>Check Transmission condition and joints for wear, excessive play and damage</li>
                  <li>Check Transmission oil level; top up if necessary. Check for leaks</li>
                  <li>Check all the mounting points</li>
                  <li>Check suspension & wheels for damage to the side walls</li>
                  <li>Check   the   Transmission   and   Steering   boots   for   damage,   leaks,   wear   and   correct
                    installation</li>
                  <li>Check the anti-freeze or coolant strength & level; top up  if necessary</li>
                  <li>Cooling system inspection</li>
                  <li>Inspect all the rubber seals, bushes and other fittings</li>
                  <li>Check and renew the belt(s), if necessary</li>
                </ul>
              </li>
            </ul>
          </div>
          <h2 className={classes.subTitle}>Importance of Regular Car Maintenance</h2>
          <div className={classes.list}>
            <ul className={classes.mainList}>
              <li>
                Ensures Safety and Keeps your Car’s Performance at its Best
                <p className={classes.textDescription}>
                  Keeping up on your routine car maintenance services will increase your safety while
                  on the road. Moreover, if you constantly take care of your car and check the systems
                  that   might   develop   problems,   you   will   take   away   the   risk   of   dealing   with   a
                  malfunctioning car.
                </p>
              </li>
              <li>
                Saves You Money
                <p className={classes.textDescription}>
                  If you take care of your car on a regular basis, you will prevent larger, more costly
                  issues from arising. Would you rather pay a little now to keep your car healthy, or a
                  lot more down the road? A well serviced engine is also an efficient car, studies show
                  that   servicing   a   car   on   a   routine   basis   improves   gas   mileage   regardless   of   your
                  engine size. The smartest way to save money is to book an appointment with one of
                  our vetted mechanics as soon as possible.
                </p>
              </li>
            </ul>
          </div>
          <h2 className={classes.subTitle}>Maintains your car’s service history (and value) with CarLogik!</h2>
          <div className={classes.list}>
            <ul className={classes.mainList}>
              <li>
                <p className={classes.textDescription}>
                  If   you   are   planning   on   selling   your   vehicle   then   maintaining   a   routine   service
                  schedule, as recommended by your car’s manufacturer, is guaranteed to improve
                  your asking price and give your potential car buyers confidence in the quality of your
                  car.
                </p>
              </li>
              <li>
                <p className={classes.textDescription}>
                  At CarLogik, you have access to your service and repair history in one place and you
                  will   be   able   to   share   it  with   potential   buyers.   If  you  have   an   existing   booklet   of
                  services from your agency we can also verify the data and add it on our system so
                  you have it all in one place for you!
                </p>
              </li>
            </ul>
          </div>
          <div className={classes.footer}>
            If you've read this far, you obviously care about your car. A lot. So next time you
            need a service, repair or inspection, visit <span className={classes.blueText}>CarLogik.com</span> We let you search and
            book qualified mechanics, who are the best at what they do!
          </div>
        </div>,
    },
    {
      id: 2,
      title: 'Minor Service',
      serviceID: null,
      isBasic: true,
      icon: OtherServiceIcon,
      buttonTitle: 'Book a Minor Service',
      description:
        <div className={classes.description}>
          <p>Simply it is an oil and oil filter change, BUT...</p>
          <p>...a   minor   service   is   necessary   to   be   carried   at   your   car   manufacturer   recommended
            intervals   and   along   with   it   the   recommended   checks   and   inspection   points.   This   would
            typically be in 10,000 to 15,000 KM intervals. The recommended interval and checks for
            when a service is due depends on the make and model of your vehicle. If you are not sure,
            input your car’s details <span className={classes.blueLinkText} onClick={() => onRedirect(dateModals[serviceNumber].serviceID == null ? dateModals[serviceNumber].isBasic : dateModals[serviceNumber].serviceID)}>HERE</span> and we can tell you the interval you need to change your oil
            and oil filter and checks that comes with it.</p>
          <p>Here at CarLogik, we provide you with the exact parts and checks recommended by your car
            manufacturer for your car at a given mileage. Just input your car’s details and mileage <span className={classes.blueLinkText} onClick={() => onRedirect(dateModals[serviceNumber].serviceID == null ? dateModals[serviceNumber].isBasic : dateModals[serviceNumber].serviceID)}>HERE </span>
            and we can tell you the exact parts and checks you need for your car! </p>
          <h2 className={classes.subTitle} style={{ marginBottom: '0' }}>Synthetic oil is best for your car!</h2>
          <p>They are not all the same, unknown oil brands are unknown and cheap for a reason. At
            CarLogik we only use, international oil brands which are highly recommended by your car
            manufacturer, such as Castrol, Total, Mobil 1, Valvoline and Wurth. Their lubrication
            properties are to the best international standards.</p>
          <h2 className={classes.subTitle} style={{ marginBottom: '0' }}>Important note! </h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                When changing the oil, you should always replace the oil filter.
              </li>
              <li>
                The type of oil used   should   always   match   the   type   specified   by   your   car
                manufacturer.
              </li>
            </ul>
          </div>
          <h2 className={classes.subTitle}>Here are a few reasons why changing your car’s oil and filter regularly is great:</h2>
          <div className={classes.list}>
            <ul className={classes.mainList} style={{ listStyle: 'decimal' }}>
              <li>
                Better Gas Mileage
                <p className={classes.textDescription}>
                  Proper and regular oil and filter changes will help maintain excellent gas mileage.
                </p>
              </li>
              <li>
                Reduces engine wear and build-up
                <p className={classes.textDescription}>
                  Oil changes help to reduce and remove any excess dirt that can build-up in your
                  engine. Basically, oil changes contribute to the cleanliness of your engine. A clean
                  engine is a smooth-running engine.
                </p>
              </li>
              <li>
                Avoiding overheating
                <p className={classes.textDescription}>
                  A car engine produces a lot of heat, while engine heat is very normal, you never want
                  your engine to overheat. Without proper oil changes, your engine is more susceptible
                  to overheating. The key is to always make sure your engine is properly lubricated and
                  clear of the possibility of drying out that can cause a number of problems!
                </p>
              </li>
            </ul>
          </div>
          <h2 className={classes.subTitle} style={{ marginBottom: '0' }}>Our recommendation:</h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                You should follow the service schedule provided by the manufacturer (we can tell you
                what your car needs and the correct interval  for your car’s oil and filter).
              </li>
              <li>
                We recommend replacing the oil and filter at least every 10,000 kms or every 12
                months.
              </li>
              <li>
                If you notice any fluids in your driveway, you should get your vehicle inspected
                immediately.
              </li>
              <li>
                Do not drive your car with low engine oil. It can severely damage the engine.
              </li>
            </ul>
          </div>
          <div className={classes.footer}>
            If you've read this far, you obviously care about your car. A lot. So next time you
            need a service, repair or inspection, visit <span className={classes.blueText}>CarLogik.com</span> We let you search and
            book qualified mechanics, who are the best at what they do!
          </div>
        </div>,
    },
    {
      id: 3,
      title: ' Replace Spark Plugs',
      serviceID: 1,
      isBasic: null,
      icon: SparkPlugs,
      buttonTitle: 'Replace your Spark Plugs',
      description:
        <div className={classes.description}>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                The spark plug is a key component of ICE (Internal Combustion Engines) engines. It
                generates an electric spark that   ignites the mixture of air and fuel in the engine
                combustion   chamber.   This   ignition   then   causes   the   engine   to   create   a   rotational
                movement,   propelling   the   car   in   to   motion.   The   spark   plugs   has   a   use   life   and
                eventually needs to be replaced when the electrode which is located at the tip of the
                spark plug wears out.
              </li>
              <li>
                If   one   spark   plug   is   found   to   be   faulty,   they   should   always   be   replaced   in   sets.
                Depending on the number of cylinders an engine will typically require 4, 6, 8 or 12
                spark plugs.
              </li>
              <li>
                Operating a vehicle that has worn spark plugs can cause performance issues and
                prolonged used of worn out spark plugs can cause other damage to the engine or
                other related parts.
              </li>
              <li>
                Spark plugs should always be promptly replaced as per the manufacturer service
                schedule. Even if they appear to be working perfectly. If you are not sure, input your
                car’s details  <span className={classes.blueLinkText} onClick={() => onRedirect(dateModals[serviceNumber].serviceID == null ? dateModals[serviceNumber].isBasic : dateModals[serviceNumber].serviceID)}>HERE</span>  and we can tell you the interval you need to change your Spark
                Plugs at.
              </li>
            </ul>
          </div>
          <h2 className={classes.subTitle}>Symptoms of bad spark plugs:</h2>
          <div className={classes.list}>
            <ul className={classes.mainList}>
              <li>
                STARTING
                <p className={classes.textDescription}>
                  Spark   plugs   are  part  of  the  engine's   ignition  system.  As   such  faulty   spark
                  plugs can make it difficult to start your car.
                </p>
              </li>
              <li>
                MISFIRE
                <p className={classes.textDescription}>
                  Spark plug failure is clearly visible. You will be able to feel the engine hesitate
                  and stumble as the drivability—the smoothness and steadiness of the driving
                  experience—becomes rough.
                </p>
              </li>
              <li>
                INCREASED FUEL CONSUMPTION
                <p className={classes.textDescription}>
                  If spark plugs are worn this can lead to less efficient combustion and an increase in
                  fuel consumption.
                </p>
              </li>
              <li>
                PERFORMANCE
                <p className={classes.textDescription}>
                  A   worn   or   damaged   spark   plug   will   lead   to   sluggish   acceleration   and
                  performance – a symptom that is noticeable.
                </p>
              </li>
            </ul>
          </div>
          <div className={classes.footer}>
            If you've read this far, you obviously care about your car. A lot. So next time you
            need a service, repair or inspection, visit <span className={classes.blueText}>CarLogik.com</span> We let you search and
            book qualified mechanics, who are the best at what they do!
          </div>
        </div>,
    },
    {
      id: 4,
      title: 'Battery Replacement',
      serviceID: 2,
      isBasic: null,
      icon: Battery,
      buttonTitle: 'Replace your Battery',
      description:
        <div className={classes.description}>
          <p>CarLogik is the easiest way to find high quality experts to replace the battery in your
            car. You can even book now, pay later with Tabby! Get your battery replaced today!</p>
          <h2 className={classes.subTitle}>When to replace a car battery?</h2>
          <div className={classes.list}>
            <ul className={classes.mainList} style={{ listStyle: 'decimal' }}>
              <li>
                Your car is not starting.
                <p className={classes.textDescription}>
                  As batteries age, the actual power that a battery supplies becomes lower than its
                  original rating. Once the power reduces to a certain level, starting problems and
                  slow cranking starts to happen.
                </p>
              </li>
              <li>
                Battery or charging system warning light in dashboard.
                <p className={classes.textDescription}>
                  Should a battery or charging system warning light illuminates, a mechanic should
                  perform a few basic tests to determine if a battery replacement is needed.
                </p>
              </li>
              <li>
                Electrical components in the car malfunctions.
                <p className={classes.textDescription}>
                  Once a battery has started to fail, then items such as internal lights, or radio
                  settings, door locks, or security system might start to malfunction. If you are
                  experiencing electrical malfunctions, get someone to test your battery.
                </p>
              </li>
            </ul>
          </div>
          <h2 className={classes.subTitle}>Tips to remember:</h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                You may lose radio and clock settings during this repair, so make sure to have
                any necessary radio PIN codes handy to restore operation.
              </li>
              <li>
                Vehicles   equipped   with   ‘Stop-Start’   systems,   which   shut   the   engine   down   in
                certain situations to save fuel, have specially designed batteries (usually AGM
                batteries) that  can  withstand the extra load that  is  put on the system. These
                vehicles are fitted with heavy-duty batteries, and failure to fit the correct battery
                may cause issues with the system, and premature failure. Always use an expert
                to check and replace your car’s battery!
              </li>
            </ul>
          </div>
          <div className={classes.footer}>
            If you've read this far, you obviously care about your car. A lot. So next time you
            need a service, repair or inspection, visit <span className={classes.blueText}>CarLogik.com</span> We let you search and
            book qualified mechanics, who are the best at what they do!
          </div>
        </div>,
    },
    {
      id: 5,
      title: 'Replace AC Filter',
      serviceID: 3,
      isBasic: null,
      icon: ACFilter,
      buttonTitle: 'Replace your AC Filter',
      description:
        <div className={classes.description}>
          <p>Nowadays, cabin air filters are standard in most new vehicles. The air that enters the
            car's   interior   from   the   outside   is   cleaned   by   this   filter.   In   particular,   if   you   have
            asthma or other allergies, it can make driving more pleasant, since it collects dust,
            pollen, and other airborne particles.</p>
          <p>To keep the air fresh inside your vehicle, it is recommended to replace this filter
            regularly, especially if you live in dusty or poor air quality environments. Most filters
            are   located   behind   the   glove   box,   or   behind   the   center   console,   although   some
            European makes will have the filter located under the bonnet, where the air enters
            into the cabin.</p>
          <p>At CarLogik, rest assured we will be using genuine replacement A/C filters.</p>
          <h2 className={classes.subTitle}>When do I need to replace my car’s A/C filter?</h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                As recommended by your car manufacturer at specific intervals
              </li>
              <li>
                Have been changing oil and oil filter only for a while
              </li>
              <li>
                Restricted airflow from the dash vents
              </li>
              <li>
                Odd, unpleasant odors coming from the dash vents
              </li>
              <li>
                Difficulty keeping windows from fogging
              </li>
            </ul>
          </div>
          <div className={classes.footer}>
            If you've read this far, you obviously care about your car. A lot. So next time you
            need a service, repair or inspection, visit <span className={classes.blueText}>CarLogik.com</span> We let you search and
            book qualified mechanics, who are the best at what they do!
          </div>
        </div>,
    },
    {
      id: 6,
      title: 'Replace Air Filter',
      serviceID: 4,
      isBasic: null,
      icon: AirFilter,
      buttonTitle: 'Replace your Air Filter',
      description:
        <div className={classes.description}>
          <p>The engine in a modern vehicle needs oxygen to function properly, just like the human body.
            In the combustion chamber, this air mixes with the fuel and is lit by the spark plugs.</p>
          <p>The Air filter captures this debris and must be changed at regular intervals. Failure to do so will
            create a restriction in airflow, decrease fuel efficiency and may even cause internal damage
            to the engine.</p>
          <p>At CarLogik, rest assured we will be using genuine replacement air filters.</p>
          <h2 className={classes.subTitle}>When do I need to replace my car’s air filter?</h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                As recommended by your car manufacturer at specific intervals
              </li>
              <li>
                Have been changing oil and oil filter only for a while
              </li>
              <li>
                Engine performance reduced
              </li>
              <li>
                Decreased fuel economy
              </li>
              <li>
                Engine running rough
              </li>
            </ul>
          </div>
          <div className={classes.footer}>
            If you've read this far, you obviously care about your car. A lot. So next time you
            need a service, repair or inspection, visit <span className={classes.blueText}>CarLogik.com</span> We let you search and
            book qualified mechanics, who are the best at what they do!
          </div>
        </div>,
    },
    {
      id: 7,
      title: 'Replace Wipers',
      serviceID: 5,
      isBasic: null,
      icon: Wipes,
      buttonTitle: 'Replace your Wipers',
      description:
        <div className={classes.description}>
          <p>The   only   thing   that   keeps   your   vision   clear   when   it's   raining   outside   are   the
            windscreen wiper blades, which also serve as a built-in mechanism for cleaning grimy
            windscreens.</p>
          <p>During   everyday   driving   conditions   and   normal   usage,   wiper   blades   wear   and
            deteriorate from the sun’s UV rays. Windscreen wipers typically last from 10,000 to
            25,000km or around 12 months.</p>
          <p>It is safe to assume that you would  roughly need to replace your wiper blades once
            per year. If you have a rear wiper blade, you might expect it to last longer than the
            front wipers because of lesser use, but that’s not always the case – sun and heat can
            cause the rubber to break down just as quickly as the fronts!</p>
          <p>At CarLogik, rest assured we will be using genuine replacement wipers.</p>
        </div>,
    },
    {
      id: 8,
      title: 'Car repair',
      serviceID: null,
      isBasic: null,
      icon: Inspection,
      buttonTitle: 'Book a Car Repair now',
      description:
        <div className={classes.description}>
          <p>
            <img src={CarRepairModal} className={classes.modalImage}></img>
            Your   car   could   be   not   starting,
            shaking   while   driving,   some   warning
            light is on, leaking fluids, over heating
            or making strange noises, this list of
            problems   can   go   on   with   multiple
            reasons behind each problem. If you
            are   facing   car   touble   and   not   sure
            whats   is   the   issue   it   is   advisable   in
            such situations to book an appointment with a mechanic and get your car inspected by a
            professional</p>
          <p>We offer you top rated workshops to select from, with a guaranteed service level, a
            service warranty on everything we do, transparent prices, and the option to buy now
            and pay later with <span className={classes.greenText}>Tabby!</span> With CarLogik you can instantly book UAE’s top workshops in
            a few clicks</p>
          <p>We will keep a record of all of the repairs and services done with any of our partner workshops.
            Your service and repair history will be in one place and you will be able to
            share it with potential buyers if you wish. If you have an existing booklet of services
            from your agency we can also verify the data and add it on our system so you have it all
            in one place for you!</p>
          <p className={classes.alert}>If your car doesn’t move, don’t worry we’ll send a tow truck to pick it up!</p>
        </div>,
    },
    {
      id: 9,
      title: 'Front Brake Pads + Disk Skimming',
      serviceID: 7,
      isBasic: null,
      icon: FrontBrake,
      buttonTitle: 'Replace your Brake pads and Skim your disks',
      description:
        <div className={classes.description}>
          <p>A brake pad is the most common form of braking hardware on a motor vehicle. They are
            usually located inside a brake caliper, which forces the pad onto the disc rotor (the shiny
            metal disc behind your wheels) when you press down on the brake pedal.</p>
          <p>Once this friction material has worn down past a certain level, usually 2 millimeters, the
            brake pads will need to be replaced.</p>
          <h2 className={classes.subTitle} style={{ marginBottom: '0' }}>Symptoms that your brake pads require replacing</h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                Squealing or grinding noise when slowing down
              </li>
              <li>
                Braking performance diminished
              </li>
              <li>
                Brake warning light on dash or instrument cluster
              </li>
            </ul>
          </div>
          <p>The braking system is the first and foremost safety system fitted to the modern-day vehicle,
            and the brake pads are the components that do all the work.
            During normal usage, the brake pads wear down over time and need to be replaced once
            the friction material has worn down to unsafe levels.</p>
          <p>Brake pads usually last around 45,000 – 60,000km, but this may be less or more, depending
            on the driving you are doing. The front brakes do 70% of the total braking when slowing
            down, so will wear out faster than the rear brakes in most cases.</p>
          <h2 className={classes.subTitle} style={{ marginBottom: '0' }}>Tips to remember</h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                It is also important to inspect the disc rotors and brake fluid when replacing brake
                pads, as these may need to be changed at the same time.
              </li>
              <li>
                Some higher end vehicles will have brake pad wear sensors, which will need to be
                replaced when the brake pads are renewed.
              </li>
            </ul>
          </div>
          <h2 className={classes.subTitle}>How important is replacing your brake pads?</h2>
          <p>Having your brake pads  replaced  when they are excessively worn will give you peace of
            mind   that   your   vehicle   will   stop   reliably   and   safely   every   time   you   press   the   pedal.
            Operating a vehicle with worn out or faulty brake pads can endanger you, your family and
            other motorists on the road, and not allow you to stop in time in the case of an emergency.</p>
          <div className={classes.footer}>
            If you've read this far, you obviously care about your car. A lot. So next time you
            need a service, repair or inspection, visit <span className={classes.blueText}>CarLogik.com</span> We let you search and
            book qualified mechanics, who are the best at what they do!
          </div>
        </div>,
    },
    {
      id: 10,
      title: 'Rear Brake Pads + Disk Skimming',
      serviceID: 8,
      isBasic: null,
      icon: RearBrake,
      buttonTitle: 'Replace your Brake pads and Skim your disks',
      description:
        <div className={classes.description}>
          <p>A brake pad is the most common form of braking hardware on a motor vehicle. They are
            usually located inside a brake caliper, which forces the pad onto the disc rotor (the shiny
            metal disc behind your wheels) when you press down on the brake pedal.</p>
          <p>Once this friction material has worn down past a certain level, usually 2 millimeters, the
            brake pads will need to be replaced.</p>
          <h2 className={classes.subTitle} style={{ marginBottom: '0' }}>Symptoms that your brake pads require replacing</h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                Squealing or grinding noise when slowing down
              </li>
              <li>
                Braking performance diminished
              </li>
              <li>
                Brake warning light on dash or instrument cluster
              </li>
            </ul>
          </div>
          <p>The braking system is the first and foremost safety system fitted to the modern-day vehicle,
            and the brake pads are the components that do all the work.
            During normal usage, the brake pads wear down over time and need to be replaced once
            the friction material has worn down to unsafe levels.</p>
          <p>Brake pads usually last around 45,000 – 60,000km, but this may be less or more, depending
            on the driving you are doing. The front brakes do 70% of the total braking when slowing
            down, so will wear out faster than the rear brakes in most cases.</p>
          <h2 className={classes.subTitle} style={{ marginBottom: '0' }}>Tips to remember</h2>
          <div className={classes.list}>
            <ul className={classes.subList}>
              <li>
                It is also important to inspect the disc rotors and brake fluid when replacing brake
                pads, as these may need to be changed at the same time.
              </li>
              <li>
                Some higher end vehicles will have brake pad wear sensors, which will need to be
                replaced when the brake pads are renewed.
              </li>
            </ul>
          </div>
          <h2 className={classes.subTitle}>How important is replacing your brake pads?</h2>
          <p>Having your brake pads  replaced  when they are excessively worn will give you peace of
            mind   that   your   vehicle   will   stop   reliably   and   safely   every   time   you   press   the   pedal.
            Operating a vehicle with worn out or faulty brake pads can endanger you, your family and
            other motorists on the road, and not allow you to stop in time in the case of an emergency.</p>
          <div className={classes.footer}>
            If you've read this far, you obviously care about your car. A lot. So next time you
            need a service, repair or inspection, visit <span className={classes.blueText}>CarLogik.com</span> We let you search and
            book qualified mechanics, who are the best at what they do!
          </div>
        </div>,
    },
  ];
  return (
    <div className={classes.modalBody}>
      {serviceNumber !== null && (
        <div className={classes.main}>
          <div className={classes.modalTitle}>
            <img className={classes.modalIcon} src={dateModals[serviceNumber].icon} alt="" />
            <h1>{dateModals[serviceNumber].title}</h1>
          </div>
          {dateModals[serviceNumber].description}
        </div>
      )}
      <div className={classes.modalButtons}>
        <ButtonComponent
          type="cancel"
          title="Cancel"
          onClick={() => setModal(false)}
          className={classes.button}
        />
        {serviceNumber !== null && (
          <ButtonComponent
            type="save"
            title={buttonState ? dateModals[serviceNumber].buttonTitle : 'Book Now'}
            onClick={() => onRedirect(dateModals[serviceNumber].serviceID == null ? dateModals[serviceNumber].isBasic : dateModals[serviceNumber].serviceID)}
            className={classes.button}
          />
        )}
      </div>
    </div>
  )
}
