import { Carousel } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyScreen } from '../../../Components/EmptyScreen';
import { userActions } from '../../../Globals/User/actions';
import { RootStateType } from '../../../Stores';
import {
  CarForAdminType,
  ResponseCarForAdminType,
} from '../../../Types/adminTypes';
import { IntervalResponseType } from '../../../Types/authTypes';
import { CarCard } from './Components/CardCarInfo';
import classes from './style.module.scss';

type CarsPropsType = {
  customerCars: ResponseCarForAdminType;
};

export const Cars: React.FC<CarsPropsType> = ({ customerCars }) => {
  const dispatch = useDispatch();
  const intervalsForAllCars = useSelector(
    (state: RootStateType) => state.user.allIntervals
  );
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: customerCars.rows.length > 1 ? 2 : 1,
    slidesToScroll: 2,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const trimsId = customerCars.rows.map((car) => car.trim.id);
    dispatch(userActions.getAllIntervals(trimsId));
  }, [customerCars.rows, dispatch]);

  const foo = (car: CarForAdminType, millage?: IntervalResponseType[]) => {
    return (
      <div
        style={{ display: 'flex', padding: '16px', justifyContent: 'center' }}
      >
        <CarCard car={car} millageData={millage} />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {customerCars.rows.length ? (
        <Carousel {...settings}>
          {customerCars.rows
            .sort((a, b) => a.id - b.id)
            .map((car) => (
              <div key={car.id}>
                {foo(
                  car,
                  intervalsForAllCars.find(
                    (interval) => interval.trimId === car.trim.id
                  )?.rows
                )}
              </div>
            ))}
        </Carousel>
      ) : (
        <EmptyScreen title="Customer dont't have a car" />
      )}
    </div>
  );
};
