import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import classes from './style.module.scss';
import { RootStateType } from '../../../Stores';
import { useSelector } from 'react-redux';
import { OrderCard } from '../../../Components/OrderCard';
import { EmptyScreen } from '../../../Components/EmptyScreen';

interface RequestsPropsType {
  loadMore: () => void;
  loading: boolean;
}
export const Requests: React.FC<RequestsPropsType> = ({ loadMore, loading }) => {
  const orders = useSelector(
    (state: RootStateType) => state.workshop.orders.rows
  );
  const isHasMore = useSelector(
    (state: RootStateType) => state.workshop.isHasMore
  );
  const pendingOnly = orders.filter((order) => order.status === 'pending');

  const onLoad = () => {
    if (loading) {
      return;
    } else {
      loadMore();
    }
  };

  return (
    <div className={classes.container}>
      <InfiniteScroll
        dataLength={orders.length}
        next={onLoad}
        hasMore={isHasMore && !loading}
        loader={null}
        endMessage={null}
      >
        {pendingOnly.length ? (
          pendingOnly.map((order) => (
            <OrderCard
              key={order.id}
              order={order}
              path={`/workshop/jobs/${order.id}`}
            />
          ))
        ) : (
          <EmptyScreen title="Very soon there will be job requests here" />
        )}
      </InfiniteScroll>
    </div>
  );
};
