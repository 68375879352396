import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {Link, useLocation, useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Checkbox, Rate} from 'antd';
import {
  CalendarTwoTone,
  StarOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import GarageIcon from '../../Assets/orderIcon/icon_at_workshop.svg';
import EmptyGarage from '../../Assets/orderIcon/icon_at_location.svg';
import Google from '../../Assets/orderIcon/google_icon.svg';

import {workshopActions} from '../../Globals/Workshop/actions';
import classes from './style.module.scss';
import {ButtonComponent} from '../../Components/Button';
import {
  OrderForWorkshopType,
  OrderService,
  OrderServicesForJob,
} from '../../Types/workshopTypes';
import moment from 'moment';
import {CarImage} from './CarImage';
import {CustomerOrderType} from '../../Types/userTypes';
import { SavedCardElement } from '../SavedCardElement';
import classNames from 'classnames';
import useConfirm from '../../Hooks/useConfirm';

import {uploadFileStore} from '../../Stores/upload';
import {RootStateType} from '../../Stores';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { adminActions } from '../../Globals/Admin/actions';

type OrderCardType =
  | 'request'
  | 'orders'
  | 'job'
  | 'expired'
  | 'customer'
  | 'history'
  | 'admin'
  | 'invoice';

type OrderCardPropsType = {
  order:
    | OrderForWorkshopType<OrderService[] | OrderServicesForJob>
    | CustomerOrderType;
  type?: OrderCardType;
  isDisableButton?: boolean;
  path?: string;
  onConfirm?: (id?: number) => void;
  onCancel?: (id?: number) => void;
  hideButtons?: boolean;
  isStartJob?: boolean;
};

export const OrderCard: React.FC<OrderCardPropsType> = ({
                                                          order,
                                                          type = 'request',
                                                          isDisableButton = false,
                                                          onConfirm,
                                                          onCancel,
                                                          path,
                                                          hideButtons = false,
                                                          isStartJob = false,
                                                        }) => {
  const [isStartedJob, setIsStartedJob] = useState(false);
  const [buttonIsHide, setButtonIsHide] = useState(hideButtons);

  const location = useLocation();
  const dispatch = useDispatch();
  const {confirm} = useConfirm();
  const history = useHistory();

  const momentEstimate = moment().set({
    hour: order.total_hour_time,
    minute: order.total_min_time,
  });

  const showConfirm = async () => {
    const isConfirmed = await confirm(
      'Did the customer miss the appointment, by 30 minutes already?'
    );
    if (isConfirmed) {
      onNoShow();
    } else {
    }
  };

  const secondsEstimate = moment
    .duration(momentEstimate.format('HH:mm'))
    .asSeconds();

  const onAccept = () => {
    if ((type === 'customer' || type === 'history') && onConfirm) {
      onConfirm(order.id);
    } else {
      dispatch(workshopActions.changeOrderStatus(order.id, 'accepted'));
    }
  };

  const onDecline = () => {
    if ((type === 'customer' || type === 'history') && onCancel) {
      onCancel(order.id);
    } else {
      dispatch(workshopActions.changeOrderStatus(order.id, 'declined'));
    }
  };

  const token = useSelector((state: RootStateType) => state.auth.user?.jwt);

  const onStartJob = () => {
    dispatch(workshopActions.changeOrderStatus(order.id, 'processing'));
  };

  const onFinishJob = () => {
    // cl-742 bugfix

    uploadFileStore.dispatch({
      type: 'UPLOAD_NOTES',
      token: token
    })

    // cl-742 bugfix

    dispatch(workshopActions.changeOrderStatus(order.id, 'finished'));
    window.location.reload();
    history.push('/workshop/jobs')
  };

  const onAdminStartJob = () => {
    dispatch(adminActions.changeOrderStatus(order.id, 'processing'));
  };

  const onAdminFinishJob = () => {
    // cl-742 bugfix

    uploadFileStore.dispatch({
      type: 'UPLOAD_NOTES',
      token: token
    })

    // cl-742 bugfix
    const payload = {
      order_id: order.id,
      status: true,
    };
    dispatch(workshopActions.markIsAllDone(payload));
    dispatch(adminActions.finishOrder(order.id));
    //window.location.reload();
    // history.push('/workshop/jobs')
  };

  const onNoShow = () => {
    dispatch(workshopActions.changeOrderStatus(order.id, 'missed_appointment'));
  };

  useEffect(() => {
    if (order.status === 'processing') {
      setIsStartedJob(true);
    } else {
      setIsStartedJob(false);
    }
  }, [order]);

  const onChangeAllServices = (value: CheckboxChangeEvent) => {
    
    const payload = {
      order_id: order.id,
      status: value.target.checked,
    };
    dispatch(workshopActions.markIsAllDone(payload));
    //window.location.reload();
  };

  const isShowButton = () => {
    if (buttonIsHide) {
      return false;
    }

    if (type !== 'orders' && order.status !== 'finished') {
      if (
        order.status !== 'declined' &&
        order.status !== 'expired' &&
        order.status !== 'canceled' &&
        order.status !== 'missed_appointment'
      ) {
        return true;
      }
    } else if (order.status === 'finished' && type === 'history') {
      return true;
    } else {
      return false;
    }
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth} = window;
    return innerWidth;
  }
  const SwitchTitleWidth = 570;
  const getButtons = (type: OrderCardType) => {
    switch (type) {
      case 'job':
        return (
          <div
            className={cn({
              [classes.twoButtonContainer]: !isStartedJob,
              [classes.allDoneContainer]: isStartedJob,
            })}
          >
            {!isStartedJob ? (
              <ButtonComponent
                title={'No Show'}
                type="decline"
                onClick={() => {
                  showConfirm();
                }}
              />)
              :
              (<Checkbox
              className={classes.checkBox}
              onChange={onChangeAllServices}
              checked={!isDisableButton}
              >
                All done
              </Checkbox>)  
            }
            <ButtonComponent
              title={isStartedJob ? 'Finish the job' : 'Start the job'}
              type="save"
              style={isStartedJob ?? {width: '100%'}}
              onClick={isStartedJob ? onFinishJob : onStartJob}
              disabled={isStartedJob && isDisableButton}
            />
          </div>
        );
      case 'admin':
        return (
          <div
            className={cn({
              [classes.twoButtonContainer]: !isStartedJob,
              [classes.allDoneContainer]: isStartedJob,
            })}
          >
            {!isStartedJob ? (
              <ButtonComponent
                title={'No Show'}
                type="decline"
                onClick={() => {
                  showConfirm();
                }}
              />)
              :
              <></> 
            }
            <ButtonComponent
              title={isStartedJob ? 'Finish the job' : 'Start the job'}
              type="save"
              style={isStartedJob ?? {width: '100%'}}
              onClick={isStartedJob ? onAdminFinishJob : onAdminStartJob}
              disabled={isStartedJob && isDisableButton}
            />
          </div>
        );
      case 'customer':
        return (
          <div
            className={classNames(classes.twoButtonContainer, {
              [classes.pendingConfirmation]:
              type === 'customer' && order.status === 'pending',
            })}
          >
            <ButtonComponent
              title={'Cancel'}
              type="decline"
              onClick={onDecline}
            />
            <ButtonComponent
              title={'Re-schedule'}
              type={'customer'}
              onClick={onAccept}
            />
          </div>
        );
      case 'history':
        return (
          <div className={classes.twoButtonContainer}>
            <a href="https://g.page/r/CbhpBkmT6O9iEB0/review" style={{marginRight: '8px'}} target='_blank'>
              <ButtonComponent
                title=""
                type="customer"
                //onClick={onDecline}
                style={!!order.review && {visibility: 'hidden'}}
              >
                  {windowSize > SwitchTitleWidth ?
                    <div>
                    <StarOutlined style={{marginRight: '8px'}}/>
                    <span>Review on Google</span>
                  </div>
                  :
                  <div>
                  <span><img className={classes.google} src={Google} alt="google-icon" /> Review</span>
                </div>
                }
              </ButtonComponent>
            </a>
            <ButtonComponent
              title={''}
              type="customer"
              onClick={onAccept}
              disabled={!order.invoice}
            >
              <div>
                <DownloadOutlined style={{marginRight: '8px'}}/>
                <span>Invoice</span>
              </div>
            </ButtonComponent>
          </div>
        );
      default:
        return (
          <div className={classes.twoButtonContainer}>
            <ButtonComponent
              title={'Decline'}
              type="decline"
              onClick={onDecline}
            />
            <ButtonComponent
              title={'Accept'}
              type={'save'}
              onClick={onAccept}
            />
          </div>
        );
    }
  };

  const getName = (type: OrderCardType) => {
    if (type === 'customer' || type === 'history' || type === 'invoice')
      if (typeof order.workshop !== 'number') {
        return order.workshop.name;
      } else {
        return order.user.username;
      }
  };

  const getListServices = () => {
    if (Array.isArray(order.order_services))
      return order.order_services.map((service) => {
        return (
          <li key={service.id}>
            <span>{service.title}</span>
          </li>
        );
      });
    else if (
      Array.isArray(
        order.order_services.log_book || order.order_services.additional
      )
    ) {
      const allServices = [
        ...order.order_services.log_book,
        ...order.order_services.additional,
      ];
      return allServices.map((service) => {
        return (
          <li key={service.id}>
            <span>{service.title}</span>
          </li>
        );
      });
    }
  };

  return (
    <div
      className={cn(classes.container, {
        [classes.boxShadow]: type !== 'job',
        [classes.horMarginNone]: type === 'invoice',
      })}
    >
      <div>
        <Link
          to={{pathname: path, search: `?is-start-job=${isStartJob}`}}
          className={cn(classes.topLeftWrapper, {
            [classes.disableLink]: !path,
          })}
        >
          <CarImage
            order={order}
            type={type}
            isTimer={isStartedJob && location.pathname !== '/workshop/jobs' && !location.pathname.includes('/admin')}
            timer={secondsEstimate}
          />

          <div className={classes.servicesInfo}>
            <p className={classes.carName}>
              {order.customer_car.year} {order.customer_car.make.title}{' '}
              {order.customer_car.model.title} {order.customer_car.trim.title}
            </p>
            {order.customer_car.maintenance_interval?.value && (
              <p>
                <span
                  style={{color: 'rgba(0, 0, 0, 0.45)', marginRight: '12px'}}
                >
                  Total mileage{' '}
                </span>
                {order.customer_car.maintenance_interval?.value.toLocaleString()}{' '}
                km
              </p>
            )}
            {type === 'job' ? (
              <p>
                <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>
                  {order.customer_note && 'Note: '} {order.customer_note}
                </span>{' '}
              </p>
            ) : (
              <ul className={classes.list}>{getListServices()}</ul>
            )}
          </div>
        </Link>
      </div>
      <div
        className={cn(classes.workshopInfo, {
          [classes.minWidthForJob]: type === 'job' || 'invoice',
        })}
      >
        <div
          className={classNames(classes.leftBlock, {
            [classes.addOpacity]:
            type === 'customer' && order.status === 'pending',
          })}
        >
          {(type === 'invoice') && (
            <div className={classes.rightPrice}>
              <div>
                AED{' '}
                {order.total_price.toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}
              </div>
              <div className={classes.orderNumber}>
                <span>Order #</span> <span>{order.id}</span>
              </div>
            </div>
          )}
          {(type === 'request' || type === 'orders') && (
            <div className={classes.rightPrice}>
              <div>
                AED{' '}
                {order.workshop_amount.toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}
              </div>
              <div className={classes.orderNumber}>
                <span>Order #</span> <span>{order.id}</span>
              </div>
            </div>
          )}
          {(type === 'invoice') && !order.isTabby && (
          <div className={classes.cardContainer}>
            <SavedCardElement payment={order} />
          </div>
          )}
          {(type === 'customer' || 'invoice') && !order.at_location && (
            <>
              {order.pick_up && (
                <div className={classes.pickUp}>
                  <span>Car Pick-Up</span>
                  <span>{order.pick_up_title}</span>
                  {/* <span>
                    {moment(order.appointment_date).format('MMM DD, YYYY')}
                  </span>{' '} */}
                  {/* <span>
                    {moment(order.pick_up_time_slot, 'HH:mm:ss').format(
                      'hh mm, A'
                    )}
                  </span> */}
                </div>
              )}
              {order.drop_off && (
                <div className={classes.pickUp}>
                  <span>Car Drop-off</span>
                  <span>{order.drop_off_title}</span>
                  {/* <span>
                    {moment(order.appointment_date)
                      .clone()
                      .add(1, 'day')
                      .format('MMM DD, YYYY')}
                  </span>{' '} */}
                  {/* <span>
                    {moment(order.drop_off_time_slot, 'HH:mm:ss').format(
                      'hh mm, A'
                    )}
                  </span> */}
                </div>
              )}
            </>
          )}
          <div className={classes.dateWrapper}>
            <div className={classes.orderDate}>
              <CalendarTwoTone
                twoToneColor="#5B8DB4"
                style={{fontSize: '22px'}}
              />
              <p className={classes.date}>
                {moment(order.appointment_date).format('DD MMM YYYY h:mm a')}
              </p>
            </div>

            <div className={classes.estimated}>
              Estimated time {order.total_hour_time}h {order.total_min_time}min
            </div>
          </div>
        </div>

        <div className={classes.rightBlock}>
          <div
            className={classNames(classes.customerInfo, {
              [classes.addOpacity]:
              type === 'customer' && order.status === 'pending',
            })}
          >
            <p className={classes.name}>{getName(type)}</p>
            <p className={classes.address}>
              {typeof order.workshop !== 'number' && order.workshop.address}
            </p>
            {/* {type === 'history' &&
            (order.review ? (
              <Rate
                disabled={true}
                onChange={() => {
                }}
                value={order.review.rate}
                style={{marginTop: '8px'}}
              />
            ) : (
              <div className={classes.notReview}>You didn’t add a review</div>
            ))} */}
          </div>
          <div
            className={classNames(classes.customerInfo, {
              [classes.addOpacity]:
              type === 'customer' && order.status === 'pending',
            })}
          >
            <p className={classes.name}>{order.user.username}</p>
            {order.address_url ? (
              <a
                style={{color: '#5B8DB4'}}
                href={order.address_url}
                target="_blank"
                className={classes.address}
                rel="noreferrer"
              >
                {order.client_address}
              </a>
            ) : (
              <p className={classes.address}>{order.client_address}</p>
            )}
          </div>
          {/* {type === 'customer' && order.status === 'pending' && (
            <div className={classes.pendingConfirmation}>
              Pending workshop confirmation
            </div>
          )} */}
          {isShowButton() && getButtons(type)}
        </div>
      </div>
    </div>
  );
};
