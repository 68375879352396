import React, { useEffect } from 'react';
import classes from './style.module.scss';
import { MainLayout } from '../../Components/MainLayout';
import miniLogo from '../../Assets/miniLogo.png';
import bottomImg from '../../Assets/bottomImg2.png';
import { ButtonComponent } from '../../Components/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { RootStateType } from '../../Stores';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../Globals/User/actions';
import { authActions } from '../../Globals/Auth/actions';
import useConfirm from '../../Hooks/useConfirm';
import classNames from 'classnames';

export const UserMyCars: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //const location = useLocation<{ params?: string }>();
  
  const { confirm } = useConfirm();
  const route = useSelector((state: RootStateType) => state.auth.route);
  
  const myCarsList = useSelector((state: RootStateType) => state.user.carList);
  
  const dataLayer = window.dataLayer || []
  
  // useEffect(() => {
  // },[location])

  const showConfirm = async (id: number) => {
    const isConfirmed = await confirm('Are you sure you want delete your car?');
    if (isConfirmed) {
      dispatch(userActions.deleteCar(id));
    } else {
    }
  };

  const goToCar = (id: number) => {
    history.push(`/user/my-cars/${id}`);
  };

  const resetOrderData = (id: number) => {
    history.push(
      '/user/get-quote',
      { carId: id }
    );
    dispatch(authActions.resetOrderData());
    dispatch(userActions.resetOrderData());
  };

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/user/my-cars",
      "page_type":"mycarsPage",
      "event":"page_view"
    });
    //End
    dispatch(userActions.getCarList());
  }, [dispatch]);

  useEffect(() => {
    if (!myCarsList.rows.length && route === '/user/my-cars') {
      //history.push(route);
      dispatch(authActions.setRoute(null));
    }
  }, [route, dispatch, history, myCarsList]);
  return (
    <>
      <MainLayout headerTitle="Add a New Car">
        <div
          className={classNames(classes.contentContainer, {
            [classes.addHeight]: !myCarsList.rows.length,
          })}
        >
          {!myCarsList.rows.length ? (
            <></>
          ) : (
            <div className={classes.addCarButton}>
              <Link
                to={{
                  pathname: '/user/add-car',
                  state: { fromAddCar: true },
                }}
              >
                <ButtonComponent
                  size="max"
                  style={{ margin: '0px' }}
                  title="+ Add a car"
                  breakpointOff
                />
              </Link>
            </div>
          )}
          {!myCarsList.rows.length ? (
            <div className={classes.container}>
              <img src={miniLogo} alt="logo" />
              <h2>
                You haven’t added a car yet… <br />
                It’s quick and easy, let’s do it!
              </h2>
              <Link
                to={{
                  pathname: '/user/add-car',
                  state: { fromAddCar: true },
                }}
              >
                <ButtonComponent
                  style={{
                    margin: '0px',
                    width: '290px',
                    position: 'relative',
                    zIndex: 1,
                  }}
                  title="+ Add a car"
                />
              </Link>
              <img className={classes.bottom} src={bottomImg} alt="img" />
            </div>
          ) : (
            <div className={classes.carlistContainer}>
              {myCarsList.rows.map((car) => (
                <div className={classes.car} key={car.id}>
                  <DeleteOutlined
                    onClick={() => {
                      showConfirm(car.id);
                    }}
                    className={classes.trash}
                  />
                  <div
                    onClick={() => goToCar(car.id)}
                    className={classes.clickable}
                  >
                    <img src={car.model.image_url} alt="car" />
                  </div>
                  <div className={classes.description}>
                    <div
                      onClick={() => goToCar(car.id)}
                      className={classes.clickable}
                    >
                      <h3>{car?.year}</h3>

                      <h3>
                        {car.make?.title} {car.model?.title}
                      </h3>
                      <h3 style={{lineHeight: '30px'}}>{car.trim?.title}</h3>
                    </div>
                    {car.maintenance_interval?.value && (
                      <p>
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                          Total mileage{' '}
                        </span>
                        {car.maintenance_interval?.value?.toLocaleString()} km
                      </p>
                    )}
                    <ButtonComponent
                      className={classes.buttonClass}
                      onClick={() => resetOrderData(car.id)}
                      title="Let's find a service"
                      breakpointOff
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </MainLayout>
    </>
  );
};
