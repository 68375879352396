import React from 'react';
import { Link } from 'react-router-dom';
import Tabby from "../../../../../Assets/tabby-logo.svg"
import Star from "../../../../../Assets/about-us1.svg"
import Clock from "../../../../../Assets/about-us2.svg"
import Verify from "../../../../../Assets/about-us3.svg"
import classes from './styles.module.scss';
import { NavHashLink } from 'react-router-hash-link';

export const AboutUsList: React.FC = () => {
  return (
    <div className={classes.contentWrapper}>
          <p>CarLogik is the UAE’s first automotive online marketplace where car owners can browse, and book car related services.<br></br><br></br>
          We delivery <span>transparency</span> and a <span>quality</span> service to our clients.<br></br><br></br>
          Here are some of the ways we achieve this:<br></br></p>
          <ul>
            <li>Vetted workshops. CarLogik partners with the best workshops in the countries in which we operate. We vet every partner. Some of the things we check:
                <ul className={classes.listP}>
                  <li><img src={Star} alt="" />Customer reviews, workshops must retain a 4.0 Google review.</li>
                  <li><img src={Clock} alt="" />Workshops must have 2+ years of experience.</li>
                  <li><img src={Verify} alt="" />Genuine parts, Warranties, & Certified Mechanics.</li>
                </ul>
                <br></br>
            </li>
            <li>Upfront prices. The price you see on our platform is the price you pay. This will <span>never</span> change. We understand the frustration of dropping your car expecting to pay AED 500 and end up paying AED 1500 when it’s ready. Never again!<br></br><br></br></li>
            <li>
              CarLogik <span>empowers</span> the car owner:
              <ul>
                <li>You know & choose the workshop to work on your car.</li>
                <li>Compare prices, expertise, and reviews of multiple workshops in one place.</li>
                <li>Tell us what service you need, and we arm you with the Agency recommended solution tailored to your car. Why should you go to your workshop blind? No more guess work!</li>
                <li>Buy now & pay later, interest free with <img src={Tabby} alt="tabby" /></li>
              </ul>
              <br></br>
            </li>
          </ul>
          <p>We cover major and minor car services, car repairs, pre-purchase inspection, battery change, oil change, and other routine maintenance tasks. <br></br><br></br>
          Get agency quality services at prices that are up to 50% less!<br></br><br></br>
          Interested in seeing the value we bring?
          <NavHashLink to="/#services-for-offer"> Check out our services!</NavHashLink></p>
    </div>
  );
};
