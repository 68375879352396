import React from 'react';
import classes from './styles.module.scss';
import QuotesIcon from '../../../../../../Assets/Landing/quotes-icon.svg';
import { Rate } from 'antd';
import GoogleIcon from '../../../../../../Assets/Landing/google-icon.svg';

type ReviewPropsType = {
  description: string;
  rateCount: number;
  workshopName: string;
  userName: string;
};

export const Review: React.FC<ReviewPropsType> = ({
  description,
  rateCount,
  workshopName,
  userName,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.reviewWrapper}>
        <h3 className={classes.header}>{workshopName}</h3>
        <div className={classes.rateContainer}>
          <Rate
            allowHalf
            value={rateCount}
            className={classes.rate}
            disabled
          />
          <div style={{display: 'flex', minWidth: '100px'}}>
            <img src={GoogleIcon} alt="google" />
            <span className={classes.google}>via Google</span>
          </div>
        </div>
        <div className={classes.text}>{description}</div>
        <div className={classes.name}>{userName}</div>
      </div>
    </div>
  );
};
