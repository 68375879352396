
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const TOGGLE_IS_LOGIN = 'TOGGLE_IS_LOGIN';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_ROUTE = 'SET_ROUTE';
export const UPDATE_PASSWORD = 'app/UPDATE_PASSWORD';

export const RESET_ORDER_DATA = 'auth/RESET_ORDER_DATA';
export const SET_ORDER_DATA = 'auth/SET_ORDER_DATA';

export const SET_ORDER_ID = 'auth/SET_ORDER_ID';

export const SET_SAVED_SUMMARY_DATA = 'auth/SET_SAVED_SUMMARY_DATA';
export const FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD';

export const IS_SENDING_CONFIRMED = 'auth/IS_SENDING_CONFIRMED';

export const RESET_PASSWORD = 'auth/RESET_PASSWORD';
