import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from './styles.module.scss';
import { Header } from './../../Components/Header';
import { Footer } from './../../Components/Footer';

export const CommunityPage: React.FC = () => {
  const history = useHistory();
  const goTo = (path: string) => {
    history.push(path);
  };
  return (
    <div className={classes.container}>
      <Header goTo={goTo} />
      <div className={classes.mainWrapper}>
        <div className={classes.container}>
          <div className={classes.bannerContent}>
            <h1>Community Guidelines</h1>
          </div>
          <div className={classes.backgroundImage}></div>
        </div>
        <div className={classes.contentWrapper}>
          <p>
            At CarLogik, we connect you with great service providers in the
            automotive industry. Help us create an environment where you help
            others by providing feedback on the job completed by the auto
            service providers. Although we welcome your ratings, reviews,
            comments, and funny jokes, we are strict about our community
            guidelines that we request you to follow. Only a customer who has
            had work done on their car with the service provider in question can
            leave a review. This allows us to verify that reviews on our
            platform come from genuine customers.
          </p>
          <h3>*Your* experience</h3>
          <p>
            Please ensure that your ratings and comments are a record of your
            recent experience with the service provider, and not something from
            a long time ago, and not an experience of a family member or friend.
            Reviews left on the CarLogik platform must be reviewing your
            experience with the service provider and not your experience with
            CarLogik or the booking process. If you have any concerns or
            comments on your experience with CarLogik please Contact Us on
            800LOGIK or email us at{' '}
            <a href="mailto:customer@carlogik.com">customer@carlogik.com</a>{' '}
            For bookings made via CarLogik, if comments on your experience
            include details on tasks that were not added to the booking upon
            checkout, we may not publish the review. The exception to this would
            be where you book in for an inspection via CarLogik and then
            complete additional repairs.
          </p>
          <h3>Defamatory, obscene, racist, or insulting comments</h3>
          <div className={classes.listWrapper}>
            In addition to keeping all comments legal, in accordance with UAE
            law, we want all feedback to be constructive. Thus, the following
            will not be permitted under any circumstances:
            <ul>
              <li>Obscenity or vulgarity</li>
              <li>Racial / ethnic slurs</li>
              <li>Hate speech or prejudiced comments</li>
              <li>Personal insults of any sort</li>
              <li>Hostile comments and threatening language</li>
              <li>Any comments that could be used to identify an individual</li>
            </ul>
            Any comments that could be used to identify an individual will not
            be published, under any circumstances. CarLogik will also have the
            right to reject the customer any future business if such behaviour
            persists. Oh, and our moderator Mr. Logik is very strict about the
            quality of jokes appearing anywhere on our site. If you want to be
            funny, please be funny! Any silly, boring, dull, humourless, and
            unamusing jokes will be taken down.”
          </div>
          <h3>Moderating Reviews</h3>
          <p>
            We reserve the right to moderate comments. Mr. Logik will act as
            judge, jury, and executioner, and will not publish any material that
            does not comply with these guidelines. Off course the customer will
            be notified in case a comment is rejected and will have the chance
            to amend it. We may also choose to edit some comments before
            publication, but only to correct typos, but we will not change the
            commenters' general opinion of their customer experience with the
            service provider. Service provider replies will also be moderated.
          </p>
          <h3>Dispute resolution</h3>
          <p>
            In case a customer’s review is disputed by a service provider, we
            will conduct an investigation to ensure that what is described in a
            review is correct and that no misleading information has been
            published. This investigation may involve us contacting both the
            reviewer and service provider to provide evidence that supports the
            events in their review or dispute. If a customer or service provider
            is uncontactable or refuses to cooperate during the review dispute
            resolution process, we reserve the right to either reject or accept
            the review at our sole discretion.
          </p>
          <h3>Be a little patient</h3>
          <p>
            There may be a lag between when you post your comment, and when it
            is published. As all comments are moderated, so please be a little
            patient with us!{' '}
          </p>
          <h3>Our goal</h3>
          <p>
            Transparency. We aim to offer you a service you trust. In providing
            the opportunity to pass on feedback we improve the customer
            experience for everyone – customers and service providers alike.
            Thank you very much for your business! We appreciate the time you
            take to rate and comment on your experience with service providers
            on our platform: our partners.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
