import React from 'react';
import ReactGA from 'react-ga';
import { Popover, Rate } from 'antd';
import { useSelector } from 'react-redux';

import { ButtonComponent } from '../Button';
import logo from '../../Assets/workshopImage.png';
import SaleFreeIcon from '../../Assets/orderIcon/sale-icon.svg';
import Google from '../../Assets/orderIcon/google_icon.svg';
import classes from './style.module.scss';
import { RateDescription } from './Components/RateDescription';
import { PopoverPrices } from './Components/PopoverPrices';
import { WorkShopType } from '../../Types/authTypes';
import { RootStateType } from '../../Stores';
import ArrowDown from '../../Assets/orderIcon/arrow-down.svg';

export interface StandardComponentProps {
  openModal?: () => void;
  availableData: string;
  goToNextStep: (id: number) => void;
  workshop: WorkShopType;
}

export function Workshop({
  openModal,
  availableData,
  goToNextStep,
  workshop,
}: StandardComponentProps) {
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const sendGAEvent = (isOpen: boolean) => {
    if (isOpen) {
      ReactGA.event({
        category: 'Price breakdown was open',
        action: `User ${
          currentUser?.username ? currentUser?.username : 'not registered'
        } opened breakdown for workshop: ${workshop.name}`,
      });
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.workshopInfo}>
        <div style={{}}>
          <div onClick={openModal} className={classes.workshopName}>
            {workshop.name}
          </div>
          <div className={classes.imageAndDescription}>
            <div className={classes.modal}>
              <img
                style={{ cursor: 'pointer', borderRadius: '6px' }}
                width={200}
                height={200}
                onClick={openModal}
                className={classes.workshopImage}
                src={workshop.avatar ? `${workshop.avatar.url}` : logo}
                alt="logo"
              />
              <p onClick={openModal} className={classes.moreInfo}>MORE INFO <img src={ArrowDown} alt="more info" /></p>
            </div>
            <RateDescription
              rate={workshop.google_review.average_rating_maps || 0}
              image={workshop.avatar}
              isPartner={workshop.partner}
              count={workshop.google_review.ratings_total_maps}
              isVerified={workshop.verified}
              sales={workshop.workshop_promotions}
              link={workshop.review_url}
              specialists={workshop.workshop_specialists}
              showModal={openModal}
            />
            <div className={classes.mobVisible}>
            <a href={workshop.review_url} target="_blank" className={classes.link}>
              {workshop.google_review.average_rating_maps || 0}
              <div className={classes.rateBox}>
                <Rate
                  allowHalf
                  value={workshop.google_review.average_rating_maps || 0}
                  count={5}
                  disabled
                  className={classes.rate}
                />
              </div> 
              {workshop.google_review.ratings_total_maps}<img className={classes.google} src={Google} alt="google-icon" /> Reviews
            </a>
              <div className={classes.leftPart}>
                <div className={classes.bookingInfoItem}>
                  <p>Available from</p>
                  <span>{availableData}</span>
                </div>

                <div className={classes.bookingInfoItem}>
                  <p>City</p>
                  <span>{workshop.city_id?.title}</span>
                </div>
              </div>
              <p className={classes.price}>
                AED{' '}
                {workshop.sub_total_price?.toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                })}
              </p>
              <div className={classes.saveButton}>
                <ButtonComponent
                  title="Book now"
                  type="save"
                  className={classes.button}
                  onClick={() => goToNextStep(workshop.id)}
                />
              </div>
              <div className={classes.parts}>
                <p>Prices include parts & labor</p>
              </div>
              <Popover
                content={<PopoverPrices workshop={workshop} />}
                trigger="click"
                onVisibleChange={sendGAEvent}
                title={
                  <div className={classes.popoverTitle}>Total price breakdown</div>
                }
              >
                <div className={classes.breakdown}>View price breakdown</div>
              </Popover>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.bookingInfo}>
        <div className={classes.leftPart}>
          <div className={classes.bookingInfoItem}>
            <p>Available from</p>
            <span>{availableData}</span>
          </div>

          <div className={classes.bookingInfoItem}>
            <p>City</p>
            <span>{workshop.city_id?.title}</span>
          </div>
        </div>

        <div className={classes.rightPart}>
          <p className={classes.price}>
            AED{' '}
            {workshop.sub_total_price?.toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </p>
          <div className={classes.parts}>
            <p>Prices include parts & labor</p>
          </div>
          <div className={classes.parts}>
            <p style={{marginTop: 0}}>All parts are OEM with warranty</p>
          </div>
          <Popover
            content={<PopoverPrices workshop={workshop} />}
            trigger="click"
            onVisibleChange={sendGAEvent}
            title={
              <div className={classes.popoverTitle}>Total price breakdown</div>
            }
          >
            <div className={classes.breakdown}>View price breakdown</div>
          </Popover>
          <div className={classes.saveButton}>
            <ButtonComponent
              title="Book now"
              type="save"
              className={classes.button}
              onClick={() => goToNextStep(workshop.id)}
            />
          </div>

          <div className={classes.workshopPoints}>
            {workshop.workshop_promotions?.sort(function (a, b) {
              if (a.priority > b.priority) {
                return 1;
              }
              if (a.priority < b.priority) {
                return -1;
              }
              return 0;
            }).map((item, index) => {
              if(index >= 3){
                return
              }
              return(
                <div className={classes.partner} key={index}>
                  <div>
                    <img src={SaleFreeIcon} alt="sale" className={classes.checkIcon} />
                  </div>
                  <span>{item.title}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Workshop;
