import React, { useEffect, useState, useRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import classes from './style.module.scss';
import { ButtonComponent } from '../../../Components/Button';
import { userActions } from '../../../Globals/User/actions';
import { appActions } from '../../../Globals/App/actions';
import { RootStateType } from '../../../Stores';
import { SavedCardElement } from '../../../Components/SavedCardElement';
import useConfirm from '../../../Hooks/useConfirm';
import * as constants from '../../../Utils/Api/constants';

type PaymentPreferencesPropsType = {};

export const PaymentPreferences: React.FC<PaymentPreferencesPropsType> =
  ({}) => {
    const [elements, setElements] = useState<any>();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const ref: any = useRef();
    const { confirm } = useConfirm();

    const payment = useSelector((state: RootStateType) => state.user.payment);
    const token = useSelector((state: RootStateType) => state.auth.user?.jwt);
    const error = useSelector((state: RootStateType) => state.app.error);

    const dataLayer = window.dataLayer || []

    const deleteCallback = () => {
      window.location.reload();
    };

    function usePrevious(value: any) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }

    const useHasChanged = (val: any) => {
      const prevVal = usePrevious(val);
      return prevVal && !val;
    };

    const paymentDeleted = useHasChanged(payment);

    useEffect(() => {
      if (paymentDeleted) {
        deleteCallback();
      }
    });

    const deleteCard = (id: string) => {
      dispatch(userActions.deleteCard(id));
    };

    const showConfirm = async () => {
      const isConfirmed = await confirm(
        `Are you sure you want delete your card? ${error ? error : ''}`
      );
      if (isConfirmed) {
        if (payment) deleteCard(payment.id);
      } else {
      }
    };

    const setCard = async () => {
      if (!payment) {
        dispatch(appActions.openLoader());
        const setupIntent: any = await fetch(
          `${constants.ApiUrls.BASE_URL}/create-setupintent`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const setupIntentLoaded = await setupIntent.json();
        const { client_secret } = setupIntentLoaded;
        if (ref.current) {
          const options = {
            clientSecret: client_secret || undefined,
            // Fully customizable with appearance API.
            appearance: {
              colorPrimary: '#0570de',
              colorBackground: '#ffffff',
              colorText: '#30313d',
              colorDanger: '#df1b41',
              fontFamily: 'LatoRegular, sans-serif',
              borderRadius: '4px',
            },
            wallets: {
              applePay: 'never',
              googlePay: 'never',
            },
            locale: 'en' as StripeElementLocale,
          };

          // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
          const elements = stripe?.elements(options);
          setElements(elements);
          // Create and mount the Payment Element
          const paymentElement = elements?.create('payment');
          paymentElement?.mount('#payment-element');
          dispatch(appActions.closeLoader());
        }
      }
    };

    useEffect(() => {
      setCard();
    }, []);

    const onFinish = async () => {
      if (elements) {
        const confirmSetup = await stripe?.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${constants.ApiUrls.WEB_URL}/user/account`,
          },
        });
        if (confirmSetup?.error) {
          console.log(confirmSetup.error?.message);
        } else {
        }
      }
    };

    useEffect(() => {
      //GTM
      //Start
      dataLayer.push({
        "path": "/user/account",
        "page_type":"userPaymentPreferencesPage",
        "event":"page_view"
      });
      //End
    }, []);

    useEffect(() => {
      dispatch(userActions.getPayment());
    }, [dispatch]);

    return (
      <div className={classes.container}>
        {!payment ? (
          <div className={classes.addCardForm}>
            <div ref={ref} id="payment-element"></div>
            <ButtonComponent onClick={() => onFinish()} title="Save" />
          </div>
        ) : (
          <div className={classes.subContainer}>
            <SavedCardElement payment={payment} />
            <ButtonComponent
              onClick={() => showConfirm()}
              title="Delete"
              type="decline"
            />
          </div>
        )}
      </div>
    );
  };
