import React, { useContext } from 'react';
import cn from 'classnames';
import { Table, Popover, TablePaginationConfig } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import classes from './style.module.scss';
import { ActionContent } from './Components/ActionContent';
import { ModalContext } from '../MainLayout';
import { TableItem } from './Components/Column';

import { ArrayElementType, TableConfigItem } from '../../Types/appTypes';

const { Column } = Table;

type TableComponentPropsType = {
  dataForTableList: Record<string, unknown>[];
  tableConfig: Array<TableConfigItem>;
  isWorkshops?: boolean;
  defaultPageSize?: number;
  totalItems?: number;
  onChangePage?: (e: number, f?: number) => void;
  isLastColumn?: boolean;
  onDownloadClick?: (key: number) => void;
  goTo?: (id: number) => void;
  onSort?: (fieldKey: string, order: 'ascend' | 'descend') => void;
  onAction?: (data: { id: number; action: string }) => void;
};

export const TableComponent: React.FC<TableComponentPropsType> = ({
  dataForTableList,
  tableConfig,
  isWorkshops = false,
  defaultPageSize = 20,
  totalItems = 15,
  onChangePage,
  isLastColumn = true,
  onDownloadClick,
  goTo,
  onSort,
  onAction = () => {},
}) => {
 

  const { openModal } = useContext(ModalContext);

  const onActionWithContextModal = (data: { id: number; action: string }) => {
    if (isWorkshops && data.action === 'Approve') {
      openModal(data);
    } else {
      onAction(data);
    }
  };

  const onClickGoTo = (tableItem: any) => {
    if (goTo) {
      if (tableItem.order_id) {
        goTo(+tableItem.order_id);
      } else {
        goTo(+tableItem.key);
      }
    }
  };

  const onDownload = (item: { key: number }) => {
    if (onDownloadClick) {
      onDownloadClick(item.key);
    }
  };

  const createActionView = (
    config: 'download' | 'dots' | 'delete' | undefined,
    value: any,
    key: { key: number; link?: string | null }
  ) => {
    switch (config) {
      case 'download':
        return (
          <DownloadOutlined
            className={classNames(classes.downloadIcon, {
              [classes.disabledIcon]: !key?.link,
            })}
            onClick={() => {
              onDownload(key);
            }}
          />
        );
      case 'dots':
        return (
          <Popover
            placement="left"
            title={'Action'}
            content={
              <ActionContent
                actions={value}
                id={key.key}
                isWorkshops={isWorkshops}
                onAction={onActionWithContextModal}
              />
            }
          >
            <div className={classes.verticalDots}>&#8942;</div>
          </Popover>
        );
      case 'delete':
        return (
          <DeleteOutlined
            className={classNames(classes.downloadIcon)}
            onClick={() => {
              onDownload(key);
            }}
          />
        );
      default:
        return (
          value.length ? <Popover
            placement="left"
            title={'Action'}
            content={
              <ActionContent
                actions={value}
                id={key.key}
                isWorkshops={isWorkshops}
                onAction={onActionWithContextModal}
              />
            }
          >
            <div className={classes.verticalDots}>&#8942;</div>
          </Popover>
          : <div/>
        );
    }
  };

  const onChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<Record<string, unknown>>
      | SorterResult<Record<string, unknown>>[],
    extra: any
  ) => {
    if (onChangePage) {
      if (extra.action === 'paginate' && pagination.current) {
        onChangePage(pagination.current, pagination.pageSize);
      }
    }

    if (sorter && onSort) {
      //@ts-ignore
      onSort(sorter.columnKey, sorter.order as 'ascend' | 'descend');
    }
  };

  type DataType = ArrayElementType<typeof dataForTableList>;
  return (
    <Table
      pagination={{
        defaultPageSize: defaultPageSize,
        total: totalItems,
        pageSizeOptions: ['20', '50', '100'],
        showSizeChanger: true,
      }}
      onChange={onChange}
      size={'middle'}
      scroll={{ x: 900 }}
      dataSource={dataForTableList as Array<DataType>}
    >
      {tableConfig?.map((item) => (
        <Column
          title={item.title}
          dataIndex={item.key}
          key={item.key}
          align={item.align}
          sorter={item.isSort}
          render={(value, tableObject: any) => (
            <div
              className={cn({
                [classes.link]: item.isClickable,
                [classes.linkColor]: item.isClickable,
              })}
              onClick={() => {
                item.isClickable && onClickGoTo(tableObject);
              }}
            >

              <TableItem
                isTag={!!~item.key.indexOf('status')}
                isPayment={!!~item.key.indexOf('isTabby')}
                key={item.key}
                itemKey={item.key}
                value={value}
              >
                {value}
              </TableItem>
            </div>
          )}
        />
      ))}
      {isLastColumn && (
        <Column
          title=""
          dataIndex="actions"
          key="key"
          render={(value, key: { key: number; link?: string | null }) => {
            return createActionView(tableConfig[0]?.suffixIcon, value, key);
          }}
        />
      )}
    </Table>
  );
};
