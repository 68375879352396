import React from 'react';
import { useSelector } from 'react-redux';
import { StaticContext } from 'react-router';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';
import { RootStateType } from '../../Stores';

interface PrivateRoutePropsType extends RouteProps {
  component?: React.FunctionComponent<any>;
}

const PrivateRoute: React.FC<PrivateRoutePropsType> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const roleType = useSelector(
    (state: RootStateType) => state.auth.user?.user.role.type
  );

  const isAuthenticated = () => {
    switch (roleType) {
      case 'authenticated_admin':
        return location.pathname.split('/')[1] === 'admin';
      case 'authenticated_workshop':
        return location.pathname.split('/')[1] === 'workshop';
      case 'authenticated':
        return location.pathname.split('/')[1] === 'user';
      default:
        return false;
    }
  };

  const redirectHandler = (
    props: RouteComponentProps<
      { [x: string]: string | undefined },
      StaticContext,
      unknown
    >
  ) => {
    return !isAuthenticated() ? (
      <Redirect to={{pathname: "/auth/customer/signin", state: {
        link: location.pathname + location.search
      } }} />
    ) : Component ? (
      <Component {...props} />
    ) : (
      <></>
    );
  };

  return rest.render ? (
    <Route
      {...rest}
      render={
        !isAuthenticated()
          ? () => <Redirect to={{pathname: "/auth/customer/signin", state: {
            link: location.pathname + location.search
          } }} />
          : rest.render
      }
    />
  ) : (
    <Route {...rest} render={(props) => redirectHandler(props)} />
  );
};
export default PrivateRoute;
