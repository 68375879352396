import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Form, Select, Input, Button } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

import classes from './style.module.scss';
import { DashboardOutlined, CalendarOutlined } from '@ant-design/icons';
import CarIcon from '../../Assets/car-icon.svg';
import ModelIcon from '../../Assets/model-icon.svg';
import EngineIcon from '../../Assets/engine-icon.svg';

import { ButtonComponent } from '../Button';
import { CarDetailsFormType, MakesResponseType } from '../../Types/authTypes';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../Globals/User/actions';
import { RootStateType } from '../../Stores';
import { authActions } from '../../Globals/Auth/actions';
import { adminActions } from '../../Globals/Admin/actions';
import classNames from 'classnames';
import { isClassExpression } from 'typescript';
import TagManager from 'react-gtm-module'
import TabbyLogo from '../../Assets/tabby-button-logo.svg'
import { TabbyPromo } from '../Tabby/TabbyPromo';

const { Option } = Select;

type AddCarFormPropsType = {
  makes?: Array<MakesResponseType>;
  goToNextStep?: (step?: number) => void;
  currentStep?: number;
  createNewCar?: (formValues: CarDetailsFormType) => void;
  fromLanding?: boolean;
};

export type FormId = {
  car: number;
  year: number;
  model: string;
  trim: number;
  interval: number;
};

export const AddCarForm: React.FC<AddCarFormPropsType> = ({
  goToNextStep,
  makes = [],
  createNewCar,
  fromLanding = false
}) => {
  const [form] = Form.useForm();
  const location = useLocation<{ carId?: number }>();
  const history = useHistory();
  const [isSearch, setIsSearch] = useState<boolean>(false)

  const [formId, setFormId] = useState<FormId>({
    car: 0,
    year: 0,
    model: '0',
    trim: 0,
    interval: 0,
  });

  const dispatch = useDispatch();

  const years = useSelector((state: RootStateType) => state.user.year);
  const models = useSelector((state: RootStateType) => state.user.model);
  const trims = useSelector((state: RootStateType) => state.user.trim);
  const intervals =
    useSelector((state: RootStateType) => state.user.intervals) || [];

  const userCars = useSelector((state: RootStateType) => state.user.carList);
  const selectedId = useSelector((state: RootStateType) => state.user.formId);
  const selectedCar = userCars.rows.find(
    (car) => car.id === location.state?.carId
  );

  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const formValues = useSelector(
    (state: RootStateType) => state.user.formValues
  );

  const route = useSelector((state: RootStateType) => state.auth.route);

  const setNewValueToForm = (value: { title: string; value: number }) => {
    const allFields = ['car', 'year', 'model', 'trim', 'milliage'];
    const indexCurrentField = allFields.indexOf(value.title);
    const fieldsForReset = allFields.slice(indexCurrentField + 1);
    form.resetFields(fieldsForReset);
    const formObj = { [value.title]: value.value };
    setFormId((prev) => ({ ...prev, ...formObj }));
  };

  const onChange = (_: number, option: any) => {
    switch (option.title) {
      case 'car':
        dispatch(userActions.getYear(option.value));
        setNewValueToForm(option);
        break;
      case 'year':
        dispatch(userActions.getModel(option.value));
        setNewValueToForm(option);
        break;
      case 'model':
        dispatch(userActions.getTrim(option.value));
        setNewValueToForm(option);
        break;
      case 'trim':
        dispatch(userActions.getIntervals(option.value));
        setNewValueToForm(option);
        dispatch(userActions.setFormId({ ...formId, trim: option.value }));
        currentUser?.role.type === 'authenticated_admin'
          ? dispatch(adminActions.getServices(formId.trim, option.value))
          : dispatch(userActions.getServices(option.value));

        break;
      case 'milliage':
        setNewValueToForm(option);
        currentUser?.role.type === 'authenticated_admin'
          ? dispatch(adminActions.getServices(formId.trim, option.value))
          : dispatch(
              userActions.getServices(
                selectedId?.trim || formId.trim,
                option.value
              )
            );
        dispatch(userActions.setFormId({ ...formId, interval: option.value }));
        break;
      default:
        return;
    }
  };

  const sendGAEvent = (value: string, label?: string) => {
    ReactGA.event({
      category: 'Get Started',
      label,
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } selected ${value}`,
    });
  };

  const onFinish = (values: {
    brand: number;
    milliage: number;
    model: string;
    trim: number;
    year: number;
  }) => {
    //GTM
    const formVal = getTitleOfId(values);
    let dataLayer = window.dataLayer || []
    dataLayer.push({
      "event": "step_1_cardetails",
      "make": `${formVal.brand?.title}`,
      "year": `${formVal.year?.year}`,
      "model": `${formVal.model?.title}`,
      "trim": `${formVal.trim?.title}`,
      "milage": `${formVal.milliage?.value}`
      });
    if (selectedCar) {
      const valueForForm = {
        brand: selectedCar.make,
        model: {
          models_id: [selectedCar.model.id],
          title: selectedCar.model.title,
          image_url: selectedCar.model.image_url,
        },
        trim: selectedCar.trim,
        milliage: selectedCar.maintenance_interval!,
      };
      dispatch(userActions.getServicesForCustomCar(selectedCar.id));
      dispatch(userActions.setFormValues(valueForForm));
    } else if (!selectedCar && currentUser && fromLanding) {
      dispatch(userActions.setFormValues(formVal));
      //createNewCar && createNewCar(formVal);
      history.push('/createorder', { params: 'fromLanding' });
    } else if (!selectedCar && currentUser) {
      dispatch(userActions.setFormValues(formVal));
      createNewCar && createNewCar(formVal);
    } else {
      dispatch(userActions.setFormValues(formVal));
      sendGAEvent(
        `${formVal.brand?.title} ${formVal.model?.title} ${formVal.trim?.title}, ${formVal.year?.year}`
      );
      if (fromLanding) {
        history.push('/createorder', { params: 'fromLanding' });
        sendGAEvent(
          `${formVal.brand?.title} ${formVal.model?.title} ${formVal.trim?.title}, ${formVal.year?.year}`,
          'From form'
        );
      }
    }
    goToNextStep && goToNextStep();
  };

  const getTitleOfId = (values: {
    brand: number;
    milliage: number;
    model: string;
    trim: number;
    year: number;
  }) => ({
    brand: makes.find((item) => item.id === values.brand),
    milliage: intervals.find((item) => item.id === values.milliage),
    model: models.find((item) => item.title === values.model),
    trim: trims.find((item) => item.id === values.trim),
    year: years.find((item) => item.year === values.year),
  });

  const onReset = () => {
    form.resetFields();
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth} = window;
    return innerWidth;
  }

  const SwitchScrollWidth = 555;

  useEffect(() => {
    if (selectedCar) {
      form.setFieldsValue({
        brand: selectedCar.make.id,
        model: selectedCar.model.title,
        trim: selectedCar.trim.title,
        year: selectedCar.year,
        milliage: selectedCar.maintenance_interval?.value?.toLocaleString(),
      });
    } else if (formValues && !currentUser) {
      form.setFieldsValue({
        brand: formValues.brand?.id,
        milliage: formValues?.milliage?.id,
        model: formValues.model?.title,
        trim: formValues.trim?.id,
        year: formValues.year?.year,
      });
      
    } else if (formValues && currentUser) {
      form.setFieldsValue({
        brand: formValues.brand?.id,
        milliage: formValues?.milliage?.id,
        model: formValues.model?.title,
        trim: formValues.trim?.id,
        year: formValues.year?.year,
      });
      
    } else if (currentUser?.role.type === 'authenticated_admin') {
      form.setFieldsValue({
        brand: selectedId?.car,
        milliage: selectedId?.interval,
        model: selectedId?.model,
        trim: selectedId?.trim,
        year: selectedId?.year,
      });
    }
  }, [form, formValues]);

  useEffect(() => {
    if (selectedCar) {
      dispatch(userActions.setSelectedCar(selectedCar));
      const valueForForm = {
        brand: selectedCar.make,
        model: {
          models_id: [selectedCar.model.id],
          title: selectedCar.model.title,
          image_url: selectedCar.model.image_url,
        },
        trim: selectedCar.trim,
        milliage: selectedCar.maintenance_interval!,
        year: selectedCar.year,
      };
      dispatch(userActions.getServicesForCustomCar(selectedCar.id));
      dispatch(userActions.setFormValues(valueForForm));

      goToNextStep && goToNextStep(1);
    }
  }, [selectedCar, dispatch]);

  return (
    <div className={classes.container}>
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        size="large"
        onFinishFailed={(e) => console.log(e)}
      >
        <Form.Item
          name="brand"
          rules={[{ required: true, message: 'brand is required' }]}
          style={{marginBottom: '16px'}}
        >
          <Select
            className={classes.input}
            placeholder={
              isSearch ?
              <></>
              :
              <>
                <img src={CarIcon} alt="car icon" />
                &nbsp; Car brand
              </>
            }
            dropdownClassName={classes.dropdown}
            onChange={onChange}
            onDropdownVisibleChange={() => {isSearch ? setIsSearch(false) : setIsSearch(true)}}
            //onDeselect={() => setIsSearch(false)}
            //autoFocus={true}
            showSearch
            optionFilterProp="children"
            virtual={(windowSize > SwitchScrollWidth) ? true : false}    
            filterOption={(input, option) => option?.children.includes(input.toUpperCase())}
            //optionFilterProp="children"
            // filterOption={(input, option) => option?.children.includes(input)}
            // filterSort={(optionA, optionB) =>
            //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            // }
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) => option?.children.includes(input)}
            // filterSort={(optionA, optionB) =>
            //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            // }  
            //dropdownMatchSelectWidth={false}
            //virtual={false}
            //getPopupContainer={triggerNode => triggerNode.parentElement}
          >
            {makes.map((brand, index) => {
              return (
                <Option key={index} value={brand.id} title="car">
                  {brand.title}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {!selectedCar && (
          <Form.Item
            name="year"
            rules={[{ required: true, message: 'year is required' }]}
            style={{marginBottom: '16px'}}
          >
            <Select
              className={classes.input}
              placeholder={
                <>
                  <CalendarOutlined style={{ color: '#5B8DB4' }} />
                  &nbsp; Year
                </>
              }
              virtual={(windowSize > SwitchScrollWidth) ? true : false}
              onChange={onChange}
            >
              {years.map((year, index) => {
                return (
                  <Option key={index} value={year.year} title="year">
                    {year.year}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="model"
          rules={[{ required: true, message: 'model is required' }]}
          style={{marginBottom: '16px'}}
        >
          <Select
            className={classes.input}
            placeholder={
              <>
                <img src={ModelIcon} alt="car icon" />
                &nbsp; Model
              </>
            }
            virtual={(windowSize > SwitchScrollWidth) ? true : false}
            onChange={onChange}
          >
            {models.map((model, index) => {
              return (
                <Option key={index} value={model.title} title="model">
                  {model.title}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="trim"
          rules={[{ required: true, message: 'trim is required' }]}
          style={{marginBottom: '16px'}}
        >
          <Select
            className={classes.input}
            placeholder={
              <>
                <img src={EngineIcon} alt="car icon" />
                &nbsp; Engine
              </>
            }
            virtual={(windowSize > SwitchScrollWidth) ? true : false}
            onChange={onChange}
          >
            {trims.map((trim, index) => {
              return (
                <Option key={index} value={trim.id} title="trim">
                  {trim.title}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="milliage">
          {true ? (
            <Select
              className={classes.input}
              placeholder={
                <>
                  <DashboardOutlined style={{ color: '#5B8DB4' }} />
                  &nbsp; Kilometers*
                </>
              }
              virtual={(windowSize > SwitchScrollWidth) ? true : false}
              onChange={onChange}
            >
              {intervals.map((data, index) => {
                return (
                  <Option key={index} value={data.id} title="milliage">
                    {data.value.toLocaleString()}
                  </Option>
                );
              })}
            </Select>
          ) : (
            <Input />
          )}
        </Form.Item>

        <span className={classes.link}>
        *Optional – Mileage allows us to tailor a suitable service for you
        </span>
        {currentUser?.role.type !== 'authenticated_admin' && (
          <Form.Item>
            <div
              className={classNames(classes.buttons, {
                [classes.margin50]: !fromLanding,
              })}
            >
              {!fromLanding && (
                <ButtonComponent
                  title="Clear"
                  type="cancel"
                  onClick={onReset}
                />
              )}
              {!fromLanding && (
                <ButtonComponent
                  title={'Continue'}
                  type={'save'}
                  onClick={() => {}}
                />
              )}
              {fromLanding && (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    borderRadius: '40px',
                    width: '300px',
                    background: '#026BB5',
                    borderColor: '#026BB5',
                    fontSize: '16px',
                    fontWeight: 700,
                    margin: '0 auto'
                  }}
                >
                  Compare Prices
                </Button>
              )}
            </div>
            <div className={classes.cantFind}>
              <a href="https://wa.me/97180056445" target="_blank">Can’t find my car</a>
            </div>
            <div className={classes.tabbyPromo}>
              <p>4 interest-free payments with</p>
              <img src={TabbyLogo} alt="tabby logo" />
            </div>
            {/* <TabbyPromo price={'228'}/> */}
          </Form.Item>
        )}
      </Form>
    </div>
  );
};
