import React, { useEffect } from 'react';
import classes from './style.module.scss';
import { MainLayout } from '../../Components/MainLayout';
import { ButtonComponent } from '../../Components/Button';
import { Tabs } from 'antd';
import { Details } from './Components/Details';
import { Maintenance } from './Components/Maintenance';
import { ServiceHistory } from './Components/ServiceHistory';
import { RootStateType } from '../../Stores';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../Globals/User/actions';
import useConfirm from '../../Hooks/useConfirm';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../Globals/Auth/actions';

const { TabPane } = Tabs;

type CarPropsType = {
  carId: number;
};
export const Car: React.FC<CarPropsType> = ({ carId }) => {
  const dispatch = useDispatch();
  const { confirm } = useConfirm();
  const history = useHistory();

  const car = useSelector((state: RootStateType) => state.user.selectedCar);
  const route = useSelector((state: RootStateType) => state.auth.route);

  const dataLayer = window.dataLayer || []

  const showConfirm = async (id: number) => {
    const isConfirmed = await confirm('Are you sure you want delete your car?');
    if (isConfirmed) {
      dispatch(userActions.deleteCar(id));
    }
  };

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/user/my-cars",
      "page_type":"userCarInfoPage",
      "event":"page_view"
    });
    //End
  }, []);

  useEffect(() => {
    dispatch(userActions.getSelectedCar(carId));
  }, [dispatch, carId]);

  useEffect(() => {
    if (route === '/user/my-cars') {
      history.push(route);
      dispatch(authActions.setRoute(null));
    }
  }, [route, dispatch, history]);

  return (
    <MainLayout>
      {car ? (
        <div className={classes.car}>
          <img src={car.model?.image_url} alt="car" />
          <div className={classes.description}>
            <h3 style={{lineHeight: '30px'}}>
              {car?.year} {car?.make.title} {car?.model.title} {car?.trim.title}
            </h3>
            {car?.maintenance_interval?.value && (
              <p>
                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                  Total mileage{' '}
                </span>
                {car.maintenance_interval?.value.toLocaleString()} km
              </p>
            )}
          </div>
          <ButtonComponent
            className={classes.buttonClass}
            type="decline"
            title="Delete car"
            onClick={() => {
              showConfirm(car?.id);
            }}
          />
        </div>
      ) : (
        <div className={classes.car}></div>
      )}
      <Tabs defaultActiveKey="1" onChange={() => {}} style={{ border: 'none' }}>
        <TabPane tab="Car details" key="1">
          <Details car={car} />
        </TabPane>
        <TabPane tab="Service History" key="2">
          <ServiceHistory car={car} />
        </TabPane>
        <TabPane tab="Scheduled maintenance" key="3">
          <Maintenance car={car} />
        </TabPane>
      </Tabs>
    </MainLayout>
  );
};
