const ApiUrls = {
  //BASE_URL: 'https://api.carlogik.com',
  //BASE_URL: 'https://api-test.develop.carlogik.com',
  BASE_URL: 'https://api.develop.carlogik.com',
  /* BASE_URL:
    process.env.NODE_ENV === 'development'
      ? 'https://api-test.develop.carlogik.com'
      : process.env.REACT_APP_STAGING === 'true' ? 'https://api.carlogik.com' : 'https://carlogik.com', */
  //WEB_URL: 'https://carlogik.com',
  WEB_URL: 'https://develop.carlogik.com',
  //WEB_URL: 'http://localhost:3000',
  /* WEB_URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : process.env.REACT_APP_STAGING === 'true' ? 'https://develop.carlogik.com' : 'https://carlogik.com',  */
};


const StripeKeys = {
  //for develop
  KEY: 'pk_test_51JMBn9C4Lq040EI2EFri9JIP3v9zSeJKJGvb5hFoko49uLioRCFPN5FJKGinP6am5vxJdjOQAFyVH4U0hYLA1iHi00vyggsivi'
 /*  KEY:
    process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true'
      ? 'pk_test_51JMBn9C4Lq040EI2EFri9JIP3v9zSeJKJGvb5hFoko49uLioRCFPN5FJKGinP6am5vxJdjOQAFyVH4U0hYLA1iHi00vyggsivi'
      : 'pk_live_51JMBn9C4Lq040EI20POppCjzlIjl4Bt9WFRVfM5ODotusSz06bAgfI5wTRkoSCUB7W1BVYsTGnD72nqbjYeelhiM00yz3cfKJY', */
  //for prod
  //KEY:'pk_live_51JMBn9C4Lq040EI20POppCjzlIjl4Bt9WFRVfM5ODotusSz06bAgfI5wTRkoSCUB7W1BVYsTGnD72nqbjYeelhiM00yz3cfKJY'
};

//const TRACKING_ID = "UA-220756500-1";
const TRACKING_ID = "";


const ContentTypes = {
  APPLICATION_JSON: 'application/json;charset=utf-8',
  FORM_DATA: 'multipart/form-data;charset=utf-8',
};

const PICK_UP_PRICE = 'FREE';
const DROP_OFF_PRICE = 'FREE';

const LIMIT_ON_PAGE = 10;

export { ApiUrls, ContentTypes, StripeKeys, TRACKING_ID, LIMIT_ON_PAGE, PICK_UP_PRICE, DROP_OFF_PRICE };

