import cn from 'classnames';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SignOut } from '../../../../../Components/SignOut';
import { RootStateType } from '../../../../../Stores';
import classes from './styles.module.scss';
import { MenuItem } from '../MenuItem';

type SideMenuPropsType = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

export const SideMenu: React.FC<SideMenuPropsType> = ({
  visible,
  setVisible,
}) => {
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const getRoute = () => {
    if (currentUser?.role.type === 'authenticated') {
      return '/user/my-cars';
    } else if (currentUser?.role.type === 'authenticated_admin') {
      return '/admin/clients';
    } else if (currentUser?.role.type === 'authenticated_workshop') {
      return '/workshop/jobs';
    } else {
      return 'auth/customer/signup';
    }
  };

  return (
    <Drawer
      placement="left"
      onClose={() => {
        setVisible(false);
      }}
      visible={visible}
      bodyStyle={{ padding: '65px 0 0 0' }}
      className={classes.landingDrawer}
      width={208}
    >
      {!currentUser ? (
        <div>
          <div className={cn(classes.mainLink, classes.primaryColor)}>
            <Link style={{ color: 'inherit' }} to={getRoute()}>
              Sign up
            </Link>
          </div>
          <div className={cn(classes.mainLink, classes.primaryColor)}>
            <Link style={{ color: 'inherit' }} to="/auth/customer/signin">
              Log in
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className={cn(classes.mainLink, classes.primaryColor)}>
            <Link style={{ color: 'inherit' }} to={getRoute()}>
              Get started
            </Link>
          </div>
          <div style={{ marginLeft: '42px' }}>
            <SignOut name={currentUser.username || currentUser.workshop?.name || ''} />
          </div>
        </div>
      )}
      {!currentUser && (
        <div className={cn(classes.mainLink, classes.primaryColor)}>
          <Link style={{ color: 'inherit' }} to="/auth/workshop/signup">
            Be a Partner workshop
          </Link>
        </div>
      )}
      <div className={classes.navLinksContainer} onClick={() => {setVisible(false);}}>
        <MenuItem title="Our workshops" path="our-workshops" isSideBar />
        <MenuItem title="How we work" path="how-work" isSideBar />
        <MenuItem
          title="Looking for other services?"
          path="services-for-offer"
          isSideBar
        />
        <MenuItem title="About Us" path="footer" isSideBar />
      </div>
    </Drawer>
  );
};
