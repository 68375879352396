import React from 'react';
import { Tree } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import classes from './style.module.scss';
import { RootStateType } from '../../Stores';
import { useSelector } from 'react-redux';
import { PartType, ServiceForAdmin } from '../../Types/authTypes';

const { TreeNode } = Tree;

const { DirectoryTree } = Tree;

type NestedTreeListPropsType = {
  activeTab: string;
  isSpares?: boolean;
};

export const NestedTreeList: React.FC<NestedTreeListPropsType> = ({
  activeTab,
  isSpares = false,
}) => {
  const partsData =
    useSelector((state: RootStateType) => state.admin.parts) || [];
  const servicesData =
    useSelector((state: RootStateType) => state.admin.services) || {};
  const serviceTitle = Object.keys(servicesData);

  const titleNode = (
    title: string,
    isColor: boolean = false,
    price?: number,
    isPointer?: boolean
  ) => {
    return (
      <div
        style={{
          backgroundColor: !isColor ? '#FAFAFA' : '#EFF8FF',
          cursor: !isPointer ? 'pointer' : 'default',
        }}
        className={classes.nodeContainer}
      >
        <div className={classes.titleNode}>{title}</div>
        {activeTab === '1' ? (
          <div className={classes.price}></div>
        ) : (
          <div className={classes.price}>{price ? `AED ${price}` : ''}</div>
        )}
      </div>
    );
  };

  const renderTreeNodes = (data: Array<string>) =>
    data.map((item, index) => {
      return (
        <TreeNode title={titleNode(item, true)} key={index}>
          {renderChildrenNods(servicesData[data[index]])}
        </TreeNode>
      );
    });

  const renderSpareNodes = (data: Array<PartType>) =>
    data.map((item, index) => {
      return (
        <TreeNode
          title={titleNode(item.title, true, item.price, true)}
          key={item.id}
        />
      );
    });

  const renderChildrenNods = (data: Array<ServiceForAdmin>) =>
    data.map((item, index) => {
      return (
        <TreeNode
          title={titleNode(item.title, false, item.working_min)}
          key={item.id}
        />
      );
    });

  return (
    <DirectoryTree
      showLine={activeTab === '1' ? { showLeafIcon: false } : false}
      showIcon={false}
      switcherIcon={<CaretDownOutlined />}
      icon={false}
      blockNode={true}
      style={{ maxWidth: '520px' }}
      selectable={false}
    >
      {activeTab === '1'
        ? renderTreeNodes(serviceTitle)
        : renderSpareNodes(partsData)}
    </DirectoryTree>
  );
};
