import React, { useEffect, useState } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import classes from './style.module.scss';
import PlaceIcon from '../../Assets/place.svg';

const SearchBox = ({ onPlacesChanged, map, point, setMyLocation }: any) => {
  let [searchBox, setSearchBox] = useState(null);
  let [input, setInput] = useState('');

  //@ts-ignore
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };
  
  const onChange = () => {
    //@ts-ignore
    if (searchBox?.getPlaces()) {
      //@ts-ignore
      onPlacesChanged(searchBox?.getPlaces())
    }
  };

  const onLocation = () => {
    setMyLocation();
   // setInput('');
  };

  useEffect(() => {
    if (point) {
      setInput(point.address);
    }
  }, [point]);

  return (
    <StandaloneSearchBox onLoad={onSBLoad} onPlacesChanged={onChange}>
      <div className={classes.searchContainer}>
        <input
          type="text"
          placeholder="Enter your address"
          value={input}
          onChange={(e) => {
            setInput(e.target.value)
          }}
        />
        <img src={PlaceIcon} alt="place" onClick={onLocation} />
      </div>
    </StandaloneSearchBox>
  );
};

export default SearchBox;
