import { CHANGE_ORDER_STATUS } from './../Workshop/constants';
import {
  CarResponseType,
  PartType,
  QueryObjectType,
  ServicesForAdmin,
  UserType,
  UserTypeRequest,
  WorkShopType,
  WorkShopTypeRequest,
} from './../../Types/authTypes';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { adminActions } from './actions';
import { adminApi } from './services';
import * as adminConstants from './constants';
import { appActions } from '../App/actions';
import * as selectors from '../Auth/selectors';
import { encodedOrderQuery } from '../../Utils/query';
import {
  ApproveWorkshopRequestType,
  RequestInvoiceType,
} from '../../Types/workshopTypes';
import {
  AdminReviewsResponseType,
  CarForAdminType,
  OrderForAdminType,
  ResponseAdminAppointmentsType,
  ResponseCarForAdminType,
  ResponseNotificationsType,
  ResponseOrderAdminType,
} from '../../Types/adminTypes';
import { CarUpdateRequestType } from '../../Types/userTypes';

export function* getWorkshopList({
  payload,
}: {
  payload?: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    const query = payload ? encodedOrderQuery(payload) : undefined;
    const confirmResult: WorkShopTypeRequest = yield call(
      adminApi.getWorkshops,
      token,
      query
    );
    yield put(adminActions.setWorkshopList(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getUsersList({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: UserTypeRequest = yield call(
      adminApi.getUsers,
      token,
      query
    );
    yield put(adminActions.setUsersList(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* approve(payload: {
  type: string;
  id: number;
  payload: ApproveWorkshopRequestType;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    yield call(adminApi.approve, token, payload.id, payload.payload);
    yield put(adminActions.getWorkshopList());
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* searchWorkshops({ payload }: any) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    const confirmResult: WorkShopTypeRequest = yield call(
      adminApi.searchWorkshops,
      token,
      payload
    );
    yield put(adminActions.setWorkshopList(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* editWorkshop({ payload, id }: any) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    yield call(adminApi.editWorkshop, token, payload, id);
    yield put(adminActions.getUsersList());
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getServices(payload: {
  type: string;
  trimId: number;
  intervalId: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: ServicesForAdmin = yield call(
      adminApi.getServices,
      token,
      payload.trimId,
      payload.intervalId
    );
    yield put(adminActions.setServiecs(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getParts(payload: { type: string; modelId: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: Array<PartType> = yield call(
      adminApi.getParts,
      token,
      payload.modelId
    );
    yield put(adminActions.setParts(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getUser(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: UserType = yield call(
      adminApi.getUser,
      token,
      payload.id
    );
    yield put(adminActions.setUser(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getOrdersList({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: ResponseOrderAdminType = yield call(
      adminApi.getOrders,
      token,
      query
    );
    yield put(adminActions.setOrders(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getCarsList(payload: {
  id: number;
  query: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload.query);
    const confirmResult: ResponseCarForAdminType = yield call(
      adminApi.getCars,
      token,
      payload.id,
      query
    );
    yield put(adminActions.setCars(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* deleteCar(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: CarResponseType = yield call(
      adminApi.deleteCar,
      token,
      payload.id
    );
    if (confirmResult) {
      yield put(
        adminActions.getCars(confirmResult.user, {
          page: { page: 1, limit: 100 },
        })
      );
    }

    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updateCar(payload: {
  type: string;
  payload: CarUpdateRequestType;
  id: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: CarForAdminType = yield call(
      adminApi.updateCar,
      token,
      payload.payload,
      payload.id
    );
    yield put(adminActions.setUpdatedCar(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getOrderById({ payload }: { payload: number; type: string }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: OrderForAdminType = yield call(
      adminApi.getOrderById,
      token,
      payload
    );
    yield put(adminActions.setOrder(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* finishOrder(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: OrderForAdminType = yield call(
      adminApi.finishOrder,
      token,
      payload.id
    );
    yield put(adminActions.updateOrder(confirmResult));
    window.location.reload();
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* deleteOrder(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    yield call(adminApi.deleteOrder, token, payload.id);
    yield put(adminActions.deleteOrderSuccess());
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* deleteUser(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    yield call(adminApi.deleteCustomer, token, payload.id);
    yield put(adminActions.deleteCustomerSuccess(payload.id));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getCustomerAppointments(payload: {
  id: number;
  query: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload.query);
    const confirmResult: ResponseAdminAppointmentsType = yield call(
      adminApi.getCustomerAppointments,
      token,
      payload.id,
      query
    );
    yield put(adminActions.setCustomerAppointments(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* blockUnblockUser(payload: {
  id: number;
  data: { blocked: boolean; type: 'workshop' | 'customer' };
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const { type, ...data } = payload.data;
    const confirmResult: {
      blocked: boolean;
      id: number;
      workshop: { id: number };
    } = yield call(adminApi.blockUnblockUser, token, payload.id, data);
    if (type === 'workshop') {
      yield put(
        adminActions.toggleBlockWorkshop({
          id: confirmResult.workshop.id,
          isBlocked: confirmResult.blocked,
        })
      );
    } else if (type === 'customer') {
      yield put(
        adminActions.toggleBlockCustomer({
          id: confirmResult.id,
          isBlocked: confirmResult.blocked,
        })
      );
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* deleteWorkshop(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    yield call(adminApi.deleteWorkshop, token, payload.id);
    yield put(adminActions.deleteWorkshopSuccess(payload.id));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getAdminInvoices({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: RequestInvoiceType<WorkShopType> = yield call(
      adminApi.getInvoices,
      token,
      query
    );
    yield put(adminActions.setInvoices(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getMonthlyRevenue() {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: { total_price: number } = yield call(
      adminApi.getMonthlyRevenue,
      token
    );
    yield put(adminActions.setMonthlyRevenue(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getNotifications({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: ResponseNotificationsType = yield call(
      adminApi.getNotifications,
      token,
      query
    );
    yield put(adminActions.setNotifications(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getReviews({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: AdminReviewsResponseType = yield call(
      adminApi.getReviews,
      token,
      query
    );
    yield put(adminActions.setReviews(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* deleteReview(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    yield call(adminApi.deleteReview, token, payload.id);
    yield put(adminActions.deleteReviewSuccess(payload.id));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* changeStatus(payload: {
  type: string;
  id: number;
  status: 'accepted' | 'declined' | 'processing' | 'finished';
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: OrderForAdminType = yield call(
      adminApi.changeStatus,
      token,
      payload.id,
      payload.status
    );
    yield put(adminActions.updateOrder(confirmResult));
    if (confirmResult.status === 'processing' || 'finished') {
      yield put(adminActions.setOrder(confirmResult));
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* changePaymentStatus(payload: {
  type: string;
  id: number;
  status: 'paid' | 'not_paid';
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    yield call(
      adminApi.changePaymentStatus,
      token,
      payload.id,
      payload.status
    );
    yield put(
      adminActions.updatePaymentStatus({
        id: payload.id,
        status: payload.status,
      })
    );
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export default function* WatcherSaga() {
  yield takeLatest(adminConstants.GET_WORKSHOP_LIST, getWorkshopList);
  yield takeLatest(adminConstants.GET_USERS_LIST, getUsersList);
  yield takeLatest(adminConstants.SEARCH_WORKSHOPS, searchWorkshops);
  yield takeLatest(adminConstants.APPROVE_WORKSHOP, approve);
  yield takeLatest(adminConstants.EDIT_WORKSHOP, editWorkshop);
  yield takeLatest(adminConstants.GET_SERVICES_FOR_ADMIN, getServices);
  yield takeLatest(adminConstants.GET_PARTS, getParts);
  yield takeLatest(adminConstants.GET_USER, getUser);
  yield takeLatest(adminConstants.GET_ORDERS, getOrdersList);
  yield takeLatest(adminConstants.GET_CUSTOMER_CARS, getCarsList);
  yield takeLatest(adminConstants.UPDATE_CAR, updateCar);
  yield takeLatest(adminConstants.DELETE_CAR, deleteCar);
  yield takeLatest(adminConstants.GET_ORDER, getOrderById);
  yield takeLatest(adminConstants.FINISH_ORDER, finishOrder);
  yield takeLatest(adminConstants.DELETE_ORDER, deleteOrder);
  yield takeLatest(
    adminConstants.GET_CUSTOMER_APPOINTMENTS,
    getCustomerAppointments
  );
  yield takeLatest(adminConstants.BLOCK_UNBLOCK_USER, blockUnblockUser);
  yield takeLatest(adminConstants.DELETE_WORKSHOP, deleteWorkshop);
  yield takeLatest(adminConstants.DELETE_CUSTOMER, deleteUser);
  yield takeLatest(adminConstants.GET_ADMIN_INVOICES, getAdminInvoices);
  yield takeLatest(adminConstants.GET_ADMIN_MONTHLY_REVENUE, getMonthlyRevenue);
  yield takeLatest(adminConstants.GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(adminConstants.GET_ADMIN_REVIEWS, getReviews);
  yield takeLatest(adminConstants.DELETE_ADMIN_REVIEW, deleteReview);
  yield takeLatest(adminConstants.CHANGE_ORDER_STATUS, changeStatus);
  yield takeLatest(adminConstants.CHANGE_PAYMENT_STATUS, changePaymentStatus);
}
