import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { OrderCard } from '../../../Components/OrderCard';
import { ModalComponent } from '../../../Components/Modal';
import { RootStateType } from '../../../Stores';
import { WorkshopReview } from './Components/WorkshopReview';
import { userActions } from '../../../Globals/User/actions';
import { ReviewRequestType } from '../../../Types/userTypes';
import { EmptyScreen } from '../../../Components/EmptyScreen';
import { appApi } from '../../../Globals/App/services';
import { FileType } from '../../../Types/appTypes';

type HistoryPropsType = {
  loadMore: () => void;
  loading: boolean;
  activeTab: string
};

export const History: React.FC<HistoryPropsType> = ({ loadMore, activeTab, loading }) => {
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState<boolean>(false);
  const [orderInModalId, setOrderInModalId] = useState<number | null>(null);

  const orders = useSelector(
    (state: RootStateType) => state.user.orders.rows
  ).filter((item) => item.status === 'finished');

  const isHasMore = useSelector(
    (state: RootStateType) => state.user.isHasMore
  );

  const dataLayer = window.dataLayer || []

  const closeModal = () => {
    setOrderInModalId(null);
    setIsModal(false);
  };
  const openModal = (id: number) => {
    setOrderInModalId(id);
    setIsModal(true);
  };

  const sendReview = (review: ReviewRequestType) => {
    if (orderInModalId)
      dispatch(userActions.sendReview(review, orderInModalId));
    setIsModal(false);
  };

  const onDownloadFile = (invoice: FileType | null) => {
    if (invoice) {
      appApi.downloadFile(`${invoice.url}`, invoice.name);
    }
  };

  const onLoad = () => {
    if (activeTab !== '2') {
      return;
    }
    if (loading) {
      return;
    } else {
      loadMore();
    }
  };

  // useEffect(() => {
  //   //GTM
  //   //Start
  //   dataLayer.push({
  //     "path": "/user/appointments",
  //     "page_type":"userServiceHistoryPage",
  //     "event":"page_view"
  //   });
  //   //End
  // }, []);

  return (
    <div>
      <InfiniteScroll
        dataLength={orders.length}
        next={onLoad}
        hasMore={isHasMore && !loading}
        loader={null}
        endMessage={null}
      >
        {orders.length ? (
          orders.map((order) => (
            <OrderCard
              key={order.id}
              order={order}
              type="history"
              path={`/user/appointments/${order.id}`}
              onConfirm={() => {
                onDownloadFile(order.invoice);
              }}
              onCancel={() => openModal(order.id)}
            />
          ))
        ) : (
          <EmptyScreen title="You don't have appointments" />
        )}
      </InfiniteScroll>
      <ModalComponent
        isModal={isModal}
        toggleModal={closeModal}
        title={`How would you review ${
          orders.find((order) => order.id === orderInModalId)?.workshop?.name
        }`}
      >
        <WorkshopReview
          onSubmit={sendReview}
          onCancel={closeModal}
          workshop={
            orders.find((order) => order.id === orderInModalId)?.workshop
          }
        />
      </ModalComponent>
    </div>
  );
};
