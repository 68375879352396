import { Moment } from 'moment';
import {
  AddCarRequestType,
  AddressPointType,
  AppointmentRespType,
  CarDetailsFormType,
  CreateOrderRequestType,
  IntervalResponseType,
  MakesResponseType,
  ModelResponseType,
  OfferedServicesResponseType,
  QueryObjectType,
  TrimResponseType,
  WorkShopType,
  YearResponseType,
  PaymentsResponseType,
  WorkShopTypeRequest,
  UpdateUserProfile,
  CarsResponseType,
  CarResponseType,
  CitiesTypeRequest,
} from './../../Types/authTypes';
import { InferActionsTypes } from '../../Stores';
import * as userConstants from './constants';
import { FormId } from '../../Components/AddCar';
import {
  OrderForWorkshopType,
  OrderService,
  OrdersRequestType,
  ReviewResponseType,
} from '../../Types/workshopTypes';
import {
  AvailableDaysResponseType,
  CancelOrderRequest,
  CarDeliveriesType,
  CarUpdateRequestType,
  CheckCancelRescheduleOrderResponse,
  CreditCardType,
  CustomerOrderType,
  GetAppointmentsRequestData,
  GetAvailableDaysParamsType,
  OrderInfoForGetWorkshopsRequest,
  RescheduleRequest,
  ReviewRequestType,
  SavedDataObjectType,
  ScheduledMaintenanceRequestType,
  ServiceHistoryRequestType,
  UserProfileType,
} from '../../Types/userTypes';

export const userActions = {
  getMakes: () =>
    ({
      type: userConstants.GET_MAKES,
    } as const),
  setMakes: (payload: Array<MakesResponseType>) =>
    ({
      type: userConstants.SET_MAKES,
      payload,
    } as const),
  getYear: (payload: number) =>
    ({
      type: userConstants.GET_YEAR_OF_MAKE,
      payload,
    } as const),
  setYear: (payload: Array<YearResponseType>) =>
    ({
      type: userConstants.SET_YEAR_OF_MAKE,
      payload,
    } as const),
  getModel: (payload: number) =>
    ({
      type: userConstants.GET_MODEL_OF_YEAR,
      payload,
    } as const),
  setModel: (payload: Array<ModelResponseType>) =>
    ({
      type: userConstants.SET_MODEL_OF_YEAR,
      payload,
    } as const),
  getTrim: (payload: number) =>
    ({
      type: userConstants.GET_MODEL_TRIM,
      payload,
    } as const),
  setTrim: (payload: Array<TrimResponseType>) =>
    ({
      type: userConstants.SET_MODEL_TRIM,
      payload,
    } as const),
  getIntervals: (id_trim: number) =>
    ({
      type: userConstants.GET_INTERVALS,
      id_trim,
    } as const),
  setIntervals: (payload: Array<IntervalResponseType>) =>
    ({
      type: userConstants.SET_INTERVALS,
      payload,
    } as const),
  getAllIntervals: (id_trims: number[]) =>
    ({
      type: userConstants.GET_ALL_INTERVALS,
      id_trims,
    } as const),
  setAllIntervals: (payload: Array<{trimId: number, rows: IntervalResponseType[]}>) =>
    ({
      type: userConstants.SET_ALL_INTERVALS,
      payload,
    } as const),
  getServices: (trim_id: number, maint_id?: number) =>
    ({
      type: userConstants.GET_SERVICES_FOR_CURRENT_INTERVAL,
      trim_id,
      maint_id,
    } as const),
  setServices: (payload: OfferedServicesResponseType) =>
    ({
      type: userConstants.SET_SERVICES_FOR_CURRENT_INTERVAL,
      payload,
    } as const),
  setFormValues: (payload: CarDetailsFormType) =>
    ({
      type: userConstants.SET_FORM_VALUES,
      payload,
    } as const),
  removeFormID: () =>
    ({
      type: userConstants.REMOVE_FORM_ID,
    } as const),
  addOtherService: (id: number) =>
    ({
      type: userConstants.ADD_OTHER_SERVICE,
      id,
    } as const),
  removeOtherService: (id: number) =>
    ({
      type: userConstants.REMOVE_OTHER_SERVICE,
      id,
    } as const),
  addCarRepair: (id: number) =>
    ({
      type: userConstants.ADD_CAR_REPAIR,
      id,
    } as const),
  removeCarRepair: (id: number) =>
    ({
      type: userConstants.REMOVE_CAR_REPAIR,
      id,
    } as const),
  removeAllCarRepair: () =>
    ({
      type: userConstants.REMOVE_ALL_CAR_REPAIR,
    } as const),
  setCarRepairText: (text: string) =>
    ({
      type: userConstants.SET_CAR_REPAIR_TEXT,
      text,
    } as const),
  setSelectedServiceType: (isBasic: boolean | null) =>
    ({
      type: userConstants.SET_SELECTED_MAIN_SERVICE_TYPE,
      isBasic,
    } as const),
  addOptionalServices: (ids: Array<number>) =>
    ({
      type: userConstants.SET_OPTIONAL_SERVICES,
      ids,
    } as const),
  removeOptionalService: (id: number) =>
    ({
      type: userConstants.REMOVE_OPTIONAL_SERVICES,
      id,
    } as const),
  getCities: (
  ) =>
    ({
      type: userConstants.GET_CITIES,
    } as const),
  setCities: (cities: Array<CitiesTypeRequest>) =>
    ({
      type: userConstants.SET_CITIES,
      cities,
    } as const),
  getWorkshops: (
    payload: OrderInfoForGetWorkshopsRequest,
    isPartner?: boolean,
    page = 1,
    city_id?: number,
    workshop_id?: number
  ) =>
    ({
      type: userConstants.GET_WORKSHOPS_ON_LOCATION,
      payload,
      isPartner,
      page,
      city_id,
      workshop_id,
    } as const),
  setWorkshops: (payload: WorkShopTypeRequest) =>
    ({
      type: userConstants.SET_WORKSHOPS_ON_LOCATION,
      payload,
    } as const),
  setSelectedLocation: (payload: 'at_location' | 'at_workshop' | null) =>
    ({
      type: userConstants.SET_SELECTED_LOCATION,
      payload,
    } as const),
  setAddressPoint: (payload: AddressPointType) =>
    ({
      type: userConstants.SET_ADDRESS_POINT,
      payload,
    } as const),
  setClientAddress: (address: string) =>
  ({
    type: userConstants.SET_CLIENT_ADDRESS,
    address,
  } as const),
  getAppointment: (payload: GetAppointmentsRequestData) =>
    ({
      type: userConstants.GET_APPOINTMENT,
      payload,
    } as const),
  setAppointment: (payload: AppointmentRespType) =>
    ({
      type: userConstants.SET_APPOINTMENT,
      payload,
    } as const),
  setSelectedWorkshop: (payload: WorkShopType) =>
    ({
      type: userConstants.SET_SELECTED_WORKSHOP,
      payload,
    } as const),
  resetSelectedWorkshop: () =>
    ({
      type: userConstants.RESET_SELECTED_WORKSHOP,
    } as const),
  addNewCar: (payload: AddCarRequestType) =>
    ({
      type: userConstants.ADD_NEW_CAR,
      payload,
    } as const),
  getCarList: () =>
    ({
      type: userConstants.GET_CARS_LIST,
    } as const),
  setCarList: (payload: CarsResponseType) =>
    ({
      type: userConstants.SET_CARS_LIST,
      payload,
    } as const),
  deleteCar: (id: number) =>
    ({
      type: userConstants.DELETE_CURRENT_CAR,
      id,
    } as const),
  getServicesForCustomCar: (id: number) =>
    ({
      type: userConstants.GET_SERVICES_FOR_CUSTOMER_CAR,
      id,
    } as const),
  getPayment: () =>
    ({
      type: userConstants.GET_PAYMENT,
    } as const),
  setPayment: (payload: PaymentsResponseType | null) =>
    ({
      type: userConstants.SET_PAYMENT,
      payload,
    } as const),
  createOrder: (payload: CreateOrderRequestType) =>
    ({
      type: userConstants.CREATE_ORDER,
      payload,
    } as const),
  setOrderData: (payload: CreateOrderRequestType) =>
    ({
      type: userConstants.SET_ORDER_DATA,
      payload,
    } as const),
  resetOrderData: () =>
    ({
      type: userConstants.RESET_ORDER_DATA,
    } as const),
  resetWithoutFormData: () =>
    ({
      type: userConstants.RESET_WITHOUT_FORM_DATA,
    } as const),
  setSelectedDate: (payload: { startDate: string; startTime: string }) =>
    ({
      type: userConstants.SET_SELECTED_DATE,
      payload,
    } as const),
  resetSelectedDate: () =>
  ({
    type: userConstants.RESET_SELECTED_DATE,
  } as const),
  createOrderSuccess: (payload: any) =>
    ({
      type: userConstants.CREATE_ORDER_SUCCESS,
      payload,
    } as const),
  resetRedirects: () =>
    ({
      type: userConstants.RESET_REDIRECTS,
    } as const),
  setFormId: (payload: FormId) =>
    ({
      type: userConstants.SET_FORM_ID,
      payload,
    } as const),
  setIsModal: (payload: boolean) =>
    ({
      type: userConstants.SET_IS_MODAL,
      payload,
    } as const),
  toggleModalIsSidebar: () =>
    ({
      type: userConstants.TOGGLE_MODAL_IS_SIDEBAR,
    } as const),
  setModalType: (payload: {
    type: 'main' | 'logbook';
    title: string;
    isSelected: boolean;
  }) =>
    ({
      type: userConstants.SET_MODAL_TYPE,
      payload,
    } as const),
  setDeliveryPickUp: (payload: number) =>
    ({
      type: userConstants.SET_DELIVERY_PICK_UP,
      payload,
    } as const),
  setDeliveryDropOff: (payload: number) =>
    ({
      type: userConstants.SET_DELIVERY_DROP_OFF,
      payload,
    } as const),
  removeDeliveryCar: () =>
    ({
      type: userConstants.REMOVE_DELIVERY_CAR,
    } as const),
  getOrders: (payload?: QueryObjectType) =>
    ({
      type: userConstants.GET_ORDERS,
      payload,
    } as const),
  setOrders: (payload: OrdersRequestType<Array<OrderService>>) =>
    ({
      type: userConstants.SET_ORDERS,
      payload,
    } as const),
  resetOrders: () =>
    ({
      type: userConstants.RESET_ORDERS,
    } as const),
  isHasMore: (payload: boolean) =>
    ({
      type: userConstants.IS_HAS_MORE,
      payload,
    } as const),
  getSelectedCar: (payload: number) =>
    ({
      type: userConstants.GET_CURRENT_CAR,
      payload,
    } as const),
  setOrderSummary: (payload: number | null) =>
    ({
      type: userConstants.SET_ORDER_SUMMARY,
      payload,
    } as const),
  updateCar: (payload: CarUpdateRequestType, id: number) =>
    ({
      type: userConstants.UPDATE_CAR,
      payload,
      id,
    } as const),
  setSelectedCar: (payload: CarResponseType) =>
    ({
      type: userConstants.SET_SELECTED_CAR,
      payload,
    } as const),
  setDeliveryTime: (
    payload:
      | {
          pick_up_time_slot: Moment | null;
        }
      | {
          drop_off_time_slot: Moment | null;
        }
  ) =>
    ({
      type: userConstants.SET_DELIVERY_TIME,
      payload,
    } as const),
  getUserProfile: () =>
    ({
      type: userConstants.GET_USER_PROFILE,
    } as const),
  updateUserProfile: (payload: UpdateUserProfile, id: number) =>
    ({
      type: userConstants.UPDATE_USER_PROFILE,
      payload,
      id,
    } as const),
  setUpdatesUser: (payload: UserProfileType) =>
    ({
      type: userConstants.SET_UPDATES_USER,
      payload,
    } as const),
  getServiceHistoryForCar: (payload: QueryObjectType, id: number) =>
    ({
      type: userConstants.GET_SERVICE_HISTORY_FOR_CAR,
      payload,
      id,
    } as const),
  setServiceHistoryForCar: (payload: ServiceHistoryRequestType) =>
    ({
      type: userConstants.SET_SERVICE_HISTORY_FOR_CAR,
      payload,
    } as const),
  getScheduledMaintenance: (payload: number) =>
    ({
      type: userConstants.GET_SCHEDULED_MAINTENANCE_FOR_CAR,
      payload,
    } as const),
  setScheduledMaintenance: (payload: ScheduledMaintenanceRequestType) =>
    ({
      type: userConstants.SET_SCHEDULED_MAINTENANCE_FOR_CAR,
      payload,
    } as const),
  getInvoice: (payload: number) =>
    ({
      type: userConstants.GET_INVOICE_PDF,
      payload,
    } as const),
  sendReview: (payload: ReviewRequestType, id: number) =>
    ({
      type: userConstants.SEND_REVIEW,
      payload,
      id,
    } as const),
  setReview: (payload: ReviewResponseType) =>
    ({
      type: userConstants.SET_REVIEW,
      payload,
    } as const),
  getOrder: (payload: number) =>
    ({
      type: userConstants.GET_ORDER,
      payload,
    } as const),
  setOrder: (payload: CustomerOrderType | null) =>
    ({
      type: userConstants.SET_ORDER,
      payload,
    } as const),
  sendCard: (payload: any) =>
    ({
      type: userConstants.SEND_CARD,
      payload,
    } as const),
  getCard: () =>
    ({
      type: userConstants.GET_CARD,
    } as const),
  updateCard: (payload: { exp_month: number; exp_year: number }) =>
    ({
      type: userConstants.UPDATE_CARD,
      payload,
    } as const),
  deleteCard: (payload: string) =>
    ({
      type: userConstants.DELETE_CARD,
      payload,
    } as const),
  setCard: (payload: CreditCardType) =>
    ({
      type: userConstants.SET_CARD,
      payload,
    } as const),
  setTabby: (payload: boolean) =>
    ({
      type: userConstants.SET_IS_TABBY,
      payload,
    } as const),
  setSavedSummaryData: (payload: SavedDataObjectType) =>
    ({
      type: userConstants.SET_SAVED_SUMMARY_DATA,
      payload,
    } as const),
  getCancelStatus: (payload: number) =>
    ({
      type: userConstants.CHECK_CANCEL_STATUS,
      payload,
    } as const),
  setCancelStatus: (payload: CheckCancelRescheduleOrderResponse) =>
    ({
      type: userConstants.SET_CANCEL_STATUS,
      payload,
    } as const),
  getRescheduleStatus: (payload: number) =>
    ({
      type: userConstants.CHECK_RESCHEDULE_STATUS,
      payload,
    } as const),
  setRescheduleStatus: (payload: CheckCancelRescheduleOrderResponse) =>
    ({
      type: userConstants.SET_RESCHEDULE_STATUS,
      payload,
    } as const),
  cancelOrder: (payload: CancelOrderRequest, id: number) =>
    ({
      type: userConstants.CANCEL_ORDER,
      payload,
      id,
    } as const),
  rescheduleOrder: (payload: RescheduleRequest, id: number) =>
    ({
      type: userConstants.RESCHEDULE_ORDER,
      payload,
      id,
    } as const),
  setChangedOrder: (payload: OrderForWorkshopType<OrderService[]>) =>
    ({
      type: userConstants.SET_CHANGED_ORDER,
      payload,
    } as const),
  getDeclinedOrder: (payload: number) =>
    ({
      type: userConstants.GET_DECLINED_ORDER,
      payload,
    } as const),
  resetDelivery: () =>
    ({
      type: userConstants.RESET_DELIVERY,
    } as const),
  setNotes: (payload: string) =>
    ({
      type: userConstants.SET_NOTES,
      payload,
    } as const),
  getCarDeliveries: () =>
    ({
      type: userConstants.GET_CAR_DELIVERIES_TIME,
    } as const),
  setCarDeliveriesTime: (payload: CarDeliveriesType[]) =>
    ({
      type: userConstants.SET_CAR_DELIVERIES_TIME,
      payload,
    } as const),
  getAvailableDays: (payload: GetAvailableDaysParamsType) =>
    ({
      type: userConstants.GET_AVAILABLE_DAYS,
      payload,
    } as const),
  setAvailableDays: (payload: AvailableDaysResponseType | null) =>
    ({
      type: userConstants.SET_AVAILABLE_DAYS,
      payload,
    } as const),
  createTabbySession: (payload: any) =>
    ({
      type: userConstants.CREATE_TABBY_SESSION,
      payload,
    } as const),
  setTabbySession: (payload: any) =>
    ({
      type: userConstants.SET_TABBY_SESSION,
      payload,
    } as const),
  reloadOrderInfo: (payload: any) =>
    ({
      type: userConstants.RELOAD_ORDER_INFO,
      payload,
    } as const),
};

export type ActionTypes = InferActionsTypes<typeof userActions>;
