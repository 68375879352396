import React from 'react';
import { Badge } from 'antd';
import classes from './style.module.scss';

import {
  OrderForWorkshopType,
  OrderService,
  OrderServicesForJob,
} from '../../../Types/workshopTypes';
import { Timer } from './../Timer';
import { StatusType } from '../../../Types/authTypes';
import { CustomerOrderType } from '../../../Types/userTypes';
import { SavedCardElement } from '../../SavedCardElement';
import classNames from 'classnames';

type CarImagePropsType = {
  order:
    | OrderForWorkshopType<OrderService[] | OrderServicesForJob>
    | CustomerOrderType;
  type?:
    | 'request'
    | 'orders'
    | 'job'
    | 'expired'
    | 'customer'
    | 'history'
    | 'invoice'
    | 'admin';
  isTimer?: boolean;
  timer?: number;
};

export const CarImage: React.FC<CarImagePropsType> = ({
  order,
  type = 'request',
  isTimer = false,
  timer,
}) => {

  const getStatusColor = (status: StatusType) => {
    switch (status) {
      case 'accepted':
        return '#52C41A';
      case 'finished':
        if(order.finished_by_admin){
          return 'cyan'
        }
        else{
          return '#5B8DB4'
        }
      case 'pending':
        return '#FAAD14';
      case 'processing':
        return '#FAAD14';
      default:
        return '#F5222D';
    }
  };
 
  return (
    <div className={classes.container}>
      {type === 'orders' && (
        <div className={classes.badge}>
          <Badge.Ribbon
            placement="start"
            style={{
              right: '-70px',
              top: '5px',
              textTransform: 'capitalize',
              width: '150px',
              textAlign: 'center',
            }}
            color={getStatusColor(order.status as StatusType)}
            text={order.finished_by_admin ? order.status.split('_').join(' ') + ' by Admin' : order.status.split('_').join(' ')}
          />
        </div>
      )}
      {(type === 'customer' || type === 'history') && (
        <div className={classes.time}>
          <div className={classes.amount}>Amount:</div>
          <p className={classes.price}>
            {order.total_price && `AED ${order.total_price.toLocaleString('en-US', {maximumFractionDigits:0})}`}
          </p>
        </div>
      )}
      {(type === 'request' || isTimer) && (
        <Timer
          createdAt={isTimer ? order.job_start_date! : order.created_at}
          timer={type === 'request' ? 1800 : timer}
        />
      )}
      <div className={classes.carImg}>
        {/* {type === 'invoice' && (
          <div className={classes.cardContainer}>
            <SavedCardElement payment={order} />
          </div>
        )} */}

        <img
          className={classNames(classes.carImage, {
            [classes.carImageWithCard]: type === 'invoice',
          })}
          src={order.customer_car.model?.image_url}
          alt="carImg"
        />
      </div>
    </div>
  );
};
