export const GET_WORKSHOP_DATA = 'GET_WORKSHOP_DATA';
export const UPDATE_WORKSHOP = 'UPDATE_WORKSHOP';
export const SET_WORKSHOP_DATA = 'SET_WORKSHOP_DATA';

export const GET_WORKSHOP_SCHEDULES = 'GET_WORKSHOP_SCHEDULES';
export const UPDATE_WORKSHOP_SCHEDULES = 'UPDATE_WORKSHOP_SCHEDULES';
export const SET_WORKSHOP_SCHEDULES = 'SET_WORKSHOP_SCHEDULES';

export const GET_WORKSHOP_PRICES = 'GET_WORKSHOP_PRICES';
export const UPDATE_WORKSHOP_PRICES = 'UPDATE_WORKSHOP_PRICES';
export const SET_WORKSHOP_PRICES = 'SET_WORKSHOP_PRICES';

export const GET_CITIES = 'GET_CITIES';
export const SET_CITIES = 'SET_CITIES';

export const GET_ORDERS = 'workshop/GET_ORDERS';
export const SET_ORDERS = 'workshop/SET_ORDERS';
export const RESET_ORDERS = 'workshop/RESET_ORDERS';

export const CHANGE_ORDER_STATUS = 'workshop/CHANGE_ORDER_STATUS';
export const UPDATE_ORDER = 'workshop/UPDATE_ORDER';

export const IS_HAS_MORE = 'workshop/IS_HAS_MORE';

export const GET_ORDER_BY_ID = 'workshop/GET_ORDER_BY_ID';
export const SET_ORDER = 'workshop/SET_ORDER';
export const RESET_ORDER = 'workshop/RESET_ORDER'

export const MARK_IS_DONE = 'workshop/MARK_IS_DONE';
export const MARK_IS_ALL_DONE = 'workshop/MARK_IS_ALL_DONE';

export const GET_WORKSHOP_SPECIALISTS = 'workshop/GET_WORKSHOP_SPECIALISTS';
export const SET_WORKSHOP_SPECIALISTS = 'workshop/SET_WORKSHOP_SPECIALISTS';
export const UPDATE_WORKSHOP_SPECIALISTS =
  'workshop/UPDATE_WORKSHOP_SPECIALISTS';

export const GET_WORKSHOP_PROMOTIONS = 'workshop/GET_WORKSHOP_PROMOTIONS';
export const SET_WORKSHOP_PROMOTIONS = 'workshop/SET_WORKSHOP_PROMOTIONS';
export const UPDATE_WORKSHOP_PROMOTIONS = 'workshop/UPDATE_WORKSHOP_PROMOTIONS';

export const GET_WORKSHOP_INVOICES = 'workshop/GET_WORKSHOP_INVOICES';
export const SET_WORKSHOP_INVOICES = 'workshop/SET_WORKSHOP_INVOICES';

export const GET_WORKSHOP_APPOINTMENTS = 'workshop/GET_WORKSHOP_APPOINTMENTS';
export const SET_WORKSHOP_APPOINTMENTS = 'workshop/SET_WORKSHOP_APPOINTMENTS';

export const GET_WORKSHOP_INVOICE_LINK = 'workshop/GET_WORKSHOP_INVOICE_LINK';

export const GET_WORKSHOP_MONTHLY_REVENUE =
  'workshop/GET_WORKSHOP_MONTHLY_REVENUE';
export const SET_WORKSHOP_MONTHLY_REVENUE =
  'workshop/SET_WORKSHOP_MONTHLY_REVENUE';

export const GET_WORKSHOP_PAYMENTS_LINK = 'workshop/GET_WORKSHOP_PAYMENTS_LINK';
export const SET_WORKSHOP_PAYMENTS_LINK = 'workshop/SET_WORKSHOP_PAYMENTS_LINK';

export const ADD_NEW_PHONE = 'workshop/ADD_NEW_PHONE';
export const EDIT_PHONE = 'workshop/EDIT_PHONE';
export const DELETE_PHONE = 'workshop/DELETE_PHONE';
export const SET_UPDATED_PHONES = 'workshop/SET_UPDATED_PHONES';

export const GET_STRIPE_INFO = 'workshop/GET_STRIPE_INFO';
export const SET_STRIPE_INFO = 'workshop/SET_STRIPE_INFO';

export const SEND_WORKSHOP_NOTE = 'workshop/SEND_WORKSHOP_NOTE';

export const GET_WORKSHOP_PAYMENTS_DATA = 'workshop/GET_WORKSHOP_PAYMENTS_DATA'
export const UPDATE_WORKSHOP_PAYMENTS_DATA = 'workshop/UPDATE_WORKSHOP_PAYMENTS_DATA'
export const SET_WORKSHOP_PAYMENTS_DATA = 'workshop/SET_WORKSHOP_PAYMENTS_DATA'

