import React from 'react';
import classes from './style.module.scss';
import CardIcon from '../../Assets/card-icon.svg';

type SavedCardPropsType = {
  payment?: any;
};

export const SavedCardElement: React.FC<SavedCardPropsType> = ({ payment }) => (
  <div className={classes.card}>
    <div>
      <img src={CardIcon} alt="credit card" />
      <span>{payment?.card.brand}</span>
    </div>
    <span>**** {payment?.card.last4}</span>
    <span>
      {payment?.card.exp_month} / {payment?.card.exp_year}
    </span>
  </div>
);
