import {
  PartType,
  QueryObjectType,
  ServicesForAdmin,
  UserType,
  UserTypeRequest,
  WorkShopRequestType,
  WorkShopType,
  WorkShopTypeRequest,
} from './../../Types/authTypes';
import { InferActionsTypes } from '../../Stores';
import * as adminConstants from './constants';
import {
  ApproveWorkshopRequestType,
  RequestInvoiceType,
} from '../../Types/workshopTypes';
import {
  AdminReviewsResponseType,
  CarForAdminType,
  OrderForAdminType,
  ResponseAdminAppointmentsType,
  ResponseCarForAdminType,
  ResponseNotificationsType,
  ResponseOrderAdminType,
} from '../../Types/adminTypes';
import { CarUpdateRequestType } from '../../Types/userTypes';

export const adminActions = {
  getWorkshopList: (payload?: QueryObjectType) =>
    ({
      type: adminConstants.GET_WORKSHOP_LIST,
      payload,
    } as const),
  setWorkshopList: (payload: WorkShopTypeRequest) =>
    ({
      type: adminConstants.SET_WORKSHOP_LIST,
      payload,
    } as const),
  approve: (id: number, payload: ApproveWorkshopRequestType) =>
    ({
      type: adminConstants.APPROVE_WORKSHOP,
      id,
      payload,
    } as const),
  searchWorkshops: (payload: string) =>
    ({
      type: adminConstants.SEARCH_WORKSHOPS,
      payload,
    } as const),
  getUsersList: (payload?: QueryObjectType) =>
    ({
      type: adminConstants.GET_USERS_LIST,
      payload,
    } as const),
  setUsersList: (payload: UserTypeRequest) =>
    ({
      type: adminConstants.SET_USERS_LIST,
      payload,
    } as const),
  editWorkshop: (payload: WorkShopRequestType, id: number) =>
    ({
      type: adminConstants.EDIT_WORKSHOP,
      payload,
      id,
    } as const),
  getServices: (trimId: number, intervalId: number) =>
    ({
      type: adminConstants.GET_SERVICES_FOR_ADMIN,
      trimId,
      intervalId,
    } as const),
  setServiecs: (payload: ServicesForAdmin) =>
    ({
      type: adminConstants.SET_SERVICES_FOR_ADMIN,
      payload,
    } as const),
  getParts: (modelId: number) =>
    ({
      type: adminConstants.GET_PARTS,
      modelId,
    } as const),
  setParts: (payload: Array<PartType>) =>
    ({
      type: adminConstants.SET_PARTS,
      payload,
    } as const),
  getUser: (id: number) =>
    ({
      type: adminConstants.GET_USER,
      id,
    } as const),
  setUser: (payload: UserType) =>
    ({
      type: adminConstants.SET_USER,
      payload,
    } as const),
  getOrders: (payload?: QueryObjectType) =>
    ({
      type: adminConstants.GET_ORDERS,
      payload,
    } as const),
  setOrders: (payload: ResponseOrderAdminType) =>
    ({
      type: adminConstants.SET_ORDERS,
      payload,
    } as const),
  getCars: (id: number, query?: QueryObjectType) =>
    ({
      type: adminConstants.GET_CUSTOMER_CARS,
      id,
      query,
    } as const),
  setCars: (payload: ResponseCarForAdminType) =>
    ({
      type: adminConstants.SET_CUSTOMER_CARS,
      payload,
    } as const),
  updateCar: (payload: CarUpdateRequestType, id: number) =>
    ({
      type: adminConstants.UPDATE_CAR,
      payload,
      id,
    } as const),
  setUpdatedCar: (payload: CarForAdminType) =>
    ({
      type: adminConstants.SET_UPDATED_CAR,
      payload,
    } as const),
  deleteCar: (id: number) =>
    ({
      type: adminConstants.DELETE_CAR,
      id,
    } as const),
  getOrderById: (payload: number) =>
    ({
      type: adminConstants.GET_ORDER,
      payload,
    } as const),
  setOrder: (payload: OrderForAdminType) =>
    ({
      type: adminConstants.SET_ORDER,
      payload,
    } as const),
  finishOrder: (id: number) =>
    ({
      type: adminConstants.FINISH_ORDER,
      id,
    } as const),
  deleteOrder: (id: number) =>
    ({
      type: adminConstants.DELETE_ORDER,
      id,
    } as const),
  deleteOrderSuccess: () =>
    ({
      type: adminConstants.DELETE_ORDER_SUCCESS,
    } as const),
  getCustomerAppointments: (id: number, query?: QueryObjectType) =>
    ({
      type: adminConstants.GET_CUSTOMER_APPOINTMENTS,
      id,
      query,
    } as const),
  setCustomerAppointments: (payload: ResponseAdminAppointmentsType) =>
    ({
      type: adminConstants.SET_CUSTOMER_APPOINTMENTS,
      payload,
    } as const),
  blockUnblockUser: (
    data: { blocked: boolean; type: 'workshop' | 'customer' },
    id: number
  ) =>
    ({
      type: adminConstants.BLOCK_UNBLOCK_USER,
      data,
      id,
    } as const),
  deleteWorkshop: (id: number) =>
    ({
      type: adminConstants.DELETE_WORKSHOP,
      id,
    } as const),
  deleteWorkshopSuccess: (id: number) =>
    ({
      type: adminConstants.DELETE_WORKSHOP_SUCCESS,
      id,
    } as const),
  deleteCustomer: (id: number) =>
    ({
      type: adminConstants.DELETE_CUSTOMER,
      id,
    } as const),
  deleteCustomerSuccess: (payload: number) =>
    ({
      type: adminConstants.DELETE_CUSTOMER_SUCCESS,
      payload,
    } as const),
  toggleBlockWorkshop: (payload: { id: number; isBlocked: boolean }) =>
    ({
      type: adminConstants.TOGGLE_IS_BLOCKED_WORKSHOP,
      payload,
    } as const),
  toggleBlockCustomer: (payload: { id: number; isBlocked: boolean }) =>
    ({
      type: adminConstants.TOGGLE_IS_BLOCKED_CUSTOMER,
      payload,
    } as const),
  getInvoices: (payload: QueryObjectType) =>
    ({
      type: adminConstants.GET_ADMIN_INVOICES,
      payload,
    } as const),
  setInvoices: (payload: RequestInvoiceType<WorkShopType>) =>
    ({
      type: adminConstants.SET_ADMIN_INVOICES,
      payload,
    } as const),
  getMonthlyRevenue: () =>
    ({
      type: adminConstants.GET_ADMIN_MONTHLY_REVENUE,
    } as const),
  setMonthlyRevenue: (payload: { total_price: number | null }) =>
    ({
      type: adminConstants.SET_ADMIN_MONTHLY_REVENUE,
      payload,
    } as const),
  getNotifications: (payload: QueryObjectType) =>
    ({
      type: adminConstants.GET_NOTIFICATIONS,
      payload,
    } as const),
  setNotifications: (payload: ResponseNotificationsType) =>
    ({
      type: adminConstants.SET_NOTIFICATIONS,
      payload,
    } as const),
  getReviews: (payload: QueryObjectType) =>
    ({
      type: adminConstants.GET_ADMIN_REVIEWS,
      payload,
    } as const),
  setReviews: (payload: AdminReviewsResponseType) =>
    ({
      type: adminConstants.SET_ADMIN_REVIEWS,
      payload,
    } as const),
  deleteReview: (id: number) =>
    ({
      type: adminConstants.DELETE_ADMIN_REVIEW,
      id,
    } as const),
  deleteReviewSuccess: (id: number) =>
    ({
      type: adminConstants.DELETE_ADMIN_REVIEW_SUCCESS,
      id,
    } as const),
  changeOrderStatus: (id: number, status: 
    | 'accepted'
    | 'declined'
    | 'processing'
    | 'finished'
    | 'missed_appointment'
    ) =>
    ({
      type: adminConstants.CHANGE_ORDER_STATUS,
      id,
      status,
    } as const),
  updateOrder: (payload: OrderForAdminType) =>
    ({
      type: adminConstants.UPDATE_ORDER,
      payload,
    } as const),
  changePaymentStatus: (id: number, status: 'paid' | 'not_paid') =>
    ({
      type: adminConstants.CHANGE_PAYMENT_STATUS,
      id,
      status,
    } as const),
  updatePaymentStatus: (payload: { id: number; status: 'paid' | 'not_paid' }) =>
    ({
      type: adminConstants.UPDATE_INVOICE_PAYMENT_STATUS,
      payload,
    } as const),
};

export type ActionTypes = InferActionsTypes<typeof adminActions>;
