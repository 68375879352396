import React, { useEffect } from 'react';
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from '../../Components/MainLayout';
import { userActions } from '../../Globals/User/actions';
import { CarDetailsFormType } from '../../Types/authTypes';

import { CarDetailsStep } from '../CreateOrder/Steps/CarDetailsStep';
import { RootStateType } from '../../Stores';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../Globals/Auth/actions';

type AddCarFormPropsType = {
 
};


export const AddCar: React.FC<AddCarFormPropsType> = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const route = useSelector((state: RootStateType) => state.auth.route);

  dispatch(userActions.removeFormID())

  const createNewCar = (formValues: CarDetailsFormType) => {
      const myCar = {
        make: formValues?.brand?.id!,
        model: formValues?.trim?.model!,
        trim: formValues?.trim?.id!,
        maintenance_interval: formValues?.milliage?.id!,
        year: formValues.year?.year
      }
      dispatch(userActions.addNewCar(myCar))
  }

  useEffect(() => {
    if (route === '/user/my-cars') {
      history.push(route);
      dispatch(authActions.setRoute(null));
    }
  }, [route, dispatch, history]);
  
  return (
    <div className={classes.addCar}>
      <MainLayout headerTitle="Add a New Car">
        <CarDetailsStep createNewCar={createNewCar} currentStep={1} goToNextStep={()=>{}}/>
      </MainLayout>
    </div>
  );
};
