import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';
import { SignUpForm } from './Form';
import Logo from '../../Assets/logo.svg';
import miniLogo from '../../Assets/Logo-icon.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ForgotPassword } from './ForgotPassword';
import { AfterSignUp } from './WorkshopAfterSignUp';
import { Header } from './Header';
import background from '../../Assets/banner-add-car.png'

export const SignUpPage: React.FC = () => {
  const [isForgotPassword, setForgotPassword] = useState(true);
  const [isWorkshopInfoMode, setWorkshopInfoMode] = useState(true);
  const history = useHistory();
  const location = useLocation<{link?: string}>();
  location.pathname.indexOf('forgot-password');

  const goTo = (path: string) => {
    history.push(path);
  };

  const getRole = (): 'admin' | 'customer' | 'workshop' => {
    if (history.location.pathname.indexOf('admin') >= 0) {
      return 'admin';
    } else if (history.location.pathname.indexOf('workshop') >= 0) {
      return 'workshop';
    } else {
      return 'customer';
    }
  };

  const getContent = () => {
    if (isForgotPassword) {
      return <ForgotPassword />;
    } else if (isWorkshopInfoMode) {
      return <AfterSignUp />;
    } else {
      return (
        <SignUpForm
          userRole={getRole()}
          isLogin={
            history.location.pathname.indexOf('signup') === -1 ||
            getRole() === 'admin'
          }
          linkForRedirectAfterLogin={location.state?.link}
        />
      );
    }
  };

  useEffect(() => {
    if (~location.pathname.indexOf('forgot-password')) {
      setWorkshopInfoMode(false);
      setForgotPassword(true);
    } else if (~location.pathname.indexOf('workshop-info-mode')) {
      setForgotPassword(false);
      setWorkshopInfoMode(true);
    } else {
      setForgotPassword(false);
      setWorkshopInfoMode(false);
    }
  }, [location.pathname]);

  return (
    <div className={classes.container}>
      <Header goTo={goTo}/>
      <div
        className={classNames(classes.backgroundImage)}
        // , {
        //   [classes.customerBackground]: getRole() === 'customer',
        //   [classes.workshopBackground]: getRole() === 'workshop',
        //   [classes.adminBackground]: getRole() === 'admin',
        // }
      >
        <div className={classNames(classes.logoForm)}>
          <img src={background} className={classes.bannerImageModels}></img>
          <div>
            <Link to="/" className={classes.header}>
              <img src={miniLogo} alt="Logo" />
            </Link>
          </div>
        </div>
        <div className={classes.loginContainer}>
          {getContent()}
        </div>
      </div>
    </div>
  );
};
