import React from 'react';
import { Rate } from 'antd';
import logo from '../../../../Assets/workshopImage.png';
import ArrowDown from '../../../../Assets/orderIcon/arrow-down.svg';
import CheckIcon from '../../../../Assets/orderIcon/partner-icon.svg';
import VerifiedIcon from '../../../../Assets/orderIcon/verified-icon.svg';
import Google from '../../../../Assets/orderIcon/google_icon.svg';
import classes from './style.module.scss';
import classNames from 'classnames';

export interface RateDescriptionPropsType {
  text?: any;
  image?: any;
  rate: number;
  isPartner: boolean | null;
  count?: number;
  isVerified?: boolean;
  sales: Array<string>;
  isMarginLeft?: boolean;
  isReview?: boolean;
  link: string;
  specialists?: Array<any>;
  showModal?: () => void;
}

export function RateDescription({
  text,
  image,
  rate,
  count,
  isPartner,
  isReview = false,
  isVerified = true,
  sales,
  showModal = () => {},
  isMarginLeft = true,
  link,
  specialists
}: RateDescriptionPropsType) {
  return (
    <div
      className={classNames(classes.description, {
        [classes.marginLeftZero]: !isMarginLeft,
      })}
    > 
      {/* {rate}

      <Rate allowHalf defaultValue={rate} disabled={true} /> */}
      <a href={link} target="_blank" className={classes.link}>
        {rate}
        <div className={classes.rateBox}>
          <Rate
            allowHalf
            value={rate}
            count={5}
            disabled
            className={classes.rate}
          />
        </div> 
        {count}<img className={classes.google} src={Google} alt="google-icon" /> Reviews
      </a>
      <div className={classes.specialisations}>
        {/* <img
            style={{ cursor: 'pointer', borderRadius: '6px' }}
            width={200}
            height={200}
            onClick={showModal}
            className={classes.workshopImage}
            src={image ? `${image.url}` : logo}
            alt="logo"
          /> */}
          <div className={classes.specialist}>
          {isPartner && (
              <div className={classes.specialistItem}>
                  <img src={CheckIcon} alt="check" className={classes.checkIcon} />
                <span>Partner workshop</span>
              </div>
            )}
            {isVerified && (
              <div className={classes.specialistItem}>
                  <img
                    src={VerifiedIcon}
                    alt="verified"
                    className={classes.checkIcon}
                  />
                <span>Verified Credentials</span>
              </div>
            )}  
            {specialists?.sort(function (a, b) {
              if (a.priority > b.priority) {
                return 1;
              }
              if (a.priority < b.priority) {
                return -1;
              }
              return 0;
            }).map((spec,index) => {
              if(index >= 4){
                return
              }
              return (
                <div className={classes.specialistItem} key={spec.id}>
                  <img src={spec.icon?.url} alt="demographic" />
                  {spec.title}
                </div>
              );
            })}
            {/* <p onClick={showModal} className={classes.moreInfo}>MORE INFO <img src={ArrowDown} alt="more info" /></p> */}
          </div>
      </div>
      {/* <span className={classes.link}>{rate}<Rate className={classes.rate} defaultValue={0} allowHalf value={rate} disabled={true} />{count ? <a href={link} target="_blank">{count} Reviews</a> : ''}</span> */}
      
    </div>
  );
}
