import {
  signUpResponseType,
  SignUpUserType,
  SignUpWorkshopType,
  SignInUserType,
  ResetPasswordRequest,
  UserType,
 // UpdateUserProfile,
  ResetPasswordRequestType,
} from './../../Types/authTypes';
import axios from 'axios';
import * as constants from '../../Utils/Api/constants';

export const authApi = {
  signUp: async (data: SignUpUserType | SignUpWorkshopType) => {
    return axios
      .post<signUpResponseType>(
        `${constants.ApiUrls.BASE_URL}/auth/local/register`,
        data
      )
      .then((resp) => resp.data);
  },
  signIn: async (payload: SignInUserType) => {
    const resp = await axios.post<signUpResponseType>(
      `${constants.ApiUrls.BASE_URL}/auth/local`,
      payload
    );
    return resp.data;
  },
  /* updateProfile: async (token: string, payload: UpdateUserProfile) => {
    const resp = await axios.put<signUpResponseType>(
      `${constants.ApiUrls.BASE_URL}/users/46`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  }, */
  updatePassword: async (token: string, payload: ResetPasswordRequest) => {
    const resp = await axios.post<UserType>(
      `${constants.ApiUrls.BASE_URL}/password`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  },
  forgotPassword: async (payload: { email: string }) => {
    const resp = await axios.post<{ ok: boolean }>(
      `${constants.ApiUrls.BASE_URL}/auth/forgot-password`,
      payload
    );
    return resp.data;
  },
  resetPassword: async (payload: ResetPasswordRequestType) => {
    const resp = await axios.post<signUpResponseType>(
      `${constants.ApiUrls.BASE_URL}/auth/reset-password`,
      payload
    );
    return resp.data;
  },
};
