import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import classes from './style.module.scss';
import { ExclamationCircleOutlined,CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { CarAndServices } from '../../../../Components/CarAndServices/index';
import { ButtonComponent } from '../../../../Components/Button';
import { ServiceType } from './Components/ServiceType';
import { OtherServices } from './Components/OtherServices';
import { ModalComponent } from '../../../../Components/Modal';
import DrawerComponent from '../../../../Components/Drawer';
import Lubricant1 from "../../../../Assets/lubricant1.svg"
import Lubricant2 from "../../../../Assets/lubricant2.svg"
import Lubricant3 from "../../../../Assets/lubricant3.svg"
import Lubricant4 from "../../../../Assets/lubricant4.svg"
import IconArrow from "../../../../Assets/icon-arrow.svg"
import TabbyLogo from '../../../../Assets/tabby-button-logo.svg'

import checkCircle from '../../../../Assets/orderIcon/check-circle.svg';

import { RootStateType } from '../../../../Stores';
import { userActions } from '../../../../Globals/User/actions';
import { appActions } from '../../../../Globals/App/actions';
import { authActions } from '../../../../Globals/Auth/actions';

const { TextArea } = Input;

type ServiceStepPropsType = {
  goToNextStep: () => void;
  goToPrevStep: () => void;
  activeStep: number;
};

export const ServiceStep: React.FC<ServiceStepPropsType> = ({
  goToNextStep,
  goToPrevStep,
  activeStep,
}) => {
  const dispatch = useDispatch();
  const services = useSelector((state: RootStateType) => state.user.services);
  const isBasic = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );
  const selectedOtherServicesId = useSelector(
    (state: RootStateType) => state.user.selectedOtherServicesId
  );
  const selectedCarRepairId = useSelector(
    (state: RootStateType) => state.user.selectedCarRepairId
  );
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  const parts = useSelector(
    (state: RootStateType) => state.user.services?.check
  );
  const selectedLocation = useSelector(
    (state: RootStateType) => state.user.location
  );

  const alerts = useSelector((state: RootStateType) => state.app.alerts);

  const [isAlertModal, setIsAlertModal] = useState<boolean>(false);

  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false);
  const [animateGo, setAnimateGo] = useState<{}>();
  const [countRender, setCountRender] = useState(0);
  const [isSelected, setIsSelected] = useState(false);

  const [isModal, setIsModal] = useState<boolean>(false);

  const dataLayer = window.dataLayer || []

  const setSelectedLocation = (location: 'at_location' | 'at_workshop') => {
    setAnimateGo({})
    dispatch(appActions.deleteAlertMessage('You should select location'));
    dispatch(userActions.setSelectedLocation(location));
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const onToggleDropDown = () => {
    setIsOpenDropDown((prev) => !prev);
  };

  const closeAlert = () => {
    setIsAlertModal(false);
  };

  const removeServiceType = () => {
    setAnimateGo({});
    dispatch(userActions.setSelectedServiceType(null));
    setIsAlertModal(false);
  };

  const openModal = (
    title: string,
    modalType: 'main' | 'logbook' | 'alert',
    isSelected: boolean = false
  ) => {
    if (modalType === 'alert') {
      setIsAlertModal(true);
    } else {
      if (modalType === 'logbook' && isBasic) {
        dispatch(userActions.setIsModal(true));
        dispatch(
          userActions.setModalType({
            type: modalType,
            title,
            isSelected: false,
          })
        );
      } else {
        setIsSelected(isSelected);
        dispatch(userActions.setIsModal(true));
        dispatch(
          userActions.setModalType({ type: modalType, title, isSelected })
        );
      }
    }
  };

  const toggleOtherServices = (id: number) => {
    setAnimateGo({});
    if (selectedOtherServicesId.findIndex((item) => item === id) >= 0) {
      dispatch(userActions.removeOtherService(id));
    } else {
      dispatch(userActions.addOtherService(id));
    }
  };

  const onNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(userActions.setNotes(event.target.value));
  };
  const goNext = () => {
    if (isBasic !== null || selectedOtherServicesId.length) {
      //GTM
      //Start
      dataLayer.push({
        "event": "step_2_services",
        "selected_services":`${ 
        isBasic !== null
        ?
          isBasic ?
          services?.log_book.filter(function(service) {
            if(service.optional == false){
              return(true)
            }
            return false;
          }).map((service) => {
              return(service.title)
          })  
          :
          services?.log_book.map((service) => {
              return(service.title)
          })
        : null}`,
        "other_services": `${selectedOtherServicesId.map((id) => {
          return(
            services?.additional.filter(function(service) {
              if(service.id == id){
                return(true)
              }
              return false;
            }).map((service) => {
              return(service.title)
          }))
        })}`,
        "car_repair": `${selectedCarRepairId.map((id) => {
          return(services?.additional[0].car_repair_theses[id-1].title)
        })}`
        });
      //End
      goToNextStep();
      ReactGA.event({
        category: 'Create order',
        label: 'Selected services',
        action: `User ${
          currentUser?.username ? currentUser?.username : 'not registered'
        } selected ${!isBasic ? 'Major service' : 'Minor service'} and ${selectedOtherServicesId.length} other services`,
      });
    } else {
      message.error('Please select a service!');
    }
  };

  const openModalHelp = () => {
    ReactGA.event({
      category: 'Create order',
      label: 'Checks done opened',
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } opened checks done window`,
    });
    setIsModal(true);
  };

  const onSubmit = () => {
    if (selectedLocation) {
      return true;
    } else {
      dispatch(
        appActions.addAlertMessage({
          message: 'You should select location',
          type: 'error',
        })
      );
      return false;
    }
  };

  const onCancel = () => {
      goToPrevStep();
      dispatch(userActions.setSelectedLocation(null));
  };

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/createorder/services",
      "page_type":"servicesStep",
      "event":"page_view"
    });
    //End
  }, []);
  useEffect(() => {
    const beforeUnloadWindow = (e:any) => {
      var e = e || window.event;
        if (e) {
            e.returnValue = 'Leaving the page?';
        }
        // For Safari
        return "Leaving the page?";
     }
    window.addEventListener('beforeunload', beforeUnloadWindow)
    return () => { 
      window.removeEventListener('beforeunload', beforeUnloadWindow)
    }
  }, []);

  useEffect(() => {
    if (alerts.length) {
      dispatch(appActions.deleteAlertMessage('Select service'));
    }
  }, [isBasic, selectedOtherServicesId, dispatch]);

  useEffect(() => {
    if (countRender) {
      setAnimateGo({});
    } else {
      setCountRender((prev) => ++prev);
    }
  }, [isBasic]);
  
  return (
    <div className={classes.contentContainer}>
      <DrawerComponent runAnimate={animateGo}>
        <CarAndServices
          openAlert={() => {
            setIsAlertModal(true);
          }}
          isShowTabbyPromo={false}
        />
      </DrawerComponent>
      <div className={classes.leftWrapper}>
        <ServiceType openModel={openModal} />
        <button className={classes.freepoints} onClick={openModalHelp}>
          Checks done on your car for FREE
          <img src={IconArrow} alt="" />
        </button>
        <ModalComponent
          isModal={isModal}
          toggleModal={closeModal}
          title="Checks done on your car for FREE"
          icon={checkCircle}
        >
          <div className={classes.partsList}>
            <div className={classes.header}>
            Major services are unique to your car, based on your car’s manufacturer recommendations, and are to the highest industry standards.
            </div>
            <div className={classes.content}>
              <div className={classes.leftBlock}>
                {parts?.map(
                  (part, index) =>
                    index % 2 === 0 && (
                      <div className={classes.row} key={part.id}>
                        <CheckCircleOutlined
                          style={{ fontSize: '15px', color: '#5B8DB4' }}
                        />
                        {part.title}
                      </div>
                    )
                )}
              </div>
              <div className={classes.rightBlock}>
                {parts?.map(
                  (part, index) =>
                    index % 2 !== 0 && (
                      <div className={classes.row} key={part.id}>
                        <CheckCircleOutlined
                          style={{ fontSize: '15px', color: '#5B8DB4' }}
                        />
                        {part.title}
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </ModalComponent>
        <div className={classes.lubricants}>
            <p>We only use  premium and fully synthetic lubricants</p>
            <div>
              <img src={Lubricant1} alt="Castrol"/>
              <img src={Lubricant2} alt="Total" />
              <img src={Lubricant3} alt="Mobil1" />
              <img src={Lubricant4} alt="Valvoline" />
            </div>
        </div>
        <OtherServices
          openModel={openModal}
          isBasic={isBasic}
          otherServices={services?.additional}
          toggleOtherServices={toggleOtherServices}
          selectedOtherServices={selectedOtherServicesId}
        />

        <div className={classes.textArea}>
          <TextArea
            placeholder="Add any notes that may be useful for us"
            rows={4}
            onChange={onNotesChange}
            maxLength={200}
          />
        </div>

        <div className={classes.tabbyPromo}>
          <p>4 interest-free payments with</p>
          <img src={TabbyLogo} alt="tabby logo" />
        </div>

        <div className={classes.buttons}>
          <ButtonComponent
            style={{ width: '172px' }}
            title="Back"
            type="cancel"
            onClick={onCancel}
          />
          <ButtonComponent
            style={{ width: '172px' }}
            title="Continue"
            type="save"
            onClick={goNext}
          />
        </div>
      </div>
      <div className={classes.hide}>
        <CarAndServices
          openAlert={() => {
            setIsAlertModal(true);
          }}
        />
      </div>
      <ModalComponent
        isModal={isAlertModal}
        toggleModal={closeAlert}
        onSubmit={removeServiceType}
        isAlert={true}
        buttonConfig={[
          { type: 'cancel', title: 'No' },
          { type: 'save', title: 'Yes, Delete' },
        ]}
      >
        <div className={classes.alertBody}>
          <ExclamationCircleOutlined
            style={{ fontSize: '22px', color: '#FAAD14' }}
          />
          <div>Are you sure you want to delete {!isBasic ? 'Major' : 'Minor'} service?</div>
        </div>
      </ModalComponent>
    </div>
  );
};
