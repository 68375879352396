import { Tabs } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import classes from './style.module.scss';
import { MainLayout } from '../../Components/MainLayout';
import { Appointment } from './Appointment';
import { History } from './History';
import { StatusType, QueryObjectType } from '../../Types/authTypes';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../Globals/User/actions';
import { RootStateType } from '../../Stores';
import { useLocation } from 'react-router-dom';

const { TabPane } = Tabs;
const LIMIT_ON_PAGE = 4;

export const UserAppointments = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState('1');
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<Array<StatusType>>([]);
  
  const [loading, setLoading] = useState<boolean>(true);

  const orders = useSelector((state: RootStateType) => state.user.orders.rows);

  const dataLayer = window.dataLayer || []

  const generateQuery = useCallback(
    (nextPage: number): QueryObjectType => {
      const obj = {
        page: {
          page: nextPage,
          limit: LIMIT_ON_PAGE,
        },
      };
      return {
        ...obj,
        ...(status.length && { status }),
        ...{ asc: true },
      };
    },
    [status]
  );

  
  const loadMore = () => {
    setLoading(true);
    setPage((prev) => prev + 1);
    const nextPage = page + 1;
    dispatch(userActions.getOrders(generateQuery(nextPage)));
  };
  
  useEffect(() => {
    if (location.search === '?tab=history') {
      setActiveTab('2');
      
    }
  }, [location]);
  
  useEffect(() => {
    if (activeTab === '2') {
      setStatus(['finished']);
    } else {
      setStatus(['accepted', 'pending']);
    }
  }, [activeTab]);
  
  useEffect(() => {
    setPage(1);
    dispatch(userActions.resetOrders());
    const page = 1;
    if (status.length !== 0) {
      dispatch(userActions.getOrders(generateQuery(page)));
      setLoading(true);
    }
  }, [dispatch, generateQuery, status]);
  
  useEffect(() => {
    if(activeTab == '1'){
        //GTM
        //Start
        dataLayer.push({
          "path": "/user/appointments",
          "page_type":"userAppointmentsPage",
          "event":"page_view"
        });
        //End
    }
    if(activeTab == '2'){
      //GTM
      //Start
      dataLayer.push({
        "path": "/user/appointments",
        "page_type":"userServiceHistoryPage",
        "event":"page_view"
      });
      //End
    }
  }, [activeTab]);
  
  useEffect(() => {
    setLoading(false);
  }, [orders]);
  
  return (
    <MainLayout headerTitle="Appointments" isHorizontalPadding={false}>
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        onChange={setActiveTab}
        className={classes.tabs}
        style={{ border: 'none', position: 'relative' }}
      >
        <TabPane tab="Upcoming appointment" key="1">
          <Appointment loadMore={loadMore} loading={loading} activeTab={activeTab} />
        </TabPane>
        <TabPane tab="Service History" key="2">
          <History loadMore={loadMore} activeTab={activeTab} loading={loading}/>
        </TabPane>
      </Tabs>
    </MainLayout>
  );
};
