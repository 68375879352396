import React, {useEffect, useState} from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import classes from './styles.module.scss';
import OtherServiceIcon from '../../../../Assets/orderIcon/other-service-icon2.svg';
import SparkPlugs from '../../../../Assets/orderIcon/spark_icon.svg';
import Battery from '../../../../Assets/orderIcon/battery_icon.svg';
import LogbookIcon from '../../../../Assets/orderIcon/logbook-icon.svg';
import ACFilter from '../../../../Assets/orderIcon/ac_filter_icon.svg';
import AirFilter from '../../../../Assets/orderIcon/air_filter_icon.svg';
import Wipes from '../../../../Assets/orderIcon/replace_wipes_icon.svg';
import Inspection from '../../../../Assets/orderIcon/inspection_icon.svg';
import RearBrake from '../../../../Assets/orderIcon/rear_break_icon.svg';
import FrontBrake from '../../../../Assets/orderIcon/front_break_icon.svg';
import OtherServiceIconMob from '../../../../Assets/orderIcon/other-service-icon2-mob.svg';
import SparkPlugsMob from '../../../../Assets/orderIcon/spark_icon-mob.svg';
import BatteryMob from '../../../../Assets/orderIcon/battery_icon-mob.svg';
import LogbookIconMob from '../../../../Assets/orderIcon/logbook-icon-mob.svg';
import ACFilterMob from '../../../../Assets/orderIcon/ac_filter_icon-mob.svg';
import AirFilterMob from '../../../../Assets/orderIcon/air_filter_icon-mob.svg';
import WipesMob from '../../../../Assets/orderIcon/replace_wipes_icon-mob.svg';
import InspectionMob from '../../../../Assets/orderIcon/inspection_icon-mob.svg';
import RearBrakeMob from '../../../../Assets/orderIcon/rear_break_icon-mob.svg';
import FrontBrakeMob from '../../../../Assets/orderIcon/front_break_icon-mob.svg';
import { ButtonComponent } from '../../../../Components/Button';

import { StaticModals } from './data';
import { RootStateType } from '../../../../Stores';
import { ModalComponent } from '../../../../Components/Modal';
import { Modal } from 'antd';
import { userActions } from '../../../../Globals/User/actions';

type ServiceForOfferPropsType = {
  goTo: (path: string) => void;
};

export const ServiceForOffer: React.FC<ServiceForOfferPropsType> = ({
  goTo,
}) => {
  const dispatch = useDispatch();

  const [isModal,setIsModal] = useState(false)
  const [selectedService,setSelectedService] = useState<number>(0)
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const dataLayer = window.dataLayer || []
  const arrayOfServiceTitles =['Major Service', 'Minor service', 'Replace Spark Plugs', 'Battery Replacement', 'Replace AC Filter', 'Replace Air Filter', 'Replace Wipers', 'Car repair', 'Front Brake Pads + Disk Skimming', 'Rear Brake Pads + Disk Skimming']
  const openModal = (serviceNumber: number) => {
    setSelectedService(serviceNumber)
    setIsModal(true)
    dataLayer.push({
      "car_service": `${arrayOfServiceTitles[serviceNumber]}`,
      "event":"select_service"
    });
  };

  const sendGAEvent = () => {
    ReactGA.event({
      category: 'Get Started',
      label: 'From service for offer',
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } get started`,
    });
  };

  const onRedirect = (selectedId?: number | boolean| null) => {
    // dataLayer.push({
    //   "event": "get_started"
    //   });
    if (currentUser?.role.type === 'authenticated') {
      goTo('user/my-cars');
    } else if (currentUser?.role.type === 'authenticated_admin') {
      goTo('admin/clients');
    } else if (currentUser?.role.type === 'authenticated_workshop') {
      goTo('workshop/jobs');
    } else {
      if(selectedId !== null){
        if(typeof selectedId == 'number'){
          dispatch(userActions.addOtherService(selectedId))
        }
        if(typeof selectedId == 'boolean'){
          dispatch(userActions.setSelectedServiceType(selectedId))
        }
      }
      goTo('createorder');
      sendGAEvent();
    }
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth} = window;
    return innerWidth;
  }
  const SwitchIconsWidth = 900;
  return (
    <section className={classes.container} id="services-for-offer">
      <h2>What do you need for your car?</h2>
      {(windowSize > SwitchIconsWidth) ? 
      <div className={classes.infoWrapper} onClick={(e) => {

      }} >
        <div className={classes.serviceCard} onClick={() => openModal(2)}>
          <img src={SparkPlugs} alt="spark"/>
          <div>
            <h3>Replace Spark Plugs</h3>
          </div>
        </div>

        <div className={classes.serviceCard} style={{gap:'0'}} onClick={() => openModal(0)}>
          <img src={LogbookIcon} alt="major_service" />
          <div style={{height:'max-content'}}>
            <h3 className={classes.mainHeader}>Major service</h3>
            <p className={classes.mainP}>A premium agency <br></br>alternative service</p>
          </div>
        </div>

        <div className={classes.serviceCard} style={{gap:'0'}} onClick={() => openModal(7)}>
          <img src={Inspection} alt="spark" />
          <div style={{height:'max-content'}}>
            <h3 className={classes.mainHeader}>Car repair</h3>
            <p className={classes.mainP}>Let us check your <br></br> car problems</p>   
          </div>
        </div>

        <div className={classes.serviceCard} style={{gap:'0'}} onClick={() => openModal(1)}>
          <img src={OtherServiceIcon} alt="minor_service" />
          <div style={{height:'max-content'}}>
            <h3 className={classes.mainHeader}>Minor service</h3>
            <p className={classes.mainP}>Just an Oil and Filter <br></br>change</p>
          </div>
        </div>

        <div className={classes.serviceCard} onClick={() => openModal(8)}>
          <img src={FrontBrake} alt="front_brake" />
          <div>
            <h3>Front Brake + Disk Skimming</h3>
          </div>
        </div>

        <div className={classes.serviceCard} onClick={() => openModal(6)}>
          <img src={Wipes} alt="wipes"/>
          <div><h3>Replace Wipers</h3></div>
        </div>

        <div className={classes.serviceCard} onClick={() => openModal(3)}>
          <img src={Battery} alt="battery" />
          <div><h3>Replace Battery</h3></div>
        </div>

        <div className={classes.serviceCard} onClick={() => openModal(5)}>
          <img src={AirFilter} alt="air filter" />
          <div><h3>Replace Air Filter</h3></div>
        </div>

        <div className={classes.serviceCard} onClick={() => openModal(4)}>
          <img src={ACFilter} alt="ac_filter" />
          <div><h3>Replace A/C Filter</h3></div>
        </div>

        <div className={classes.serviceCard} onClick={() => openModal(9)}>
          <img src={RearBrake} alt="spark" />
          <div><h3>Rear Brake + Disk Skimming</h3></div>
        </div>

        <div className={classNames(classes.getStarted, classes.hideMax890)}>
          <div>Try Us!</div>
          <ButtonComponent
            type="dark"
            title="Get Started"
            onClick={onRedirect}
          />
        </div>
      </div>
    :
    
    <div className={classes.infoWrapper}>
      <div className={classes.serviceCard} onClick={() => openModal(0)}>
        <img src={LogbookIconMob} alt="major_service"/>
        <div>
          <h3>Major service</h3>
          <p className={classes.mainP}>A premium agency <br></br>alternative service</p>
        </div>
      </div>

      <div className={classes.serviceCard} onClick={() => openModal(7)}>
        <img src={InspectionMob} alt="spark" />
        <div>Car Repair</div>
      </div>

      <div className={classes.serviceCard} onClick={() => openModal(2)}>
        <img src={SparkPlugsMob} alt="spark" />
        <div>Replace Spark Plugs</div>
      </div>

      <div className={classes.serviceCard} onClick={() => openModal(4)}>
        <img src={ACFilterMob} alt="ac_filter"/>
        <div>Replace A/C Filter</div>
      </div>

      <div className={classes.serviceCard} onClick={() => openModal(8)}>
        <img src={FrontBrakeMob} alt="front_brake"/>
        <div>Front Brakes + Disk Skimming</div>
      </div>

      <div className={classes.serviceCard} onClick={() => openModal(1)}>
        <img src={OtherServiceIconMob} alt="minor_service"/>
        <div>
          <h3>Minor service</h3>
          <p className={classes.mainP}>Just an Oil and Filter <br></br>change</p>
        </div>
      </div>

      <div className={classes.serviceCard} onClick={() => openModal(3)}>
        <img src={BatteryMob} alt="spark" />
        <div>Replace Battery</div>
      </div>

      <div className={classes.serviceCard} onClick={() => openModal(6)}>
        <img src={WipesMob} alt="wipes" />
        <div>Replace Wipers</div>
      </div>

      <div className={classes.serviceCard}  onClick={() => openModal(5)}>
        <img src={AirFilterMob} alt="spark"/>
        <div>Replace Air Filter</div>
      </div>

      <div className={classes.serviceCard} onClick={() => openModal(9)}>
        <img src={RearBrakeMob} alt="spark" />
        <div>Rear Brakes + Disk Skimming</div>
      </div>

      <div className={classNames(classes.getStarted, classes.hideMax890)}>
        <div>Try Us!</div>
        <ButtonComponent
          type="dark"
          title="Get Started"
          onClick={onRedirect}
          className={classes.button}
        />
      </div>
    </div>
  }
      <div
        className={classNames(classes.getStarted, classes.show890)}
        style={{ maxWidth: '300px', margin: '0 auto' }}
      >
        <div style={{ marginBottom: '12px' }}>Try Us!</div>
        <ButtonComponent
          type="dark"
          size="max"
          title="Get Started"
          onClick={onRedirect}
          className={classes.button}
        />
      </div>
      <Modal
        visible={isModal}
        onCancel={() => setIsModal(false)}
        width={1000}
        footer={null}
        title={
          <div className={classes.modalHeader}>
            Service details
          </div>
        }
      >
        <StaticModals onRedirect={onRedirect} setModal={setIsModal} serviceNumber={selectedService} buttonState={(windowSize > SwitchIconsWidth)}>

        </StaticModals>
      </Modal>
    </section>
  );
};
