import {
  AdminReviewsResponseType,
  OrderForAdminType,
  ResponseAdminAppointmentsType,
  ResponseCarForAdminType,
  ResponseNotificationsType,
  ResponseOrderAdminType,
} from '../../Types/adminTypes';
import { RequestInvoiceType } from '../../Types/workshopTypes';
import {
  PartType,
  ServicesForAdmin,
  UserType,
  UserTypeRequest,
  WorkShopType,
  WorkShopTypeRequest,
} from './../../Types/authTypes';
import { ActionTypes } from './actions';
import * as adminConstants from './constants';

export type InitialStateType = {
  workshopList: WorkShopTypeRequest | { count: 0; rows: [] };
  usersList: UserTypeRequest | { count: 0; rows: [] };
  services: ServicesForAdmin | null;
  parts: Array<PartType>;
  user: UserType | null;
  orders: ResponseOrderAdminType;
  order: OrderForAdminType | null;
  cars: ResponseCarForAdminType;
  appointments: ResponseAdminAppointmentsType;
  invoices: RequestInvoiceType<WorkShopType>;
  monthlyRevenue: number | null;
  notifications: ResponseNotificationsType | null;
  reviews: AdminReviewsResponseType;
};
const initialState: InitialStateType = {
  workshopList: {
    count: 0,
    rows: [],
  },
  usersList: {
    count: 0,
    rows: [],
  },
  services: null,
  parts: [],
  user: null,
  orders: {
    rows: [],
    count: 0,
  },
  order: null,
  cars: {
    rows: [],
    count: 0,
  },
  appointments: {
    rows: [],
    count: 0,
  },
  invoices: { count: 0, rows: [] },
  monthlyRevenue: null,
  notifications: null,
  reviews: {
    rows: [],
    count: 0,
  },
};

const adminReducer = (
  state: InitialStateType = initialState,
  action: ActionTypes
): InitialStateType => {
  switch (action.type) {
    case adminConstants.SET_WORKSHOP_LIST:
      return {
        ...state,
        workshopList: action.payload,
      };
    case adminConstants.SET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload,
      };
    case adminConstants.SET_SERVICES_FOR_ADMIN:
      return {
        ...state,
        services: action.payload,
      };
    case adminConstants.SET_PARTS:
      return {
        ...state,
        parts: action.payload,
      };
    case adminConstants.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case adminConstants.SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case adminConstants.UPDATE_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          rows: [
            ...state.orders.rows.filter(
              (order) => order.id !== action.payload.id
            ),
            action.payload,
          ].sort((a, b) => b.id - a.id),
        },
      };
    case adminConstants.UPDATE_INVOICE_PAYMENT_STATUS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          rows: [
            ...state.invoices.rows.map((invoice) => {
              if (invoice.id === action.payload.id) {
                return {
                  ...invoice,
                  workshop_payment_status: action.payload.status,
                };
              } else {
                return invoice;
              }
            }),
          ],
        },
      };
    case adminConstants.SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case adminConstants.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        order: null,
      };
    case adminConstants.SET_CUSTOMER_CARS:
      return {
        ...state,
        cars: action.payload,
      };
    case adminConstants.SET_UPDATED_CAR:
      return {
        ...state,
        cars: {
          count: state.cars.count,
          rows: state.cars.rows.map((car) => {
            if (car.id === action.payload.id) {
              return action.payload;
            } else {
              return car;
            }
          }),
        },
      };
    case adminConstants.SET_CUSTOMER_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      };
    case adminConstants.DELETE_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshopList: {
          count: state.workshopList.count - 1,
          rows: state.workshopList.rows.filter(
            (workshop) => workshop.id !== action.id
          ),
        },
      };
    case adminConstants.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        usersList: {
          count: state.usersList.count - 1,
          rows: state.usersList.rows.filter(
            (user) => user.id !== action.payload
          ),
        },
      };
    case adminConstants.TOGGLE_IS_BLOCKED_WORKSHOP:
      return {
        ...state,
        workshopList: {
          ...state.workshopList,
          rows: state.workshopList.rows.map((workshop) => {
            if (workshop.id === action.payload.id) {
              return {
                ...workshop,
                status: action.payload.isBlocked ? 'blocked' : 'approved',
              };
            } else {
              return workshop;
            }
          }),
        },
      };
    case adminConstants.TOGGLE_IS_BLOCKED_CUSTOMER:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          rows: state.usersList.rows.map((user) => {
            if (user.id === action.payload.id) {
              return {
                ...user,
                status: action.payload.isBlocked ? 'blocked' : 'active',
              };
            } else {
              return user;
            }
          }),
        },
      };
    case adminConstants.SET_ADMIN_MONTHLY_REVENUE:
      return {
        ...state,
        monthlyRevenue: action.payload.total_price,
      };
    case adminConstants.SET_ADMIN_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case adminConstants.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case adminConstants.SET_ADMIN_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };
    case adminConstants.DELETE_ADMIN_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: {
          count: state.reviews.count - 1,
          rows: state.reviews.rows.filter((review) => review.id !== action.id),
        },
      };
    default:
      return state;
  }
};

export default adminReducer;
