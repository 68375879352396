import React from 'react';
import classes from './styles.module.scss';

export const HowItWorks: React.FC = () => {
  return (
    <section className={classes.container} id="how-work">
      <h2>How to make a booking</h2>
      <div className={classes.infoWrapper}>
        <div className={classes.leftBlockWrapper}></div>
        <div className={classes.stepsWrapper}>
          <ul className={classes.stepper}>
            <li>
              <div className={classes.circle}>01</div>
              <div className={classes.descriptionWrapper}>
                <div className={classes.title}>Car & Service</div>
                <div className={classes.description}>
                Share your car’s details & select a service or ask for car repair
                </div>
              </div>
            </li>
            <li>
              <div className={classes.circle}>02</div>
              <div className={classes.descriptionWrapper}>
                <div className={classes.title}>Choose your workshop</div>
                <div className={classes.description}>
                  Compare prices, ratings & specializations of workshops
                </div>
              </div>
            </li>
            <li>
              <div className={classes.circle}>03</div>
              <div className={classes.descriptionWrapper}>
                <div className={classes.title}>Book an appointment</div>
                <div className={classes.description}>
                Tell us your preferred date & time. We offer free pickup & delivery
                </div>
              </div>
            </li>
            <li>
              <div className={classes.circle}>04</div>
              <div className={classes.descriptionWrapper}>
                <div className={classes.title}>Done</div>
                <div className={classes.description}>
                That’s it. Complete your payment & expect live job updates
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
