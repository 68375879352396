import React, { useEffect } from 'react';
import classes from './style.module.scss';
import { AddCarForm } from '../../../../Components/AddCar';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../../Stores';
import { userActions } from '../../../../Globals/User/actions';
import { CarDetailsFormType } from '../../../../Types/authTypes';
import classNames from 'classnames';
import Banner from '../../../../Assets/banner-add-car.png';

type CarDetailsStepPropsType = {
  goToNextStep: (step?: number) => void;
  currentStep: number;
  createNewCar?: (formValues: CarDetailsFormType) => void;
};

export const CarDetailsStep: React.FC<CarDetailsStepPropsType> = ({
  goToNextStep,
  currentStep,
  createNewCar
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  const makes = useSelector((state: RootStateType) => state.user.makes);

  // const dataLayer = window.dataLayer || []

  // useEffect(() => {
  //   //GTM
  //   //Start
  //   dataLayer.push({
  //     "path": "/createorder",
  //     "page_type":"carsdetailsStep",
  //     "event":"page_view"
  //   });
  //   //End
  // }, []);

  useEffect(() => {
    dispatch(userActions.getMakes());
  }, [dispatch]);
  return (
    <div className='content'>
      <div className={`${classes.contentContainer}`}>
      <h1 className='title'>Let’s Find You a Service</h1>
      <div
        className={classNames(classes.formWrapper, {
          [classes.formWrapperGuest]: currentUser?.role.type !== 'authenticated',
        })}
      >
        <AddCarForm
          goToNextStep={goToNextStep}
          makes={makes}
          currentStep={currentStep}
          createNewCar={createNewCar}
        />
        <img src={Banner} className={classes.banner} alt="carlogik models"/>
      </div>
    </div>
    </div>
  );
};
