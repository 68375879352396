import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import moment from 'moment';

import GarageIcon from '../../Assets/orderIcon/icon_at_workshop.svg';
import EmptyGarage from '../../Assets/orderIcon/icon_at_location.svg';

import { MainLayout } from '../../Components/MainLayout';
import classes from './style.module.scss';
import { TableComponent } from '../../Components/Table/Table';
import { TableConfigItem } from '../../Types/appTypes';

import { QueryObjectType } from '../../Types/authTypes';

import { RootStateType } from '../../Stores';
import { workshopActions } from '../../Globals/Workshop/actions';
import { RangeValue } from 'rc-picker/lib/interface';
import { useHistory } from 'react-router-dom';
const { RangePicker } = DatePicker;

const tableConfig: Array<TableConfigItem> = [
  {
    title: 'Date',
    key: 'appointment_date',
    isSort: true,
    align: 'left',
  },
  {
    title: 'Time',
    key: 'time',
    isSort: false,
    align: 'left',
  },
  {
    title: 'Order Number',
    key: 'order_id',
    isSort: false,
    align: 'right',
    isClickable: true,
  },
  {
    title: 'Customer',
    key: 'customer_name',
    isSort: false,
    align: 'left',
  },
  {
    title: 'Car',
    key: 'customer_car',
    isSort: false,
    align: 'left',
  }
];

const LIMIT_ON_PAGE = 20;

export const WorkshopAppointments = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(LIMIT_ON_PAGE);
  const [sortDate, setSortDate] = useState<Moment | null>(null);
  const [sortRangeDate, setSortRangeDate] = useState<Array<string> | null>(
    null
  );
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([{ appointment_date: 'DESC' }]);
  const [sortStatus, setSortStatus] = useState<
    'paid' | 'not_paid' | 'pending' | null
  >(null);

  const appointmentList = useSelector(
    (state: RootStateType) => state.workshop.appointments
  );

  const dataLayer = window.dataLayer || []

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const onDataChange = (value: Moment | null, dateString: string) => {
    setSortDate(value);
  };

  const onChangeRange = (
    values: RangeValue<Moment>,
    formatString: [string, string]
  ) => {
    if (formatString) {
      if (formatString[0] === '') {
        setSortRangeDate(null);
      } else {
        setSortRangeDate(formatString);
      }
    }
  };

  const onSort = (fieldKey: string, order?: 'ascend' | 'descend') => {
    if (fieldKey === 'status') {
      if (order === 'ascend') {
        setSortValue([{ blocked: 'ASC' }]);
      } else {
        setSortValue([{ unblocked: 'ASC' }]);
      }
    } else {
      setSortValue(
        order
          ? [{ [fieldKey]: order === 'ascend' ? 'ASC' : 'DESC' }]
          : fieldKey === 'appointment_date'
          ? [{ appointment_date: 'DESC' }]
          : []
      );
    }
  };

  const generateQuery = useCallback(
    (nextPage: number): QueryObjectType => {
      const obj = {
        page: {
          page: nextPage,
          limit: limitOnPage,
        },
        filter: [],
        sort: sortValue,
      };
      return {
        ...obj,
        ...((sortRangeDate || sortDate) && {
          filter: [
            ...obj.filter,
            {
              appointment_date_gte:
                moment(sortRangeDate ? sortRangeDate[0] : sortDate).format(
                  'YYYY-MM-DD'
                ) + 'T00:00:00.000Z',
            },
            {
              appointment_date_lt:
                moment(sortRangeDate ? sortRangeDate[1] : sortDate)
                  .add(1, 'day')
                  .format('YYYY-MM-DD') + 'T00:00:00.000Z',
            },
          ],
        }),
      };
    },
    [limitOnPage, sortStatus, sortValue, sortDate, sortRangeDate]
  );

  const addTime = (startTime: string, minutes: number) => {
    return moment(startTime).add(minutes, 'm').format('hh:mm A');
  };

  // const getLocation = (
  //   at_location: boolean | null,
  //   at_workshop: boolean | null
  // ) => {
  //   if (at_location) {
  //     return (
  //       <div className={classes.location}>
  //         <img src={EmptyGarage} alt="at location" />
  //         <p>at Location</p>
  //       </div>
  //     );
  //   } else if (at_workshop) {
  //     return (
  //       <div className={classes.location}>
  //         <img src={GarageIcon} alt="at workshop" />
  //         <div>at Workshop</div>
  //       </div>
  //     );
  //   } else {
  //     return;
  //   }
  // };

  const createDataList = () => {
    if (appointmentList.rows.length) {
      return appointmentList.rows.map((invoice) => {
        return {
          key: invoice.id,
          order_id: invoice.id,
          appointment_date: moment(invoice.appointment_date).format(
            'MMM DD YYYY'
          ),
          time: `${moment(invoice.appointment_date).format(
            'hh:mm A'
          )} - ${addTime(
            invoice.appointment_date,
            invoice.total_hour_time * 60 + invoice.total_min_time
          )}`,
          customer_name: invoice.user.username,
          customer_car: `${invoice.customer_car?.year} ${invoice.customer_car?.make.title} ${invoice.customer_car?.model.title}`,
          //location: getLocation(invoice.at_location, invoice.at_workshop),
        };
      });
    } else {
      return [];
    }
  };

  const redirectTo = (id: number) => {
    history.push(`/workshop/jobs/${id}?is-start-job=false`);
  };

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/workshop/appointments",
      "page_type":"workshopAppointmentsPage",
      "event":"page_view"
    });
    //End
  }, []);

  useEffect(() => {
    dispatch(
      workshopActions.getWorkshopAppointments(generateQuery(currentPage))
    );
  }, [currentPage, limitOnPage, dispatch, sortValue, generateQuery]);

  return (
    <MainLayout headerTitle="Appointments">
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.filtersContainer}>
            <DatePicker
              onChange={onDataChange}
              picker="date"
              size="large"
              className={classes.dataPicker}
              disabled={!!sortRangeDate?.length}
            />
            <RangePicker
              className={classes.rangePicker}
              onChange={onChangeRange}
              disabled={!!sortDate}
            />
          </div>
        </div>
        <TableComponent
          dataForTableList={createDataList()}
          tableConfig={tableConfig}
          totalItems={appointmentList.count}
          onChangePage={onChangePage}
          isLastColumn={false}
          onSort={onSort}
          goTo={redirectTo}
        />
      </div>
    </MainLayout>
  );
};
