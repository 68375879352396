export const OPEN_LOADER = 'CLOSE_LOADER';
export const CLOSE_LOADER = 'OPEN_LOADER';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const ADD_ALERT_MESSAGE = 'app/ADD_ALERT_MESSAGE'
export const DELETE_ALERT_MESSAGE = 'app/DELETE_ALERT_MESSAGE'
export const RESET_ALERTS = 'app/RESET_ALERTS'

export const SHOW_CONFIRM_WINDOW = 'app/SHOW_CONFIRM_WINDOW'
export const HIDE_CONFIRM_WINDOW = 'app/HIDE_CONFIRM_WINDOW'

export const GET_WORKSHOPS_FOR_LENDING = 'app/GET_WORKSHOPS_FOR_LENDING'
export const SET_WORKSHOPS_FOR_LENDING = 'app/SET_WORKSHOPS_FOR_LENDING'

export const GET_SELECTED_WORKSHOP_BY_ID = 'app/GET_SELECTED_WORKSHOP_BY_ID'
export const SET_SELECTED_WORKSHOP = 'app/SET_SELECTED_WORKSHOP'
export const RESET_SELECTED_WORKSHOP = 'app/RESET_SELECTED_WORKSHOP'

export const GET_REVIEWS_FOR_WORKSHOP = 'app/GET_REVIEWS_FOR_WORKSHOP'
export const SET_REVIEWS_FOR_WORKSHOP = 'app/SET_REVIEWS_FOR_WORKSHOP'

export const GET_RECEIPT_INVOICE = 'app/GET_RECEIPT_INVOICE'