import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, DatePicker, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { MainLayout } from '../../Components/MainLayout';
import classes from './style.module.scss';
import { TableComponent } from '../../Components/Table/Table';
import { RootStateType } from '../../Stores';
import { QueryObjectType, WorkShopType } from '../../Types/authTypes';
import { TableConfigItem } from '../../Types/appTypes';
import { adminActions } from '../../Globals/Admin/actions';
import { InvoiceType } from '../../Types/workshopTypes';
import { useHistory } from 'react-router-dom';
import { appApi } from '../../Globals/App/services';
import classNames from 'classnames';
import { appActions } from '../../Globals/App/actions';
import useConfirm from '../../Hooks/useConfirm';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
const { Option } = Select;

const tableConfig: Array<TableConfigItem> = [
  {
    title: 'Workshop',
    key: 'workshop.name',
    isSort: true,
    isClickable: false,
    suffixIcon: 'download',
  },
  {
    title: 'Invoice ID',
    key: 'invoice_id',
    isSort: false,
    align: 'right',
  },
  {
    title: 'Order ID',
    key: 'order_id',
    isSort: false,
    align: 'right',
    isClickable: true,
  },
  {
    title: 'Date & Time',
    key: 'created_at',
    isSort: true,
    align: 'right',
  },
  {
    title: 'Payment status',
    key: 'payment_status',
    isSort: true,
    align:'center'
  },
  {
    title: 'Payment',
    key: 'isTabby',
    isSort: true,
    align: 'center',
  },
  {
    title: 'Payment Receipt Amount',
    key: 'total_price',
    isSort: true,
    align: 'right',
  },
  {
    title: 'Workshop Payment',
    key: 'workshop_amount',
    isSort: true,
    align: 'right',
  },
  {
    title: 'Paid',
    key: 'is_paid',
    isSort: false,
    align: 'right',
  },
  {
    title: 'Delivery Payment',
    key: 'delivery_amount',
    isSort: true,
    align: 'right',
  },
  {
    title: 'CarLogik Commission',
    key: 'payable_amount',
    isSort: true,
    align: 'right',
  },
  {
    title: 'WSH receipt',
    key: 'wsh_receipt',
    isSort: false,
    align: 'center',
  },
  {
    title: 'Payment receipt',
    key: 'payment_receipt',
    isSort: false,
    align: 'center',
  },
];

const LIMIT_ON_PAGE = 20;

export const AdminInvoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(LIMIT_ON_PAGE);
  const [sortDate, setSortDate] = useState<Moment | null>(null);
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([{ created_at: 'DESC' }]);
  const [sortStatus, setSortStatus] = useState<
    'paid' | 'not_paid' | 'pending' | null
  >(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { confirm } = useConfirm();

  const monthlyRevenue = useSelector(
    (state: RootStateType) => state.admin.monthlyRevenue
  );

  const invoicesList = useSelector(
    (state: RootStateType) => state.admin.invoices
  );

  const onChangeSortStatus = (e: 'paid' | 'not_paid' | 'pending' | '') => {
    if (e) {
      setSortStatus(e);
    } else {
      setSortStatus(null);
    }
  };

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const onCheckboxHandler = async (
    e: CheckboxChangeEvent,
    invoiceId: number,
    paymentAmount: number
  ) => {
    let isConfirmed: boolean;
    if (e.target.checked) {
      isConfirmed = await confirm(
        `Yes, I’ve already paid to workshop AED ${paymentAmount}`,
        ['Not yet ))', 'Confirm']
      );
    } else {
      isConfirmed = await confirm(
        `Yes, I want to cancel AED ${paymentAmount} payment  to workshop`,
        ['Leave as it is', 'Cancel payment']
      );
    }
    if (!isConfirmed) {
      return;
    }
    dispatch(
      adminActions.changePaymentStatus(
        invoiceId,
        e.target.checked ? 'paid' : 'not_paid'
      )
    );
  };

  const onSort = (fieldKey: string, order?: 'ascend' | 'descend') => {
    if (fieldKey === 'status') {
      if (order === 'ascend') {
        setSortValue([{ blocked: 'ASC' }]);
      } else {
        setSortValue([{ unblocked: 'ASC' }]);
      }
    } else {
      setSortValue(
        order
          ? [{ [fieldKey]: order === 'ascend' ? 'ASC' : 'DESC' }]
          : [{ created_at: 'DESC' }]
      );
    }
  };

  const redirectTo = (id: number) => {
    history.push(`/admin/orders/${id}`);
  };

  const generateQuery = useCallback(
    (nextPage: number): QueryObjectType => {
      const obj = {
        page: {
          page: nextPage,
          limit: limitOnPage,
        },
        filter: sortStatus ? [{ payment_status: sortStatus }] : [],
        sort: sortValue,
      };
      return {
        ...obj,
        ...(sortDate && {
          filter: [
            ...obj.filter,
            {
              job_end_date_gte:
                sortDate?.startOf('month').format('YYYY-MM-DD') +
                'T00:00:00.000Z',
            },
            {
              job_end_date_lt:
                sortDate
                  .clone()
                  .endOf('month')
                  .add(1, 'day')
                  .format('YYYY-MM-DD') + 'T00:00:00.000Z',
            },
          ],
        }),
      };
    },
    [limitOnPage, sortStatus, sortValue, sortDate]
  );

  const onDataChange = (value: Moment | null, dateString: string) => {
    setSortDate(value);
  };

  const createDataList = () => {
    if (invoicesList.rows.length) {
      return invoicesList.rows.map((invoice: InvoiceType<WorkShopType>) => {
        return {
          key: invoice.id,
          'workshop.name': invoice.workshop.name,
          invoice_id: (<div style={{width: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{invoice.payment_id}</div>),
          order_id: invoice.id,
          created_at:
            invoice.job_end_date &&
            moment(invoice.job_end_date).format('MMM DD, YYYY'),
          total_price: invoice.total_price.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          }),
          payable_amount: invoice.payable_amount.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          }),
          workshop_amount: invoice.workshop_amount.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          }),
          delivery_amount: invoice.delivery_amount?.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          }), 
          isTabby: invoice.isTabby,
          is_paid: (
            <Checkbox
              className="green"
              checked={invoice.workshop_payment_status === 'paid'}
              onChange={(e) => {
                onCheckboxHandler(e, invoice.id, invoice.workshop_amount);
              }}
            />
          ),
          payment_status: invoice.payment_status,
          wsh_receipt: (
            <DownloadOutlined
              className={classNames(classes.downloadIcon, {
                [classes.disabledIcon]: !invoice.invoice?.url,
              })}
              onClick={() => {
                getInvoice(invoice.id);
              }}
            />
          ),
          payment_receipt: (
            <DownloadOutlined
              className={classNames(classes.downloadIcon, {
                [classes.disabledIcon]: !invoice.payment_id,
              })}
              onClick={() => {
                getReceiptInvoice(invoice.id);
              }}
            />
          ),
          //link: invoice.invoice?.url,
        };
      });
    } else {
      return [];
    }
  };

  const getInvoice = (key: number) => {
    const selectedInvoice = invoicesList.rows.find(
      (invoice) => invoice.id === key
    );
    appApi.downloadFile(
      selectedInvoice?.invoice?.url!,
      selectedInvoice?.invoice.name
    );
  };

  const getReceiptInvoice = async (id: number) => {
    dispatch(
      appActions.getReceiptInvoice({ address: '/load-admin-invoice/', id })
    );
  };

  useEffect(() => {
    dispatch(adminActions.getInvoices(generateQuery(currentPage)));
  }, [currentPage, limitOnPage, dispatch, sortValue, generateQuery]);

  useEffect(() => {
    dispatch(adminActions.getMonthlyRevenue());
  }, [dispatch]);

  return (
    <MainLayout headerTitle="Invoices">
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.filtersContainer}>
            <div className={classes.statusContainer}>
              <div className={classes.label}>Filter by</div>
              <Select
                placeholder="Status"
                className={classes.statusFilter}
                size="large"
                onChange={onChangeSortStatus}
              >
                <Option value="paid">Paid</Option>
                <Option value="pending">Pending</Option>
                <Option value="not_paid">Not Paid</Option>
                <Option value="">All</Option>
              </Select>
            </div>
            <DatePicker
              onChange={onDataChange}
              picker="month"
              size="large"
              className={classes.dataPicker}
            />
          </div>
          <div className={classes.totalMonth}>
            <span>Current Month Total: </span>
            <span>AED {monthlyRevenue ?? 0}</span>
          </div>
        </div>
        <TableComponent
          dataForTableList={createDataList()}
          tableConfig={tableConfig}
          totalItems={invoicesList.count}
          goTo={redirectTo}
          onChangePage={onChangePage}
          onDownloadClick={getInvoice}
          onSort={onSort}
          isLastColumn={false}
        />
      </div>
    </MainLayout>
  );
};
