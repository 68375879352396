import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './style.module.scss';
import { TableComponent } from '../../Components/Table/Table';
import { MainLayout } from '../../Components/MainLayout';
import { adminActions } from '../../Globals/Admin/actions';
import { RootStateType } from '../../Stores';
import { OrderForAdminType } from '../../Types/adminTypes';
import { CarResponseType } from '../../Types/authTypes';
import { useHistory } from 'react-router-dom';

const LIMIT_ON_PAGE = 20;

const tableConfig = [
  { title: 'Order ID', key: 'id', isSort: true, isClickable: true },
  { title: 'Full Name', key: 'user.username', isSort: true },
  { title: 'Address', key: 'address', isSort: false },
  { title: 'Services', key: 'services', isSort: false },
  { title: 'Payment', key: 'isTabby', isSort: false },
  { title: 'Car', key: 'car', isSort: false },
  { title: 'Status', key: 'status', isSort: true },
  { title: 'Workshop', key: 'workshop.name', isSort: true },
];

export const AdminOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(LIMIT_ON_PAGE);
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([{ id: 'DESC' }]);

  const dispatch = useDispatch();
  const history = useHistory();

  const ordersList = useSelector((state: RootStateType) => state.admin.orders);

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const redirectTo = (id: number) => {
    history.push(`/admin/orders/${id}?is-start-job=true`);
  };

  const onSort = (fieldKey: string, order?: 'ascend' | 'descend') => {
    if (false) {
      if (order === 'ascend') {
        setSortValue([{ approved: 'ASC' }]);
      } else {
        setSortValue(order ? [{ approved: 'DESC' }] : []);
      }
    } else {
      setSortValue(
        order
          ? [{ [fieldKey]: order === 'ascend' ? 'ASC' : 'DESC' }]
          : fieldKey === 'id'
          ? [{ id: 'DESC' }]
          : []
      );
    }
  };

  const onAction = (data: { id: number; action: string }) => {
    if (data.action === 'Accept order') {
      dispatch(adminActions.changeOrderStatus(data.id, 'accepted'));
    } else if (data.action === 'Decline order') {
      dispatch(adminActions.changeOrderStatus(data.id, 'declined'));
    }
  };

  const carToString = (car: CarResponseType) => {
    return `${car.year}  ${car.make?.title} ${car.trim?.title}`;
  };

  useEffect(() => {
    dispatch(
      adminActions.getOrders({
        page: { page: currentPage, limit: limitOnPage },
        sort: sortValue,
      })
    );
  }, [currentPage, dispatch, limitOnPage, sortValue]);

  const createDataList = () => {
    if (ordersList.rows.length) {
      return ordersList.rows.map((order: OrderForAdminType) => {
        return {
          key: order.id,
          id: order.id,
          'user.username': order.user.username,
          address:  (<div style={{width: '180px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{order.client_address}</div>),
          services: [
            ...order.order_services.additional.map((item) => item.title),
            ...order.order_services.log_book.map((item) => item.title),
          ],
          isTabby: order.isTabby,
          car: carToString(order.customer_car),
          status: order.status == 'finished' ? (order.finished_by_admin ? 'finished_by_admin' : 'finished') : order.status,
          actions:
            order.status === 'pending' ? ['Accept order', 'Decline order'] : [],
          'workshop.name': order.workshop.name,
        };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
  dispatch(adminActions.deleteOrderSuccess())
  })

  return (
    <MainLayout headerTitle="Settings">
      <div className={classes.contentContainer}>
        <TableComponent
          dataForTableList={createDataList()}
          tableConfig={tableConfig}
          totalItems={ordersList.count}
          onChangePage={onChangePage}
          goTo={redirectTo}
          onSort={onSort}
          onAction={onAction}
        />
      </div>
    </MainLayout>
  );
};
