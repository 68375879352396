import { RootStateType } from '../../Stores';

export const getYears = (state: RootStateType) => state.user.year;

export const getTrimId = (state: RootStateType) =>
  state.user.formValues?.trim?.id;
export const getIntervalId = (state: RootStateType) =>
  state.user.formValues?.milliage?.id;
  export const getWorkshopId = (state: RootStateType) =>
  state.user.selectedWorkshop?.id;
  export const isBasic = (state: RootStateType) => state.user.isBasicSelected;
  export const getAdditionalTasksId = (state: RootStateType) =>
  state.user.selectedOtherServicesId;
  // export const getCarRepairId = (state: RootStateType) =>
  // state.user.selectedCarRepairId;
export const getModels = (state: RootStateType) => state.user.model; 
export const getModelsByTrim = (state: RootStateType) =>
  state.user.formValues?.trim?.model;  
export const location = (state: RootStateType) => state.user.location;

export const getOrders = (state: RootStateType) => state.user.orders;





export const getOrderData = (state: RootStateType) => state.user.orderData;
