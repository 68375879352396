import { Carousel } from 'antd';
import React, { Component, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import Typed from 'react-typed';

const ArrayOfTitles = ['Car Service, Simplified!','Easy Online Booking','Honest Upfront Prices','Compare and Save','Auto Repair Marketplace']

var settings = {
  dots: false,
  infinite: true,
  speed: 600,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: true
};

type TitlePropsType = {
  setImageVisible: (path: any) => void;
};

export const Title: React.FC<TitlePropsType> = ({setImageVisible}) => {
  const [ContentIndex,setContentIndex] = useState(1)
  let ref = useRef<any | null>(null)
  const [isStopped, setIsStopped] = useState(false)
  useEffect(() => {
    return () => {
      ref.current.destroy()
    }
  },[])

  return(
    <div className={classes.titleBox}>
      <Typed
        strings={ArrayOfTitles}
        typeSpeed={50}
        backSpeed={60}
        backDelay={2000}
        className={classes.titleCursor}
        loop
        typedRef={(typed:any) => {ref.current = typed }}
        preStringTyped={(e:any) => {
          setImageVisible((prev:any) => !prev)
        }}
        onDestroy={(e:any) => {
        }}
        stopped={isStopped}
        >
          <span className={classes.title}></span>
        </Typed>
    </div>
  );
}

export default Title;