import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Tabs, Select } from 'antd';
import { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { MainLayout } from '../../Components/MainLayout';
import { Requests } from './Requests';
import { Orders } from './Orders';
import classes from './style.module.scss';
import { workshopActions } from '../../Globals/Workshop/actions';
import { QueryObjectType, StatusType } from '../../Types/authTypes';
import { RootStateType } from '../../Stores';

const { TabPane } = Tabs;
const { Option } = Select;

const LIMIT_ON_PAGE = 4;

export const WorkshopJobs = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('1');
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<Array<StatusType>>(['pending']);
  const [date, setDate] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([]);

  const orders = useSelector(
    (state: RootStateType) => state.workshop.orders.rows
  );
  
  const dataLayer = window.dataLayer || []

  const onChangeDate = (date: Moment | null) => {
    if (date) {
      setDate([
        date.format('YYYY-MM-DD')!,
        date.clone().add(1, 'day').format('YYYY-MM-DD')!,
      ]);
    } else {
      setDate([]);
    }
  };

  const onChangeStatus = (value: StatusType | 'all') => {
    if (value === 'all') {
      setStatus([]);
    } else {
      setStatus([value]);
    }
  };

  const generateQuery = useCallback(
    (nextPage: number): QueryObjectType => {
      const obj = {
        page: {
          page: nextPage,
          limit: LIMIT_ON_PAGE,
        },
        sort: sortValue,
      };
      return {
        ...obj,
        ...(status.length && { status }),
        ...(date.length ? { date } : null),
      };
    },
    [date, status, sortValue]
  );

  const loadMore = useCallback(() => {
    setLoading(true);
    setPage((prev) => prev + 1);
    const nextPage = page + 1;
    dispatch(workshopActions.getOrders(generateQuery(nextPage)));
  }, [dispatch, generateQuery, page]);

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/workshop/jobs",
      "page_type":"workshopJobsPage",
      "event":"page_view"
    });
    //End
  }, []);

  useEffect(() => {
    if (activeTab === '1') {
      setStatus(['pending']);
      setSortValue([{ created_at: 'DESC' }]);
    } else {
      setStatus([]);
      setSortValue([]);
    }
    return () => {
      setPage(1);
    };
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    setPage(1);
    dispatch(workshopActions.resetOrders());
    const page = 1;
    dispatch(workshopActions.getOrders(generateQuery(page)));
  }, [status, date, dispatch, generateQuery]);

  useEffect(() => {
    setLoading(false);
  }, [orders]);

  return (
    <MainLayout headerTitle="Jobs" isHorizontalPadding={false}>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={setActiveTab}
        className={classes.tabs}
        style={{ border: 'none', position: 'relative' }}
      >
        <TabPane tab="Requests" key="1">
          <Requests loadMore={loadMore} loading={loading} />
        </TabPane>
        <TabPane tab="Orders" key="2">
          <div className={classes.date}>
            <DatePicker
              className={classes.datePicker}
              onChange={onChangeDate}
            />
            <div style={{ display: 'flex' }}>
              <p className={classes.filterText}>Filter by:</p>
              <Select
                style={{ width: '149px' }}
                placeholder="Status:"
                onChange={onChangeStatus}
              >
                <Option value="accepted">Accepted</Option>
                <Option value="declined">Declined</Option>
                <Option value="expired">Expired</Option>
                <Option value="finished">Finished</Option>
                <Option value="processing">Processing</Option>
                <Option value="canceled">Canceled</Option>
                <Option value="all">All</Option>
              </Select>
            </div>
          </div>
          <Orders loadMore={loadMore} loading={loading} />
        </TabPane>
      </Tabs>
    </MainLayout>
  );
};
