import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import classes from './styles.module.scss';
import WhiteLogo from '../../../../Assets/Landing/logo-white.svg';
import InstagramIcon from '../../../../Assets/Landing/Icon_Instagram.svg';
import FacebookIcon from '../../../../Assets/Landing/Icon_facebook.svg';
import LinkedinIcon from '../../../../Assets/Landing/Icon_linkedin.svg';
import TikTokIcon from '../../../../Assets/Landing/Icon_tik-tok.svg';
import Sertification from '../../../../Assets/Landing/SertificationCoin.svg';

export const Footer: React.FC = () => {
  return (
    <footer className={classes.container} id="footer">
      <div className={classes.contentWrapper}>
        <div className={classes.header}>
          <div className={classes.leftBlock}>
            <img src={WhiteLogo} alt="logo" />
          </div>
          <div className={classes.rightBlock}>
            <p>CarLogik in Socials</p>
            <a href="https://www.instagram.com/carlogikdxb/" target="_blank">
              <img src={InstagramIcon} alt="instagram" />
            </a>
            <a href="https://www.facebook.com/Carlogikdxb-106858075323708" target="_blank">
              <img src={FacebookIcon} alt="facebook" />
            </a>
            <a href="https://www.linkedin.com/company/carlogikdxb/"  target="_blank">
              <img src={LinkedinIcon} alt="linkedin" />
            </a>
            <a href="https://www.tiktok.com/@carlogikdxb"  target="_blank">
              <img src={TikTokIcon} alt="tiktok" />
            </a>
          </div>
        </div>
        <div className={classes.phone}>
          <div>800 LOGIK</div>
          <div>56445</div>
        </div>
        <div className={classes.linkContainer}>
          <div className={classes.leftGroup}>
            <ul>
              <li>
                <NavHashLink
                  to="/privacy-policy#"
                  activeClassName={classes.active}
                >
                  Privacy Policy
                </NavHashLink>
              </li>
              <li>
                <NavHashLink
                  to="/terms-conditions#"
                  activeClassName={classes.active}
                >
                  Terms and Conditions
                </NavHashLink>
              </li>
              <li>
                <NavHashLink to="/community#" activeClassName={classes.active}>
                  Community Guideline
                </NavHashLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavHashLink to="/faq#" activeClassName={classes.active}>
                  FAQs
                </NavHashLink>
              </li>
              <li>
                <NavHashLink to="/about-carlogik#" activeClassName={classes.active}>
                  About CarLogik
                </NavHashLink>
              </li>
            </ul>
          </div>
          <div className={classes.sertification}>
            <img src={Sertification} alt="Sertification" />
          </div>
          <div className={classes.email}>
            <div className={classes.link}>customer@carlogik.com</div>
            <div>
              Car Logik FZCO, <br />
              8th Floor, The Offices 4, One Central, <br />
              Dubai, United Arab Emirates.
            </div>
          </div>
        </div>
      </div>
      <div className={classes.copyright}>Copyright 2022 CarLogik</div>
    </footer>
  );
};
