import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, DatePicker, Input ,Select, Calendar, Button, Radio, Carousel} from 'antd';
import { FieldTimeOutlined, EnvironmentOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import classes from './style.module.scss';
import { ButtonComponent } from '../../../../../../Components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../../../Globals/User/actions';
import { RootStateType } from '../../../../../../Stores';
import { DeliveryTime } from '../../Components/DeliveryTime';
import cn from 'classnames';
import { SelectValue } from 'antd/lib/select';
import { PickUp } from '../DropOff';
import { ArrowRightOutlined, ArrowLeftOutlined} from '@ant-design/icons';
//import { Calendar } from './Calendar';
import {
  CarDeliveriesType,
  OrderDataForAppointment,
} from '../../../../../../Types/userTypes';
import { TermsList } from '../../../../../LandingPage/Views/TermsConditions/TermsList';
import { weekDayType } from '../../../../../../Types/appTypes';

const { TextArea } = Input;

type AppointmentFormPropsType = {
  //goToNextStep: () => void;
  //goToPrevStep: () => void;
  onCancel: () => void;
  onFinish: (values: { date: Moment; time: string }) => void;
  type?: 'customer' | 'steps';
  data?: OrderDataForAppointment;
  defaultValues?: { date: any; time: any };
  carDeliveries: CarDeliveriesType[];
  reschedule?: boolean;
};

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cn(className, classes.arrow)}
      style={{
        ...style,
        left: '0px',
      }}
      onClick={onClick}
    >
      <ArrowLeftOutlined />
    </div>
  );
}
function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cn(className, classes.arrow, classes.arrowRight)}
      style={{
        ...style,
        right: '6px',
      }}
      onClick={onClick}
    >
      <div className={classes.rightArrow}> 
      dasdfsfaf
        <ArrowRightOutlined className={classes.arrows} style={{color: 'black'}}/>
      </div>
    </div>
  );
}

var settings = {
  dots: false,
  infinite: false,
  speed: 750,
  //autoplaySpeed: 5000,
  slidesToShow: 7,
  slidesToScroll: 7,
  initialSlide: 0,
  draggable: true,
  arrows: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
};

const { Option } = Select;

export const AppointmentForm: React.FC<AppointmentFormPropsType> = ({
  //goToNextStep,
  //goToPrevStep,
  onCancel,
  onFinish,
  type = 'steps',
  data,
  defaultValues,
  carDeliveries,
  reschedule = true
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  
  const Moment = require("moment")
  const MomentRange = require("moment-range")
  const moment = MomentRange.extendMoment(Moment); /*add plugin to moment instance*/ 

  const [startDate, setStartDate] = useState(new Date(moment().format('ddd MMMM D YYYY')));
  const [endDate, setEndDate] = useState(new Date(moment().add(20, 'days').format('ddd MMMM D YYYY')));
  const [calendarArray, setCalendarArray] = useState<Array<any>>([])
  const [weeksArray, setWeeksArray] = useState<Array<any>>([])
  const [availableWeek, setAvailableWeek] = useState<number>(0)
  const [selectedWeek, setSelectedWeek] = useState<number>(0)


 //const [myAddress, setMyAddress] = useState<PointType>();
  const [PickUpId, setPickUpId] = useState<number>(1);
  const [DropOffId, setDropOffId] = useState<number>(1);
  const [animateGo, setAnimateGo] = useState<{}>();

  const [timeStart, setTimeStart] = useState<string>();
  const [dateStart, setDateStart] = useState<Moment>();

  const [isSelectedDate, setIsSelectedDate] = useState(false);
  const [isSelectedTime, setIsSelectedTime] = useState(false);

  const timeSlots = useSelector(
    (state: RootStateType) => state.user.appointment?.time_slots
  );

  const SelectedWorkshopPrice = useSelector(
    (state: RootStateType) => state.user.selectedWorkshop?.total_vat_price
  );

  const deliveredPickUp = useSelector(
    (state: RootStateType) => state.user.deliveryCar.pick_up_slot
  );
  const deliveredDropOff = useSelector(
    (state: RootStateType) => state.user.deliveryCar.drop_off_slot
  );
  
  const appointment = useSelector(
    (state: RootStateType) => state.user.appointment
  );

  const availableDays = useSelector(
    (state: RootStateType) => state.user.availableDays
  );

  const deliveryProperties = useSelector(
    (state: RootStateType) => state.user.car_deliveries
  );

  const timeStartSelected = useSelector(
    (state: RootStateType) => state.user.selectedDate?.startTime
  )

  const onChangeDate = (value: Moment, disabled: boolean, index: number) => {
    form.resetFields(['time']);
    setAvailableWeek(index)
    setSelectedWeek(index)
    if (!disabled && value && data) {
      dispatch(
        userActions.getAppointment({
          date: value.format('YYYY MM DD'),
          ...data,
        })
      );
      setDateStart(value)
      setIsSelectedDate(true)
      dispatch(userActions.setDeliveryPickUp(1));
      dispatch(userActions.setDeliveryDropOff(1));
      setIsSelectedTime(false)
    }
    else{ 
      setIsSelectedDate(false)
      setIsSelectedTime(false)
    }
  };

  const onTimeChange = (value: SelectValue) => {
    setTimeStart(value?.toString());
    setIsSelectedTime(true)
  };

  const isDayAvailable = <T extends Record<weekDayType, boolean>>(
    key: weekDayType,
    availableDays: T | null
  ): boolean => {
    if (availableDays) {
      return !availableDays[key];
    } else {
      return true;
    }
  };
  
  // const onSubmit = () => {
  //   if (!isPickUp) {
  //     if (myAddress) {
  //       if (selectedLocation) {
  //         goToNextStep();
  //       } else {
  //         dispatch(
  //           appActions.addAlertMessage({
  //             message: 'You should select location',
  //             type: 'error',
  //           })
  //         );
  //       }
  //     }
  //   } else if (isPickUp) {
  //     setIsPickUp(false);
  //   }
  // };

  // const onCancel = () => {
  //   if (isPickUp) {
  //     goToPrevStep();
  //     dispatch(userActions.setSelectedLocation(null));
  //     dispatch(userActions.removeDeliveryCar('pickUp'));
  //     dispatch(userActions.removeDeliveryCar('dropOff'));
  //   } else {
  //       setIsPickUp(true);
  //   }
  // };
  const selectDateAppointment = () =>{
    let values ={
      date: dateStart!,
      time: timeStart!
    }
    onFinish(values)
  }
  const setAdressPoint = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(userActions.setClientAddress(event.target.value))
  };

  const onSelectPickUp = (typeOfDelivery: number, type: 'pickUp' | 'dropOff') => {
    setAnimateGo({})
    let bufferPickUpId = PickUpId
    let bufferDropOffId = DropOffId
    if (type == 'pickUp') {
      setPickUpId(typeOfDelivery)
      bufferPickUpId = typeOfDelivery
      dispatch(userActions.setDeliveryPickUp(typeOfDelivery));
    } else {
      setDropOffId(typeOfDelivery)
      bufferDropOffId = typeOfDelivery
      dispatch(userActions.setDeliveryDropOff(typeOfDelivery));
    }
    if(bufferDropOffId == 1 && bufferPickUpId == 1){
      dispatch(userActions.setClientAddress(''))
    }
    // console.log(SelectedWorkshopPrice , deliveryProperties[0].delivery_types[PickUpId - 1].price)
    if(SelectedWorkshopPrice)
    dispatch(userActions.setOrderSummary(SelectedWorkshopPrice + Number(deliveryProperties[0].delivery_types[bufferPickUpId - 1].price) + Number(deliveryProperties[1].delivery_types[bufferDropOffId - 1].price)))
  };

  useEffect(() => {
    if(SelectedWorkshopPrice)
    dispatch(userActions.setOrderSummary(SelectedWorkshopPrice))

    dispatch(userActions.removeDeliveryCar());
    if (defaultValues) {
      form.setFieldsValue({
        date: defaultValues.date,
        time: defaultValues.time,
      });
    }
  }, [defaultValues, form]);

  useEffect(() => {
    setCalendarArray(Array.from(moment().range(startDate,endDate).by("days")).map((date:any, index) => {
      if(index == 0){
        return(
          {    
            key: date,
            date: date.format('ddd D').split(' '),
            disabled: true
          }
        )
      }
      return(
          {    
              key: date,
              date: date.format('ddd D').split(' '),
              disabled: isDayAvailable(date.format('ddd').toLowerCase() as keyof typeof availableDays,availableDays)
          }
      );
    }))
  },[availableDays])

  useEffect(() => {
    let newWeek = 
    Array.from(moment().range(startDate,endDate).by("days")).map((date:any, index) => {
      return(
        {
          month: date.format('MMMM'),
          year: date.format('YYYY')
        }
      )
    })
    // setWeeksArray(newWeek.filter(function(number) {
    //   return number != undefined;
    // }))
    setWeeksArray(newWeek)
  },[])
  return (
    <div
      className={cn(classes.container, {
        [classes.notMargin]: type === 'customer',
      })}
    >
      <h2
        className={cn(classes.header, {
          [classes.primaryColor]: type === 'customer',
        })}
      >
        Select your preferred appointment
      </h2>
      <span className={classes.subtitle}>
        Estimated service time:{' '}
        {appointment?.estimate.hours && appointment?.estimate.hours + ' h'}{' '}
        {appointment?.estimate.min !== undefined &&
          appointment?.estimate.min + ' m'}
      </span>

      <Form
        form={form}
        name="time"
        //onFinish={selectDateAppointment}
        size="large"
        style={{
          display: 'flex',
          flexDirection: 'column',
          //marginTop: '24px',
        }}
      >
        <Form.Item
          name="date"
          className={classes.date}
          //rules={[{ required: true, message: 'Select a date' }]}
        > 
          <h1 className={classes.month}>{weeksArray[availableWeek]?.month}, {weeksArray[availableWeek]?.year}</h1>
          <Carousel {...settings} className={classes.carousel} afterChange={(index) => selectedWeek >= index && selectedWeek <= index + 7 ? setAvailableWeek(selectedWeek) : setAvailableWeek(index)}>
            {calendarArray.map((date,index) => {
                return(
                    <button disabled={date.disabled} key={date.key} className={classes.dateButton} onClick={() => onChangeDate(date.key,date.disabled,index)}>
                         <div className={`${classes.card} ${date.disabled ? classes.disabled : classes.enabled} ${dateStart == date.key ? classes.active : ''}`}>
                            <h3 className={classes.name}>{date.date[0]}</h3>
                            <span className={classes.number}>{date.date[1]}</span>
                        </div>
                    </button>
                )  
            })}
            </Carousel>
          {/* <Calendar /> */}
          {/* <Calendar
            fullscreen={false}
            mode={'month'}
            className={classes.calendar}
            //style={{width:"300px"}}
            //responsive={false}
            //validRange= {[dateNow, dateNow]
            disabledDate={(current) => {
              return (
                moment() >= current ||
                isDayAvailable(
                  current
                    .format('ddd')
                    .toLowerCase() as keyof typeof availableDays,
                  availableDays
                )
              );
            }}
            onSelect={onChangeDate}/> */}
        </Form.Item>
        <div className={classes.forms}>
        {isSelectedDate && (
          <div>
            <div className={classes.worksTime}>
              What time works for you?
            </div>
            <Form.Item
              name="time"
              className={classes.timeButtons}
            >
              <Radio.Group value={timeStartSelected} className={classes.group}>
                {timeSlots &&
                  timeSlots.map((slot, index) => {
                    return (
                      <Radio.Button disabled={!slot.active} className={classes.time} key={index} value={slot.start} onClick={() => onTimeChange(slot.start)}>
                        <span>
                        {slot.start}
                        </span>
                      </Radio.Button >
                    );
                  })}
              </Radio.Group>
            </Form.Item>
            {/* <div className={classes.timeButtons}>
            </div>       */}
            {reschedule &&(
              <div>
                <div className={classes.alert}>
                  For appointments after 1:00pm, your car will be ready the next day
                </div>
                <PickUp onChange={onSelectPickUp} deliverySlots={deliveryProperties[0]} type="pickUp" />
                <PickUp onChange={onSelectPickUp} deliverySlots={deliveryProperties[1]} type="dropOff" />
              </div>
            )
            }
            {/* {deliveredTypes.map((item, index) => ( 
             <DeliveryTime
              type={item}
              key={index}
              timeStart={timeStart}
              carDeliveries={carDeliveries}
              isSelected={isSelectedDate}
            />
            ))} */}
            {(deliveredDropOff !== 1 || deliveredPickUp !== 1) &&(
              <div>
              <div className={classes.adress}>
                <p>Please share your address. <br></br>
                Our team will confirm yor address with you prior to your appointment</p>
              </div>
              <Form.Item className={classes.input} rules={[{ required: true, message: 'Field is required' }]}>
                <TextArea
                  className={classes.inputAddress}
                  //prefix={<EnvironmentOutlined style={{ color: '#5B8DB4' }}/>}
                  placeholder={"Please enter your address"}
                  onChange={setAdressPoint}
                  autoSize
                  maxLength={310}
                />
              </Form.Item>
            </div>
            )}
          </div>
        )}

          {/* {!isPickUp && (
            
            <div>
              <div className={classes.adress}>
              Please share your address, otherwise we will contact you for your location
              </div>
              <Form.Item className={classes.input} rules={[{ required: true, message: 'Field is required' }]}>
                <Input
                  className={classes.input}
                  prefix={<EnvironmentOutlined style={{ color: '#5B8DB4' }}/>}
                  placeholder={"Optional:please enter your address"}
                ></Input>
              </Form.Item>
            </div>
          )

          } */}
          <Form.Item className={classes.inputButtons}>
            <div className={classes.buttons}>
              <ButtonComponent
                title={type === 'steps' ? 'Back' : 'Cancel'}
                type="cancel"
                onClick={onCancel}
              />
              <ButtonComponent disabled={!isSelectedDate || !isSelectedTime} title="Continue" type="save" onClick={() => {selectDateAppointment()}} />
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
