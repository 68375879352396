import { PaymentDetailsRequest } from './../../Types/workshopTypes';
import { appApi } from './../App/services';
import { encodedOrderQuery } from './../../Utils/query';
import {
  QueryObjectType,
  ScheduleRequest,
  WorkshopPrices,
  WorkshopPricesResponse,
  WorkShopRequestType,
  WorkShopType,
  CitiesTypeRequest
} from './../../Types/authTypes';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { workshopActions } from './actions';
import { workshopApi } from './services';
import * as workshopConstants from './constants';
import { appActions } from '../App/actions';
import * as selectors from '../Auth/selectors';
import {
  OrderForWorkshopType,
  OrderService,
  OrderServicesForJob,
  OrdersRequestType,
  PaymentPreferencesRequestType,
  PaymentsDetailsResponse,
  RequestInvoiceType,
  SpecialistType,
  StripeInfoType,
  WorkshopAppointmentRequestType,
} from '../../Types/workshopTypes';

export function* getWorkshopData(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    //const id: number = yield select(selectors.getWorkshopId);
    const confirmResult: WorkShopType = yield call(
      workshopApi.getWorkshopData,
      token,
      payload.id
    );
    yield put(workshopActions.setWorkshopData(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updateWorkshop({
  payload,
  workshopId,
}: {
  payload: WorkShopRequestType;
  type: string;
  workshopId?: number;
}) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    let id: number = yield select(selectors.getWorkshopId);
    if (!id) {
      id = workshopId!;
    }
    const confirmResult: WorkShopType = yield call(
      workshopApi.updateWorkshop,
      token,
      id,
      payload
    );
    yield put(workshopActions.setWorkshopData(confirmResult));
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'success',
        message: 'your settings changed',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 4000);
    yield put(appActions.deleteAlertMessage('your settings changed'));
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopSchedules({ id }: any) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    //const id: number = yield select(selectors.getScheduleId);
    const confirmResult: ScheduleRequest = yield call(
      workshopApi.getWorkshopSchedules,
      id
    );
    yield put(workshopActions.setWorkshopSchedules(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updateWorkshopSchedules({
  payload,
  workshopId,
}: {
  payload: ScheduleRequest;
  workshopId?: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    let id: number = yield select(selectors.getScheduleId);
    if (!id) {
      id = workshopId!;
    }
    const confirmResult: ScheduleRequest = yield call(
      workshopApi.updateWorkshopSchedules,
      token,
      id,
      payload
    );
    yield put(workshopActions.setWorkshopSchedules(confirmResult));
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'success',
        message: 'your settings changed',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 3000);
    yield put(appActions.deleteAlertMessage('your settings changed'));
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopPrices({
  workshopId,
}: {
  type: string;
  workshopId?: number;
}) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    let id: number = yield select(selectors.getPricesId);
    if (!id) {
      id = workshopId!;
    }
    const confirmResult: WorkshopPricesResponse = yield call(
      workshopApi.getWorkshopPrices,
      token,
      id
    );
    yield put(workshopActions.setWorkshopPrices(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updateWorkshopPrices({
  payload,
  workshopId,
}: {
  payload: WorkshopPrices;
  workshopId?: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(selectors.getToken);
    let id: number = yield select(selectors.getPricesId);
    if (!id) {
      id = workshopId!;
    }
    const confirmResult: WorkshopPricesResponse = yield call(
      workshopApi.updateWorkshopPrices,
      token,
      id,
      payload
    );
    yield put(workshopActions.setWorkshopPrices(confirmResult));
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'success',
        message: 'your settings changed',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 3000);
    yield put(appActions.deleteAlertMessage('your settings changed'));
  } catch (e: any) {
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'error',
        message: 'something went wrong',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 3000);
    yield put(appActions.deleteAlertMessage('something went wrong'));
  }
}

export function* getOrders({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: OrdersRequestType<Array<OrderService>> = yield call(
      workshopApi.getOrders,
      token,
      query
    );
    yield put(workshopActions.setOrders(confirmResult));
    if (confirmResult.rows.length < payload.page.limit) {
      yield put(workshopActions.isHasMore(false));
    } else {
      yield put(workshopActions.isHasMore(true));
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    console.log(e);
    yield put(appActions.closeLoader());
  }
}

export function* changeStatus(payload: {
  type: string;
  id: number;
  status: 'accepted' | 'declined' | 'processing' | 'finished';
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: OrderForWorkshopType<OrderServicesForJob> = yield call(
      workshopApi.changeStatus,
      token,
      payload.id,
      payload.status
    );
    yield put(workshopActions.updateOrder(confirmResult));
    if (confirmResult.status === 'processing' || 'finished') {
      yield put(workshopActions.setOrder(confirmResult));
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getOrderById({ payload }: { payload: number; type: string }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: OrderForWorkshopType<OrderServicesForJob> = yield call(
      workshopApi.getOrderById,
      token,
      payload
    );
    yield put(workshopActions.setOrder(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* isMarkDone({
  payload,
}: {
  payload: { id: number; note?: string; status?: boolean };
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: OrderForWorkshopType<OrderServicesForJob> = yield call(
      workshopApi.markIsDone,
      token,
      payload
    );
    yield put(workshopActions.setOrder(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* isMarkAllDone({
  payload,
}: {
  payload: { order_id: number; note?: string; status?: boolean };
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: OrderForWorkshopType<OrderServicesForJob> = yield call(
      workshopApi.markIsAllDone,
      token,
      payload
    );
    yield put(workshopActions.setOrder(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* sendWorkshopNote({
  payload,
}: {
  payload: { id: number; workshop_note: string };
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: OrderForWorkshopType<OrderServicesForJob> = yield call(
      workshopApi.sendNote,
      token,
      payload
    );
    yield put(workshopActions.setOrder(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getCitiesForSettings() {
  try {
    yield put(appActions.openLoader());
    const confirmResult: Array<CitiesTypeRequest> = yield call(
      workshopApi.getCities
    );
    yield put(workshopActions.setCities(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopSpecialists(payload: {
  id?: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: Array<SpecialistType> = yield call(
      workshopApi.getSpecialists,
      token,
      payload.id
    );
    yield put(workshopActions.setWorkshopSpecialists(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updateSpecialists({
  payload,
  workshopId,
}: {
  payload: Array<{ id: number; status: boolean }>;
  workshopId?: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: Array<SpecialistType> = yield call(
      workshopApi.updateSpecialists,
      token,
      payload,
      workshopId
    );
    yield put(workshopActions.setWorkshopSpecialists(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopPromotions(payload: {
  payload?: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: Array<SpecialistType> = yield call(
      workshopApi.getPromotions,
      token,
      payload.payload
    );
    yield put(workshopActions.setWorkshopPromotions(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updatePromotions({
  payload,
  workshopId,
}: {
  payload: Array<{ id: number; status: boolean }>;
  workshopId?: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: Array<SpecialistType> = yield call(
      workshopApi.updatePromotions,
      token,
      payload,
      workshopId
    );
    yield put(workshopActions.setWorkshopPromotions(confirmResult));
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'success',
        message: 'your promotions changed',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 3000);
    yield put(appActions.deleteAlertMessage('your promotions changed'));
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopInvoices({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: RequestInvoiceType = yield call(
      workshopApi.getWorkshopInvoices,
      token,
      query
    );
    yield put(workshopActions.setWorkshopInvoices(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopAppointments({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: WorkshopAppointmentRequestType = yield call(
      workshopApi.getWorkshopAppointments,
      token,
      query
    );
    yield put(workshopActions.setWorkshopAppointments(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopInvoiceLink({
  payload,
}: {
  payload: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: { url: string } = yield call(
      workshopApi.getWorkshopInvoiceLink,
      token,
      payload
    );
    yield call(appApi.downloadFile, confirmResult.url);
    //yield put(workshopActions.setWorkshopInvoiceLink(confirmResult.url));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopMonthlyRevenue() {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: { total_price: number } = yield call(
      workshopApi.getWorkshopMonthlyRevenue,
      token
    );
    yield put(workshopActions.setWorkshopMonthlyRevenue(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopPaymentsLink() {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: PaymentPreferencesRequestType = yield call(
      workshopApi.getWorkshopPaymentsLink,
      token
    );
    yield put(workshopActions.setWorkshopPaymentsLink(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* addPhoneNumber(payload: {
  type: string;
  workshopId: number;
  phone: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: WorkShopType = yield call(
      workshopApi.addNewPhone,
      token,
      payload.workshopId,
      payload.phone
    );
    yield put(
      workshopActions.setUpdatedPhones(confirmResult.workshop_phones || null)
    );
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* editPhoneNumber(payload: {
  type: string;
  workshopId: number;
  phone: string;
  phoneId: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: WorkShopType = yield call(
      workshopApi.editPhone,
      token,
      payload.workshopId,
      payload.phone,
      payload.phoneId
    );
    yield put(workshopActions.setUpdatedPhones(confirmResult.workshop_phones));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* deletePhoneNumber(payload: {
  type: string;
  workshopId: number;
  phoneId: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: WorkShopType = yield call(
      workshopApi.deletePhone,
      token,
      payload.workshopId,
      payload.phoneId
    );
    yield put(workshopActions.setUpdatedPhones(confirmResult.workshop_phones));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getStripeInfo() {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: StripeInfoType = yield call(
      workshopApi.getStripeInfo,
      token
    );
    yield put(workshopActions.setStripeInfo(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getPaymentsData(payload: { type: string; payload: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: { data: PaymentsDetailsResponse } = yield call(
      workshopApi.getPaymentsDetails,
      token,
      payload.payload
    );
    yield put(workshopActions.setPaymentsData(confirmResult.data));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updatePaymentsData(payload: {
  type: string;
  data: PaymentDetailsRequest;
  id: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(selectors.getToken);
    const confirmResult: { data: PaymentsDetailsResponse } = yield call(
      workshopApi.updatePaymentsDetails,
      token,
      payload.id,
      payload.data
    );
    yield put(workshopActions.setPaymentsData(confirmResult.data));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export default function* WatcherSaga() {
  yield takeLatest(workshopConstants.GET_WORKSHOP_DATA, getWorkshopData);
  yield takeLatest(workshopConstants.UPDATE_WORKSHOP, updateWorkshop);
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_SCHEDULES,
    getWorkshopSchedules
  );
  yield takeLatest(
    workshopConstants.UPDATE_WORKSHOP_SCHEDULES,
    updateWorkshopSchedules
  );
  yield takeLatest(workshopConstants.GET_WORKSHOP_PRICES, getWorkshopPrices);
  yield takeLatest(
    workshopConstants.UPDATE_WORKSHOP_PRICES,
    updateWorkshopPrices
  );
  yield takeLatest(workshopConstants.GET_ORDERS, getOrders);
  yield takeLatest(
    workshopConstants.GET_CITIES,
    getCitiesForSettings
  );
  yield takeLatest(workshopConstants.CHANGE_ORDER_STATUS, changeStatus);
  yield takeLatest(workshopConstants.GET_ORDER_BY_ID, getOrderById);
  yield takeLatest(workshopConstants.MARK_IS_DONE, isMarkDone);
  yield takeLatest(workshopConstants.MARK_IS_ALL_DONE, isMarkAllDone);
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_SPECIALISTS,
    getWorkshopSpecialists
  );
  yield takeLatest(
    workshopConstants.UPDATE_WORKSHOP_SPECIALISTS,
    updateSpecialists
  );
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_PROMOTIONS,
    getWorkshopPromotions
  );
  yield takeLatest(
    workshopConstants.UPDATE_WORKSHOP_PROMOTIONS,
    updatePromotions
  );
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_INVOICES,
    getWorkshopInvoices
  );
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_INVOICE_LINK,
    getWorkshopInvoiceLink
  );
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_MONTHLY_REVENUE,
    getWorkshopMonthlyRevenue
  );
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_APPOINTMENTS,
    getWorkshopAppointments
  );
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_PAYMENTS_LINK,
    getWorkshopPaymentsLink
  );
  yield takeLatest(workshopConstants.ADD_NEW_PHONE, addPhoneNumber);
  yield takeLatest(workshopConstants.EDIT_PHONE, editPhoneNumber);
  yield takeLatest(workshopConstants.DELETE_PHONE, deletePhoneNumber);
  yield takeLatest(workshopConstants.GET_STRIPE_INFO, getStripeInfo);
  yield takeLatest(workshopConstants.SEND_WORKSHOP_NOTE, sendWorkshopNote);
  yield takeLatest(
    workshopConstants.GET_WORKSHOP_PAYMENTS_DATA,
    getPaymentsData
  );
  yield takeLatest(
    workshopConstants.UPDATE_WORKSHOP_PAYMENTS_DATA,
    updatePaymentsData
  );
}
