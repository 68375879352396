import classes from './style.module.scss';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { OrderServiceForJob } from '../../../Types/workshopTypes';

type CheckboxComponentType = {
  order: OrderServiceForJob;
  isInput?: boolean;
};

export const ListBoxComponent: React.FC<CheckboxComponentType> = ({
  order,
  isInput = false,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.containerWithInput}>
        <div className={classes.row}>
          <CheckCircleOutlined style={{ fontSize: '15px', color: '#5B8DB4' }} />
          {order.title}
        </div>
        {isInput && order.note && (
          <Input
            placeholder="Findings"
            value={order.note}
            className={classes.input}
          />
        )}
      </div>
    </div>
  );
};
