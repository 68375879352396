import React, { useEffect, useState } from 'react';
import { Rate, Row } from 'antd';
import classes from './style.module.scss';
import logo from '../../Assets/workshopImage.png';
import toolIcon from '../../Assets/tool.svg';
import Recommended from '../../Assets/orderIcon/recommended.svg';
import Partner from '../../Assets/orderIcon/partner.svg';
import CheckIcon from '../../Assets/orderIcon/partner-icon.svg';
import VerifiedIcon from '../../Assets/orderIcon/verified-icon.svg';
import SaleFreeIcon from '../../Assets/orderIcon/sale-icon.svg';

import { ButtonComponent } from '../Button';
import { RootStateType } from '../../Stores';
import { useDispatch, useSelector } from 'react-redux';
import { RateDescription } from '../Workshop/Components/RateDescription';
import { Schedule, WorkShopType } from '../../Types/authTypes';
import { appActions } from '../../Globals/App/actions';
import Google from '../../Assets/orderIcon/google_icon.svg';
import moment from 'moment';
import { weekDayType } from '../../Types/appTypes';

const weekDays: Array<weekDayType> = [
  'sun',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
];

type WorkshopInfoModalPropsType = {
  goToNextStep: (id: number) => void;
  selectedWorkshop: WorkShopType;
  hideButton?: boolean;
};

export const WorkshopInfoModal: React.FC<WorkshopInfoModalPropsType> = ({
  goToNextStep,
  selectedWorkshop,
  hideButton = false,
}) => {
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState<Schedule>();
  const separateSchedule = useSelector(
    (state: RootStateType) => state.workshop.workshopSchedules
  );
  const reviews = useSelector((state: RootStateType) => state.app.reviews);

  useEffect(() => {
    if (
      selectedWorkshop.schedule &&
      typeof selectedWorkshop.schedule !== 'number'
    ) {
      setSchedule(selectedWorkshop.schedule);
    } else if (separateSchedule) {
      const scheduleTypeAdduction = (({
        id,
        sun,
        mon,
        tue,
        wed,
        thu,
        fri,
        sat,
        updated_at,
        created_at,
        workshop,
      }): Schedule => ({
        id,
        sun,
        mon,
        tue,
        wed,
        thu,
        fri,
        sat,
        updated_at,
        created_at,
        workshop: workshop.id,
      }))(separateSchedule);
      setSchedule(scheduleTypeAdduction);
    }
  }, [separateSchedule, selectedWorkshop]);
  useEffect(() => {
    dispatch(appActions.getReviewsForWorkshop(selectedWorkshop.id));
  }, [dispatch, selectedWorkshop.id]);
  
  return (
    <div className={classes.container}>
      <div className={classes.cardWrapper}>
        <img
          src={selectedWorkshop?.avatar?.url || logo}
          alt="logo"
          className={classes.workshopImage}
          width={182}
        />

        <div className={classes.workshopInfoContainer}>
          <h3 className={classes.workshopName}>{selectedWorkshop.name}</h3>
          {/* <h3 className={classes.workshopManager}>{selectedWorkshop.contact_person} - Workshop Manager</h3> */}
          <a href={selectedWorkshop.review_url} target="_blank" className={classes.link}>
            {selectedWorkshop?.google_review.average_rating_maps || 0}
            <div className={classes.rateBox}>
              <Rate
                allowHalf
                value={selectedWorkshop?.google_review.average_rating_maps || 0}
                count={5}
                disabled
                className={classes.rate}
              />
            </div> 
            {selectedWorkshop?.google_review.ratings_total_maps}<img className={classes.google} src={Google} alt="google-icon" /> Reviews
          </a>
          <h4 className={classes.headerHours}>Opening hours</h4>
          <div className={classes.traidingHoursContainer}>
            {weekDays.map((day, index) => {
              return (
                <div key={index} className={classes.dayWrapp}>
                  <div className={classes.weekDay}>{day}</div>
                  <div className={classes.workTime}>
                    {schedule && schedule[day]?.split('-')[0]}
                  </div>
                  <div className={classes.workTime}>
                    {schedule && schedule[day]?.split('-')[1]}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={classes.descriptionText}>
        <div className={classes.diagram}>
          <div className={classes.quality}>
            {!selectedWorkshop.partner ?  
            <img src={Partner} alt="partner"/>
            :
            <img src={Recommended} alt="recommended"/>
            }
          </div>
          <div className={classes.workshopSpec}>
            <div className={classes.specialist}>
              {selectedWorkshop?.workshop_specialists.sort(function (a, b) {
                if (a.priority > b.priority) {
                  return 1;
                }
                if (a.priority < b.priority) {
                  return -1;
                }
                return 0;
              }).map((spec) => {
                return (
                  <div className={classes.specialistItem} key={spec.id}>
                    <img src={spec.icon?.url} alt="demographic" />
                    {spec.title}
                  </div>
                );
              })}
            </div>
            <div className={classes.workshopPoints}>
                {selectedWorkshop?.partner && (
                  <div className={classes.partner}>
                    <div>
                      <img src={CheckIcon} alt="check" className={classes.checkIcon} />
                    </div>
                    <span>Partner workshop</span>
                  </div>
                )}
                {selectedWorkshop?.verified && (
                  <div className={classes.partner}>
                    <div>
                      <img
                        src={VerifiedIcon}
                        alt="verified"
                        className={classes.checkIcon}
                        />
                    </div>
                    <span>Verified Credentials</span>
                  </div>
                )}
                {selectedWorkshop.workshop_promotions?.sort(function (a, b) {
                  if (a.priority > b.priority) {
                    return 1;
                  }
                  if (a.priority < b.priority) {
                    return -1;
                  }
                  return 0;
                }).map((item, index) => (
                  <div className={classes.partner} key={index}>
                    <div>
                      <img src={SaleFreeIcon} alt="sale" className={classes.checkIcon} />
                    </div>
                    <span>{item.title}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <p className={classes.aboutTeam}>About the team at {selectedWorkshop.name}</p>
        <p className={classes.headline}>
          {selectedWorkshop?.headline}
        </p>
        <p>{selectedWorkshop.about}</p>
      </div>
      <div className={classes.reviewsHeader}>
        {!!selectedWorkshop.google_review.reviews_maps?.length && <div style={{width: '22px'}}></div>}  
        {!!selectedWorkshop.google_review.reviews_maps?.length && <h3 >Reviews</h3>}
        {!!selectedWorkshop.google_review.reviews_maps?.length && <div></div>}
      </div>
      {!!selectedWorkshop.google_review.reviews_maps?.length && <div className={classes.feedbackContainer}>
        <div className={classes.feedbackItem}>
          {selectedWorkshop.google_review.reviews_maps.map((review, index) => {
            let date = new Date(review.time * 1000);
            return(
              <div className={classes.reviews} key={index}>
                <h3>{review.author_name}</h3>
                <div className={classes.rate}>
                  <Rate disabled defaultValue={review.rating}/>
                  <h4>{date.getDate()} {date.toLocaleString('en-us',{month:'short'})} {date.getFullYear()}</h4>
                </div>
                <p>{review.text}</p>
              </div>
            );
          })}
        </div>
      </div>}
      {!hideButton && (
        <Row justify="end">
          <ButtonComponent
            type="save"
            title="Book now"
            onClick={() => goToNextStep(selectedWorkshop.id)}
            style={{ marginTop: '16px', marginBottom: '16px' }}
            className={classes.button}
          />
        </Row>
      )}
    </div>
  );
};
