export const GET_WORKSHOP_LIST = 'GET_WORKSHOP_LIST';
export const SET_WORKSHOP_LIST = 'SET_WORKSHOP_LIST';

export const APPROVE_WORKSHOP = 'APPROVE_WORKSHOP';

export const SEARCH_WORKSHOPS = 'SEARCH_WORKSHOPS';

export const GET_USERS_LIST = 'GET_USERS_LIST';
export const SET_USERS_LIST = 'SET_USERS_LIST';

export const EDIT_WORKSHOP = 'EDIT_WORKSHOP';

export const GET_SERVICES_FOR_ADMIN = 'ADMIN/GET_SERVICES_FOR_ADMIN';
export const SET_SERVICES_FOR_ADMIN = 'ADMIN/SET_SERVICES_FOR_ADMIN';

export const GET_PARTS = 'ADMIN/GET_PARTS';
export const SET_PARTS = 'ADMIN/SET_PARTS';

export const GET_USER = 'ADMIN/GET_USER';
export const SET_USER = 'ADMIN/SET_USER';

export const GET_ORDERS = 'ADMIN/GET_ORDERS';
export const SET_ORDERS = 'ADMIN/SET_ORDERS';
export const CHANGE_ORDER_STATUS = 'ADMIN/CHANGE_ORDER_STATUS'
export const UPDATE_ORDER = 'ADMIN/UPDATE_ORDER';

export const GET_CUSTOMER_CARS = 'ADMIN/GET_CUSTOMER_CARS';
export const SET_CUSTOMER_CARS = 'ADMIN/SET_CUSTOMER_CARS';

export const UPDATE_CAR = 'ADMIN/UPDATE_CAR';
export const SET_UPDATED_CAR = 'ADMIN/SET_UPDATED_CAR';
export const DELETE_CAR = 'ADMIN/DELETE_CAR';

export const GET_ORDER = 'ADMIN/GET_ORDER';
export const SET_ORDER = 'ADMIN/SET/ORDER';
export const FINISH_ORDER = 'ADMIN/FINISH_ORDER'
export const DELETE_ORDER = 'ADMIN/DELETE_ORDER'
export const DELETE_ORDER_SUCCESS = 'ADMIN/DELETE_ORDER_SUCCESS'

export const GET_CUSTOMER_APPOINTMENTS = 'ADMIN/GET_CUSTOMER_APPOINTMENTS'
export const SET_CUSTOMER_APPOINTMENTS = 'ADMIN/SET_CUSTOMER_APPOINTMENTS'

export const BLOCK_UNBLOCK_USER = 'ADMIN/BLOCK_UNBLOCK_USER'

export const DELETE_WORKSHOP = 'ADMIN/DELETE_WORKSHOP'
export const DELETE_WORKSHOP_SUCCESS = 'ADMIN/DELETE_WORKSHOP_SUCCESS'

export const DELETE_CUSTOMER = 'ADMIN/DELETE_CUSTOMER'
export const DELETE_CUSTOMER_SUCCESS = 'ADMIN/DELETE_CUSTOMER_SUCCESS'

export const TOGGLE_IS_BLOCKED_WORKSHOP =  'ADMIN/TOGGLE_IS_BLOCKED_WORKSHOP'
export const TOGGLE_IS_BLOCKED_CUSTOMER =  'ADMIN/TOGGLE_IS_BLOCKED_CUSTOMER'

export const GET_ADMIN_INVOICES = 'ADMIN/GET_WORKSHOP_INVOICES'
export const SET_ADMIN_INVOICES = 'ADMIN/SET_WORKSHOP_INVOICES'

export const GET_ADMIN_MONTHLY_REVENUE = 'ADMIN/GET_ADMIN_MONTHLY_REVENUE'
export const SET_ADMIN_MONTHLY_REVENUE = 'ADMIN/SET_ADMIN_MONTHLY_REVENUE'

export const GET_NOTIFICATIONS = 'admin/GET_NOTIFICATIONS'
export const SET_NOTIFICATIONS = 'admin/SET_NOTIFICATIONS'

export const GET_ADMIN_REVIEWS = 'admin/GET_ADMIN_REVIEWS'
export const SET_ADMIN_REVIEWS = 'admin/SET_ADMIN_REVIEWS'
export const DELETE_ADMIN_REVIEW = 'admin/DELETE_ADMIN_REVIEW'
export const DELETE_ADMIN_REVIEW_SUCCESS = 'admin/DELETE_ADMIN_REVIEW_SUCCESS'

export const CHANGE_PAYMENT_STATUS = 'admin/CHANGE_PAYMENT_STATUS'
export const UPDATE_INVOICE_PAYMENT_STATUS = 'admin/UPDATE_INVOICE_PAYMENT_STATUS'


