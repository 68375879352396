import React, { ChangeEvent, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Input } from 'antd';

import classes from './style.module.scss';
import { MainLayout } from '../../Components/MainLayout';
import { OrderCard } from '../../Components/OrderCard';
import { RootStateType } from '../../Stores';

import {
  OrderForWorkshopType,
  OrderServiceForJob,
  OrderServicesForJob,
} from '../../Types/workshopTypes';
import { workshopActions } from '../../Globals/Workshop/actions';
import { CheckBoxGroup } from './CheckBoxGroup';
import useDebounce from '../../Hooks/useDebounce';
import { useLocation } from 'react-router-dom';
import { ListBoxComponent } from './ListBoxGroup';
import { OrderForAdminType } from '../../Types/adminTypes';
import { CustomerOrderType } from '../../Types/userTypes';
import { UploadComponent } from './Upload';
import { adminActions } from '../../Globals/Admin/actions';
import { userActions } from '../../Globals/User/actions';

const { TextArea } = Input;

/* const consumables = [
  ['1 piece ', 'ServiceJapanese princess to wed commoner.'],
  ['1 litre ', 'ServiceJapanese princess to wed '],
]; */

type StartJobPropsType = {
  orderId: number;
};

export const StartJob: React.FC<StartJobPropsType> = ({ orderId }) => {
  const [order, setOrder] = useState<
    OrderForAdminType | CustomerOrderType | null
  >(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const [inspection, setInspection] = useState<Array<OrderServiceForJob>>([]);

  const [services, setServices] = useState<Array<OrderServiceForJob>>([]);

  const [otherServices, setOtherServices] = useState<Array<OrderServiceForJob>>(
    []
  );

  const [car_repair, setCar_repair] = useState<Array<OrderServiceForJob>>([]);

  const [inputValue, setInputValue] = useState<{ id: number; note: string }>();
  const [workshopNote, setWorkshopNote] = useState<string>();

  const userRole = useSelector(
    (state: RootStateType) => state.auth.user?.user.role.type
  );

  const workshopOrder = useSelector(
    (state: RootStateType) => state.workshop.order
  );

  const customerOrder = useSelector((state: RootStateType) => state.user.order);

  const adminOrder = useSelector((state: RootStateType) => state.admin.order);

  const debouncedSearchTerm = useDebounce(inputValue, 700);
  const debouncedWorkshopNote = useDebounce(workshopNote, 700);
  
  const onChangeServices = (value: CheckboxChangeEvent) => {
    const payload = {
      id: value.target.value,
      status: value.target.checked,
    };
    dispatch(workshopActions.markIsDone(payload));
  };

  const onInputChange = (id: number, e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue({ id, note: e.target.value });
  };

  const onChangeNote = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setWorkshopNote(event.target.value);
  };

  const isCheckedAll = () => {
    const inspectionNotChecked = inspection.find((item) => !item.status);
    const servicesNotChecked = services.find((item) => !item.status);
    const otherServicesNotChecked = otherServices.find((item) => !item.status);
    const carRepairNotChecked = car_repair.find((item) => !item.status);
    if (inspectionNotChecked || servicesNotChecked || otherServicesNotChecked || carRepairNotChecked) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (debouncedWorkshopNote) {
      const payload = {
        id: orderId,
        workshop_note: debouncedWorkshopNote,
      };
      dispatch(workshopActions.sendWorkshopNote(payload));
    }
  }, [debouncedWorkshopNote, dispatch, orderId]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const payload = {
        id: debouncedSearchTerm.id,
        note: debouncedSearchTerm.note,
      };
      dispatch(workshopActions.markIsDone(payload));
    }
  }, [debouncedSearchTerm, dispatch]);

  useEffect(() => {
    switch (userRole) {
      case 'authenticated_admin':
        setOrder(adminOrder);
        break;
      case 'authenticated_workshop':
        setOrder(workshopOrder);
        break;
      case 'authenticated':
        setOrder(customerOrder);
        break;
    }
  }, [workshopOrder, adminOrder, customerOrder, userRole]);

  useEffect(() => {
    switch (userRole) {
      case 'authenticated_admin':
        dispatch(adminActions.getOrderById(orderId));
        break;
      case 'authenticated_workshop':
        dispatch(workshopActions.getOrderById(orderId));
        break;
      case 'authenticated':
        dispatch(userActions.getOrder(orderId));
        break;
    }
  }, [dispatch, orderId, userRole]);

  useEffect(() => {
    if (order) {
      const inspectionList = order?.order_services.check;
      const servicesList = order?.order_services.log_book;
      const otherServicesList = order?.order_services.additional;
      const carRepairList = order?.order_services.car_repair;
      setInspection(inspectionList);
      setServices(servicesList);
      setOtherServices(otherServicesList);
      setCar_repair(carRepairList)
    }
  }, [order]);
  useEffect(() => {
    return () => {
      setOrder(null);
      dispatch(workshopActions.resetOrder());
    };
  }, [dispatch]);

  return (
    <MainLayout>
      {userRole === 'authenticated_workshop' && order && (
        <OrderCard
          order={order as OrderForWorkshopType<OrderServicesForJob>}
          type="job"
          isDisableButton={!isCheckedAll()}
          //hideButtons={pathName === `/workshop/jobs/order-card/${order.id}`}
          hideButtons={location.search === '?is-start-job=false' || !location.search.includes('is-start-job')}
        />
      )}
      {userRole === 'authenticated' && order && (
        <OrderCard
          order={order as CustomerOrderType}
          type="invoice"
          isDisableButton={!isCheckedAll()}
          hideButtons
        />
      )}
      {userRole === 'authenticated_admin' && order &&  (
        order?.status == "accepted" || order?.status == "processing" ?
        <OrderCard
          order={order as OrderForAdminType}
          type="admin"
          isDisableButton={false}
          hideButtons={false}
        />
        :
        <OrderCard
          order={order as OrderForAdminType}
          type="invoice"
          isDisableButton={!isCheckedAll()}
          hideButtons
        />
      )}
      

      <div className={classes.checkBoxes}>
        <div className={classes.form}>
          <div className={classes.leftColumn}>
            <h2>Services</h2>
            {userRole === 'authenticated_workshop' && order ? (
              <CheckBoxGroup
                services={order.order_services.log_book}
                onChange={onChangeServices}
                isDisable={
                  order?.status === 'finished' || !order.job_start_date
                }
              />
            ) : (
              order &&
              order.order_services.log_book.map((order) => (
                <ListBoxComponent order={order} key={order.id} />
              ))
            )}
            <h2>Other Services</h2>
            {userRole === 'authenticated_workshop' &&
            order?.order_services.additional.length ? (
              <CheckBoxGroup
                services={order.order_services.additional}
                onChange={onChangeServices}
                isDisable={order.status === 'finished' || !order.job_start_date}
              />
            ) : (
              order &&
              order.order_services.additional.map((order) => (
                <ListBoxComponent order={order} key={order.id} />
              ))
            )}
            <h2>Car Repair</h2>
            {userRole === 'authenticated_workshop' &&
            order?.order_services.car_repair.length ? (
              <CheckBoxGroup
                services={order.order_services.car_repair}
                onChange={onChangeServices}
                isDisable={order.status === 'finished' || !order.job_start_date}
              />
            ) : (
              order &&
              order.order_services.car_repair.map((order) => (
                <ListBoxComponent order={order} key={order.id} />
              ))
            )
            }
            {
              order?.car_repair_note ? 
              <TextArea rows={4} value={order?.car_repair_note} disabled={true} style={{marginTop: '28px'}}></TextArea>
              :
              ''
            }
            

            {/* <div className={classes.consumables}>
              <h2>Consumables</h2>
              {consumables.map((consumable) => (
                <div key={consumable.toString()} className={classes.checkbox}>
                  <span className={classes.consumablesSpan}>
                    {consumable[0]}
                  </span>
                  {consumable[1]}
                </div>
              ))}
            </div> */}
          </div>
          <div className={classes.inspection}>
            <h2>Inspection check-list</h2>
            {userRole === 'authenticated_workshop' &&
            order?.order_services.check ? (
              <CheckBoxGroup
                services={order?.order_services.check}
                onChange={onChangeServices}
                isDisable={
                  order?.status === 'finished' || !order.job_start_date
                }
                isInspect
                onInputChange={onInputChange}
              />
            ) : (
              order &&
              order.order_services.check.map((order) => (
                <ListBoxComponent order={order} isInput key={order.id} />
              ))
            )}
            <div className={classes.extraNotesContainer}>
              <div className={classes.header}>
                <h2>Extra notes</h2>
                {order && (
                  <UploadComponent
                    order={order}
                    userRole={userRole ? userRole : ''}
                  />
                )}
              </div>
              <TextArea
                placeholder={
                  userRole !== 'authenticated_workshop'
                    ? 'Recommendations'
                    : 'Leave some recommendations for further maintenance'
                }
                autoSize
                disabled={
                  userRole !== 'authenticated_workshop' ||
                  order?.status !== 'processing'
                }
                maxLength={200}
                value={
                  userRole !== 'authenticated_workshop' && order?.workshop_note
                    ? order.workshop_note
                    : workshopNote
                }
                onChange={onChangeNote}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
