import React, {
  LegacyRef,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { RootStateType } from '../../Stores';
import classes from './style.module.scss';
import { Layout, Menu, Spin, Breadcrumb } from 'antd';
import WorkshopLogo from './../../Assets/workshop.svg';
import {
  TableOutlined,
  SettingOutlined,
  CarOutlined,
  TeamOutlined,
  ToolOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  ShoppingOutlined,
  BellOutlined,
  WhatsAppOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import Logo from './../../Assets/layoutLogo.svg';
import watsappLogo from '../../Assets/whatsapp.svg'
import Visa from '../../Assets/visa-icon.svg'
import MasterCard from '../../Assets/mastercard-icon.svg'
import GPay from '../../Assets/GPay-icon.svg'
import ApPay from '../../Assets/ApPay-icon.svg'
import Tabby from '../../Assets/tabby-logo.svg'
import { ModalComponent } from '../Modal';
import { adminActions } from '../../Globals/Admin/actions';
import { WorkshopForm } from '../WorkshopForm';
import { AlertComponent } from '../Alert';
import { ApproveWorkshopRequestType } from '../../Types/workshopTypes';
import { appActions } from '../../Globals/App/actions';
import { SignOut } from '../SignOut';

type MainLayoutPropsType = {
  headerTitle?: string;
  isHorizontalPadding?: boolean;
  isCreateOrder?: boolean;
};

const menuLinks = {
  guest: [],
  user: [
    {
      title: 'My cars',
      icon: <CarOutlined />,
      route: '/user/my-cars',
    },
    {
      title: 'Appointments',
      icon: <TableOutlined />,
      route: '/user/appointments',
    },
    {
      title: 'Account',
      icon: <SettingOutlined />,
      route: '/user/account',
    },
  ],
  admin: [
    {
      title: 'Clients',
      icon: <TeamOutlined />,
      route: '/admin/clients',
    },
    {
      title: 'Workshops',
      icon: <img src={WorkshopLogo} alt="work ikon" />,
      route: '/admin/workshops',
    },
    {
      title: 'Services',
      icon: <ToolOutlined />,
      route: '/admin/services',
    },
    {
      title: 'Invoices',
      icon: <DollarCircleOutlined />,
      route: '/admin/invoices',
    },
    {
      title: 'Notifications',
      icon: <BellOutlined />,
      route: '/admin/notifications',
    },
    {
      title: 'Orders',
      icon: <ShoppingOutlined />,
      route: '/admin/orders',
    },
    // {
    //   title: 'Reviews',
    //   icon: <MessageOutlined />,
    //   route: '/admin/reviews',
    // },
  ],
  workshop: [
    {
      title: 'Jobs',
      icon: <SettingOutlined />,
      route: '/workshop/jobs',
    },
    {
      title: 'Appointments',
      icon: <TableOutlined />,
      route: '/workshop/appointments',
    },
    {
      title: 'Finance',
      icon: <DollarOutlined />,
      route: '/workshop/finance',
    },
    {
      title: 'Settings',
      icon: <SettingOutlined />,
      route: '/workshop/settings',
    },
  ],
};

export const ModalContext = React.createContext({
  isModal: false,
  openModal: (data: { id: number; action: string }): void => {},
});

export const MainLayout: React.FC<MainLayoutPropsType> = ({
  children,
  headerTitle,
  isHorizontalPadding = true,
  isCreateOrder = true
}) => {
  const { Header, Content, Sider } = Layout;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const whatsUpRef = useRef<HTMLAnchorElement>(null);

  const [isModal, setIsModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{ id: number; action: string }>({
    id: 0,
    action: '',
  });
  const [isSidebarMobile, setIsSidebarMobile] = useState<boolean>();
  const [isSelected, setIsSelected] = useState<boolean>();
  const closeModal = () => {
    setIsModal(false);
  };

  const openModal = (data: { id: number; action: string }) => {
    setModalData(data);
    setIsModal(true);
  };

  const submitModal = (values: ApproveWorkshopRequestType) => {
    if (modalData.action === 'Approve') {
      dispatch(adminActions.approve(modalData.id, values));
    }
  };

  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `${pathSnippets.slice(0, index + 2).join('/')}`;
    const crumbsToShow = pathSnippets.slice(1);
    const title = `${crumbsToShow
      .slice(index, index + 1)
      .join(index + 1 === pathSnippets.length ? '/' : '')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={`/${url}`} className={classes.breadCrumbsLink}>
          {title}
        </Link>
      </Breadcrumb.Item>
    );
  });

  const userName = useSelector(
    (state: RootStateType) => state.auth.user?.user.username
  );

  const workShopName = useSelector((state: RootStateType) => {
    if (state.auth.user?.user.workshop) {
      return state.auth.user?.user.workshop?.name;
    }
  });

  const isLoading = useSelector((state: RootStateType) => state.app.loading);

  const userEmail = useSelector(
    (state: RootStateType) => state.auth.user?.user.email
  );
  const user = useSelector((state: RootStateType) => state.auth.user);

  const workshopInfo = useSelector(
    (state: RootStateType) => state.workshop.workshopData
  );

  useEffect(() => {
    if (
      history.location.pathname.indexOf('admin') === 1 &&
      user?.user.role.type !== 'authenticated_admin'
    ) {
      history.push('/');
    }
    if (
      history.location.pathname.indexOf('workshop') === 1 &&
      user?.user.role.type !== 'authenticated_workshop'
    ) {
      if (user?.user.role.type !== 'authenticated_admin') {
        history.push('/');
      }
    }
  }, [history, user]);

  const getMenuLinks = () => {
    let role: 'user' | 'admin' | 'workshop' | 'guest' = 'user';

    if (user) {
      if (user?.user.role.type.indexOf('admin') >= 0) {
        role = 'admin';
      } else if (user?.user.role.type.indexOf('workshop') >= 0) {
        role = 'workshop';
      }
    } else {
      role = 'guest';
    }
    return menuLinks[role].map(
      (item: { title: string; icon: any; route: string }, index) => {
        return (
          <Menu.Item key={item.route} icon={item.icon}>
            {item.title}
            <NavLink to={item.route} activeClassName="active-custom-link" />
          </Menu.Item>
        );
      }
    );
  };

  useEffect(() => {
    const elem = document.querySelector('.active-custom-link');
    const parent = elem?.parentNode?.parentNode as HTMLElement;
    parent?.classList.add('customSelect');
    const event = () => {
      parent?.classList.add('customSelect');
    };
    parent?.addEventListener('mouseleave', event);
    return () => {
      parent?.removeEventListener('mouseleave', event);
    };
  }, []);

  useEffect(() => {
    if (user?.user.role.type === 'authenticated_workshop') {
      if (user.user.workshop?.stripe_activated !== true) {
        /*  dispatch(
          appActions.addAlertMessage({
            type: 'warning',
            message:
              "Let's start by you telling us about your workshop - Please fill in all the fields",
          })
        ); */
      } else if (user.user.workshop?.stripe_activated === true) {
        dispatch(
          appActions.deleteAlertMessage(
            "Let's start by you telling us about your workshop - Please fill in all the fields"
          )
        );
      }
    }
  }, [dispatch, user?.user.role.type, workshopInfo]);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth} = window;
    return innerWidth;
  }
  
  const MobWidth = 570;

  return (
    <Spin spinning={isLoading} size="large" tip="loading...">
      <Layout className={classes.mainLayout}>
        <Sider
          theme="light"
          breakpoint="lg"
          className={classes.sideBarContainer}
          collapsedWidth="0"
          onBreakpoint={setIsSidebarMobile}
          onCollapse={(collapsed, type) => {setIsSelected(!collapsed)}}
          //trigger={null}
        >
         <div>
          <NavLink to="/">
              <div className={classes.logo}>
                <img src={Logo} alt="Logo" />
              </div>
            </NavLink>
            <Menu
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
              className={classes.menuContainer}
            >
              {getMenuLinks()}
            </Menu>
         </div>
          {!isCreateOrder && (
            <div className={classes.payments}>
            <p>We accept</p>
            <div className={classes.paymentIcons}>
              <img src={MasterCard} alt="mastercard" />
              <img src={Visa} alt="visa" />
              <img src={GPay} alt="google pay" />
              <img src={ApPay} alt="apple pay" />
            </div>
            <div className={classes.tabby}>
              <img src={Tabby} alt="tabby payment" />
            </div>
          </div>
          )}
        </Sider>
        <Layout>
          <Header className={classes.siteLayoutSubHeaderBackground}>
            {/* <div>
            {false && (
              <NavLink to="/">
                <LeftOutlined />
                {headerTitle}
              </NavLink>
            )} 
          </div> */}
            {!isSidebarMobile ? (
              <Breadcrumb>{breadcrumbItems}</Breadcrumb>
            ) : (
              <div></div>
            )}

            <AlertComponent />
            <div className={classes.whatsapp}>
              {user ? (
                <SignOut isHide={windowSize > MobWidth ? false: isSelected} name={workShopName || userName || userEmail || '' } />
              ) : (
                <div>
                  <NavLink to="/auth">Sign in</NavLink>
                </div>
              )}
              <a className={classes.whatsapp} href="https://wa.me/97180056445" target="_blank">
                <img src={watsappLogo} alt="whatsapp logo" />
              </a>
            </div>
          </Header>
          <Content className={classes.content}>
            <ModalContext.Provider
              value={{
                isModal,
                openModal,
              }}
            >
              <div
                className={`${cn(classes.siteLayoutBackground, {
                  [classes.removeHorizontalPadding]: !isHorizontalPadding,
                })}`}
              >
                {/* <Spin spinning={isLoading} size="large" tip="loading...">
                {children ? children : ''}
              </Spin> */}
                {children ? children : ''}
              </div>
            </ModalContext.Provider>
          </Content>
        </Layout>
        <ModalComponent
          isModal={isModal}
          toggleModal={closeModal}
          title="Approve workshop"
        >
          <WorkshopForm
            submitModal={submitModal}
            closeModal={closeModal}
            data={modalData}
          />
        </ModalComponent>
      </Layout>
    </Spin>
  );
};
