import React, {useEffect, useState} from 'react';
import VerifiedIcon from '../../../../Assets/Landing/Icon_1.svg';
import VerifiedIconMob from '../../../../Assets/Landing/Icon_1Mob.svg';
import CompareIcon from '../../../../Assets/Landing/Icon_2.svg';
import CompareIconMob from '../../../../Assets/Landing/Icon_2Mob.svg';
import MaintenanceIcon from '../../../../Assets/Landing/Icon_3.svg';
import MaintenanceIconMob from '../../../../Assets/Landing/Icon_3Mob.svg';
import BookIcon from '../../../../Assets/Landing/Icon_4.svg';
import BookIconMob from '../../../../Assets/Landing/Icon_4Mob.svg';
import HistoryIcon from '../../../../Assets/Landing/Icon_5.svg';
import HistoryIconMob from '../../../../Assets/Landing/Icon_5Mob.svg';
import { Card } from './Card';
import classes from './styles.module.scss';

export const Tailored: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth} = window;
    return innerWidth;
  }
  const SwitchIconsWidth = 555;
  const IPadSize = 700;

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
      <h2>Empowering You</h2>
        <div className={classes.cardContainer}>
          <div className={classes.couple}>
            <Card icon={(windowSize > SwitchIconsWidth) ? CompareIcon : CompareIconMob}>
              <p>Compare Live {(windowSize > IPadSize) ? <br></br> : ''}Prices </p>
            </Card>
            <Card icon={(windowSize > SwitchIconsWidth) ? MaintenanceIcon : MaintenanceIconMob}>
              <p>Agency Alternative {(windowSize > IPadSize) ? <br></br> : ''}Workshops </p>
            </Card>
            <Card icon={(windowSize > SwitchIconsWidth) ? BookIcon : BookIconMob}>
              <p>Free Pickup {(windowSize > IPadSize) ? <br></br> : ''}and Delivery </p>
            </Card>
            <Card icon={(windowSize > SwitchIconsWidth) ? HistoryIcon : HistoryIconMob}>
              <p>Service History{(windowSize > IPadSize) ? <br></br> : ''} in one Place</p>
            </Card> 
            <Card icon={(windowSize > SwitchIconsWidth) ? VerifiedIcon : VerifiedIconMob}>
              <p>Warranty and{(windowSize > IPadSize) ? <br></br> : ''} Genuine Parts</p>
            </Card> 
          </div>
        </div>
      </div>
    </div>
  );
};
