import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { TableComponent } from '../../../Components/Table/Table';
import classes from './style.module.scss';
import { RootStateType } from '../../../Stores';
import { adminActions } from '../../../Globals/Admin/actions';
import { CarResponseType } from '../../../Types/authTypes';


const tableConfig = [
  { title: 'Date', key: 'created_at', isSort: true, isClickable: false },
  { title: 'Order ID', key: 'order_id', isSort: false, isClickable: true },
  { title: 'Car Info', key: 'car_info', isSort: false, isClickable: false },
  { title: 'Services', key: 'services', isSort: false, isClickable: false },
  { title: 'Amount', key: 'amount', isSort: false, isClickable: false },
  { title: 'Status', key: 'status', isSort: false, isClickable: false },
];

const LIMIT_ON_PAGE = 20;

type AppointmentsPropsType = {
  userId: number;
};

export const Appointments: React.FC<AppointmentsPropsType> = ({ userId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(LIMIT_ON_PAGE);
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const appointments = useSelector((state: RootStateType) => state.admin.appointments);

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const redirectTo = (id: number) => {
    history.push(`/admin/orders/${id}`);
  };

  const onSort = (fieldKey: string, order?: 'ascend' | 'descend') => {
    if (false) {
      if (order === 'ascend') {
        setSortValue([{ approved: 'ASC' }]);
      } else {
        setSortValue(order ? [{ approved: 'DESC' }] : []);
      }
    } else {
      setSortValue(
        order ? [{ [fieldKey]: order === 'ascend' ? 'ASC' : 'DESC' }] : []
      );
    }
  };

  const onAction = (data: { id: number; action: string }) => {
    console.log(data);
  };

  const carToString = (car: CarResponseType) => {
    return `${car.year}  ${car.make.title} ${car.trim.title}`
  }

  const createDataList = () => {
    if (true) {
      return appointments.rows.map((appointment, index) => {
        return {
          key: appointment.id,
          created_at: moment(appointment.created_at).format('DD MMM YYYY hh:mm A'),
          order_id: appointment.id,
          car_info: carToString(appointment.customer_car),
          services: [
            ...appointment.order_services.additional.map((item) => item.title),
            ...appointment.order_services.log_book.map((item) => item.title),
          ],
          amount: 'AED ' + appointment.total_price,
          status: appointment.status,
        };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    dispatch(
      adminActions.getCustomerAppointments(userId, {
        page: { page: currentPage, limit: limitOnPage },
        sort: sortValue,
      })
    );
  }, [currentPage, dispatch, limitOnPage, sortValue, userId]);

  return (
    <div className={classes.container}>
      <TableComponent
        dataForTableList={createDataList()}
        tableConfig={tableConfig}
        totalItems={appointments.count}
        onChangePage={onChangePage}
        goTo={redirectTo}
        onSort={onSort}
        onAction={onAction}
        isLastColumn={false}
      />
    </div>
  );
};
