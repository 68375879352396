import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import SearchBox from './SearchBox';

const containerStyle = {
  width: '100%',
  height: '300px',
};

const centerDefault = {
  lat: 25.2048493,
  lng: 55.2707828,
};

const GOOGLE_MAP_LINK = 'https://www.google.com/maps/search/?api=1'

const API_KEY_GOOGLE_MAPS = 'AIzaSyCM6vspawgSzba0CU20Bq_BIIWCN3ouztM';
const libraries: Array<
  'drawing' | 'geometry' | 'localContext' | 'places' | 'visualization'
> = ['places'];

export type PointType = {
  address: string;
  link: string;
  lat: number;
  lng: number;
};

type MapComponentPropsType = {
  getMeAddress?: (point: PointType) => void;
  lat?: number | null;
  long?: number | null;
};

const MapComponent: React.FC<MapComponentPropsType> = ({
  getMeAddress,
  lat,
  long,
}) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] =
    useState<{ lat: number; lng: number }>(centerDefault);
  const [point, setPoint] = useState<PointType | null>(null);

  //const [myLocation, setMyLocation] = useState<boolean>(false);

  const onLoad = React.useCallback(function callback(map) {
    //@ts-ignore
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const createMapLink = (coords: {lat: number, lng: number}, place_id?: string) => {
    if(place_id) {
      return `${GOOGLE_MAP_LINK}&query=${coords.lat},${coords.lng}&query_place_id=${place_id}`
    } else {
      return `${GOOGLE_MAP_LINK}&query=${coords.lat},${coords.lng}`
    }
  }

  const onPlacesChanged = (e: any) => {
    console.log(e)
    const { location } = e[0]?.geometry;
    const { formatted_address, name = '' } = e[0];
    const coords = {
      lat: location.lat(),
      lng: location.lng(),
    };
    const url = e[0].url
    const link = createMapLink(coords, e[0].place_id)
    setCenter(coords);
    setPoint({
      address: formatted_address + ', ' + name,
      lat: location.lat(),
      lng: location.lng(),
      link
    });
  };



  const setPointAddressFromCord = (lat: number, lng: number) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY_GOOGLE_MAPS}&language=en`;
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.results.length > 0) {
          const { formatted_address, name = '', place_id } = resJson.results[0];
          const link = createMapLink({lat, lng}, place_id);
          setPoint({
            address: formatted_address + ', ' + name,
            lat,
            lng,
            link
          });
        }
      });
  };

  const onClick = (object: any) => {
    const lat = object.latLng.lat();
    const lng = object.latLng.lng();
    setPointAddressFromCord(lat, lng);
  };

  const setMyLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (geolocation: GeolocationPosition) => {
        const coord = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
        if (coord) {
          setCenter(coord);
          setPointAddressFromCord(coord.lat, coord.lng);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (long && lat) {
      const coord = {
        lat,
        lng: long,
      };
      setPointAddressFromCord(coord.lat, coord.lng);
      setCenter(coord);
    } else if (!long || !lat) {
      //setMyLocation();
    }
  }, [long, lat, setMyLocation]);

  useEffect(() => {
    if (point && getMeAddress) {
      getMeAddress(point);
    }
  }, [point]);

  const getRef = (ref: GoogleMap | null) => {
    ref?.state.map?.panTo(center);
  };

  return (
    <div style={{ paddingTop: '10px' }}>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={API_KEY_GOOGLE_MAPS}
        libraries={libraries}
        language="en"
      >
        <SearchBox
          placeholder="Enter your address"
          onPlacesChanged={onPlacesChanged}
          map={map}
          point={point}
          setMyLocation={setMyLocation}
        />
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onClick}
          ref={getRef}
        >
          {point && <Marker position={{ lat: point.lat, lng: point.lng }} />}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default React.memo(MapComponent);
