import { InferActionsTypes } from '../../Stores';
import * as authConstants from './constants';
import {
  SignUpWorkshopType,
  SignUpUserType,
  signUpResponseType,
  SignInUserType,
  ResetPasswordRequest,
  CreateOrderRequestType,
  ResetPasswordRequestType,
} from './../../Types/authTypes';
import { SavedDataObjectType } from '../../Types/userTypes';

type userRoleType = 'admin' | 'customer' | 'workshop';
export const authActions = {
  signUp: (payload: SignUpWorkshopType | SignUpUserType) =>
    ({
      type: authConstants.SIGN_UP,
      payload,
    } as const),
  signIn: (payload: SignInUserType, link?: string) =>
    ({
      type: authConstants.SIGN_IN,
      payload,
      link
    } as const),
  signOut: () =>
    ({
      type: authConstants.SIGN_OUT,
    } as const),
  setProfileData: (payload: signUpResponseType) =>
    ({
      type: authConstants.SET_PROFILE_DATA,
      payload,
    } as const),
  setUserRole: (payload: userRoleType) =>
    ({
      type: authConstants.SET_USER_ROLE,
      payload,
    } as const),
  isLogin: () =>
    ({
      type: authConstants.TOGGLE_IS_LOGIN,
    } as const),
  setRoute: (payload: string | null) =>
    ({
      type: authConstants.SET_ROUTE,
      payload,
    } as const),
  updatePassword: (payload: ResetPasswordRequest) =>
    ({
      type: authConstants.UPDATE_PASSWORD,
      payload,
    } as const),
  setOrderData: (payload: CreateOrderRequestType) =>
    ({
      type: authConstants.SET_ORDER_DATA,
      payload,
    } as const),
  resetOrderData: () =>
    ({
      type: authConstants.RESET_ORDER_DATA,
    } as const),
  setOrderID: (payload: number | null) =>
    ({
      type: authConstants.SET_ORDER_ID,
      payload,
    } as const),
  setSavedSummaryData: (payload: SavedDataObjectType) =>
    ({
      type: authConstants.SET_SAVED_SUMMARY_DATA,
      payload,
    } as const),
  forgotPassword: (payload: { email: string }) =>
    ({
      type: authConstants.FORGOT_PASSWORD,
      payload,
    } as const),
  isSendingConfirmed: (payload: boolean) =>
    ({
      type: authConstants.IS_SENDING_CONFIRMED,
      payload,
    } as const),
  resetPassword: (payload: ResetPasswordRequestType) =>
    ({
      type: authConstants.RESET_PASSWORD,
      payload,
    } as const),
};

export type ActionTypes = InferActionsTypes<typeof authActions>;
