import {
  CreateOrderRequestType,
  ResetPasswordRequest,
  ResetPasswordRequestType,
  SignInUserType,
  signUpResponseType,
} from './../../Types/authTypes';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { authActions } from './actions';
import * as authSelectors from './selectors';
import { authApi } from './services';
import * as authConstants from './constants';
import { appActions } from '../App/actions';
import * as userSelectors from '../User/selectors';

export function* signUp({ payload }: any) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const confirmResult: signUpResponseType = yield call(
      authApi.signUp,
      payload
    );
    yield put(authActions.setProfileData(confirmResult));
    //GTM
    //Start
    let dataLayer = window.dataLayer || []
    dataLayer.push({
      "event": "signup",
      "user_id": `${confirmResult.user.id}`,
      "email": `${confirmResult.user.email}`,
    });
    //End
    if (confirmResult.user.role.type === 'authenticated_workshop') {
      yield put(
        authActions.setRoute('/auth/workshop/signup/workshop-info-mode')
      );
    } else if (confirmResult.user.role.type === 'authenticated_admin') {
      yield put(authActions.setRoute('/admin/clients'));
    } else if (confirmResult.user.role.type === 'authenticated') {
      const orderData: CreateOrderRequestType = yield select(
        userSelectors.getOrderData
      );
      if (orderData) {
        yield put(authActions.setRoute('/user/get-quote'));
      } else {
        yield put(authActions.setRoute('/user/my-cars'));
      }
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
    yield put(
      appActions.setError(e.response.data.message[0].messages[0].message)
    );
  }
}

export function* signIn({
  payload,
  link,
}: {
  payload: SignInUserType;
  type: string;
  link?: string;
}) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());

    const confirmResult: signUpResponseType = yield call(
      authApi.signIn,
      payload
    );
    yield put(authActions.setProfileData(confirmResult));
    //GTM
    //Start
    let dataLayer = window.dataLayer || []
    dataLayer.push({
      "event": "login",
      "user_id": `${confirmResult.user.id}`,
      "email": `${confirmResult.user.email}`,
    });
    //End
    if (confirmResult.user.role.type === 'authenticated_workshop') {
      yield put(authActions.setRoute(link || '/workshop/jobs'));
    } else if (confirmResult.user.role.type === 'authenticated_admin') {
      yield put(authActions.setRoute(link || '/admin/clients'));
    } else if (confirmResult.user.role.type === 'authenticated') {
      const orderData: CreateOrderRequestType = yield select(
        userSelectors.getOrderData
      );
      if (orderData) {
        yield put(authActions.setRoute(link || '/user/get-quote'));
      } else {
        yield put(authActions.setRoute(link || '/user/my-cars'));
      }
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(
      appActions.setError(e.response.data.message[0].messages[0].message)
    );
    yield put(appActions.closeLoader());
  }
}

export function* updatePassword(payload: {
  type: string;
  payload: ResetPasswordRequest;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    yield call(authApi.updatePassword, token, payload.payload);
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'success',
        message: 'password was changed',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(appActions.deleteAlertMessage('password was changed'));
  } catch (e: any) {
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'error',
        message: 'something went wrong',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(appActions.deleteAlertMessage('something went wrong'));
  }
}

export function* forgotPassword(payload: {
  type: string;
  payload: { email: string };
}) {
  try {
    yield put(appActions.openLoader());
    yield call(authApi.forgotPassword, payload.payload);
    yield put(appActions.closeLoader());
    yield put(authActions.isSendingConfirmed(true));
  } catch (e: any) {
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'error',
        message: 'something went wrong',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(appActions.deleteAlertMessage('something went wrong'));
  }
}

export function* resetPassword(payload: {
  type: string;
  payload: ResetPasswordRequestType;
}) {
  try {
    yield put(appActions.openLoader());
    const confirmResult: signUpResponseType = yield call(
      authApi.resetPassword,
      payload.payload
    );
    yield put(authActions.setProfileData(confirmResult));
    yield put(
      appActions.addAlertMessage({
        type: 'success',
        message: 'password was changed',
      })
    );
    if (confirmResult.user.role.type === 'authenticated_workshop') {
      yield put(authActions.setRoute('/workshop/jobs'));
    } else if (confirmResult.user.role.type === 'authenticated') {
      const orderData: CreateOrderRequestType = yield select(
        userSelectors.getOrderData
      );
      if (orderData) {
        yield put(authActions.setRoute('/user/get-quote'));
      } else {
        yield put(authActions.setRoute('/user/my-cars'));
      }
    }
    yield put(appActions.closeLoader());
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(appActions.deleteAlertMessage('password was changed'));
  } catch (e: any) {
    yield put(appActions.closeLoader());
    yield put(
      appActions.addAlertMessage({
        type: 'error',
        message: 'something went wrong',
      })
    );
    const delay = (time: number) =>
      new Promise((resolve) => setTimeout(resolve, time));
    yield call(delay, 2000);
    yield put(appActions.deleteAlertMessage('something went wrong'));
  }
}

export default function* WatcherSaga() {
  yield takeLatest(authConstants.SIGN_UP, signUp);
  yield takeLatest(authConstants.SIGN_IN, signIn);
  yield takeLatest(authConstants.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(authConstants.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(authConstants.RESET_PASSWORD, resetPassword);
}
