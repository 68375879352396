import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';
import classes from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from '../../../Components/Auth';
import { RootStateType } from '../../../Stores';
import { authActions } from '../../../Globals/Auth/actions';
import classNames from 'classnames';
import { ManagePasswordForm } from '../../UserSettings/PaymentPreferences/Components/ManagePasswordForm';
import { useHistory, useLocation } from 'react-router-dom';

type ForgotPasswordPropsType = {};
export const ForgotPassword: React.FC<ForgotPasswordPropsType> = ({}) => {
  const dispatch = useDispatch();
  const location = useLocation<{code?: string}>();
  const history = useHistory();

  const [isFirstSending, setIsFirstSending] = useState(true);
  const [email, setEmail] = useState<string>();

  const error = useSelector((state: RootStateType) => state.app.error);
  const isSendingConfirmed = useSelector(
    (state: RootStateType) => state.auth.isSendingConfirmed
  );
  const route = useSelector((state: RootStateType) => state.auth.route);

  const resentEmail = () => {
    if (isFirstSending && email) {
      dispatch(authActions.forgotPassword({ email }));
    }
    setIsFirstSending(false);
  };

  const onResetPassword = (payload: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (location.state?.code) {
      dispatch(
        authActions.resetPassword({
          code: location.state.code.split('?code=')[1],
          password: payload.newPassword,
          passwordConfirmation: payload.confirmPassword,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(authActions.isSendingConfirmed(false));
  }, [dispatch]);

  useEffect(() => {
    if (route) {
      history.push(route);
      dispatch(authActions.setRoute(''));
    }
    return () => {
      dispatch(authActions.setRoute(''));
    };
  }, [route, dispatch, history]);


  return (
    <div className={classes.contain}>
      {error && (
        <Alert type="error" message={error} className={classes.alert} />
      )}
      <div>
        <h1 className={classes.header}>Reset Password</h1>
        {!isSendingConfirmed ? (
          <div className={classes.forgotPassword}>
            Can’t remember your password? Enter your email address and we will
            send you an email to create a new password.
          </div>
        ) : (
          <div className={classes.forgotPassword}>
            If an account exists for that email address, we have sent you an
            email with a link to reset your password.
            {isFirstSending ? (
              <div className={classes.subTitle}>
                Didn’t receive an email? <br /> That’s okay, we can{' '}
                <span
                  className={classNames({
                    [classes.link]: isFirstSending,
                  })}
                  onClick={resentEmail}
                >
                  resend the email
                </span>
              </div>
            ) : (
              <div className={classes.subTitle}>
                Password reset email has been resent
              </div>
            )}
          </div>
        )}
      </div>
      {location.state?.code ? (
        <ManagePasswordForm
          onSubmit={onResetPassword}
          onCancel={() => {}}
          isResetMode={true}
        />
      ) : (
        !isSendingConfirmed && (
          <LoginForm
            userRole={'forgot-password'}
            isLogin={true}
            setEmail={setEmail}
          />
        )
      )}
    </div>
  );
};
