import { Moment } from 'moment';
import { FormId } from '../../Components/AddCar';
import { PURGE } from 'redux-persist';
import {
  AvailableDaysResponseType,
  CarDeliveriesType,
  CheckCancelRescheduleOrderResponse,
  CustomerOrderType,
  ScheduledMaintenanceRequestType,
  ServiceHistoryRequestType,
  UserProfileType,
} from '../../Types/userTypes';
import { OrderService, OrdersRequestType } from '../../Types/workshopTypes';
import {
  AddressPointType,
  AppointmentRespType,
  CarDetailsFormType,
  CreateOrderRequestType,
  IntervalResponseType,
  MakesResponseType,
  ModelResponseType,
  OfferedServicesResponseType,
  TrimResponseType,
  WorkShopType,
  YearResponseType,
  PaymentsResponseType,
  WorkShopTypeRequest,
  CarsResponseType,
  CarResponseType,
  CitiesTypeRequest,
} from './../../Types/authTypes';
import { ActionTypes } from './actions';
import * as userConstants from './constants';
import { act } from '@testing-library/react';

export type InitialStateType = {
  makes: Array<MakesResponseType>;
  year: Array<YearResponseType>;
  model: Array<ModelResponseType>;
  trim: Array<TrimResponseType>;
  intervals: Array<IntervalResponseType> | null;
  allIntervals: Array<{trimId: number, rows: IntervalResponseType[]}> | [];
  services: OfferedServicesResponseType | null;
  formValues: CarDetailsFormType | null;
  selectedOtherServicesId: Array<number>;
  selectedCarRepairId: Array<number>;
  responseTabby: any;
  carRepairText: string;
  selectedOptionalServices: Array<number>;
  isBasicSelected: boolean | null;
  cities: Array<CitiesTypeRequest>;
  workshops: WorkShopTypeRequest | { count: 0; rows: [] };
  location: 'at_location' | 'at_workshop' | null;
  addressPoint: AddressPointType | null;
  client_address: string | null;
  appointment: null | AppointmentRespType;
  selectedWorkshop: WorkShopType | null;
  carList: CarsResponseType;
  selectedDate: { startDate: string; startTime: string } | null;
  orderData: null | CreateOrderRequestType;
  redirect_url: string | null;
  client_secret: string | null;
  payment: PaymentsResponseType | null;
  formId: FormId | null;
  finalSummary: number | null;
  isModal: boolean;
  isTabby: boolean;
  modalData: {
    type: 'main' | 'logbook';
    title: string;
    isSelected: boolean;
  };
  isModalFromSidebar: boolean;
  deliveryCar: {
    pick_up_slot: number | null;
    drop_off_slot: number | null;
    time: {
      pick_up_time_slot: Moment | null;
      drop_off_time_slot: Moment | null;
    };
  };
  isHasMore: boolean;
  orders: OrdersRequestType<Array<OrderService>>;
  selectedCar: CarResponseType | null;
  userProfile: UserProfileType | null;
  serviceHistoryForCar: ServiceHistoryRequestType | null;
  scheduledMaintenance: ScheduledMaintenanceRequestType | null;
  order: CustomerOrderType | null;
  cancelStatus: CheckCancelRescheduleOrderResponse | null;
  rescheduleStatus: CheckCancelRescheduleOrderResponse | null;
  customer_note: string;
  car_deliveries: Array<CarDeliveriesType>;
  availableDays: AvailableDaysResponseType | null;
};

const initialState: InitialStateType = {
  makes: [],
  year: [],
  model: [],
  trim: [],
  intervals: null,
  allIntervals: [],
  services: null,
  formValues: null,
  selectedOtherServicesId: [],
  selectedCarRepairId: [],
  responseTabby: {},
  carRepairText: '',
  selectedOptionalServices: [],
  isBasicSelected: null,
  cities: [],
  workshops: { count: 0, rows: [] },
  location: null,
  addressPoint: null,
  client_address: null,
  appointment: null,
  selectedWorkshop: null,
  carList: { count: 0, rows: [] },
  selectedDate: null,
  orderData: null,
  formId: null,
  redirect_url: null,
  client_secret: null,
  payment: null,
  isTabby: false,
  isModal: false,
  finalSummary: null,
  modalData: {
    type: 'main',
    title: '',
    isSelected: false,
  },
  isModalFromSidebar: false,
  deliveryCar: {
    pick_up_slot: null,
    drop_off_slot: null,
    time: {
      pick_up_time_slot: null,
      drop_off_time_slot: null,
    },
  },
  isHasMore: true,
  orders: { count: 0, rows: [] },
  selectedCar: null,
  userProfile: null,
  serviceHistoryForCar: null,
  scheduledMaintenance: null,
  order: null,
  cancelStatus: null,
  rescheduleStatus: null,
  customer_note: '',
  car_deliveries: [],
  availableDays: null,
};

const userReducer = (
  state: InitialStateType = initialState,
  action: ActionTypes | { type: typeof PURGE }
): InitialStateType => {
  switch (action.type) {
    case userConstants.SET_MAKES:
      return {
        ...state,
        makes: action.payload,
      };
    case userConstants.SET_YEAR_OF_MAKE:
      return {
        ...state,
        year: action.payload,
      };
    case userConstants.SET_MODEL_OF_YEAR:
      return {
        ...state,
        model: action.payload,
      };
    case userConstants.SET_MODEL_TRIM:
      return {
        ...state,
        trim: action.payload,
      };
    case userConstants.SET_INTERVALS:
      return {
        ...state,
        intervals: action.payload,
      };
    case userConstants.SET_ALL_INTERVALS:
      return {
        ...state,
        allIntervals: action.payload,
      };
    case userConstants.SET_SERVICES_FOR_CURRENT_INTERVAL:
      return {
        ...state,
        services: action.payload,
      };
    case userConstants.SET_FORM_VALUES:
      return {
        ...state,
        formValues: action.payload,
      };
    case userConstants.REMOVE_FORM_ID:
      return {
        ...state,
        formId: null,
        formValues: null,
      };
    case userConstants.ADD_OTHER_SERVICE:
      return {
        ...state,
        selectedOtherServicesId: [...state.selectedOtherServicesId, action.id],
      };
    case userConstants.REMOVE_OTHER_SERVICE:
      return {
        ...state,
        selectedOtherServicesId: state.selectedOtherServicesId.filter(
          (item) => item !== action.id
        ),
      };
    case userConstants.ADD_CAR_REPAIR:
      return {
        ...state,
        selectedCarRepairId: [...state.selectedCarRepairId, action.id],
      };
    case userConstants.SET_TABBY_SESSION:
      return {
        ...state,
        responseTabby: action,
      };
    case userConstants.REMOVE_CAR_REPAIR:
      return {
        ...state,
        selectedCarRepairId: state.selectedCarRepairId.filter(
          (item) => item !== action.id
        ),
      };
    case userConstants.SET_CAR_REPAIR_TEXT:
      return {
        ...state,
        carRepairText: action.text
      };
    case userConstants.REMOVE_ALL_CAR_REPAIR:
      return {
        ...state,
        selectedCarRepairId: [],
        carRepairText: ''
      };
    case userConstants.SET_SELECTED_MAIN_SERVICE_TYPE:
      return {
        ...state,
        isBasicSelected: action.isBasic,
      };
    case userConstants.SET_OPTIONAL_SERVICES:
      return {
        ...state,
        selectedOptionalServices: [
          ...state.selectedOptionalServices,
          ...action.ids,
        ],
      };
    case userConstants.SET_ORDER_SUMMARY:
      return {
        ...state,
        finalSummary: action.payload
      };
    case userConstants.REMOVE_OPTIONAL_SERVICES:
      return {
        ...state,
        selectedOptionalServices: state.selectedOptionalServices.filter(
          (item) => item !== action.id
        ),
      };
    case userConstants.SET_CITIES:
      return {
        ...state,
        cities: action.cities,
      };
    case userConstants.SET_WORKSHOPS_ON_LOCATION:
      return {
        ...state,
        workshops: action.payload,
      };
    case userConstants.SET_SELECTED_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case userConstants.SET_ADDRESS_POINT:
      return {
        ...state,
        addressPoint: action.payload,
      };
    case userConstants.SET_CLIENT_ADDRESS:
      return {
        ...state,
        client_address: action.address,
      };
    case userConstants.SET_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      };
    case userConstants.SET_SELECTED_WORKSHOP:
      return {
        ...state,
        selectedWorkshop: action.payload,
      };
    case userConstants.RESET_SELECTED_WORKSHOP:
      return {
        ...state,
        selectedWorkshop: null,
      };
    case userConstants.SET_CARS_LIST:
      return {
        ...state,
        carList: action.payload,
      };
    case userConstants.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case userConstants.RESET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: null,
      };
    case userConstants.RESET_ORDER_DATA:
      const { carList, ...initialWithoutCarList } = initialState;
      return {
        ...state,
        ...initialWithoutCarList,
      };
    case userConstants.RESET_WITHOUT_FORM_DATA:
      const {
        formValues,
        services,
        intervals,
        makes,
        year,
        model,
        formId,
        trim,
        ...initialWithoutForm
      } = initialState;
      return {
        ...state,
        ...initialWithoutForm,
      };
    case userConstants.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        redirect_url: action.payload.redirect_url,
        client_secret: action.payload.client_secret,
      };
    case userConstants.RESET_REDIRECTS:
      return {
        ...state,
        redirect_url: null,
        client_secret: null,
      };
    case userConstants.SET_IS_TABBY:
      return {
        ...state,
        isTabby: action.payload,
      };
    case userConstants.SET_ORDER_DATA:
      return {
        ...state,
        orderData: action.payload,
      };
    case userConstants.SET_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };
    case userConstants.SET_FORM_ID:
      return {
        ...state,
        formId: action.payload,
      };
    case userConstants.SET_MODAL_TYPE:
      return {
        ...state,
        modalData: action.payload,
      };
    case userConstants.SET_IS_MODAL:
      return {
        ...state,
        isModal: action.payload,
      };
    case userConstants.TOGGLE_MODAL_IS_SIDEBAR:
      return {
        ...state,
        isModalFromSidebar: !state.isModalFromSidebar,
      };
      case userConstants.SET_DELIVERY_PICK_UP:
        return {
          ...state,
          deliveryCar: {
            ...state.deliveryCar,
            pick_up_slot: action.payload,
          },
        };
      case userConstants.SET_DELIVERY_DROP_OFF:
        return {
          ...state,
          deliveryCar: {
            ...state.deliveryCar,
            drop_off_slot: action.payload,
          },
        };
    case userConstants.REMOVE_DELIVERY_CAR:
      return {
        ...state,
        deliveryCar: {
          ...state.deliveryCar,
          pick_up_slot: null,
          drop_off_slot: null,
        },
      };
    case userConstants.SET_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          rows: [...state.orders.rows, ...action.payload.rows],
        },
      };
    case userConstants.RESET_ORDERS:
      return {
        ...state,
        orders: { count: 0, rows: [] },
        isHasMore: false,
        formValues: null,
      };
    case userConstants.IS_HAS_MORE:
      return {
        ...state,
        isHasMore: action.payload,
      };
    case userConstants.SET_SELECTED_CAR:
      return {
        ...state,
        selectedCar: action.payload,
      };
    case userConstants.SET_DELIVERY_TIME:
      return {
        ...state,
        deliveryCar: {
          ...state.deliveryCar,
          time: {
            ...state.deliveryCar.time,
            ...action.payload,
          },
        },
      };
    case userConstants.RESET_DELIVERY:
      return {
        ...state,
        deliveryCar: initialState.deliveryCar,
      };
    case userConstants.SET_UPDATES_USER:
      return {
        ...state,
        userProfile: action.payload,
      };
    case userConstants.SET_SERVICE_HISTORY_FOR_CAR:
      return {
        ...state,
        serviceHistoryForCar: action.payload,
      };
    case userConstants.SET_SCHEDULED_MAINTENANCE_FOR_CAR:
      return {
        ...state,
        scheduledMaintenance: action.payload,
      };
    case userConstants.SET_REVIEW:
      return {
        ...state,
        orders: {
          ...state.orders,
          rows: state.orders.rows.map((order) => {
            if (order.id === action.payload.order) {
              return { ...order, review: action.payload };
            } else {
              return order;
            }
          }),
        },
      };
    case userConstants.SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case userConstants.SET_SAVED_SUMMARY_DATA:
      const { isBasic, otherServices, parts, ...data } = action.payload;
      return {
        ...state,
        ...{ ...data, isBasicSelected: isBasic },
      };
    case userConstants.SET_CANCEL_STATUS:
      return {
        ...state,
        cancelStatus: action.payload,
      };
    case userConstants.SET_RESCHEDULE_STATUS:
      return {
        ...state,
        rescheduleStatus: action.payload,
      };
    case userConstants.SET_NOTES:
      return {
        ...state,
        customer_note: action.payload,
      };
    case userConstants.SET_CHANGED_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          rows: state.orders.rows.map((order) => {
            if (order.id === action.payload.id) {
              const { customer_car, workshop, ...otherValues } = action.payload;
              return { ...order, ...otherValues };
            } else {
              return order;
            }
          }),
        },
      };
    case userConstants.SET_CAR_DELIVERIES_TIME:
      return {
        ...state,
        car_deliveries: action.payload,
      };
    case userConstants.SET_AVAILABLE_DAYS:
      return {
        ...state,
        availableDays: action.payload,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
