import {
  WorkShopType,
  WorkShopRequestType,
  ServiceForAdmin,
  PartType,
  UserType,
  UserTypeRequest,
  WorkShopTypeRequest,
  CarResponseType,
} from './../../Types/authTypes';
import axios from 'axios';
import * as constants from '../../Utils/Api/constants';
import {
  ApproveWorkshopRequestType,
  RequestInvoiceType,
} from '../../Types/workshopTypes';
import {
  AdminReviewsResponseType,
  OrderForAdminType,
  ResponseAdminAppointmentsType,
  ResponseCarForAdminType,
  ResponseNotificationsType,
  ResponseOrderAdminType,
} from '../../Types/adminTypes';
import { CarUpdateRequestType } from '../../Types/userTypes';

export const adminApi = {
  getWorkshops: async (token: string, query?: string) => {
    return axios
      .get<WorkShopTypeRequest>(
        `${constants.ApiUrls.BASE_URL}/workshops${query?.length ? query : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getUsers: async (token: string, query?: string) => {
    return axios
      .get<UserTypeRequest>(
        `${constants.ApiUrls.BASE_URL}/users${
          query?.length ? query : ''
        }&role.type=authenticated_client`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  approve: async (
    token: string,
    id: number,
    payload: ApproveWorkshopRequestType
  ) => {
    return axios
      .put<any>(`${constants.ApiUrls.BASE_URL}/workshops/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  searchWorkshops: async (token: string, value: string) => {
    return axios
      .get<Array<WorkShopType>>(
        `${constants.ApiUrls.BASE_URL}/workshops?name_contains=${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  editWorkshop: async (
    token: string,
    value: WorkShopRequestType,
    id: number
  ) => {
    return axios
      .put<WorkShopType>(
        `${constants.ApiUrls.BASE_URL}/workshops/${id}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getServices: async (token: string, trimId: number, intervalId: number) => {
    return axios
      .get<Array<ServiceForAdmin>>(
        `${constants.ApiUrls.BASE_URL}/services-list?maintenance_interval=${intervalId}&trim=${trimId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getParts: async (token: string, modelId: number) => {
    return axios
      .get<Array<PartType>>(
        `${constants.ApiUrls.BASE_URL}/part-prices-list?model=${modelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getUser: async (token: string, id: number) => {
    return axios
      .get<UserType>(`${constants.ApiUrls.BASE_URL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  getOrders: async (token: string, query?: string) => {
    return axios
      .get<ResponseOrderAdminType>(
        `${constants.ApiUrls.BASE_URL}/admin-orders${
          query?.length ? query : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getCars: async (token: string, id: number, query?: string) => {
    return axios
      .get<ResponseCarForAdminType>(
        `${constants.ApiUrls.BASE_URL}/admin-customer-cars/${id}/${
          query?.length ? query : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  updateCar: async (
    token: string,
    payload: CarUpdateRequestType,
    id: number
  ) => {
    return axios
      .put<CarResponseType>(
        `${constants.ApiUrls.BASE_URL}/customer-cars/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  deleteCar: async (token: string, id: number) => {
    return axios
      .delete<CarResponseType>(
        `${constants.ApiUrls.BASE_URL}/customer-cars/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getOrderById: async (token: string, id: number) => {
    return axios
      .get<OrderForAdminType>(
        `${constants.ApiUrls.BASE_URL}/admin-orders/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  finishOrder: async (token: string, id: number) => {
    return axios
      .put<OrderForAdminType>(`${constants.ApiUrls.BASE_URL}/finish-order/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },  
      })
      .then((resp) => resp.data);
  },
  deleteOrder: async (token: string, id: number) => {
    return axios
      .delete<OrderForAdminType>(`${constants.ApiUrls.BASE_URL}/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  getCustomerAppointments: async (
    token: string,
    id: number,
    query?: string
  ) => {
    return axios
      .get<ResponseAdminAppointmentsType>(
        `${constants.ApiUrls.BASE_URL}/admin-customer-appointments/${id}/${
          query?.length ? query : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  blockUnblockUser: async (
    token: string,
    id: number,
    payload: { blocked: boolean }
  ) => {
    return axios
      .put<UserType>(`${constants.ApiUrls.BASE_URL}/users/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  deleteWorkshop: async (token: string, id: number) => {
    return axios
      .delete<any>(`${constants.ApiUrls.BASE_URL}/workshops/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  deleteCustomer: async (token: string, id: number) => {
    return axios
      .delete<OrderForAdminType>(`${constants.ApiUrls.BASE_URL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  getInvoices: async (token: string, query: string) => {
    return axios
      .get<RequestInvoiceType<WorkShopType>>(
        `${constants.ApiUrls.BASE_URL}/admin-invoices${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getMonthlyRevenue: async (token: string) => {
    return axios
      .get<{ total_price: number }>(
        `${constants.ApiUrls.BASE_URL}/admin-month-revenue`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getNotifications: async (token: string, query: string) => {
    return axios
      .get<ResponseNotificationsType>(
        `${constants.ApiUrls.BASE_URL}/notifications${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  getReviews: async (token: string, query: string) => {
    return axios
      .get<AdminReviewsResponseType>(
        `${constants.ApiUrls.BASE_URL}/admin-reviews${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => resp.data);
  },
  deleteReview: async (token: string, id: number) => {
    return axios
      .delete<any>(`${constants.ApiUrls.BASE_URL}/reviews/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => resp.data);
  },
  changeStatus: async (
    token: string,
    id: number,
    status: 'accepted' | 'declined' | 'processing' | 'finished'
    ) => {
    const resp = await axios.put<OrderForAdminType>(
      `${constants.ApiUrls.BASE_URL}/order-job-status/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  },
  changePaymentStatus: async (
    token: string,
    id: number,
    status: 'paid' | 'not_paid'
  ) => {
    const resp = await axios.put<{ status: 'paid' | 'not_paid' }>(
      `${constants.ApiUrls.BASE_URL}/orders/workshop-payment/${id}`,
      {
        workshop_payment_status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  },
};
