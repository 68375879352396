import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';

import { Form, Select, Input, Radio, Col, Row } from 'antd';
import { CarResponseType } from '../../../../Types/authTypes';
import { ButtonComponent } from '../../../../Components/Button';
import { userActions } from '../../../../Globals/User/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../../Stores';
const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

type CarDetailsPropsType = {
  car: CarResponseType | null;
};

export const Details: React.FC<CarDetailsPropsType> = ({ car }) => {
  const [isChanged, setIsChanged] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const intervals = useSelector((state: RootStateType) => state.user.intervals);

  const onFinish = (e: any) => {
    const { av_daily_mileage, ...values } = e;
    dispatch(
      userActions.updateCar(
        {
          ...{
            make: car!.make.id,
            year: car!.year,
            model: car!.model.id,
            trim: car!.trim.id,
          },
          ...values,
        },
        car!.id
      )
    );
  };

  const onChanged = () => {
    setIsChanged(true);
  };

  const onCancel = () => {
    form.setFieldsValue({
      maintenance_interval: car?.maintenance_interval?.id,
      vin_chassis: car?.vin_chassis,
      av_daily_mileage: car?.av_daily_mileage,
      gears_change: car?.gears_change,
    });
    setIsChanged(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      maintenance_interval: car?.maintenance_interval?.id,
      vin_chassis: car?.vin_chassis,
      av_daily_mileage: car?.av_daily_mileage,
      gears_change: car?.gears_change,
    });
    if (car?.trim) {
      dispatch(userActions.getIntervals(car.trim.id));
    }
  }, [car, form, dispatch]);

  return (
    <Form
      className={classes.item}
      {...layout}
      form={form}
      name="car-details"
      onFinish={onFinish}
      onChange={onChanged}
    >
      <Form.Item label="Current Mileage" name="maintenance_interval">
        <Select onChange={onChanged} className={classes.input}>
          {intervals?.map((item) => {
            return (
              <Option value={item.id} key={item.id}>
                {item.value.toLocaleString()}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="VIN/Chassis#" name="vin_chassis">
        <Input className={classes.input} />
      </Form.Item>
      <Form.Item
        label="Average daily mileage"
        name="av_daily_mileage"
        style={{ marginBottom: 0 }}
      >
        <Input placeholder="6500" disabled className={classes.input} />
      </Form.Item>
      <Col span={24}>
        <span className={classes.bottomLabel}>
          We deduce this from the age of your car<br></br> and the current
          mileage 😉
        </span>
      </Col>

      <Form.Item
        className={classes.gears}
        label="Do you change gears or does the car do it for you?"
        name="gears_change"
      >
        <Radio.Group style={{ marginTop: '10px' }}>
          <Radio value="Manual">Manual</Radio>
          <Radio value="Automatic">Automatic</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item wrapperCol={{span: 24}}>
        <Row justify="end" style={{flexWrap: 'nowrap' }}>
          <ButtonComponent
            title={'Cancel'}
            type={'cancel'}
            onClick={onCancel}
            disabled={!isChanged}
            style={{ marginBottom: '12px' }}
          />
          <ButtonComponent
            title={'Save'}
            type={'save'}
            onClick={() => {}}
            disabled={!isChanged}
          />
        </Row>
      </Form.Item>
    </Form>
  );
};
