import { appApi } from './../App/services';
import {
  AddCarRequestType,
  AppointmentRespType,
  CreateOrderRequestType,
  IntervalResponseType,
  MakesResponseType,
  ModelResponseType,
  OfferedServicesResponseType,
  QueryObjectType,
  TrimResponseType,
  YearResponseType,
  CreateOrderResponseType,
  PaymentsResponseType,
  WorkShopTypeRequest,
  UpdateUserProfile,
  CarsResponseType,
  CarResponseType,
  CitiesTypeRequest,
  TabbySessionRequestType,
  TabbySessionResponseType,
} from './../../Types/authTypes';
import ReactGA from 'react-ga';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { userActions } from './actions';
import { userApi } from './services';
import * as userConstants from './constants';
import { appActions } from '../App/actions';
import * as selectors from './selectors';
import * as authSelectors from '../Auth/selectors';
import { authActions } from '../Auth/actions';
import { encodedOrderQuery } from '../../Utils/query';
import {
  OrderForWorkshopType,
  OrderService,
  OrdersRequestType,
  ReviewResponseType,
} from '../../Types/workshopTypes';
import {
  AvailableDaysResponseType,
  CancelOrderRequest,
  CarDeliveriesType,
  CarUpdateRequestType,
  CheckCancelRescheduleOrderResponse,
  CustomerOrderType,
  GetAppointmentsRequestData,
  GetAvailableDaysParamsType,
  OrderInfoForGetWorkshopsRequest,
  RescheduleRequest,
  ReviewRequestType,
  ScheduledMaintenanceRequestType,
  ServiceHistoryRequestType,
  UserProfileType,
  ReloadOrderInfoType,
} from '../../Types/userTypes';
import { adminActions } from '../Admin/actions';

export function* getMakes() {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const confirmResult: Array<MakesResponseType> = yield call(
      userApi.getMakes
    );
    yield put(userActions.setMakes(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getYears({ payload }: any) {
  try {
    yield put(appActions.openLoader());
    const confirmResult: Array<YearResponseType> = yield call(
      userApi.getYears,
      payload
    );
    yield put(userActions.setYear(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getModels({ payload }: any) {
  try {
    yield put(appActions.openLoader());
    const years: Array<YearResponseType> = yield select(selectors.getYears);
    const selectedYears = years.find((item) => item.year === payload);
    const confirmResult: Array<ModelResponseType> = yield call(
      userApi.getModel,
      selectedYears!.years_id
    );
    yield put(userActions.setModel(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getTrims({ payload }: any) {
  try {
    yield put(appActions.openLoader());
    const models: Array<ModelResponseType> = yield select(selectors.getModels);
    const selectedModelsIds = models.find((item) => item.title === payload);
    const confirmResult: Array<TrimResponseType> = yield call(
      userApi.getTrim,
      selectedModelsIds?.models_id!
    );
    yield put(userActions.setTrim(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getIntervals(payload: { type: string; id_trim: number }) {
  try {
    yield put(appActions.openLoader());
    const confirmResult: Array<IntervalResponseType> = yield call(
      userApi.getIntervals,
      payload.id_trim
    );
    yield put(userActions.setIntervals(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getAllIntervals(payload: {
  type: string;
  id_trims: number[];
}) {
  try {
    yield put(appActions.openLoader());
    const confirmResult: Array<{
      trimId: number;
      rows: IntervalResponseType[];
    }> = yield call(userApi.getIntervalsForAllCars, payload.id_trims);
    yield put(userActions.setAllIntervals(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getServices(payload: {
  type: string;
  trim_id: number;
  maint_id?: number;
}) {
  try {
    yield put(appActions.openLoader());
    const confirmResult: OfferedServicesResponseType = yield call(
      userApi.getServices,
      payload.trim_id,
      payload.maint_id && payload.maint_id
    );
    yield put(userActions.setServices(confirmResult));
    yield put(authActions.resetOrderData());
    //yield put(userActions.resetWithoutFormData());
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getCitiesForOrder() {
  try {
    yield put(appActions.openLoader());
    const confirmResult: Array<CitiesTypeRequest> = yield call(
      userApi.getCities
    );
    yield put(userActions.setCities(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getWorkshopsForOrder(payload: {
  payload: OrderInfoForGetWorkshopsRequest;
  isPartner?: boolean;
  city_id?: number;
  page: number;
  workshop_id?: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const confirmResult: WorkShopTypeRequest = yield call(
      userApi.getWorkshops,
      payload.page,
      payload.payload.trimId,
      payload.payload.modelId,
      payload.payload.additionalTasks,
      payload.payload.isBasic,
      payload.payload.intervalId && payload.payload.intervalId,
      payload.isPartner && payload.isPartner,
      payload.city_id && payload.city_id,
      payload.workshop_id && payload.workshop_id
    );
    const confirmResultCities: Array<CitiesTypeRequest> = yield call(
      userApi.getCities
    );
    yield put(userActions.setWorkshops(confirmResult));
    yield put(userActions.setCities(confirmResultCities));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getAppointment(payload: {
  payload: GetAppointmentsRequestData;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const confirmResult: AppointmentRespType = yield call(
      userApi.getAppointment,
      payload.payload.date,
      payload.payload.trimId,
      payload.payload.additionalTasks,
      payload.payload.isBasic,
      payload.payload.workshopId,
      payload.payload.intervalId,
      payload.payload.order_id
    );
    yield put(userActions.setAppointment(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* addCar(payload: { type: string; payload: AddCarRequestType }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    yield call(userApi.addCar, token, payload.payload);
    yield put(authActions.setRoute('/user/my-cars'));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getCarList() {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: CarsResponseType = yield call(
      userApi.getCarList,
      token
    );
    yield put(userActions.setCarList(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* deleteCar(payload: { type: string; id: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: CarResponseType = yield call(
      userApi.deleteCar,
      token,
      payload.id
    );
    if (confirmResult) {
      yield put(authActions.setRoute('/user/my-cars'));
      yield put(userActions.getCarList());
    }

    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getCar(payload: { type: string; payload: number }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: CarResponseType = yield call(
      userApi.getCar,
      token,
      payload.payload
    );
    yield put(userActions.setSelectedCar(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updateCar(payload: {
  type: string;
  payload: CarUpdateRequestType;
  id: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: CarResponseType = yield call(
      userApi.updateCar,
      token,
      payload.payload,
      payload.id
    );
    yield put(userActions.setSelectedCar(confirmResult));
    yield put(userActions.getSelectedCar(payload.id));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getServicesForCustomCar(payload: {
  type: string;
  id: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: OfferedServicesResponseType = yield call(
      userApi.getServicesForCustomCar,
      token,
      payload.id
    );
    yield put(userActions.setServices(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* createOrder(payload: {
  type: string;
  payload: CreateOrderRequestType;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const result: CreateOrderResponseType = yield call(
      userApi.createOrder,
      token,
      payload.payload
    );
    const { redirect_url, client_secret } = result;
    yield put(userActions.resetOrderData());
    yield put(authActions.resetOrderData());
    yield put(
      userActions.createOrderSuccess({
        redirect_url,
        client_secret,
      })
    );
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getPayment() {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const payment: PaymentsResponseType = yield call(userApi.getPayment, token);
    if (Object.keys(payment).length) {
      yield put(userActions.setPayment(payment));
    } else {
      yield put(userActions.setPayment(null));
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getOrders({
  payload,
}: {
  payload: QueryObjectType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const query = encodedOrderQuery(payload);
    const confirmResult: OrdersRequestType<Array<OrderService>> = yield call(
      userApi.getOrders,
      token,
      query
    );
    yield put(userActions.setOrders(confirmResult));
    const orders: OrdersRequestType<Array<OrderService>> = yield select(
      selectors.getOrders
    );
    if (orders.rows.length >= confirmResult.count) {
      yield put(userActions.isHasMore(false));
    } else {
      yield put(userActions.isHasMore(true));
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    console.log(e);
    yield put(appActions.closeLoader());
  }
}

export function* getUserProfile(payload: { type: string }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: UserProfileType = yield call(
      userApi.getUserProfile,
      token
    );
    yield put(userActions.setUpdatesUser(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* updateProfile(payload: {
  type: string;
  payload: UpdateUserProfile;
  id: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const roleType: string = yield select(authSelectors.getRoleType);
    const confirmResult: UserProfileType = yield call(
      userApi.updateProfile,
      token,
      payload.payload,
      payload.id
    );
    if (roleType === 'authenticated_admin') {
      yield put(adminActions.setUser(confirmResult));
    } else {
      yield put(userActions.setUpdatesUser(confirmResult));
    }

    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getServiceHistoryForCar(payload: {
  type: string;
  payload: QueryObjectType;
  id: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const query = encodedOrderQuery(payload.payload);
    const confirmResult: ServiceHistoryRequestType = yield call(
      userApi.getServiceHistoryForCar,
      token,
      payload.id,
      query
    );
    yield put(userActions.setServiceHistoryForCar(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getScheduledMaintenance(payload: {
  type: string;
  payload: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: ScheduledMaintenanceRequestType = yield call(
      userApi.getScheduledMaintenance,
      token,
      payload.payload
    );
    yield put(userActions.setScheduledMaintenance(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getInvoicePDF({ payload }: { payload: number; type: string }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: { url: string } = yield call(
      userApi.getInvoiceLink,
      token,
      payload
    );
    yield call(appApi.downloadFile, confirmResult.url);
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* sendReview(payload: {
  payload: ReviewRequestType;
  type: string;
  id: number;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: ReviewResponseType = yield call(
      userApi.sendReview,
      token,
      payload.payload,
      payload.id
    );
    yield put(userActions.setReview(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getOrder(payload: { payload: number; type: string }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: CustomerOrderType = yield call(
      userApi.getOrder,
      token,
      payload.payload
    );
    yield put(userActions.setOrder(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* sendCard(payload: { type: string; payload: any }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const payment: PaymentsResponseType = yield call(
      userApi.sendCard,
      token,
      payload.payload
    );
    if (Object.keys(payment).length) {
      yield put(userActions.setPayment(payment));
    } else {
      yield put(userActions.setPayment(null));
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* deleteCard(payload: { type: string; payload: string }) {
  try {
    yield put(appActions.openLoader());
    yield put(appActions.clearError());
    const token: string = yield select(authSelectors.getToken);
    yield call(userApi.deleteCard, token, payload.payload);
    yield put(userActions.setPayment(null));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.setError(e.response.data.message.messages[0].message));
    yield put(appActions.closeLoader());
  }
}

export function* checkCancelOrderStatus(payload: {
  payload: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: CheckCancelRescheduleOrderResponse = yield call(
      userApi.getCancelStatus,
      token,
      payload.payload
    );
    yield put(userActions.setCancelStatus(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* checkRescheduleOrderStatus(payload: {
  payload: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: CheckCancelRescheduleOrderResponse = yield call(
      userApi.getRescheduleStatus,
      token,
      payload.payload
    );
    yield put(userActions.setRescheduleStatus(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* cancelOrder(payload: {
  payload: CancelOrderRequest;
  id: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: OrderForWorkshopType<OrderService[]> = yield call(
      userApi.cancelOrder,
      token,
      payload.id,
      payload.payload
    );
    if (payload.payload.payment_id) {
      yield put(userActions.createOrderSuccess(confirmResult));
      yield put(authActions.setRoute('/createorder/cancel-order'));
    } else {
      yield put(userActions.setChangedOrder(confirmResult));
      yield put(authActions.setRoute('/createorder/cancel-order'));
    }
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* rescheduleOrder(payload: {
  payload: RescheduleRequest;
  id: number;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: OrderForWorkshopType<OrderService[]> = yield call(
      userApi.rescheduleOrder,
      token,
      payload.id,
      payload.payload
    );
    if (payload.payload.payment_id) {
      yield put(userActions.createOrderSuccess(confirmResult));
      yield put(authActions.setRoute('/createorder/reschedule-order'));
    } else {
      yield put(userActions.setChangedOrder(confirmResult));
      yield put(authActions.setRoute('/createorder/reschedule-order'));
    }

    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getDeclinedOrder(payload: { payload: number; type: string }) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: CustomerOrderType = yield call(
      userApi.getDeclinedOrder,
      token,
      payload.payload
    );
    yield put(userActions.setOrder(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getDeliveries() {
  try {
    yield put(appActions.openLoader());
    const confirmResult: CarDeliveriesType[] = yield call(
      userApi.getCarDeliveries
    );
    yield put(userActions.setCarDeliveriesTime(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* getAvailableDays(payload: {
  payload: GetAvailableDaysParamsType;
  type: string;
}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: AvailableDaysResponseType = yield call(
      userApi.getAvailableDays,
      token,
      payload.payload
    );
    yield put(userActions.setAvailableDays(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* createTabbySession(payload: { type: string; payload: any}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: TabbySessionResponseType = yield call(
      userApi.createTabbySession,
      token,
      payload.payload,
    );
    yield put(userActions.setTabbySession(confirmResult));
    yield put(appActions.closeLoader());
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export function* reloadOrderInfo(payload: { type: string; payload: any}) {
  try {
    yield put(appActions.openLoader());
    const token: string = yield select(authSelectors.getToken);
    const confirmResult: ReloadOrderInfoType = yield call(
      userApi.reloadOrder,
      token,
      payload.payload,
    );
    yield put(appActions.closeLoader());
    return confirmResult
  } catch (e: any) {
    yield put(appActions.closeLoader());
  }
}

export default function* WatcherSaga() {
  yield takeLatest(userConstants.GET_MAKES, getMakes);
  yield takeLatest(userConstants.GET_YEAR_OF_MAKE, getYears);
  yield takeLatest(userConstants.GET_MODEL_OF_YEAR, getModels);
  yield takeLatest(userConstants.GET_MODEL_TRIM, getTrims);
  yield takeLatest(userConstants.GET_INTERVALS, getIntervals);
  yield takeLatest(userConstants.GET_ALL_INTERVALS, getAllIntervals);
  yield takeLatest(
    userConstants.GET_SERVICES_FOR_CURRENT_INTERVAL,
    getServices
  );
  yield takeLatest(
    userConstants.GET_CITIES,
    getCitiesForOrder
  );
  yield takeLatest(
    userConstants.GET_WORKSHOPS_ON_LOCATION,
    getWorkshopsForOrder
  );
  yield takeLatest(userConstants.GET_APPOINTMENT, getAppointment);
  yield takeLatest(userConstants.ADD_NEW_CAR, addCar);
  yield takeLatest(userConstants.GET_CARS_LIST, getCarList);
  yield takeLatest(userConstants.DELETE_CURRENT_CAR, deleteCar);
  yield takeLatest(
    userConstants.GET_SERVICES_FOR_CUSTOMER_CAR,
    getServicesForCustomCar
  );
  yield takeLatest(userConstants.CREATE_ORDER, createOrder);
  yield takeLatest(userConstants.GET_PAYMENT, getPayment);
  yield takeLatest(userConstants.GET_ORDERS, getOrders);
  yield takeLatest(userConstants.GET_CURRENT_CAR, getCar);
  yield takeLatest(userConstants.UPDATE_CAR, updateCar);
  yield takeLatest(userConstants.GET_USER_PROFILE, getUserProfile);
  yield takeLatest(userConstants.UPDATE_USER_PROFILE, updateProfile);
  yield takeLatest(
    userConstants.GET_SERVICE_HISTORY_FOR_CAR,
    getServiceHistoryForCar
  );
  yield takeLatest(
    userConstants.GET_SCHEDULED_MAINTENANCE_FOR_CAR,
    getScheduledMaintenance
  );
  yield takeLatest(userConstants.GET_INVOICE_PDF, getInvoicePDF);
  yield takeLatest(userConstants.SEND_REVIEW, sendReview);
  yield takeLatest(userConstants.GET_ORDER, getOrder);
  yield takeLatest(userConstants.SEND_CARD, sendCard);
  yield takeLatest(userConstants.DELETE_CARD, deleteCard);
  yield takeLatest(userConstants.CHECK_CANCEL_STATUS, checkCancelOrderStatus);
  yield takeLatest(
    userConstants.CHECK_RESCHEDULE_STATUS,
    checkRescheduleOrderStatus
  );
  yield takeLatest(userConstants.CANCEL_ORDER, cancelOrder);
  yield takeLatest(userConstants.RESCHEDULE_ORDER, rescheduleOrder);
  yield takeLatest(userConstants.GET_DECLINED_ORDER, getDeclinedOrder);
  yield takeLatest(userConstants.GET_CAR_DELIVERIES_TIME, getDeliveries);
  yield takeLatest(userConstants.GET_AVAILABLE_DAYS, getAvailableDays);
  yield takeLatest(userConstants.CREATE_TABBY_SESSION, createTabbySession);
  yield takeLatest(userConstants.RELOAD_ORDER_INFO, reloadOrderInfo);
}
