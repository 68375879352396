import {
  AlertType,
  ReviewForWorkshopRequestType,
  WorkshopForLandingRequestType,
} from '../../Types/appTypes';
import { WorkShopType } from '../../Types/authTypes';
import { ActionTypes } from './actions';
import * as appConstants from './constants';

const initialState = {
  loading: false,
  error: null as any,
  alerts: [],
  confirm: {
    isShow: false,
    text: '',
    buttonText: ['Cancel', 'Confirm'] as [string, string],
  },
  workshopList: {
    count: 0,
    rows: [],
  },
  selectedWorkshop: null,
  reviews: {
    count: 0,
    rows: [],
  },
};

export type InitialStateType = {
  loading: boolean;
  error: any;
  alerts: Array<AlertType> | [];
  confirm: {
    isShow: boolean;
    text: string;
    buttonText: [string, string];
  };
  workshopList: WorkshopForLandingRequestType;
  selectedWorkshop: WorkShopType | null;
  reviews: ReviewForWorkshopRequestType;
};

const appReducer = (
  state: InitialStateType = initialState,
  action: ActionTypes
): InitialStateType => {
  switch (action.type) {
    case appConstants.OPEN_LOADER:
      return {
        ...state,
        loading: true,
      };
    case appConstants.CLOSE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case appConstants.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case appConstants.CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };
    case appConstants.ADD_ALERT_MESSAGE:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case appConstants.DELETE_ALERT_MESSAGE:
      return {
        ...state,
        alerts: state.alerts.filter((item) => item.message !== action.payload),
      };
    case appConstants.RESET_ALERTS:
      return {
        ...state,
        alerts: [],
      };
    case appConstants.SHOW_CONFIRM_WINDOW:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          isShow: true,
          text: action.payload,
          buttonText: action.textButtons || initialState.confirm.buttonText,
        },
      };
    case appConstants.HIDE_CONFIRM_WINDOW:
      return {
        ...state,
        confirm: initialState.confirm,
      };
    case appConstants.SET_WORKSHOPS_FOR_LENDING:
      return {
        ...state,
        workshopList: action.payload,
      };
    case appConstants.SET_SELECTED_WORKSHOP:
      return {
        ...state,
        selectedWorkshop: action.payload,
      };
    case appConstants.RESET_SELECTED_WORKSHOP:
      return {
        ...state,
        selectedWorkshop: null,
      };
    case appConstants.SET_REVIEWS_FOR_WORKSHOP:
      return {
        ...state,
        reviews: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
