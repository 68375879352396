import { Carousel,Rate } from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalComponent } from '../../../../Components/Modal';
import { WorkshopInfoModal } from '../../../../Components/WorkshopInfoModal';
import { appActions } from '../../../../Globals/App/actions';
import { RootStateType } from '../../../../Stores';
import { WorkshopForLandingType } from '../../../../Types/appTypes';
import { WorkshopCard } from './Components/WorkshopCard';
import classes from './styles.module.scss';

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cn(className, classes.arrow)}
      style={{
        ...style,
        left: '-70px',
      }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={cn(className, classes.arrow, classes.arrowRight)}
      style={{
        ...style,
        right: '-70px',
      }}
      onClick={onClick}
    />
  );
}

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: true,
  arrows: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  responsive: [
    // {
    //   breakpoint: 1700,
    //   settings: {
    //     slidesToShow: 4 ,
    //     slidesToScroll: 1,
    //   },
    // },
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 970,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

export const Partners: React.FC = () => {
  const dispatch = useDispatch();

  //const [selectedWorkshop, setSelectedWorkshop] = useState<WorkShopType>();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isModalShouldOpen, setIsModalShouldOpen] = useState<boolean>(false);

  const workshops = useSelector(
    (state: RootStateType) => state.app.workshopList
  );

  const selectedWorkshop = useSelector(
    (state: RootStateType) => state.app.selectedWorkshop
  );

  const closeModal = () => {
    setIsModal(false);
    setIsModalShouldOpen(false);
  };

  const openModal = (id: number) => {
    dispatch(appActions.getSelectedWorkshop(id));
    setIsModalShouldOpen(true);
  };

  const foo = (workshop: WorkshopForLandingType, index: number) => {
    return (
      <div key={workshop.id}>
        <WorkshopCard
          workshop={workshop}
          onClickCard={openModal}
          index={index}
        />
      </div>
    );
  };

  const createLength = <T,>(list: Array<T>, count: number): Array<T> => {
    if (list.length < count && list.length !== 0) {
      return createLength([...list, ...list], count);
    } else {
      return list;
    }
  };

  const afterClose = () => {
    dispatch(appActions.resetSelectedWorkshop());
  };

  useEffect(() => {
    if (isModalShouldOpen && selectedWorkshop) {
      setIsModal(true);
    }
  }, [selectedWorkshop]);

  const [NumberOfReview,setNumberOfReview] = useState(1) 
  function ReviewCounter(props: any) {
    setNumberOfReview(props + 1)
  }
  return (
    <div className={classes.container} id="our-workshops">
      <div className={classes.contentWrapper}>
        <div className={classes.titleBox}>
          <h2>We work with the best!</h2>
          <h4>Average rating 4.0 {<Rate
            disabled
            defaultValue={1}
            count={1}
            className={classes.rate}
          />} from 2,000+ reviews!</h4>
        </div>
        <Carousel {...settings} className={classes.carousel} afterChange={ReviewCounter}>
          {createLength(workshops.rows, 5).map((workshop, index) =>
            foo(workshop, index)

          )}
        </Carousel>
        <div className={classes.counterPages}><span>{NumberOfReview}\</span>{workshops.count}</div>
      </div>
      <ModalComponent
        isModal={isModal}
        toggleModal={closeModal}
        title={`More about ${selectedWorkshop?.name}`}
        afterClose={afterClose}
      >
        <WorkshopInfoModal
          goToNextStep={() => {}}
          selectedWorkshop={selectedWorkshop!}
          hideButton={true}
        />
      </ModalComponent>
    </div>
  );
};
