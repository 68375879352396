import { CarAndServices } from '../../../../Components/CarAndServices/index';
import classes from './style.module.scss';
import { PayForm, FormType } from './Components/Form';
import DrawerComponent from '../../../../Components/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../../../Globals/Auth/actions';

type PayStepPropsType = {
  goToNextStep: () => void;
  goToPrevStep: () => void;
  clientSecret: string | null
  tabbyUrl: string | null;
  orderSavedForConfirm: string | null
};

export const PayStep: React.FC<PayStepPropsType> = ({
  goToNextStep,
  goToPrevStep,
  clientSecret,
  tabbyUrl,
  orderSavedForConfirm
  
}) => {
  const goNext = (values: FormType) => {
  };
  const dispatch = useDispatch();
  return (
    <div className={classes.contentContainer}>
      <DrawerComponent runAnimate={{}}>
        <CarAndServices canRemove={false} step={6} isShow={false} isShowTabbyPromo={false}/>
      </DrawerComponent>
      <div className={classes.leftContainerWrapper}>
        <PayForm
          goToPrevStep={goToPrevStep}
          goToNextStep={goNext}
          clientSecret={clientSecret}
          tabbyUrl={tabbyUrl}
          orderSavedForConfirm={orderSavedForConfirm}
        />
      </div>
      <div className={classes.hide}>
        <CarAndServices canRemove={false} step={6} isShow={false} isShowTabbyPromo={false}/>
      </div>
    </div>
  );
};
