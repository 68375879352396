import React from 'react';
import cn from 'classnames';
import { Rate } from 'antd';
import classes from './styles.module.scss';
import WorkshopImage from '../../../../../../Assets/Landing/workshop-image.png';
import Google from '../../../../../../Assets/orderIcon/google_icon.svg';
import { WorkshopForLandingType } from '../../../../../../Types/appTypes';

type WorkshopCardPropsType = {
  workshop: WorkshopForLandingType;
  onClickCard: (id: number) => void;
  index: number;
};

export const WorkshopCard: React.FC<WorkshopCardPropsType> = ({ workshop, onClickCard, index }) => {
  return (
    <div className={classes.partnersWrapper}>
      <div className={cn(classes.container, {
        //[classes.marginRight]: !((index + 1) % 4)
      })} onClick={() => onClickCard(workshop.id)}>
        <img
          src={workshop.avatar?.url || WorkshopImage}
          alt="workshop"
          style={{height: 300}}
          className={classes.workshopImage}
          //height={300}
        />
        <div className={classes.infoWrapper}>
          <h3 className={classes.workshop}>{workshop.name}</h3>
          <div className={classes.description}>{workshop.headline}</div>
          <div className={classes.rateBox}>
            {workshop.google_review.average_rating_maps} 
            {<Rate
              allowHalf
              value={workshop.google_review.average_rating_maps}
              count={5}
              disabled
              className={classes.rate}
            />}
            {workshop.google_review.ratings_total_maps} <img className={classes.google} src={Google} alt="google-icon" /> Reviews</div>
            {/*  <div className={classes.rateContainer}>
                <Rate
                  allowHalf
                  defaultValue={workshop.average_rating}
                  className={classes.rate}
                  disabled
                />
                <span className={classes.reviews}>
                  {workshop.rating_count} reviews
                </span>
              </div> */}
        </div>
      </div>
    </div>
  );
};
