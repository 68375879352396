import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from './styles.module.scss';
import { Header } from '../../Components/Header';
import { Footer } from '../../Components/Footer';
import { PrivacyList } from './PrivacyPolisyList';

export const PrivacyPolicy: React.FC = () => {
  const history = useHistory();
  const goTo = (path: string) => {
    history.push(path);
  };
  return (
    <div className={classes.container}>
      <Header goTo={goTo} />
      <div className={classes.mainWrapper}>
        <div className={classes.container}>
          <div className={classes.bannerContent}>
            <h1>Privacy Policy</h1>
          </div>
          <div className={classes.backgroundImage}></div>
        </div>
       <PrivacyList/>
      </div>
      <Footer />
    </div>
  );
};
