import classes from './style.module.scss';
import miniLogo from '../../Assets/miniLogo.png';
import { ReactNode } from 'react';

type EmptyScreenPropsType = {
  children?: ReactNode;
  title?: string;
};

export const EmptyScreen: React.FC<EmptyScreenPropsType> = ({
  title,
  children,
}) => {
  return (
    <div className={classes.emptyScreenContainer}>
      <img src={miniLogo} alt="logo" />
      {title && (
        <h2>
          {title} <br />
        </h2>
      )}
      {children && children}
    </div>
  );
};
