import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment, { Moment } from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { RootStateType } from '../../../Stores';
import classes from './style.module.scss';
import { OrderCard } from '../../../Components/OrderCard';
import { ModalComponent } from '../../../Components/Modal';

import { AppointmentForm } from '../../CreateOrder/Steps/ApointmentStep/Components/AppointmentForm';

import { EmptyScreen } from '../../../Components/EmptyScreen';
import { userActions } from '../../../Globals/User/actions';
import { OrderDataForAppointment } from '../../../Types/userTypes';
import { authActions } from '../../../Globals/Auth/actions';
import { ButtonComponent } from '../../../Components/Button';
import CardSection from '../../../Components/CardElement';
import { SavedCardElement } from '../../../Components/SavedCardElement';
import {
  OrderForWorkshopType,
  OrderService,
} from '../../../Types/workshopTypes';

type AppointmentPropsType = {
  loadMore: () => void;
  loading: boolean;
  activeTab: string;
};

export const Appointment: React.FC<AppointmentPropsType> = ({
  loadMore,
  loading,
  activeTab,
}) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<number>();
  const [paymentMode, setPaymentMode] = useState<boolean>(false);
  const [rescheduleValues, setRescheduleValues] = useState<{
    date: Moment;
    time: string;
  }>();

  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const isHasMore = useSelector(
    (state: RootStateType) => state.workshop.isHasMore
  );

  const client_address = useSelector(
    (state: RootStateType) => state.user.client_address
  );

  const delivery = useSelector(
    (state: RootStateType) => state.user.deliveryCar
  );

  const redirectUrl = useSelector((state: RootStateType) => state.auth.route);
  const orders = useSelector(
    (state: RootStateType) => state.user.orders.rows
  ).filter((item) => item.status === 'accepted' || item.status === 'pending');

  const cancelStatus = useSelector(
    (state: RootStateType) => state.user.cancelStatus
  );

  const rescheduleStatus = useSelector(
    (state: RootStateType) => state.user.rescheduleStatus
  );

  const payment = useSelector((state: RootStateType) => state.user.payment);

  const carDeliveries = useSelector(
    (state: RootStateType) => state.user.car_deliveries
  );

  const dataLayer = window.dataLayer || []

  const closeModal = () => {
    setIsModal(false);
    setIsCancelModal(false);
    setPaymentMode(false);
    dispatch(userActions.setAvailableDays(null));
  };

  const openRescheduleModal = (order: OrderForWorkshopType<OrderService[]>) => {
    const availableDaysParams = {
      maintenance_interval: order.customer_car.id,
      trim: order.customer_car.trim.id,
      log_book: true,
      basic: false,
      workshop: order.workshop.id,
      order_id: order.id
    };

    if (order.id) {
      dispatch(userActions.getRescheduleStatus(order.id));
      dispatch(userActions.getAvailableDays(availableDaysParams));
      setSelectedOrderId(order.id);
      setIsModal(true);
    }
  };

  const onCancelCancelModal = () => {
    window.location.href = 'https://wa.me/97180056445';
    closeModal();
  };

  const openCancelModal = (id?: number) => {
    if (id) {
      dispatch(userActions.getCancelStatus(id));
      setSelectedOrderId(id);
      setIsCancelModal(true);
    }
  };

  const onCancelOrder = () => {
    if (selectedOrderId){
      dispatch(userActions.cancelOrder({}, selectedOrderId));
      closeModal();
    }
  };

  const createRequestData = useCallback(():
    | OrderDataForAppointment
    | undefined => {
    const order = orders.find((order) => order.id === selectedOrderId);
    if (order)
      return {
        trimId: order.customer_car.trim.id,
        intervalId: order.customer_car.maintenance_interval?.id,
        workshopId: order.workshop.id,
        isBasic: false,
        additionalTasks: order.order_services.map((item) => item.id),
        order_id: order.id,
      };
  }, [orders, selectedOrderId]);

  const onReSchedule = (values: { date: Moment; time: string }) => {
    setRescheduleValues(values);
    const rescheduleData = {
      start_date: values.date.format('YYYY MM DD'),
      start_time: moment(values.time, 'HH:mm a').format('HH:mm:ss ZZ'),
      pick_up: delivery.pick_up_slot,
      drop_off: delivery.drop_off_slot,
      
      address: client_address
    };
    if (selectedOrderId)
      if (!rescheduleStatus?.penalty && true) {
        dispatch(userActions.rescheduleOrder(rescheduleData, selectedOrderId));
        closeModal();
      } else {
        if (payment?.id) {
          dispatch(
            userActions.rescheduleOrder(
              { ...rescheduleData, payment_id: payment?.id },
              selectedOrderId
            )
          );
          closeModal();
        } else {
          setPaymentMode(true);
        }
      }
  };

  const onFinish = () => {
    const cardElement = elements?.getElement(CardElement);
    if (cardElement) {
      stripe
        ?.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then((result) => stripePaymentMethodHandler(result));
    }
  };

  const stripePaymentMethodHandler = (result: any) => {
    if (result.error) {
      // Show error in payment form
      console.log(result.error);
    } else {
      dispatch(
        userActions.sendCard({ payment_method_id: result.paymentMethod.id })
      );
    }
  };

  const onLoad = () => {
    if (activeTab !== '1') {
      return;
    }
    if (loading) {
      return;
    } else {
      loadMore();
    }
  };

  useEffect(() => {
    dispatch(userActions.getPayment());
  }, [dispatch]);

  // useEffect(() => {
  //   //GTM
  //   //Start
  //   dataLayer.push({
  //     "path": "/user/appointments",
  //     "page_type":"userAppointmentsPage",
  //     "event":"page_view"
  //   });
  //   //End
  // }, []);

  useEffect(() => {
    if (redirectUrl) {
      //history.push(redirectUrl);
    }
    return () => {
      dispatch(authActions.setRoute(null));
    };
  }, [redirectUrl, history, dispatch]);

  useEffect(() => {
    if (payment) {
      setPaymentMode(false);
    }
  }, [payment]);

  useEffect(() => {
    dispatch(userActions.getCarDeliveries());
  }, [dispatch]);

  return (
    <div>
      <InfiniteScroll
        dataLength={orders.length}
        next={onLoad}
        hasMore={isHasMore && !loading}
        loader={null}
        endMessage={null}
      >
        {orders.length ? (
          orders.map((order) => (
            <OrderCard
              key={order.id}
              order={order}
              type="customer"
              onConfirm={() => {
                openRescheduleModal(order);
              }}
              onCancel={() => openCancelModal(order.id)}
            />
          ))
        ) : (
          <EmptyScreen title="You don't have appointments" />
        )}
      </InfiniteScroll>
      <ModalComponent
        isModal={isModal}
        toggleModal={closeModal}
        title="Re-schedule appointment"
      >
        {!paymentMode ? (
          <AppointmentForm
            onFinish={onReSchedule}
            onCancel={closeModal}
            type="customer"
            data={createRequestData()}
            defaultValues={rescheduleValues}
            carDeliveries={carDeliveries}
            reschedule={false}
          />
        ) : (
          <div>
            <div className={classes.subContainer}>
              {!payment ? <></> : <SavedCardElement payment={payment} />}
              <ButtonComponent
                onClick={() => onFinish()}
                title="Save"
                style={{ alignSelf: 'flex-end' }}
              />
            </div>
            {!paymentMode && (
              <ButtonComponent
                type="save"
                title="continue"
                onClick={() => {
                  setPaymentMode(false);
                }}
              />
            )}
          </div>
        )}
      </ModalComponent>
      <ModalComponent
        isModal={isCancelModal}
        toggleModal={closeModal}
        isAlert={false}
        buttonConfig={
          !paymentMode
            ? [
                { type: 'customer', title: 'I want to cancel' },
                { type: 'save-cancel', title: 'Contact us' },
              ]
            : []
        }
        onSubmit={onCancelOrder}
        onCancel={onCancelCancelModal}
      >
        {!paymentMode ? (
          <div className={classes.alertBody}>
            <ExclamationCircleOutlined
              style={{ fontSize: '22px', color: '#FAAD14' }}
            />
            <div>
              It’s sad to see you cancel, is there anything we can do to change
              your mind?
            </div>
          </div>
        ) : (
          <div className={classes.subContainer}>
            {!payment ? (
              <CardSection />
            ) : (
              <SavedCardElement payment={payment} />
            )}
            <ButtonComponent
              onClick={() => onFinish()}
              title="Save"
              style={{ alignSelf: 'flex-end' }}
            />
          </div>
        )}
      </ModalComponent>
    </div>
  );
};
