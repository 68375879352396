import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';

import { Checkbox } from 'antd';

import { OrderServiceForJob } from '../../../Types/workshopTypes';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxComponent } from './Components/Checkbox';

type CheckBoxGroupType = {
  services: OrderServiceForJob[];
  onChange: (e: CheckboxChangeEvent) => void;
  isDisable?: boolean;
  children?: ReactNode;
  isInspect?: boolean;
  onInputChange?: (id: number, e: ChangeEvent<HTMLTextAreaElement>) => void;
  defaultInputValue?: string;
};

export const CheckBoxGroup: React.FC<CheckBoxGroupType> = ({
  services,
  onChange,
  isDisable = false,
  isInspect = false,
  onInputChange,
}) => {
  return (
    <Checkbox.Group
      style={{ display: 'flex', flexDirection: 'column' }}
      disabled={isDisable}
      value={services
        .filter((service) => service.status)
        .map((service) => service.id)}
    >
      {services
        .sort((a, b) => a.id - b.id)
        .map((service) => (
          <CheckboxComponent
            service={service}
            isDisable={isDisable}
            onInputChange={onInputChange}
            onChange={onChange}
            isInspect={isInspect}
            key={service.id}
          />
        ))}
    </Checkbox.Group>
  );
};
