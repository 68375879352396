import React, { useEffect } from 'react';
import { Form, Select, Input, Row, Radio } from 'antd';
import classes from './style.module.scss';
import { ButtonComponent } from '../../../Components/Button';

type AdditionalPropsType = {};

export const Additional: React.FC<AdditionalPropsType> = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {};

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({});
  }, [form]);

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 24 },
  };

  return (
    <div className={classes.container}>
      <Form {...layout} form={form} name="customer-info" onFinish={onFinish}>
        <Form.Item name="other" label="Other">
          <Input />
        </Form.Item>
        <Form.Item name="other2" label="Other">
          <Input />
        </Form.Item>
        <Form.Item name="other3" label="Other">
          <Input />
        </Form.Item>
        <Form.Item name="other4" label="Other">
          <Input />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            {false && (
              <ButtonComponent type="cancel" onClick={onReset} title="Cancel">
                Cancel
              </ButtonComponent>
            )}
            {false && <ButtonComponent title="Save Changes" />}
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};
