import React from 'react';
import classes from './style.module.scss';
import { OptionsList } from '../OptionsList';
import { OfferedServiceType } from '../../../../../../Types/authTypes';
import { ButtonComponent } from '../../../../../../Components/Button';
import { RootStateType } from '../../../../../../Stores';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../../../Globals/User/actions';

type ServiceListModalPropsType = {
  type: 'logbook' | 'main';
  logbook: Array<OfferedServiceType> | undefined;
  closeModal: () => void;
  onConfirm: (type: 'logbook' | 'main') => void;
  isSelected: boolean;
};

export const ServiceListModal: React.FC<ServiceListModalPropsType> = ({
  type,
  logbook,
  closeModal,
  onConfirm,
  isSelected
}) => {
  const dispatch = useDispatch();
  const onSubmit = () => {
    onConfirm(type);
    if (isSidebar) {
      dispatch(userActions.toggleModalIsSidebar())
    }
  };

  const basicService = logbook?.filter((serv) => serv.optional === false) || [];
  const otherService = logbook?.filter((serv) => serv.optional === true) || [];

  const isSidebar = useSelector((state: RootStateType) => state.user.isModalFromSidebar)

  return (
    <div style={{ paddingBottom: '16px' }}>
      <div className={classes.container}>
        <h1 className={classes.header}>What's included</h1>
        <div className={classes.description}>
          {type === 'logbook'
            ? 'Major service are unique to your car, based on your car’s manufacturer recommendations, and are to the highest industry standards.'
            : 'Minor services are based on your car’s manufacturer recommendations, and are to the highest industry standards.'}
        </div>
        <div className={classes.mainInfo}>
          <OptionsList options={basicService} title="Required Major service" />
          {type === 'logbook' && (
            <OptionsList
              options={otherService}
              title="Required Major service"
            />
          )}
        </div>
      </div>
      <div className={classes.buttons}>
        {(!isSidebar && !isSelected) && <ButtonComponent type="cancel" title="Cancel" onClick={closeModal} />}
        <ButtonComponent type="save" title={(isSidebar || isSelected) ? "Got it" : "Confirm"} onClick={isSelected ? closeModal : onSubmit} />
      </div>
    </div>
  );
};
