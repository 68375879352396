import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Divider } from 'antd';
import classes from './style.module.scss';
import { RootStateType } from '../../../Stores';
import { OrderCard } from '../../../Components/OrderCard';
import {FileType} from "../../../Types/appTypes";
import {appApi} from "../../../Globals/App/services";

interface OrdersPropsType {
  loadMore: () => void;
  loading: boolean;
}

export const Orders: React.FC<OrdersPropsType> = ({ loadMore, loading }) => {
  const orders = useSelector(
    (state: RootStateType) => state.workshop.orders.rows.filter(order => order.status !== 'pending')
  );
  const isHasMore = useSelector(
    (state: RootStateType) => state.workshop.isHasMore
  );

  const onLoad = () => {
    if (loading) {
      return;
    } else {
      loadMore();
    }
  };

  const onDownloadFile = (invoice: FileType | null) => {
    if (invoice) {
      appApi.downloadFile(`${invoice.url}`, invoice.name);
    }
  };

  return (
    <div className={classes.container}>
      <InfiniteScroll
        dataLength={orders.length}
        next={onLoad}
        hasMore={isHasMore && !loading}
        loader={null}
        endMessage={null}
      >
        {orders.map((order, index) => (
          <div key={order.id}>
            {order.appointment_date !== orders[index - 1]?.appointment_date && (
              <Divider orientation="left" className={classes.devider}>
                {moment(order.appointment_date) !== moment()
                  ? moment(order.appointment_date).format('DD MMM YYYY')
                  : 'Today'}
              </Divider>
            )}
            <OrderCard
              order={order}
              type="orders"
              path={`/workshop/jobs/${order.id}`}
              isStartJob={true}
              onConfirm={() => {
                onDownloadFile(order.invoice);
              }}
            />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};
