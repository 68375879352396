import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from './styles.module.scss';
import { Header } from './../../Components/Header';
import { Footer } from './../../Components/Footer';

export const HelpPage: React.FC = () => {
  const history = useHistory();
  const goTo = (path: string) => {
    history.push(path);
  };
  return (
    <div className={classes.container}>
      <Header goTo={goTo} />
      <div className={classes.mainWrapper}>
        <div className={classes.container}>
          <div className={classes.bannerContent}>
            <h1>Frequently Asked Questions - FAQs</h1>
          </div>
          <div className={classes.backgroundImage}></div>
        </div>
        <div className={classes.contentWrapper}>
          <h3>How can I book a service online?</h3>
          <p>
            After you fill out your details, the system will show you the
            ‘search results’ with instant and final-price quotes from quality
            service providers. Select the date and time that suites you best and
            book online. For more details, please visit the ‘How the Magic
            works’ section.
          </p>
          <h3>Can I get my car picked up and delivered?</h3>
          <p>
            Yes, Akeed! Off course! Just select the Car Pick-up service if you
            want our dedicated truck to pick up your car from your location to
            the workshop. And select Car Drop-off service if you want the same
            truck to drop it off at your location once the service is done.
          </p>
          <h3>Can I get my oil changed at my house?</h3>
          <p>
            Again yes! Choose the ‘At location’ service and one of our partners,
            that offers such a service, will come to your location and service
            your car. We do the deed at your home, your office, your gym, your
            university.... or wherever you like to get the job done!
          </p>
          <h3>
            Does CarLogik or its technicians perform the services on my car?
          </h3>
          <p>
            We are a Marketplace, think of “Booking.com” or “Deliveroo”, serving
            the auto industry with a focus on transparency. We, unlike some
            other websites in the market (We will not say who 😉) truly work
            with vetted workshops, that offer a quality service, every time.
            CarLogik’s staff work very closely with our partner workshops, to
            ensure you are getting what was promised, that genuine parts are
            always used, and an overall high-quality service is provided. Just
            look at the reviews and ratings of our partner workshops! We are so
            proud of them.
          </p>
          <h3>Which jobs are instantly priced, and I can book online?</h3>
          <p>
            All minor repairs, routine log-book car services, and inspections
            can be booked online and are instantly priced. For ease of
            reference, here are some examples of minor repairs: battery change,
            brakes and brake disks change, wipers change. For complex repairs,
            we’ll get one of our vetted service providers to give you a
            personalized quote.
          </p>
          <h3>Will I know the name of the workshop that works on my car?</h3>
          <p>
            Yup! As a matter of fact, you must select the workshop. As I said we
            are a Market place listing quality vetted workshops; part of the
            booking process is that you get to see the workshops listed with us,
            read about them (a brief description including their
            specializations), see how other users review and rate them, and view
            the prices they will charge you.
          </p>
          <h3>Can I get quotes for more complex repairs?</h3>
          <p>
            Yes. Once you have entered in your vehicle details and location,
            you’ll be able to browse through available repair tasks and
            inspections and book the most suitable one for you. In the case that
            our partner workshops identify additional services needed, they will
            inform you of such services, with the additional costs involved.
            Once you approve, such additional services will be performed, and
            you will be charged accordingly.
          </p>
          <h3>
            I cannot find my car model or make; how can I still use your
            service?
          </h3>
          <p>
            CarLogik can provide servicing and repair quotes, via our network of
            partner workshops, for most passenger vehicles on GCC roads. If you
            don’t see your vehicle listed, give us a call on 800LOGIK or send us
            an email at{' '}
            <a href="mailto:customer@carlogik.com">customer@carlogik.com</a>
          </p>
          <h3>
            Will the price I am quoted on CarLogik be the final price I pay?
          </h3>
          <p>
            Yes! Whatever price is quoted online is the final price you will pay
            for the services that you have booked. We do not provide an
            estimated price, or a price range.
          </p>
          <h3>
            What if the service provider finds additional work that is required?
          </h3>
          <p>
            Service providers are advised to note down any additional repairs or
            work that is required to ensure the health of your car. And to
            contact you to discuss and approve such additional tasks.
          </p>
          <h3>Can I reschedule the date and/or time of my booking?</h3>
          <div className={classes.listWrapper}>
            Yes, just go back to your booking on our site and click re-schedule!
            Most of the time rescheduling or canceling a booking is free of
            charge. Do note however that once a booking is confirmed we get a
            commitment from the service provider. That means they reserve the
            spot you booked for you. To maintain a fair playing field, and to
            ensure that the service providers consistently offer you a
            professional service the following conditions apply: <br />
            Cancellations and Rescheduling Policy:
            <ol>
              <li>
                Job is booked and cancelled within 12 hours of the appointment:
                Free of charge.
              </li>
              <li>
                Job is booked and cancelled after 12 hours of making the
                booking, but before the actual appointment: Penalty of Dhs 100.
              </li>
              <li>
                Job is booked and no show to the appointment: Penalty of Dhs
                200. This in most cases is to compensate the workshops for the
                consumables they prepared to use on your car, for the services
                booked.
              </li>
              <li>
                Job is booked and reschedules within 2 hours of appointment:
                Free of charge.
              </li>
              <li>
                Job is booked and reschedules less than 2 hours of appointment:
                Penalty of Dhs 100.
              </li>
            </ol>
          </div>
          <h3>How long will my service or repair take?</h3>
          <p>
            It depends on your car and the services that you have selected.
            However, if you have booked with a workshop, the service provider
            will usually keep your vehicle for six to eight hours. If you have
            booked with a mobile provider, you will only be without your vehicle
            for a maximum of three hours, the time it takes to complete the job.
          </p>
          <h3>Will my car be washed?</h3>
          <p>Off course.</p>
          <h3>
            Will my manufacturer’s warranty be affected if an independent auto
            workshop completes my logbook service instead of a dealership?
          </h3>
          <p>
            The most likely answer is YES. And this is probably the result of
            the terms and conditions or contract that you signed with the agency
            from which you purchased the car. We would recommend that you
            thoroughly review your warranty’s terms and conditions. On this
            topic, please refer to the Khaleej Times article published on 16 May
            2021 (
            <a href=" https://www.khaleejtimes.com/legal-view/uae-law-will-car-warranty-get-affected-if-serviced-from-centres-not-affiliated-to-dealer">
              https://www.khaleejtimes.com/legal-view/uae-law-will-car-warranty-get-affected-if-serviced-from-centres-not-affiliated-to-dealer
            </a>
            ): “It should be noted that the New Consumer Protection Law of UAE
            was published in UAE official gazette on November 15, 2020, and it
            may have come into effect from May 15, 2021...However, in the year
            2016 the Ministry of Economy stated that conditions related to
            compulsory servicing of the vehicle in the dealer’s service centre
            is abolished and the owners of the vehicles may service their
            vehicles in any of the vehicle service centres approved by Emirates
            Authority for Standardizations and Metrology (the ‘ESMA’).”
          </p>
          <h3>Will the service provider stamp by logbook?</h3>
          <p>
            Yes they will, in order to indicate that your car has been serviced
            at the interval you booked the service for. If a service provider
            refuses to stamp your logbook, please call us at 800LOGIK or email us
            at <a href="mailto:customer@carlogik.com">customer@carlogik.com</a>
          </p>
          <h3>Is there a warranty on the service offered? </h3>
          <p>
            Yes there is! We ensure that any warranty offered by our partner
            workshops is transferred to you. For full details on our warranties
            and quality assurance policy please refer to our Terms and
            Conditions and Warranty sections.
          </p>
          <h3>When do I pay for a service? And do I pay upfront?</h3>
          <p>
            You do not pay upfront. You will only pay after a service is
            completed.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
