import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Row } from 'antd';

import classes from './style.module.scss';
import { RootStateType } from '../../Stores';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonComponent } from '../Button';
import { workshopActions } from '../../Globals/Workshop/actions';
import { PaymentDetailsRequest } from '../../Types/workshopTypes';

type BancAccountPropsType = {
  wsId: number;
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};

export const BankAccount: React.FC<BancAccountPropsType> = ({ wsId }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const paymentsDetails = useSelector(
    (state: RootStateType) => state.workshop.paymentsDetails
  );

  const onChangeIban = (e: any) => {
    if (!e.target.value.trim()) {
      return;
    }
    let iban_num = e.target.value.trim().split(' ').join('').toUpperCase();
    if (iban_num.length > 0) {
      iban_num = iban_num.match(new RegExp('.{1,4}', 'g')).join(' ');
    }
    form.setFieldsValue({
      iban_num,
    });
  };

  const onSaveHandler = (formData: PaymentDetailsRequest) => {
    const isEmpty = Object.values(formData).every(
      (x) => x === null || x === ''
    );
    if (isEmpty) {
      return;
    }
    dispatch(workshopActions.updatePaymentsData(formData, wsId));
    setIsDisabled(true);
  };

  const setDefaultValues = useCallback(
    (formData: PaymentDetailsRequest) => {
      form.setFieldsValue({
        ...formData,
      });
    },
    [form]
  );

  const onCancel = () => {
    if (paymentsDetails) setDefaultValues(paymentsDetails);
  };

  const onChange = (field: Record<keyof PaymentDetailsRequest, string>) => {
    if (!paymentsDetails) {
      return setIsDisabled(false);
    }
    const keys = Object.keys(field) as Array<keyof PaymentDetailsRequest>;

    switch (true) {
      case paymentsDetails[keys[0]] === Object.values(field)[0].toUpperCase():
        setIsDisabled(true);
        break;
      case paymentsDetails[keys[0]] !== Object.values(field)[0].toUpperCase():
        setIsDisabled(false);
        break;
      default:
        setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (wsId) dispatch(workshopActions.getPaymentsData(wsId));
  }, [wsId, dispatch]);

  useEffect(() => {
    if (paymentsDetails) setDefaultValues(paymentsDetails);
  }, [paymentsDetails, setDefaultValues]);

  return (
    <div className={classes.container}>
      <Form
        {...layout}
        form={form}
        name="work-account"
        onFinish={onSaveHandler}
        onValuesChange={onChange}
      >
        <Form.Item
          name="bank_name"
          label={<span className={classes.label}>Bank name</span>}
          rules={[{ required: true, message: 'Account Number is required' }]}
        >
          <Input maxLength={100} placeholder="Bank name" />
        </Form.Item>

        <Form.Item
          name="ben_account_name"
          label={
            <span>
              Beneficiary/ <br /> Account Number
            </span>
          }
          rules={[{ required: true, message: 'Account Number is required' }]}
        >
          <Input maxLength={100} placeholder="Account Number" />
        </Form.Item>

        <Form.Item
          name="iban_num"
          label="IBAN Number"
          rules={[{ required: true, message: 'IBAN Number is required' }]}
        >
          <Input
            onChange={onChangeIban}
            placeholder="IBAN Number"
            minLength={6}
            maxLength={41}
          />
        </Form.Item>

        <Form.Item
          name="bank_account_number"
          label="Bank Account number"
          rules={[
            { required: true, message: 'Bank Account number is required' },
          ]}
        >
          <Input maxLength={100} placeholder="Bank Account number" />
        </Form.Item>

        <Form.Item>
          <Row justify="end" style={{ gap: 14 }}>
            <ButtonComponent type="cancel" title="Cancel" onClick={onCancel} />
            <ButtonComponent
              type="save"
              title="Save"
              disabled={isDisabled}
              breakpointOff
              style={{ marginLeft: 0 }}
            />
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};
