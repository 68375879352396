import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import classes from './style.module.scss';
import { TableComponent } from '../../Components/Table/Table';
import { MainLayout } from '../../Components/MainLayout';
import { adminActions } from '../../Globals/Admin/actions';
import { RootStateType } from '../../Stores';
import useDebounce from '../../Hooks/useDebounce';
import { WorkShopType } from '../../Types/authTypes';
import { TableConfigItem } from '../../Types/appTypes';
import { useHistory } from 'react-router-dom';

const { Search } = Input;

const LIMIT_ON_PAGE = 20;

const tableConfig: Array<TableConfigItem> = [
  { title: 'Full Name', key: 'name', isSort: true, isClickable: true },
  { title: 'Phone', key: 'phone', isSort: false, align: 'right' },
  { title: 'Email', key: 'email', isSort: false },
  { title: 'Address', key: 'address', isSort: false },
  { title: 'Trade License #', key: 'license', isSort: false, align: 'right' },
  { title: 'Status', key: 'status', isSort: true },
];

export const AdminWorkshops: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(LIMIT_ON_PAGE);
  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const workshops = useSelector(
    (state: RootStateType) => state.admin.workshopList
  );

  const workshopsWithoutDeleted = workshops.rows.filter(
    (workshop) => !workshop.deleted
  );

  const searchWorkshops = useCallback(
    (value: string) => {
      dispatch(adminActions.searchWorkshops(value));
    },
    [dispatch]
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onAction = (data: { id: number; action: string }) => {
    if (data.action === 'Block' || data.action === 'Unblock') {
      const userId = workshopsWithoutDeleted.find(
        (workshop) => workshop.id === data.id
      )?.user.id;
      if (userId)
        dispatch(
          adminActions.blockUnblockUser(
            { blocked: data.action === 'Block', type: 'workshop' },
            userId
          )
        );
    } else if (data.action === 'Delete') {
      dispatch(adminActions.deleteWorkshop(data.id));
    } else if (data.action === 'Reject') {
      dispatch(adminActions.approve(data.id, { rejected: true }));
    }
  };

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const redirectTo = (id: number) => {
    dispatch(adminActions.getUser(id));
    history.push('/admin/workshops/workshop/settings', { workshopId: id });
  };

  const debouncedSearchTerm = useDebounce(searchValue, 700);

  useEffect(() => {
    dispatch(
      adminActions.getWorkshopList({
        page: { page: currentPage, limit: limitOnPage },
        sort: sortValue,
      })
    );
  }, [currentPage, dispatch, limitOnPage, sortValue]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchWorkshops(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, searchWorkshops]);

  const onSort = (fieldKey: string, order?: 'ascend' | 'descend') => {
    if (fieldKey === 'status') {
      if (order === 'ascend') {
        setSortValue([{ approved: 'ASC' }]);
      } else {
        setSortValue(order ? [{ approved: 'DESC' }] : []);
      }
    } else {
      setSortValue(
        order ? [{ [fieldKey]: order === 'ascend' ? 'ASC' : 'DESC' }] : []
      );
    }
  };

  const getActions = (
    status: 'approved' | 'blocked' | 'pending approval' | 'rejected'
  ): Array<'Approve' | 'Reject' | 'Delete' | 'Block' | 'Unblock'> => {
    switch (status) {
      case 'approved':
        return ['Block', 'Delete'];
      case 'blocked':
        return ['Unblock', 'Delete'];
      case 'pending approval':
        return ['Approve', 'Reject', 'Delete'];
      case 'rejected':
        return ['Approve', 'Delete'];
      default:
        return [];
    }
  };

  const createDataList = () => {
    if (workshopsWithoutDeleted.length) {
      return workshopsWithoutDeleted.map((workshop: WorkShopType) => {
        return {
          key: workshop.id,
          name: workshop.name,
          phone: workshop.phone,
          email: workshop.user.email,
          address: workshop.address,
          license: workshop.trade_license,
          status: workshop.status,
          actions: getActions(workshop.status),
        };
      });
    } else {
      return [];
    }
  };

  return (
    <MainLayout headerTitle="Workshops List">
      <div className={classes.contentContainer}>
        <div className={classes.tableHeader}>
          <Search
            placeholder="Search"
            size="large"
            onSearch={searchWorkshops}
            style={{ width: 300 }}
            value={searchValue}
            onChange={(e) => {
              onInputChange(e);
            }}
          />
          {/* <ButtonComponent
            style={{ fontSize: '13px' }}
            title="+ Add Workshop"
            onClick={showConfirm}
          /> */}
        </div>
        <TableComponent
          dataForTableList={createDataList()}
          tableConfig={tableConfig}
          isWorkshops
          totalItems={workshops.count}
          onChangePage={onChangePage}
          goTo={redirectTo}
          onSort={onSort}
          onAction={onAction}
        />
      </div>
    </MainLayout>
  );
};
