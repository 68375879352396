//import classes from './style.module.scss';
import { Tag } from 'antd';
import { ReactNode } from 'react';
import TabbyIcon from '../../../../Assets/tabby-logo.svg'
import StripeIcon from '../../../../Assets/stripe-icon.png'
import classNames from 'classnames';
import classes from './style.module.scss'

type ColumnComponentPropsType = {
  isTag?: boolean;
  isPayment?: boolean;
  value: string | Array<string>;
  children: ReactNode;
  itemKey?: string;
};

enum TagType {
  approved = 'blue',
  blocked = 'red',
  'pending approval' = 'gold',
  paid = 'green',
  not_paid = 'red',
  pending = 'gold',
  declined = 'red',
  accepted = 'blue',
  processing = 'purple',
  finished = 'green',
  finished_by_admin = 'cyan',
  canceled = 'red',
  missed_appointment = 'red',
  rejected = 'red',
}

export const TableItem: React.FC<ColumnComponentPropsType> = ({
  isTag = false,
  isPayment = false,
  value,
  children,
  itemKey,
}): JSX.Element => {
  const getTagColor = (tag: any): TagType => {
    switch (tag) {
      case 'accepted':
        return TagType.accepted;
      case 'approved':
        return TagType.approved;
      case 'blocked':
        return TagType.blocked;
      case 'declined':
        return TagType.declined;
      case 'finished_by_admin':
        return TagType.finished_by_admin;
      case 'finished':
        return TagType.finished;
      case 'not_paid':
        return TagType.not_paid;
      case 'paid':
        return TagType.paid;
      case 'pending':
        return TagType.pending;
      case 'pending approval':
        return TagType.pending;
      case 'processing':
        return TagType.processing;
      case 'canceled':
        return TagType.canceled;
      case 'missed_appointment':
        return TagType.canceled;
      case 'rejected':
        return TagType.rejected;
      default:
        return TagType.accepted;
    }
  };

  if (isTag) {
    return (
      <Tag
        color={getTagColor(children)}
        key={itemKey}
        style={{ textTransform: 'capitalize', borderRadius: '6px' }}
      >
        {typeof children === 'string'
          ? children.split('_').join(' ')
          : children}
      </Tag>
    );
  }
  if(isPayment){
    return(
      children ?
       <img className={classes.paymentIcon} src={TabbyIcon} alt="" />
      :
      <img className={classes.paymentIcon} src={StripeIcon} alt="" />
    )
  }
  else {
    return (
      <>
        {Array.isArray(value) ? (
          <ul>
            {value.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : itemKey === 'phone' && value ? (
          '+971' + value.replace('+', '')
        ) : (
          value
        )}
      </>
    );
  }
};
