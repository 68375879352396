import React, { useEffect } from 'react';
import { Button, Alert } from 'antd';
import classes from './style.module.scss';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginForm from '../../../Components/Auth';
import GoogleIcon from '../../../Assets/google.svg';
//import FacebookIcon from '../../../Assets/facebook.svg';
import { RootStateType } from '../../../Stores';
import { ApiUrls } from '../../../Utils/Api/constants';

type SignUpFormPropsType = {
  userRole: 'workshop' | 'customer' | 'admin';
  isLogin: boolean;
  linkForRedirectAfterLogin?: string 
};
export const SignUpForm: React.FC<SignUpFormPropsType> = ({
  userRole,
  isLogin,
  linkForRedirectAfterLogin
}) => {
  // const dispatch = useDispatch();
  const error = useSelector((state: RootStateType) => state.app.error);

  const dataLayer = window.dataLayer || []

  useEffect(() => {
    //GTM
    //Start
    if(isLogin){
      dataLayer.push({
        "path": "/auth/customer/signin",
        "page_type":"loginPage",
        "event":"page_view"
      });
    }
    else{
      dataLayer.push({
        "path": "/auth/customer/signup",
        "page_type":"signUpPage",
        "event":"page_view"
      });
    }
    //End
  }, [isLogin]);

  return (
    <div className={classes.contain}>
      {userRole === 'customer' && (
        <h1 className={classes.header}>{isLogin ? 'Log in' : 'Sign Up'}</h1>
      )}
      {userRole === 'workshop' && (
        <h1 className={classes.header}>{isLogin ? 'Log in' : 'Application'}</h1>
      )}
      {userRole === 'admin' && (
        <h1 className={classes.header}>{isLogin ? 'Log in' : 'Sign Up'}</h1>
      )}

      {userRole === 'customer' && (
        <>
          <Button
            type="default"
            shape="round"
            style={{height: '40px'}}
            className={classes.button}
            onClick={() =>
              // @ts-ignore
              (window.location = `${ApiUrls.BASE_URL}/connect/google`)
            }
          >
            <img src={GoogleIcon} alt="Google Logo" />
            <span className={classes.btnText}>{isLogin ? 'Login' : 'Sign up'} with Google</span>
          </Button>
          {/* <Button type="default" shape="round" className={classes.button}>
            <img src={FacebookIcon} alt="Facebook Logo" />
            <span className={classes.btnText}>Sign up with Facebook</span>
          </Button> */}
          <div style={{ textAlign: 'center', marginBottom: '28px', marginTop: '8px' }}>or</div>
        </>
      )}
      <LoginForm userRole={userRole} isLogin={isLogin} linkForRedirectAfterLogin={linkForRedirectAfterLogin}/>
      {isLogin && (
        <div className={classes.haveAccountText}>
          <span>
            <NavLink to="forgot-password">Forgot password?</NavLink>
          </span>
        </div>
      )}
      {userRole === 'admin' || (
        <div className={classes.haveAccountText}>
          {isLogin ? (
            <span>
              Don't have an account?
              <NavLink to={`/auth/${userRole}/signup`}> Sign up</NavLink>
            </span>
          ) : (
            <span>
              Already have an account?
              <NavLink to={`/auth/${userRole}/login`}> Log in</NavLink>
            </span>
          )}
        </div>
      )}
      {error && (
        <Alert type="error" message={error} className={classes.alert} />
      )}
    </div>
  );
};
