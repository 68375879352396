import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Steps } from 'antd';
import ReactGA from 'react-ga';
import classes from './style.module.scss';
import { ServiceStep } from '../Steps/ServicesStep';
import { AddressStep } from '../Steps/AddressStep';
import { CarDetailsStep } from '../Steps/CarDetailsStep';
import { SelectWorkshopStep } from '../Steps/SelectWorkshopStep';
import { AppointmentStep } from '../Steps/ApointmentStep';
import { PayStep } from '../Steps/PayStep';
import { BookStep } from '../Steps/BookStep';
import { RootStateType } from '../../../Stores';
import { ModalComponent } from '../../../Components/Modal';
import { ServiceListModal } from '../Steps/ServicesStep/Components/ServiceListModal';
import { userActions } from '../../../Globals/User/actions';
import LogbookIkon from '../../../Assets/orderIcon/logbook-icon-services.svg';
import Visa from '../../../Assets/visa-icon.svg'
import MasterCard from '../../../Assets/mastercard-icon.svg'
import GPay from '../../../Assets/GPay-icon.svg'
import ApPay from '../../../Assets/ApPay-icon.svg'
import Tabby from '../../../Assets/tabby-logo.svg'
import BasicIkon from '../../../Assets/orderIcon/basic-icon.svg';
import { appActions } from '../../../Globals/App/actions';
import * as constants from '../../../Utils/Api/constants';
import { authActions } from '../../../Globals/Auth/actions';

const { Step } = Steps;

type CreateOrderPropsType = {
  isUser?: boolean;
};

export const CreateOrderComponent: React.FC<CreateOrderPropsType> = ({isUser = true}) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [tabbyUrl, setTabbyUrl] = useState<string | null>(null);
  const [orderSavedForConfirm, setOrderSavedForConfirm] = useState<
    string | null
  >(null);
 // debugger
  const [activeStep, setActiveStep] = useState<number>(0);

  const dispatch = useDispatch();
  const location = useLocation<{ params?: string }>();
  const history = useHistory();
  const { path } = useRouteMatch();

  const isModal = useSelector((state: RootStateType) => state.user.isModal);
  const modalData = useSelector((state: RootStateType) => state.user.modalData);
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );
  const orderData = useSelector((state: RootStateType) => state.auth.orderData);
  const orderId = useSelector((state: RootStateType) => state.auth.orderID);
  const services = useSelector((state: RootStateType) => state.user.services);
  const workshops = useSelector((state: RootStateType) => state.user.workshops);
  const token = useSelector((state: RootStateType) => state.auth.user?.jwt);
  const savedSummaryData = useSelector(
    (state: RootStateType) => state.auth.summaryData
  );
  const dataLayer = window.dataLayer || []

  const closeModal = () => {
    dispatch(userActions.setIsModal(false));
  };

  const onConfirm = (type: 'main' | 'logbook') => {
    if (type === 'logbook') {
      dispatch(userActions.setSelectedServiceType(false));
    } else {
      dispatch(userActions.setSelectedServiceType(true));
    }
    closeModal();
  };

  const goToNextStep = (step?: number) => {
    if (activeStep === 5) {
      setActiveStep(0);
      return;
    }
    step ? setActiveStep(step) : setActiveStep(prev => prev +1)
  };

  const goToMyCars = () => {
    if (currentUser?.role.type === 'authenticated') {
      history.push('/user/my-cars');
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  const getUrlFromStepNumber = (step: number) => {
    switch (step) {
      case 0:
        return '';
      case 1:
        return '/services';
      // case 2:
      //   return '/address';
      case 2:
        return '/workshops';
      case 3:
        return '/appointment';
      case 4:
        return '/book';
      case 5:
        return '/payment';
      default:
        return '';
    }
  };

  const goToPrevStep = () => {
    setActiveStep((prev) => {
      // if (prev === 4) {
      //   dispatch(userActions.resetSelectedWorkshop());
      // }
      if (currentUser?.role.type === 'authenticated' && prev === 1) {
        history.push('/user/my-cars');
      }
      return prev - 1;
    });
    dispatch(appActions.clearError());
  };

  const fetchReloadOrderInfoFunction = async (orderDataInfo: any) => {
    dispatch(appActions.openLoader());
    const data = orderDataInfo;
    data['emailSuccess'] = `${constants.ApiUrls.WEB_URL}/createorder/congratulation`;
    data['emailCancel'] = `${constants.ApiUrls.WEB_URL}/createorder/payment`;
    data['emailFailure'] = `${constants.ApiUrls.WEB_URL}/createorder/payment`;
    
    const order = await fetch(`${constants.ApiUrls.BASE_URL}/order-reload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const order_loaded = await order.json();
    setClientSecret(order_loaded.client_secret)
    setTabbyUrl(order_loaded.tabbyUrl)
    dispatch(appActions.closeLoader());
    return order_loaded;
  };

  useLayoutEffect(() => {
    const currentUrl = getUrlFromStepNumber(activeStep);
    if (path + currentUrl !== location.pathname) {
      if (!+location.pathname.split('/')[3]) {
        history.push(path + currentUrl);
      }
    }
  }, [activeStep, history, path]);

  useLayoutEffect(() => {
    const eventHandler = (e: PopStateEvent) => {
      goToPrevStep();
    };

    window.addEventListener('popstate', (e) => {
      eventHandler(e);
    });

    return window.removeEventListener('popstate', eventHandler);
  }, []);

  useEffect(() => {
    if (savedSummaryData && orderId && activeStep === 0) {
      //dispatch(userActions.reloadOrderInfo({id_order: orderId}))
      const order: any =  fetchReloadOrderInfoFunction({id_order: orderId})
      setOrderSavedForConfirm(orderId.toString())
      dispatch(authActions.setOrderID(null))
      dispatch(userActions.setSavedSummaryData(savedSummaryData));
      //dispatch(userActions.setSavedSummaryData(orderData));
      setActiveStep(5)
      return
    }
    //console.log(dispatch(userActions.reloadOrderInfo({id_order: 1356})))
    if (orderData && activeStep === 0) {
      setActiveStep(4);
      dataLayer.push({
        "path": "/createorder/book",
        "page_type":"bookStep",
        "event":"page_view"
      });
      return
    }
    if (
      location.state?.params === 'fromLanding' &&
      activeStep === 0 &&
      !orderData
      )
      {
      setActiveStep(1);
      history.replace({});
    }
    if(activeStep == 0){
      dataLayer.push({
        "path": "/createorder",
        "page_type":"carsdetailsStep",
        "event":"page_view"
      });
    }
    // if (
    //   activeStep === 0 &&
    //   !orderData
    // ){
    //   setActiveStep(1);
    //   history.replace({});
    // }
  }, [location, orderData, workshops]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [activeStep, history]);

  // useEffect(() => {
  //   if (currentUser?.role.type === 'authenticated' && activeStep === 0) {
  //     history.push('/user/my-cars');
  //   }
  // }, []);
  
  /*   const getActiveStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <CarDetailsStep
            goToNextStep={goToNextStep}
            currentStep={activeStep}
            
          />
        );
      case 1:
        ReactGA.pageview('/createorder/services');
        return (
          <ServiceStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToMyCars}
            activeStep={activeStep}
          />
        );
      case 2:
        ReactGA.pageview('/createorder/address');
        return (
          <AddressStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
          />
        );
      case 3:
        ReactGA.pageview('/createorder/workshop');
        return (
          <SelectWorkshopStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
          />
        );
      case 4:
        ReactGA.pageview('/createorder/appointment');
        return (
          <AppointmentStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
          />
        );
      case 5:
        ReactGA.pageview('/createorder/book');
        return (
          <BookStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            setClientSecret={setClientSecret}
            setOrderSavedForConfirm={setOrderSavedForConfirm}
          />
        );
      case 6:
        ReactGA.pageview('/createorder/payment-details');
        return (
          <PayStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            clientSecret={clientSecret}
            orderSavedForConfirm={orderSavedForConfirm}
          />
        );
    } 
  }; */

  return (
    <div>
      {isUser && (
        <div className={classes.paymentContainer}>
          <div className={classes.payments}>
            <p>We accept</p>
            <div className={classes.paymentIcons}>
              <img src={MasterCard} alt="mastercard" />
              <img src={Visa} alt="visa" />
              <img src={GPay} alt="google pay" />
              <img src={ApPay} alt="apple pay" />
            </div>
            <div className={classes.tabby}>
              <img src={Tabby} alt="tabby payment" />
            </div>
          </div>
      </div>
      )}
      <div className={classes.stepsContainer}>
        <Steps
          progressDot
          type="default"
          current={activeStep}
          className={classes.steps}
          size="small"
        >
          <Step title="Car Details" />
          <Step title="Services" />
          {/* <Step title="Address" /> */}
          <Step title="Workshop" />
          <Step title="Appointment" />
          <Step title="Book" />
          <Step title="Payment" />
        </Steps>
        <ModalComponent
          isModal={isModal}
          toggleModal={closeModal}
          title={modalData.title}
          icon={modalData.type === 'logbook' ? LogbookIkon : BasicIkon}
        >
          <ServiceListModal
            type={modalData.type}
            logbook={services?.log_book}
            onConfirm={onConfirm}
            closeModal={closeModal}
            isSelected={modalData.isSelected}
          />
        </ModalComponent>
      </div>
      <div className={[classes.containerMinSize].join(' ')}>
        <Steps current={activeStep} className={classes.steps} size="small" responsive={false}>
          <Step title="" />
          <Step title="" />
          <Step title="" />
          <Step title="" />
          <Step title="" />
          <Step title="" />
          {/* <Step title="" /> */}
        </Steps>
      </div>
      <Switch>
        <Route
          path={`${path}`}
          exact
          render={({ match }) => (
            <CarDetailsStep
              goToNextStep={goToNextStep}
              currentStep={activeStep}
            />
          )}
        />
        <Route path={`${path}/services`}>
          <ServiceStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToMyCars}
            activeStep={activeStep}
          />
        </Route>

        {/* <Route path={`${path}/address`}>
          <AddressStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
          />
        </Route> */}
        <Route
          path={`${path}/workshops/:id`}
          render={({ match }) => (
            <SelectWorkshopStep
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
              orderId={+match.params.id}
            />
          )}
        />
        <Route
          path={`${path}/workshops`}
          render={({ match }) => (
            <SelectWorkshopStep
              isSpinning={isUser}
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
            />
          )}
        />
        <Route path={`${path}/appointment`}>
          <AppointmentStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
          />
        </Route>
        <Route path={`${path}/book`}>
          <BookStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            setClientSecret={setClientSecret}
            setTabbyUrl={setTabbyUrl}
            setOrderSavedForConfirm={setOrderSavedForConfirm}
          />
        </Route>
        <Route path={`${path}/payment`}>
          <PayStep
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            clientSecret={clientSecret}
            tabbyUrl={tabbyUrl}
            orderSavedForConfirm={orderSavedForConfirm}
          />
        </Route>
      </Switch>
      {/* {getActiveStep()} */}
    </div>
  );
};
