import fileDownload from 'js-file-download';
import axios from 'axios';
import { constants } from '../../Utils/Api';
import {
  ReviewForWorkshopRequestType,
  WorkshopForLandingRequestType,
} from '../../Types/appTypes';
import { WorkShopType } from '../../Types/authTypes';

export const appApi = {
  downloadFile: async (link: string, name: string = 'invoice') => {
    axios({
      url: link,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        fileDownload(response.data, name);
      })
      .catch((err) => console.log(err));
  },
  getWorkshops: async () => {
    return axios
      .get<WorkshopForLandingRequestType>(
        `${constants.ApiUrls.BASE_URL}/landing-workshops`
      )
      .then((resp) => resp.data);
  },
  getWorkshop: async (id: number) => {
    return axios
      .get<WorkShopType>(`${constants.ApiUrls.BASE_URL}/workshops/${id}`)
      .then((resp) => resp.data);
  },
  getReviewsForWorkshop: async (id: number) => {
    return axios
      .get<ReviewForWorkshopRequestType>(
        `${constants.ApiUrls.BASE_URL}/reviews?workshop=${id}`
      )
      .then((resp) => resp.data);
  },
  getInvoiceLink: async (token: string, address: string, id: number) => {
    const resp = await axios.get<{ url: string }>(
      `${constants.ApiUrls.BASE_URL}${address}${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp.data;
  },
};
