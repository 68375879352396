import React, { useCallback, useEffect, useState } from 'react';
//import { DownloadOutlined } from '@ant-design/icons';
import { CarResponseType, QueryObjectType } from '../../../../Types/authTypes';
import { userActions } from '../../../../Globals/User/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TableComponent } from '../../../../Components/Table/Table';
import { RootStateType } from '../../../../Stores';
import { TableConfigItem } from '../../../../Types/appTypes';
import moment from 'moment';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';

const tableConfig: Array<TableConfigItem> = [
  { title: 'Date', key: 'appointment_date', isSort: true },
  {
    title: 'Order ID',
    key: 'order_id',
    isSort: false,
    align: 'right',
    isClickable: true,
  },
  { title: 'Services', key: 'services', isSort: false },
  { title: 'Workshop', key: 'workshop', isSort: false },
  { title: 'Price', key: 'price', isSort: false, align: 'right' },
];

type ServiceHistoryPropsType = {
  car: CarResponseType | null;
};

export const ServiceHistory: React.FC<ServiceHistoryPropsType> = ({ car }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limitOnPage, setLimitOnPage] = useState<number>(20);
  const [sortDate, setSortDate] = useState<Moment | null>(null);

  const [sortValue, setSortValue] = useState<
    Array<{ [key: string]: 'ASC' | 'DESC' }>
  >([]);

  const [sortStatus, setSortStatus] = useState<
    'paid' | 'not_paid' | 'pending' | null
  >(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const serviceHistory = useSelector(
    (state: RootStateType) => state.user.serviceHistoryForCar
  );

  const onChangePage = (pageNumber: number, limitOnPage?: number) => {
    setCurrentPage(pageNumber);
    if (limitOnPage) {
      setLimitOnPage(limitOnPage);
    }
  };

  const redirectTo = (id: number) => {
    history.push(`/user/appointments/${id}`);
  };

  const onSort = (fieldKey: string, order?: 'ascend' | 'descend') => {
    if (fieldKey === 'status') {
      if (order === 'ascend') {
        setSortValue([{ blocked: 'ASC' }]);
      } else {
        setSortValue([{ unblocked: 'ASC' }]);
      }
    } else {
      setSortValue(
        order ? [{ [fieldKey]: order === 'ascend' ? 'ASC' : 'DESC' }] : []
      );
    }
  };

  const generateQuery = useCallback(
    (nextPage: number): QueryObjectType => {
      const obj = {
        page: {
          page: nextPage,
          limit: limitOnPage,
        },
        filter: sortStatus ? [{ payment_status: sortStatus }] : [],
        sort: sortValue,
      };
      return {
        ...obj,
        status: ['finished'],
        ...(sortDate && {
          filter: [
            ...obj.filter,
            {
              created_at_gte:
                sortDate?.startOf('month').format('YYYY-MM-DD') +
                'T00:00:00.000Z',
            },
            {
              created_at_lt:
                sortDate?.endOf('month').format('YYYY-MM-DD') +
                'T00:00:00.000Z',
            },
          ],
        }),
      };
    },
    [limitOnPage, sortStatus, sortValue, sortDate]
  );

  const createDataList = () => {
    if (serviceHistory?.rows.length) {
      return serviceHistory.rows.map((service) => {
        return {
          key: service.id,
          appointment_date: moment(service.appointment_date).format('MMM DD, YYYY'),
          order_id: service.id,
          services: service.order_services.map((item) => item.title),
          workshop: service.workshop.name,
          price: service.total_price,
        };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (car?.id) {
      dispatch(
        userActions.getServiceHistoryForCar(generateQuery(currentPage), car?.id)
      );
    }
  }, [currentPage, limitOnPage, dispatch, sortValue, generateQuery, car?.id]);

  return (
    <TableComponent
      dataForTableList={createDataList()}
      tableConfig={tableConfig}
      totalItems={serviceHistory?.count}
      onChangePage={onChangePage}
      isLastColumn={false}
      onSort={onSort}
      goTo={redirectTo}
    />
  );
};
