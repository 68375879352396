import  {useState, useEffect} from 'react'


const useDebounce = <T = string> (value: T, delay: number) => {

    const [debouncedValue, setDebouncedValue] = useState<T>();

    useEffect( () => {
        const handler = setTimeout( () => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearInterval(handler)
        }
    },[value, delay])

    return debouncedValue

}

export default useDebounce