import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import cn from 'classnames';
import classes from './styles.module.scss';

type MenuItemPropsType = {
  title: string;
  path: string;
  isSideBar?: boolean;
  style?: Record<string, unknown> 
};

export const MenuItem: React.FC<MenuItemPropsType> = ({
  title,
  path,
  isSideBar = false,
  style
}) => {
  const history = useHistory();

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  useEffect(() => {
    const el = document.getElementById(path);
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (history.location.pathname === '/')
          history.push(`#${path}`);
          return;
        }
      },
      {
        root: null,
        threshold: 0.9,
      }
    );
    if(el) {
      observer.observe(el);
    }

    
  }, [history, path]);

  return (
    <NavHashLink
      smooth
      className={cn({
        [classes.mainLink]: isSideBar
      })}
      to={`/#${path}`}
      scroll={(el) => scrollWithOffset(el)}
      style={{ color: 'inherit', ...style}}
      activeClassName={classes.active}
    >
      {title}
    </NavHashLink>
  );
};
