import {
  PhoneType,
  QueryObjectType,
  ScheduleRequest,
  WorkshopPrices,
  WorkshopPricesResponse,
  WorkShopRequestType,
  CitiesTypeRequest
} from './../../Types/authTypes';
import { InferActionsTypes } from '../../Stores';
import * as workshopConstants from './constants';
import { WorkShopType } from '../../Types/authTypes';
import {
  OrderForWorkshopType,
  OrderService,
  OrderServicesForJob,
  OrdersRequestType,
  PaymentDetailsRequest,
  PaymentPreferencesRequestType,
  PaymentsDetailsResponse,
  RequestInvoiceType,
  SpecialistType,
  StripeInfoType,
  WorkshopAppointmentRequestType,
} from '../../Types/workshopTypes';
import axios from 'axios';
import { ApiUrls } from '../../Utils/Api/constants';

export const workshopActions = {
  getWorkshopData: (id: number) =>
    ({
      type: workshopConstants.GET_WORKSHOP_DATA,
      id,
    } as const),
  updateWorkshop: (payload: WorkShopRequestType, workshopId?: number) =>
    ({
      type: workshopConstants.UPDATE_WORKSHOP,
      payload,
      workshopId,
    } as const),
  setWorkshopData: (payload: WorkShopType) =>
    ({
      type: workshopConstants.SET_WORKSHOP_DATA,
      payload,
    } as const),
  getWorkshopSchedules: (id: number, workshopId?: number) =>
    ({
      type: workshopConstants.GET_WORKSHOP_SCHEDULES,
      id,
      workshopId,
    } as const),
  updateWorkshopSchedules: (payload: ScheduleRequest, workshopId?: number) =>
    ({
      type: workshopConstants.UPDATE_WORKSHOP_SCHEDULES,
      payload,
      workshopId,
    } as const),
  setWorkshopSchedules: (payload: ScheduleRequest) =>
    ({
      type: workshopConstants.SET_WORKSHOP_SCHEDULES,
      payload,
    } as const),
  getWorkshopPrices: (workshopId?: number) =>
    ({
      type: workshopConstants.GET_WORKSHOP_PRICES,
      workshopId,
    } as const),
  updateWorkshopPrices: (payload: WorkshopPrices, workshopId?: number) =>
    ({
      type: workshopConstants.UPDATE_WORKSHOP_PRICES,
      payload,
      workshopId,
    } as const),
  setWorkshopPrices: (payload: WorkshopPricesResponse) =>
    ({
      type: workshopConstants.SET_WORKSHOP_PRICES,
      payload,
    } as const),
  getOrders: (payload: QueryObjectType) =>
    ({
      type: workshopConstants.GET_ORDERS,
      payload,
    } as const),
  setOrders: (payload: OrdersRequestType<Array<OrderService>>) =>
    ({
      type: workshopConstants.SET_ORDERS,
      payload,
    } as const),
  resetOrders: () =>
    ({
      type: workshopConstants.RESET_ORDERS,
    } as const),
  changeOrderStatus: (
    id: number,
    status:
      | 'accepted'
      | 'declined'
      | 'processing'
      | 'finished'
      | 'missed_appointment'
  ) =>
    ({
      type: workshopConstants.CHANGE_ORDER_STATUS,
      id,
      status,
    } as const),
  updateOrder: (payload: OrderForWorkshopType<OrderServicesForJob>) =>
    ({
      type: workshopConstants.UPDATE_ORDER,
      payload,
    } as const),
  getCities: () =>
    ({
      type: workshopConstants.GET_CITIES,
    } as const),
  setCities: (cities: Array<CitiesTypeRequest>) =>
    ({
      type: workshopConstants.SET_CITIES,
      cities,
    } as const),
  isHasMore: (payload: boolean) =>
    ({
      type: workshopConstants.IS_HAS_MORE,
      payload,
    } as const),
  getOrderById: (payload: number) =>
    ({
      type: workshopConstants.GET_ORDER_BY_ID,
      payload,
    } as const),
  setOrder: (payload: OrderForWorkshopType<OrderServicesForJob> | null) =>
    ({
      type: workshopConstants.SET_ORDER,
      payload,
    } as const),
  resetOrder: () =>
    ({
      type: workshopConstants.RESET_ORDER,
    } as const),
  markIsDone: (payload: { id: number; note?: string; status?: boolean }) =>
    ({
      type: workshopConstants.MARK_IS_DONE,
      payload,
    } as const),
  markIsAllDone: (payload: { order_id: number; note?: string; status?: boolean }) =>
  ({
    type: workshopConstants.MARK_IS_ALL_DONE,
    payload,
  } as const),
  sendWorkshopNote: (payload: { id: number; workshop_note: string }) =>
    ({
      type: workshopConstants.SEND_WORKSHOP_NOTE,
      payload,
    } as const),
  getWorkshopSpecialists: (id?: number) =>
    ({
      type: workshopConstants.GET_WORKSHOP_SPECIALISTS,
      id,
    } as const),
  setWorkshopSpecialists: (payload: Array<SpecialistType>) =>
    ({
      type: workshopConstants.SET_WORKSHOP_SPECIALISTS,
      payload,
    } as const),
  updateWorkshopSpecialists: (
    payload: Array<{ id: number; status: boolean }>,
    workshopId?: number
  ) =>
    ({
      type: workshopConstants.UPDATE_WORKSHOP_SPECIALISTS,
      payload,
      workshopId,
    } as const),
  getWorkshopPromotions: (payload?: number) =>
    ({
      type: workshopConstants.GET_WORKSHOP_PROMOTIONS,
      payload,
    } as const),
  setWorkshopPromotions: (payload: Array<SpecialistType>) =>
    ({
      type: workshopConstants.SET_WORKSHOP_PROMOTIONS,
      payload,
    } as const),
  updateWorkshopPromotions: (
    payload: Array<{ id: number; status: boolean }>,
    workshopId?: number
  ) =>
    ({
      type: workshopConstants.UPDATE_WORKSHOP_PROMOTIONS,
      payload,
      workshopId,
    } as const),
  getWorkshopInvoices: (payload: QueryObjectType) =>
    ({
      type: workshopConstants.GET_WORKSHOP_INVOICES,
      payload,
    } as const),
  setWorkshopInvoices: (payload: RequestInvoiceType) =>
    ({
      type: workshopConstants.SET_WORKSHOP_INVOICES,
      payload,
    } as const),
  getWorkshopAppointments: (payload: QueryObjectType) =>
    ({
      type: workshopConstants.GET_WORKSHOP_APPOINTMENTS,
      payload,
    } as const),
  setWorkshopAppointments: (payload: WorkshopAppointmentRequestType) =>
    ({
      type: workshopConstants.SET_WORKSHOP_APPOINTMENTS,
      payload,
    } as const),
  getWorkshopInvoiceLink: (payload: number) =>
    ({
      type: workshopConstants.GET_WORKSHOP_INVOICE_LINK,
      payload,
    } as const),
  getWorkshopMonthlyRevenue: () =>
    ({
      type: workshopConstants.GET_WORKSHOP_MONTHLY_REVENUE,
    } as const),
  setWorkshopMonthlyRevenue: (payload: { total_price: number | null }) =>
    ({
      type: workshopConstants.SET_WORKSHOP_MONTHLY_REVENUE,
      payload,
    } as const),
  getWorkshopPaymentsLink: () =>
    ({
      type: workshopConstants.GET_WORKSHOP_PAYMENTS_LINK,
    } as const),
  setWorkshopPaymentsLink: (payload: PaymentPreferencesRequestType) =>
    ({
      type: workshopConstants.SET_WORKSHOP_PAYMENTS_LINK,
      payload,
    } as const),
  addNewPhone: (workshopId: number, phone: string) =>
    ({
      type: workshopConstants.ADD_NEW_PHONE,
      workshopId,
      phone,
    } as const),
  editPhone: (workshopId: number, phone: string, phoneId: number) =>
    ({
      type: workshopConstants.EDIT_PHONE,
      workshopId,
      phone,
      phoneId,
    } as const),
  deletePhone: (workshopId: number, phoneId: number) =>
    ({
      type: workshopConstants.DELETE_PHONE,
      workshopId,
      phoneId,
    } as const),
  setUpdatedPhones: (payload: Array<PhoneType> | null) =>
    ({
      type: workshopConstants.SET_UPDATED_PHONES,
      payload,
    } as const),
  getStripeInfo: () =>
    ({
      type: workshopConstants.GET_STRIPE_INFO,
    } as const),
  setStripeInfo: (payload: StripeInfoType) =>
    ({
      type: workshopConstants.SET_STRIPE_INFO,
      payload,
    } as const),
  getPaymentsData: (payload: number) =>
    ({
      type: workshopConstants.GET_WORKSHOP_PAYMENTS_DATA,
      payload,
    } as const),
  updatePaymentsData: (data: PaymentDetailsRequest, id: number) =>
    ({
      type: workshopConstants.UPDATE_WORKSHOP_PAYMENTS_DATA,
      data,
      id
    } as const),
  setPaymentsData: (payload: PaymentsDetailsResponse) =>
    ({
      type: workshopConstants.SET_WORKSHOP_PAYMENTS_DATA,
      payload,
    } as const)
};

export type ActionTypes = InferActionsTypes<typeof workshopActions>;
