import classNames from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ButtonComponent } from '../../../Components/Button';
import classes from './style.module.scss';

type AfterSignUpPropsType = {};
export const AfterSignUp: React.FC<AfterSignUpPropsType> = ({}) => {
  const history = useHistory()
  return (
    <div className={classes.contain}>
      <div className={classNames(classes.textContainer)}>
        <div className={classNames(classes.textBlock)}>
          Thank you for your application, <br/> we love that you want to be <br/> part of
          CarLogik.
        </div>
        <div className={classNames(classes.textBlock)}>
          Help us get to know you better! <br/>Start by telling us about your
          workshop – Please fill in all the fields <Link to='/workshop/settings'>here</Link>
        </div>
        <div className={classNames(classes.textBlock)}>
          We are carefully reviewing <br/> your application, and will contact you
          shortly!
        </div>
      </div>
      <div className={classes.signature}>Car Logik Team</div>
      <ButtonComponent title="Go to settings" size="lg" style={{width: '100%'}} onClick={() => {history.push('/workshop/settings')}}/>
    </div>
  );
};
