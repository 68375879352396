import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import classes from './style.module.scss';
import LogbookIkon from '../../../../../../Assets/orderIcon/logbook-icon-services2.svg';
import BasicIkon from '../../../../../../Assets/orderIcon/basic-icon.svg';
import BasicIkonMob from '../../../../../../Assets/orderIcon/other-service-icon2-mob.svg';
import LogbookIkonMob from '../../../../../../Assets/orderIcon/logbook-icon-mob.svg';
import { ServiceCard } from '../ServiceCard';
import { RootStateType } from '../../../../../../Stores';

type ServiceTypePropsType = {
  openModel: (title: string, modalType: 'main' | 'logbook', isSelected: boolean) => void;
};

export const ServiceType: React.FC<ServiceTypePropsType> = ({ openModel }) => {
  const formValues = useSelector(
    (state: RootStateType) => state.user.formValues
  );
  const isBasic = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );
  
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth} = window;
    return innerWidth;
  }
  const SwitchIconsWidth = 570;
  return (
    <div className={classes.container}>
      <h3 className={classes.header}>
        What does your {formValues?.brand?.title} {formValues?.model?.title} need?
      </h3>
      <div className={classes.cardContainer}>
        <ServiceCard
          //style={{ width: '272px', height: '144px' }}
          title="Major service"
          subtitle='A premium agency alternative service'
          isSelected={isBasic === false}
          icon={(windowSize > SwitchIconsWidth) ? LogbookIkon : LogbookIkonMob}
          onClick={() =>
            openModel(
              `Required Major service ${formValues?.milliage?.value ?  '- ' + formValues?.milliage?.value.toLocaleString() + ' kms': ''}`,
              'logbook',
              isBasic !== null
            )
          }
        />

        <ServiceCard
          //style={{ width: '272px', height: '144px' }}
          title="Minor service"
          subtitle='Just an Oil and Filter Change'
          isDisable={isBasic === false}
          isSelected={isBasic === true}
          isShowTooltip={isBasic === false }
          icon={(windowSize > SwitchIconsWidth) ? BasicIkon : BasicIkonMob}
          onClick={() =>
            openModel(
              `Required Minor service ${formValues?.milliage?.value ?  '- ' + formValues?.milliage?.value.toLocaleString() + 'kms': ''}`,
              'main',
              isBasic !== null
            )
          }
        />
      </div>
    </div>
  );
};
