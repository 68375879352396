import React, {useEffect} from 'react';
import { Radio, Tooltip } from 'antd';
import ReactGA from "react-ga";
import classes from './style.module.scss';
import './styles.scss';
import {useDispatch} from "react-redux";
import {userActions} from "../../../../../../Globals/User/actions";
import classNames from 'classnames';

type ServiceCardPropsType = {
  style?: any;
  isDisable?: boolean;
  title: string;
  icon: any;
  column?: boolean;
  subtitle?: string;
  checked?: boolean;
  id?: number;
  onClick?: () => void;
  onSelect?: (id: number) => void;
  car_repair?: boolean;
  isShowTooltip?: boolean;
  isSelected?: boolean;
  tooltipText?: string;
};

export const ServiceCard: React.FC<ServiceCardPropsType> = ({
  isDisable = false,
  isShowTooltip = false,
  title,
  icon,
  column = true,
  subtitle,
  checked,
  onClick = () => {},
  onSelect,
  car_repair,
  id,
  style,
  isSelected = false,
  tooltipText
}) => {
  const dispatch = useDispatch();

  const onSelectHandler = () => {
    if (isSelected) {
      //return onClick();
    }
    if (onSelect && id && !isDisable) {
      if(car_repair && !isSelected){
        onClick();
      }
      onSelect(id);
    } else if (!isDisable || subtitle) {
      onClick();
    }
  };
  const tooltipProp = () => {
    if (isShowTooltip) {
      return {};
    } else {
      return {
        visible: false,
      };
    }
  };


  useEffect(() => {
    if (id && isDisable) {
      dispatch(userActions.removeOtherService(id));
      dispatch(userActions.removeAllCarRepair())
    }
  }, [id, isDisable, dispatch]);


  return (
    <Tooltip
      title={tooltipText || "Part of your Major service"}
      {...tooltipProp()}
      color="#205680"
      placement="right"
    >
      <div
        style={{ ...style }}
        onClick={onSelectHandler}
        className={classNames(classes.card, {
          [classes.disable]: isDisable,
          [classes.column]: column,
          [classes.selectedBorder]: isSelected,
          [classes.hideDot]: false,
        })}
      >
        {/* {subtitle && (
          <span className={classes.radio}>
            <Radio checked={checked} disabled={isDisable}/>
          </span>
        )} */}
        <img src={icon} alt="icon" className={classes.image}/>
        <div className='otherText'>
          <h3>{title}</h3>
          {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
        </div>
      </div>
    </Tooltip>
  );
};
