import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { authActions } from '../../Globals/Auth/actions';
import { Avatar } from 'antd';
import useConfirm from '../../Hooks/useConfirm';
import { persistor } from '../../Stores/index';
import { appActions } from '../../Globals/App/actions';
import UserIcon from '../../Assets/userIcon.svg';
import classNames from 'classnames';
import classes from "./style.module.scss"

type SignOutPropsType = {
  isHide?: boolean
  name: string;
};

export const SignOut: React.FC<SignOutPropsType> = ({ isHide, name }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { confirm } = useConfirm();

  const showConfirm = async () => {
    const isConfirmed = await confirm('Do  you really want to Log out?', [
      'No',
      'Yes, Log out',
    ]);
    if (isConfirmed) {
      signOut();
    } else {
    }
  };


  const signOut = () => {
    dispatch(authActions.signOut());
    dispatch(appActions.resetAlerts());
    history.push('/');
    persistor.purge().then((e) => console.log(e));
    window.location.reload();
  };

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer'}}
      onClick={showConfirm}
    > 
    {isHide ? 
      <span className={classes.span} style={{display: 'none'}}>{name}</span>
      :
      <span className={classes.span} >{name}</span>
    }{/* <Avatar
        size={24}
        style={{
          marginLeft: '8px',
          cursor: 'pointer',
        }}
      >
        User
      </Avatar> */}
      <img src={UserIcon} style={{ marginLeft: '9px', marginRight: '24px' }} alt="user icon" />
    </div>
  );
};
