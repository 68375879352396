import React, { useState, useEffect, MouseEvent } from 'react';
import { Tooltip, Form, Input, Checkbox } from 'antd';
import { ButtonComponent } from '../Button';
import GarageIcon from '../../Assets/garage.svg';
import FieldNumberIcon from '../../Assets/fieldNumber.svg';
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import classes from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../Globals/Auth/actions';
import { useHistory } from 'react-router';
import { RootStateType } from '../../Stores';
import { SignUpWorkshopType } from '../../Types/authTypes';
//import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import { TermsList } from '../../Views/LandingPage/Views/TermsConditions/TermsList';
import { ModalComponent } from '../Modal';
import { PrivacyList } from '../../Views/LandingPage/Views/PrivacyPolicy/PrivacyPolisyList';

type LoginFormPropsType = {
  userRole: 'workshop' | 'customer' | 'admin' | 'forgot-password';
  isLogin?: boolean;
  setEmail?: (email: string) => void;
  linkForRedirectAfterLogin?: string
};

const LoginForm: React.FC<LoginFormPropsType> = ({
  userRole,
  isLogin,
  setEmail,
  linkForRedirectAfterLogin
}) => {
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [isPrivacyVisible, setIsPrivacyVisible] = useState(false);
  //const [isError, setError] = useState(false);

  const dispatch = useDispatch();
  const route = useSelector((state: RootStateType) => state.auth.route);

  const history = useHistory();


  useEffect(() => {
    if (route) {
      history.push(route);
      dispatch(authActions.setRoute(''));
    }
    return () => {
      dispatch(authActions.setRoute(''));
    };
  }, [route, dispatch, history]);

  const createSignUpObjectForWorkShop = (value: any): SignUpWorkshopType => {
    return {
      email: value.email,
      password: value.password,
      app_role: 'workshop',
      workshop: {
        name: value.workshop_name,
        is_workshop: value.is_workshop,
        mobile_mechanic: value.mobile_mechanic,
        phone: value.phone,
        contact_person: value.name,
        trade_license: value.trade_license,
      },
    };
  };

  const onFinish = (values: any) => {
    if (userRole === 'workshop' && !isLogin) {
      dispatch(authActions.signUp(createSignUpObjectForWorkShop(values)));
    } else if (userRole === 'customer' && !isLogin) {
      dispatch(
        authActions.signUp({
          email: values.email,
          password: values.password,
          username: values.name,
        })
      );
    } else if (userRole === 'forgot-password') {
      dispatch(
        authActions.forgotPassword({
          email: values.email,
        })
      );
      if (setEmail) {
        setEmail(values.email);
      }
    } else if (isLogin) {
      dispatch(
        authActions.signIn({
          identifier: values.email,
          password: values.password,
        }, linkForRedirectAfterLogin)
      );

      }
  };

  const showModal = (kind: 'terms' | 'privacy', e: MouseEvent<HTMLElement>) => {
    if (kind === 'terms') {
      setIsTermsVisible(true);
    } else {
      setIsPrivacyVisible(true);
    }
  };

  const handleOk = () => {
    setIsTermsVisible(false);
    setIsPrivacyVisible(false);
  };

  const handleCancel = () => {
    setIsTermsVisible(false);
    setIsPrivacyVisible(false);
  };

  /* const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setError(!e.target.checked);
  }; */

  const handleValidator = (value: string, message: string) => {
    //setError(!value);
    if (!value) {
      return Promise.reject(new Error('Field is required'));
    } else {
      return value.trim()
        ? Promise.resolve()
        : Promise.reject(new Error(message));
    }
  };

  return (
    <Form
      name="register"
      className={classes.loginForm}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      {userRole === 'workshop' && !isLogin && (
        <Form.Item
          name="workshop_name"
          style={{ marginBottom: '16px' }}
          rules={[
            {
              validator: (_, value) =>
                handleValidator(value, 'Please input correct name!'),
            },
          ]}
        >
          <Input
            className={classes.input}
            prefix={<img src={GarageIcon} alt="workshop" />}
            placeholder="Workshop Name"
          />
        </Form.Item>
      )}
      {isLogin || (
        <Form.Item
          name="name"
          style={{ marginBottom: '16px' }}
          rules={[
            // { required: true, message: 'Please input your name!' },
            {
              validator: (_, value) =>
                handleValidator(value, 'Please input correct name!'),
            },
          ]}
        >
          <Input
            className={classes.input}
            prefix={<UserOutlined />}
            placeholder={userRole === 'workshop' ? 'Contact Person' : 'Name'}
          />
        </Form.Item>
      )}

      <Form.Item
        name="email"
        style={{ marginBottom: '16px' }}
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email' },
        ]}
      >
        <Input
          className={classes.input}
          prefix={<MailOutlined />}
          placeholder="Email"
        />
      </Form.Item>

      {userRole === 'workshop' && !isLogin && (
        <Form.Item
          name="phone"
          style={{ marginBottom: '16px' }}
          rules={[
            { required: true, message: 'Please input your phone number!' },
            {
              validator: (_, value: number) => {
                if (
                  value.toString().length !== 9 &&
                  value.toString().length !== 0
                ) {
                  return Promise.reject(new Error('must be 9 characters'));
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            onWheel={(event) => event.currentTarget.blur()}
            type="number"
            className={classNames(classes.input, classes.phoneInput)}
            prefix={<PhoneOutlined />}
            placeholder="5x xxx xxxx"
            addonBefore="+971"
            maxLength={9}
          />
        </Form.Item>
      )}

      {userRole === 'workshop' && !isLogin && (
        <Form.Item
          name="trade_license"
          style={{ marginBottom: '16px' }}
          rules={[
            { required: true, message: 'Please input your trade license!' },
            {
              validator: (_, value) =>
                handleValidator(value, 'Please input correct trade license!'),
            },
          ]}
        >
          <Input
            className={classes.input}
            prefix={<img src={FieldNumberIcon} alt="field number" />}
            placeholder="Trade License number"
          />
        </Form.Item>
      )}

      {userRole !== 'forgot-password' && (
        <Form.Item
          style={{ marginBottom: '5px' }}
          name="password"
          rules={[
            { required: true, message: 'Please input your Password!' },
            {
              type: 'string',
              min: 6,
              max: 20,
              message: 'password must be at from 6 to 20 characters',
            },
            {
              validator: (_, value) =>
                handleValidator(value, 'Please input correct password!'),
            },
          ]}
        >
          <Input
            className={classes.input}
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
      )}
      {userRole === 'workshop' && !isLogin && (
        <Form.Item name="mobile_mechanic" noStyle valuePropName="checked">
          <Checkbox
            className={classes.checkBoxText}
            style={{ marginTop: '24px' }}
          >
            <p
              style={{
                marginLeft: '8px',
                cursor: 'pointer',
              }}
            >
              I’m a mobile Mechanic
              <Tooltip
                placement="right"
                title="Do you have a dedicated vehicle to service clients at their location?"
                style={{ marginLeft: '8px' }}
                color={'#205680'}
              >
                <InfoCircleOutlined className={classes.tooltipIcon} />
              </Tooltip>
            </p>
          </Checkbox>
        </Form.Item>
      )}
      {userRole === 'workshop' && !isLogin && (
        <Form.Item name="is_workshop" noStyle valuePropName="checked">
          <Checkbox
            className={classes.checkBoxText}
            style={{ marginTop: '24px' }}
          >
            <p
              style={{
                marginLeft: '8px',
                cursor: 'pointer',
              }}
            >
              I’m a Workshop
              {/*  <Tooltip
                placement="right"
                title="Do you have a dedicated vehicle to service clients at their location?"
                style={{marginLeft: '8px'}}
                color={'#205680'}
              >
                <InfoCircleOutlined className={classes.tooltipIcon} />
              </Tooltip> */}
            </p>
          </Checkbox>
        </Form.Item>
      )}

      {isLogin || (
        <Form.Item
          name="accept"
          //noStyle
          valuePropName="checked"
          style={{ marginBottom: '0' }}
          rules={[
            {
              required: true,
              transform: (value) => value || undefined,
              type: 'boolean',
              message: 'You should accept our terms and conditions',
            },
          ]}
        >
          <Checkbox className={classes.checkBoxText}>
            <p
              style={{
                marginLeft: '8px',
                cursor: 'pointer',
              }}
            >
              I accept the{' '}
              <span
                className={classes.link}
                onClick={(e) => {
                  showModal('terms', e);
                }}
              >
                Terms of use
              </span>{' '}
              and{' '}
              <span
                className={classes.link}
                onClick={(e) => {
                  showModal('privacy', e);
                }}
              >
                Privacy Policy
              </span>
            </p>
          </Checkbox>
        </Form.Item>
      )}
      {/* {isError && (
        <div style={{ marginLeft: '40px', position: 'relative' }}>
          <span className={classes.error}>
            Accept the Terms of use and Privacy Policy!
          </span>
        </div>
      )} */}
      <ModalComponent
        title="Terms and Conditions"
        isModal={isTermsVisible}
        toggleModal={handleOk}
        buttonConfig={[{ type: 'save', title: 'ok' }]}
        onCancel={handleCancel}
      >
        <div style={{ height: '500px', overflowY: 'scroll' }}>
          <h2>Terms and Conditions of Use</h2>
          <TermsList />
        </div>
      </ModalComponent>

      <ModalComponent
        title="Privacy Policy"
        isModal={isPrivacyVisible}
        toggleModal={handleOk}
        buttonConfig={[{ type: 'save', title: 'ok' }]}
        onCancel={handleCancel}
      >
        <div style={{ height: '500px', overflowY: 'scroll' }}>
          <h2>Privacy Policy</h2>
          <PrivacyList />
        </div>
      </ModalComponent>

      <Form.Item>
        <ButtonComponent
          type="save"
          title={
            isLogin
              ? userRole === 'forgot-password'
                ? 'Send reset link '
                : 'Log in'
              : userRole === 'workshop'
              ? 'Apply'
              : 'Sign Up'
          }
          style={{ width: '100%', marginLeft: 0, marginTop: '20px' }}
        />
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
