import { useDispatch, useSelector } from 'react-redux';
import React, { ReactNode } from 'react';
import { RootStateType } from '../Stores';
import { appActions } from '../Globals/App/actions';

export const ConfirmContext = React.createContext({
  confirmState: {
    isShow: false,
    text: '',
    buttonText: ['Cancel', 'Confirm'],
  },
  showConfirm: (text: string, textButtons?: [string, string]): void => {},
  hideConfirm: (): void => {}
});

export const ConfirmContextProvider = ({ children }: {children: ReactNode}) => {
  const confirmState = useSelector((state: RootStateType) => state.app.confirm);
  const dispatch = useDispatch();
  
  const showConfirm = (text: string, textButtons: [string, string] = ['Cancel', 'Confirm']) => {
    dispatch(appActions.showConfirm(text, textButtons))
  }

  const hideConfirm = () => {
    dispatch(appActions.hideConfirm())
  }

  return (
    <ConfirmContext.Provider value={{confirmState, showConfirm, hideConfirm}}>
      {children}
    </ConfirmContext.Provider>
  );
};
