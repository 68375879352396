import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import classes from './style.module.scss';
import watsappLogo from '../../Assets/whatsapp.svg'
import { MainLayout } from '../../Components/MainLayout';
import { RootStateType } from '../../Stores';
import { CreateOrderComponent } from './CreateOrderComponent';
import LogoIcon from '../../Assets/Logo-icon.svg';
import { userActions } from '../../Globals/User/actions';
import { CarDetailsFormType } from '../../Types/authTypes';
import { SavedDataObjectType } from '../../Types/userTypes';

export const EventGA = (category: string, action: string) => {
  ReactGA.event({
    category,
    action,
  });
};

type CreateOrderPropsType = {

};

export const CreateOrder: React.FC<CreateOrderPropsType> = ({ }) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const order = useSelector((state: RootStateType) => state.user.order);
  const services = useSelector((state: RootStateType) => state.user.services);

  const resetForm = () => {
    dispatch(userActions.resetOrderData());
  };

  const createFormValues = useCallback((): CarDetailsFormType => {
    if (order) {
      return {
        brand: order.customer_car.make,
        milliage: order.customer_car.maintenance_interval || undefined,
        model: {
          ...order.customer_car.model,
          models_id: [order.customer_car.model.id],
        },
        trim: order.customer_car.trim,
        year: order.customer_car.year,
      };
    } else {
      return {
        brand: undefined,
        milliage: undefined,
        model: undefined,
        trim: undefined,
        year: undefined,
      };
    }
  }, [order]);

  const createDataObject = useCallback((): SavedDataObjectType | undefined => {
    if (order && services) {
     
      return {
        selectedOtherServicesId: order.order_services.additional.map(
          (item) => item.id
        ),
        otherServices: services.additional,
        isBasic: order.basic ? true : order.log_book ? false : null,
        parts: services.check,
        selectedOtherServices:
          services.additional?.filter((service) =>
            order.order_services.additional.find(
              (item) => +service.id === +item.id
            )
          ) || [],
        appointment: null,
        deliveryCar: {
          pick_up_slot: order.pick_up,
          drop_off_slot: order.drop_off,
          time: {
            pick_up_time_slot: null,
            drop_off_time_slot: order.drop_off_time_slot,
          },
        },
        selectedWorkshop: null,
        selectedDate: null,
        formValues: createFormValues(),
        location: order.at_location ? 'at_location' : 'at_workshop',
        addressPoint: {
          address: order.client_address,
          //lat: order.latitude,
          //lng: order.longitude,
          //link: order.address_url || '',
        },
        customer_note: '',
        car_deliveries: order.car_deliveries
      };
    }
  }, [createFormValues, order, services]);

  const dataObject = createDataObject();

  // function handleSubmit(event:any) {
  //   event.preventDefault();
  // }
  /* useEffect(() => {
    if (currentUser?.role.type === 'authenticated' && orderId) {
      dispatch(userActions.getDeclinedOrder(orderId));
    }
  }, [currentUser?.role.type, dispatch, orderId]); */
  
  useEffect(() => {
    if (order) {
      dispatch(
        userActions.getWorkshops(
          {
            trimId: order.customer_car.trim.id,
            intervalId: order.customer_car.maintenance_interval?.id,
            modelId: order.customer_car.model.id,
            isBasic: order.basic ? true : order.log_book ? false : null,
            additionalTasks: order.order_services.additional
              .filter((item) => item.id < 9)
              .map((item) => item.id),
            city_id: 1
            // pick_up: order.pick_up,
            // drop_off: order.drop_off,
            // coord: { lat: order.latitude, lng: order.longitude },
            // mobile_mechanic: order.at_location === true,
          },
          false,
          1,
          undefined,
          order.workshop
        )
      );
      dispatch(userActions.getServicesForCustomCar(order.customer_car.id));

      dispatch(userActions.setSelectedCar(order.customer_car));
    }
  }, [dispatch, order]);
  
  useEffect(() => {
    if (dataObject) {
      dispatch(userActions.setSavedSummaryData(dataObject));
    }
  }, [dispatch, dataObject]);

  useEffect(() => {
    ReactGA.event({
      category: 'Create order',
      label: 'Create order',
      action: `User ${
        currentUser?.username ? currentUser?.username : 'not registered'
      } started create order`,
    });
  }, [currentUser?.username]);

  const getLayout = () => {
    if (currentUser?.role.type === 'authenticated') {
      return (
        <MainLayout isHorizontalPadding={false} isCreateOrder={false}>
          <CreateOrderComponent isUser={false}/>
        </MainLayout>
      );
    } else {
      return (
        <div className={classes.container}>
          <div className={classes.header}>
            <Link
              to="/"
              onClick={() => {
                resetForm();
              }}
            >
              <img src={LogoIcon} alt="logo" className={classes.logoIcon} />
            </Link>
            <div className={classes.whatsapp}>
              <a className={classes.whatsapp} href="https://wa.me/97180056445" target="_blank">
                <img src={watsappLogo} alt="whatsapp logo" />
              </a>
            </div>
          </div>
          <div className={classes.centerBlock}>
            <CreateOrderComponent/>
          </div>
        </div>
      );
    }
  };

  return <>{getLayout()}</>;
};
