import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  Spin,
  FormItemProps,
  Tooltip,
} from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import PasswordIcon from '../../../Assets/icon_password.svg';
import classes from './style.module.scss';
import { RootStateType } from '../../../Stores';
import { useDispatch, useSelector } from 'react-redux';
import { workshopActions } from '../../../Globals/Workshop/actions';
import { Avatar } from './Avatar';
import MapComponent, {
  PointType,
} from '../../../Components/Map/RegistrationMap';
import { ButtonComponent } from '../../../Components/Button';
import classNames from 'classnames';
import useDebounce from '../../../Hooks/useDebounce';
import { ModalComponent } from '../../../Components/Modal';
import { ManagePasswordForm } from '../../UserSettings/PaymentPreferences/Components/ManagePasswordForm';
import { authActions } from '../../../Globals/Auth/actions';
import useConfirm from '../../../Hooks/useConfirm';

const { TextArea } = Input;
const { Option } = Select;

type SettingFormPropsType = {
  wsId?: number;
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 24 },
};

export const SettingsForm: React.FC<SettingFormPropsType> = ({ wsId }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [address, setAddress] = useState<PointType>();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [avatar, setAvatar] = useState();
  const [phoneInputValue, setPhoneInputValue] = useState<{
    key: number;
    e: React.ChangeEvent<HTMLInputElement> | undefined;
  }>();
  const [isModal, setIsModal] = useState(false);

  const { confirm } = useConfirm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const cities = useSelector((state: RootStateType) => state.workshop.cities);

  const workshopId = useSelector(
    (state: RootStateType) => state.auth.user?.user.workshop?.id
  );

  const specialistOptions = useSelector(
    (state: RootStateType) => state.workshop.specialists
  );

  const workshopInfo = useSelector(
    (state: RootStateType) => state.workshop.workshopData
  );

  const userRole = useSelector(
    (state: RootStateType) => state.auth.user?.user.role.type
  );

  const userProfile = useSelector(
    (state: RootStateType) => state.workshop.workshopData?.user
  );

  const closeModal = () => {
    setIsModal(false);
  };
  const onResetPassword = (payload: {
    password: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (userProfile) {
      dispatch(
        authActions.updatePassword({
          ...payload,
          identifier: userProfile.email,
        })
      );
      setIsModal(false);
    }
  };

  const onFinish = (values: any) => {
    const { workshop_specialists, phones, city_id, ...otherValues } = values;
    const specialistPayload = specialistOptions.map((spec) => {
      if (workshop_specialists.includes(spec.id)) {
        return { id: spec.id, status: true };
      } else {
        return { id: spec.id, status: false };
      }
    });
    dispatch(
      workshopActions.updateWorkshopSpecialists(specialistPayload, wsId)
    );
    dispatch(
      workshopActions.updateWorkshop(
        {
          ...otherValues,
          city_id: city_id,
          mobile_mechanic: isMobile,
          latitude: address?.lat,
          longitude: address?.lng,
          address: address?.address,
          avatar,
        },
        wsId
      )
    );
    setIsDisabled(true);
  };
  
  const showConfirm = async (values: any) => {
    if (!wsId) {
      const isConfirmed = await confirm(
        'Changes to the data in your account must be verified by the CarLogic administrator. Are you sure?',
        ['Cancel', 'Yes, save changes']
      );
      if (isConfirmed) {
        onFinish(values);
      } else {
      }
    } else {
      onFinish(values);
    }
  };

  const onAddress = (point: PointType) => {
    setAddress(point);
    if (
      workshopInfo?.latitude === point.lat &&
      workshopInfo.longitude === point.lng
    ) {
      return;
    } else {
      setIsDisabled(false);
    }
  };

  const onPhoneInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    key: number
  ) => {
    if (e) {
      setPhoneInputValue({ key, e });
    }
  };

  const CheckToValid = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e)
  }

  const debouncedSearchTerm = useDebounce(phoneInputValue, 700);

  const addNewPhone = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | undefined, key: number) => {
      if (e?.target.value.length === 9) {
        if ((workshopInfo?.workshop_phones?.length ?? -1) <= key) {
          dispatch(
            workshopActions.addNewPhone(workshopId || wsId!, e?.target.value)
          );
        } else if (
          workshopInfo?.workshop_phones &&
          (workshopInfo?.workshop_phones.length ?? 0) > key
        ) {
          dispatch(
            workshopActions.editPhone(
              workshopId || wsId!,
              e?.target.value,
              workshopInfo?.workshop_phones[key].id!
            )
          );
        } else {
          console.log('error');
        }
      }
    },
    [dispatch, workshopId, workshopInfo?.workshop_phones, wsId]
  );

  const removePhoneNumber = (phoneIndex: number, removeField: () => void) => {
    if (
      workshopInfo?.workshop_phones &&
      workshopInfo?.workshop_phones[phoneIndex]
    ) {
      dispatch(
        workshopActions.deletePhone(
          workshopId || wsId!,
          workshopInfo?.workshop_phones[phoneIndex].id!
        )
      );
    } else {
      removeField();
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      addNewPhone(debouncedSearchTerm.e, debouncedSearchTerm.key);
    }
  }, [debouncedSearchTerm, dispatch]);

  useEffect(() => {
    if (wsId) {
      dispatch(workshopActions.getWorkshopData(wsId));
    } else if (workshopId) {
      dispatch(workshopActions.getWorkshopData(workshopId));
    }
    dispatch(workshopActions.getCities());
  }, [wsId, workshopId, dispatch]);

  useEffect(() => {
    setIsMobile(workshopInfo?.mobile_mechanic || false);
    const phonesWithPrimaryFirst = [
      ...(workshopInfo?.workshop_phones ?? []),
    ].sort((a, b) => {
      return a.primary ? -1 : b.primary ? 1 : 0;
    });
    const defaultValues = {
      ...{
        name: workshopInfo?.name,
        contact_person: workshopInfo?.contact_person,
        email: workshopInfo?.email,
        location: workshopInfo?.location,
        trade_license: workshopInfo?.trade_license,
        about: workshopInfo?.about,
        place_id: workshopInfo?.place_id,
        city_id: workshopInfo?.city_id,
        verified: workshopInfo?.verified,
        partner: workshopInfo?.partner,
        workshop_specialists: specialistOptions
          .filter((spec) => spec.status)
          .map((item) => item.id),
        headline: workshopInfo?.headline,
        mobile_mechanic: workshopInfo?.mobile_mechanic,
        is_workshop: workshopInfo?.is_workshop,
        phones: phonesWithPrimaryFirst.map((phone) => phone.phone),
      },
    };
    form.setFieldsValue(defaultValues);
  }, [workshopInfo, specialistOptions, form]);

  useEffect(() => {}, [workshopInfo, specialistOptions, form]);

  // useEffect(() => {
  //   dispatch(workshopActions.getCities());
  // }, [workshopInfo, specialistOptions, form]);
  const onChange = (e: any) => {
    if (!e.target.id.includes('work-account_phones')) {
      setIsDisabled(false);
    }
  };


  const onChangeCityAndSpec = (e: any) => {
    setIsDisabled(false);
};

  return (
    <div className={classes.container}>
      <div className={classes.avatar}>
        <Avatar setAvatar={setAvatar} workshopId={workshopInfo?.id!} />
        <Tooltip
          placement="right"
          title="We recommend uploading a square image and no larger than 2mb"
          style={{ marginLeft: '8px' }}
          color={'#205680'}
        >
          <InfoCircleOutlined className={classes.tooltipIcon} />
        </Tooltip>
      </div>

      <Form
        {...layout}
        form={form}
        name="work-account"
        onFinish={showConfirm}
        onChange={onChange}
      >
        <Form.Item
          name="name"
          label={<span className={classes.label}>Workshop name</span>}
        >
          <Input style={{ maxWidth: 360 }} placeholder="Your name" />
        </Form.Item>
        <Form.Item name="contact_person" label="Contact name">
          <Input style={{ maxWidth: 360 }} placeholder="How can we call you" />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input style={{ maxWidth: 360 }} placeholder="Your email" />
        </Form.Item>
        <Form.List name="phones">
          {(fields: any[], { add, remove }: any, { errors }: any) => {
            return (
              <>
                {fields.map(
                  (
                    field: JSX.IntrinsicAttributes & FormItemProps<any>,
                    index: number
                  ) => (
                    <Form.Item
                      help={
                        index === fields.length - 1 && (
                          <div onClick={() => add()} className={classes.helper}>
                            + Add phone number
                          </div>
                        )
                      }
                      label={index === 0 ? 'Mobile No' : 'none'}
                      required={false}
                      key={field.key}
                      className={classNames({
                        [classes.withOutDotAnyLabel]: index !== 0,
                      })}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        noStyle
                        rules={[
                          {
                            validator: (_, value) => {
                              if (
                                form
                                  .getFieldValue('phones')
                                  .filter(
                                    (
                                      _: any,
                                      index: React.Key | React.Key[] | undefined
                                    ) => index !== field.fieldKey
                                  )
                                  .includes(value)
                              ) {
                                return Promise.reject(
                                  new Error(
                                    'You should accept our terms and conditions'
                                  )
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                          {
                            validator: (_, value) => {
                              if (value.length > 9) {
                                return Promise.reject(
                                  new Error('should be 9 characters')
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input
                          onWheel={(event) => event.currentTarget.blur()}
                          type="number"
                          onChange={(e) => {
                            onPhoneInputChange(e, field.fieldKey as number);
                          }}
                          className={classNames(classes.phoneInput, {
                            [classes.inputWidth360]: index === 0,
                            [classes.inputWidth320]: index !== 0,
                          })}
                          placeholder="5x xxx xxxx"
                          addonBefore="+971"
                          addonAfter={false}
                          maxLength={9}
                        />
                      </Form.Item>
                      {index !== 0 ? (
                        <DeleteOutlined
                          onClick={() => {
                            removePhoneNumber(field.fieldKey as number, () => {
                              remove(field.name);
                            });
                          }}
                          style={{
                            marginLeft: '12px',
                            marginTop: '5px',
                            color: '#5B8DB4',
                            fontSize: '20px',
                          }}
                        />
                      ) : null}
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  )
                )}
              </>
            );
          }}
        </Form.List>
        <Form.Item
          name="trade_license"
          label={<span className={classes.label}>Trade License</span>}
        >
          <Input
            style={{ maxWidth: 360 }}
            placeholder="Your trade license number"
          />
        </Form.Item>
        <Form.Item label="Password">
          <Button
            shape="round"
            onClick={() => {
              setIsModal(true);
            }}
          >
            Manage Password
          </Button>
        </Form.Item>
        <Form.Item style={{ maxWidth: 670 }} name="about" label="About">
          <TextArea
            rows={4}
            placeholder="Share with us what makes your workshop stand-out, how would you advertise your workshop?"
            maxLength={1000}
          />
        </Form.Item>
        <Form.Item style={{ maxWidth: 670 }} name="headline" label="Headline">
          <TextArea
            showCount
            maxLength={150}
            rows={4}
            placeholder="One or two sentences with key features of your service for headline in advertisement"
          />
        </Form.Item>
        <Form.Item 
          name="place_id" 
          label="Google account"
          // rules={[
          //   //{ required: true, message: 'required' },
          //   {
          //     validator(_, value) {
          //       if (value.slice(0,48) == "https://search.google.com/local/reviews?placeid=") {
          //         return Promise.resolve();
          //       }
          //       return Promise.reject(
          //         new Error("The link form is not correct")
          //       );
          //     },
          //   },
          // ]}
          >
          {/* {addonBefore="https://search.google.com/local/reviews?placeid="} */}
          <Input onChange={CheckToValid} style={{ maxWidth: 670 }} placeholder="To share google reviews" />
        </Form.Item>
        <Form.Item
          name="workshop_specialists"
          label={<span className={classes.label}>Specialist in</span>}
        >
          <Select
            placeholder="What cars you service?"
            onChange={onChangeCityAndSpec}
            mode="multiple"
            style={{ maxWidth: 360 }}
            optionFilterProp="label"
            showArrow
          >
            {specialistOptions.map((specialistType, index) => {
              return (
                <Option key={index} label={specialistType.title} value={specialistType.id} title={''}>
                  {specialistType.title}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {/* <Form.Item
          name="mobile_mechanic"
          label=" "
          className={classes.withOutDot}
        >
          <Checkbox
            disabled={!!workshopId}
            className={classes.checkBox}
            checked={isMobile}
            onChange={(e) => setIsMobile(e.target.checked)}
          >
            I’m a mobile Mechanic
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="is_workshop"
          valuePropName="checked"
          label=" "
          className={classes.withOutDotIsWorkshop}
        >
          <Checkbox className={classes.checkBox} disabled={!!workshopId}>
            I’m a Workshop
          </Checkbox>
        </Form.Item> */}

        <Form.Item
          name="city_id"
          label={<span className={classes.label}>Location</span>}
        >
          <Select
              placeholder="Choose your city"
              style={{ maxWidth: 360 }} 
              onChange={onChangeCityAndSpec}
            >
              {/* <Option style={{borderBottom: '1px rgba(0, 0, 0, 0.85) solid'}} value="">All cities</Option> */}
              {cities.map((city,index) => {
                return(
                  <Option key={index} value={city.id}>{city.title}</Option>
                )
              })
              }
              {/* <Option value="PRICE_ASC">Price &uarr;</Option>
              <Option value="PRICE_DESC">Price &darr;</Option>
              <Option value="DIST_ASC">Distance &harr;</Option> */}
            </Select>
        </Form.Item>
        
        {userRole === 'authenticated_admin' && (
          <Form.Item
            name="partner"
            label="Partner"
            style={{ marginBottom: '12px' }}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        )}
        {userRole === 'authenticated_admin' && (
          <Form.Item
            name="verified"
            label="Verified"
            style={{ marginBottom: '12px' }}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        )}

        <Form.Item style={{ maxWidth: 670 }} label="Address">
          {workshopInfo ? (
            <MapComponent
              getMeAddress={onAddress}
              lat={workshopInfo.latitude}
              long={workshopInfo.longitude}
            />
          ) : (
            <Spin tip="Loading..." />
          )}
        </Form.Item>

        <Form.Item>
          <div
            style={{
              gap: 14,
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <ButtonComponent type="cancel" title="Cancel" />
            <ButtonComponent
              type="save"
              title="Save"
              disabled={isDisabled}
              breakpointOff
              style={{ marginLeft: 0 }}
            />
          </div>
        </Form.Item>
      </Form>
      <ModalComponent
        isModal={isModal}
        toggleModal={closeModal}
        title="Manage password"
        icon={PasswordIcon}
      >
        <ManagePasswordForm onSubmit={onResetPassword} onCancel={closeModal} />
      </ModalComponent>
    </div>
  );
};
