import React, { useCallback, useEffect } from 'react';
import moment,  { Moment } from 'moment';
import classes from './style.module.scss';
import { CarAndServices } from '../../../../Components/CarAndServices/index';
import DrawerComponent from '../../../../Components/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../Globals/User/actions';
import { AppointmentForm } from './Components/AppointmentForm';
import { OrderDataForAppointment } from '../../../../Types/userTypes';
import { RootStateType } from '../../../../Stores';
//import { DropOff } from './Components/';

type AppointmentStepPropsType = {
  goToNextStep: () => void;
  goToPrevStep: () => void;
};

export const AppointmentStep: React.FC<AppointmentStepPropsType> = ({
  goToNextStep,
  goToPrevStep,
}) => {
  const dispatch = useDispatch();

  const trimId = useSelector(
    (state: RootStateType) => state.user.formValues?.trim?.id
  );
  const intervalId = useSelector(
    (state: RootStateType) => state.user.formValues?.milliage?.id
  );
  const workshopId = useSelector(
    (state: RootStateType) => state.user.selectedWorkshop?.id
  );
  const isBasic = useSelector(
    (state: RootStateType) => state.user.isBasicSelected
  );
  const additionalTasks = useSelector(
    (state: RootStateType) => state.user.selectedOtherServicesId
  );

  const carDeliveries = useSelector(
    (state: RootStateType) => state.user.car_deliveries
  );

  const orderId = useSelector(
      (state: RootStateType) => state.user.order
  );

  const user = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const dataLayer = window.dataLayer || []

  const onFinish = (values: { date: Moment; time: string }) => {
    //GTM
    //Start
    dataLayer.push({
      "event": "step_4_appointment",
      "date":`${values.date.format('YYYY MM DD')}`,
      "time":`${values.time}`
    });
    //End
    dispatch(
      userActions.setSelectedDate({
        startDate: values.date.format('YYYY MM DD'),
        startTime: values.time,
      })
    );
   //GTM
    //Start
    if(user?.role.name){ 
      dataLayer.push({
        "path": "/createorder/book",
        "page_type":"bookStep",
        "event":"page_view"
      });
    }
    //End
    goToNextStep && goToNextStep();
  };

  const onBack = () => {
    goToPrevStep();
  };

  const createRequestData = useCallback(():
    | OrderDataForAppointment
    | undefined => {
      if (trimId && workshopId && additionalTasks)
      return {
        trimId,
        intervalId,
        workshopId,
        isBasic,
        additionalTasks,
      };
    else {
      console.error('createRequestData not data');
    }
  }, [additionalTasks, intervalId, isBasic, trimId, workshopId]);

  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/createorder/appointment",
      "page_type":"appointmentStep",
      "event":"page_view"
    });
    //End
  }, []);
  
  useEffect(() => {
    //dispatch(userActions.createTabbySession('tabbySession'));
    const beforeUnloadWindow = (e:any) => {
      var e = e || window.event;
        if (e) {
            e.returnValue = 'Leaving the page?';
        }
        // For Safari
        return "Leaving the page?";
     }
    window.addEventListener('beforeunload', beforeUnloadWindow)
    return () => { 
      window.removeEventListener('beforeunload', beforeUnloadWindow)
    }
  }, []);

  useEffect(() => {
    dispatch(userActions.getCarDeliveries());
    dispatch(userActions.resetSelectedDate());
  }, [dispatch]);

  useEffect(() => {
    if (trimId && workshopId)
    dispatch(
      userActions.getAvailableDays({
        maintenance_interval: intervalId,
        trim: trimId,
        additional_tasks: `[${additionalTasks}]`,
        log_book: isBasic === false,
        basic: isBasic === true,
        workshop: workshopId,
        order_id: orderId?.id
      })
    );
  }, [additionalTasks, dispatch, intervalId, isBasic, trimId, workshopId, orderId]);

  return (
    <div className={classes.contentContainer}>
      <DrawerComponent runAnimate={{}}>
        <CarAndServices canRemove={false} isShow={false} />
      </DrawerComponent>

      <AppointmentForm
        onFinish={onFinish}
        onCancel={onBack}
        data={createRequestData()}
        carDeliveries={carDeliveries}
      />

      <div className={classes.hide}>
        <CarAndServices canRemove={false} />
      </div>
    </div>
  );
};
