export const GET_MAKES = 'GET_MAKES';
export const SET_MAKES = 'SET_MAKES';

export const GET_YEAR_OF_MAKE = 'GET_YEAR_OF_MAKE';
export const SET_YEAR_OF_MAKE = 'SET_YEAR_OF_MAKE';

export const GET_MODEL_OF_YEAR = 'GET_MODEL_OF_YEAR';
export const SET_MODEL_OF_YEAR = 'SET_MODEL_OF_YEAR';

export const GET_MODEL_TRIM = 'GET_MODEL_TRIM';
export const SET_MODEL_TRIM = 'SET_MODEL_TRIM';

export const GET_INTERVALS = 'GET_INTERVALS';
export const SET_INTERVALS = 'SET_INTERVALS';

export const GET_ALL_INTERVALS = 'GET_ALL_INTERVALS'
export const SET_ALL_INTERVALS = 'SET_ALL_INTERVALS'

export const GET_SERVICES_FOR_CURRENT_INTERVAL =
  'GET_SERVICES_FOR_CURRENT_INTERVAL';
export const SET_SERVICES_FOR_CURRENT_INTERVAL =
  'SET_SERVICES_FOR_CURRENT_INTERVAL';

export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const REMOVE_FORM_ID = 'REMOVE_FORM_ID'

export const ADD_OTHER_SERVICE = 'ADD_OTHER_SERVICE';
export const REMOVE_OTHER_SERVICE = 'REMOVE_OTHER_SERVICE';

export const ADD_CAR_REPAIR = 'ADD_CAR_REPAIR';
export const REMOVE_CAR_REPAIR = 'REMOVE_CAR_REPAIR';
export const REMOVE_ALL_CAR_REPAIR = 'REMOVE_ALL_CAR_REPAIR';

export const SET_CAR_REPAIR_TEXT = 'SET_CAR_REPAIR_TEXT';

export const SET_SELECTED_MAIN_SERVICE_TYPE = 'SET_SELECTED_MAIN_SERVICE_TYPE';
export const SET_OPTIONAL_SERVICES = 'SET_OPTIONAL_SERVICES';
export const REMOVE_OPTIONAL_SERVICES = 'REMOVE_OPTIONAL_SERVICES';

export const GET_WORKSHOPS_ON_LOCATION = 'GET_WORKSHOPS_ON_LOCATION';
export const SET_WORKSHOPS_ON_LOCATION = 'SET_WORKSHOPS_ON_LOCATION';

export const GET_CITIES = 'GET_CITIES';
export const SET_CITIES = 'SET_CITIES';

export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';

export const SET_ADDRESS_POINT = 'SET_ADDRESS_POINT';

export const SET_CLIENT_ADDRESS = 'SET_CLIENT_ADDRESS';

export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const SET_APPOINTMENT = 'SET_APPOINTMENT';

export const SET_SELECTED_WORKSHOP = 'SET_SELECTED_WORKSHOP';
export const RESET_SELECTED_WORKSHOP = 'user/RESET_SELECTED_WORKSHOP'

export const ADD_NEW_CAR = 'ADD_NEW_CAR';
export const UPDATE_CAR = 'UPDATE_CAR';
export const GET_CURRENT_CAR = 'GET_CURRENT_CAR';
export const DELETE_CURRENT_CAR = 'DELETE_CURRENT_CAR';
export const GET_CARS_LIST = 'GET_CARS_LIST';
export const SET_CARS_LIST = 'SET_CARS_LIST';


export const GET_PAYMENT = 'GET_PAYMENT'
export const SET_PAYMENT = 'SET_PAYMENT'

export const SET_SELECTED_DATE = 'SET_SELECTED_DATE'
export const RESET_SELECTED_DATE = 'RESET_SELECTED_DATE'

export const GET_SERVICES_FOR_CUSTOMER_CAR = 'GET_SERVICES_FOR_CUSTOMER_CAR';

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const RESET_REDIRECTS = 'RESET_REDIRECTS'

export const RESET_ORDER_DATA = 'RESET_ORDER_DATA';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';

export const SET_IS_TABBY = 'SET_IS_TABBY'

export const SET_FORM_ID = 'USER/SET_FORM_ID';

export const SET_IS_MODAL = 'USER/SET_IS_MODAL';
export const SET_MODAL_TYPE = 'USER/SET_MODAL_TYPE';
export const TOGGLE_MODAL_IS_SIDEBAR = 'USER/TOGGLE_MODAL_IS_SIDEBAR'

export const SET_DELIVERY_PICK_UP = 'USER/SET_DELIVERY_PICK_UP';
export const SET_DELIVERY_DROP_OFF = 'USER/SET_DELIVERY_DROP_OFF';
export const REMOVE_DELIVERY_CAR = 'USER/REMOVE_DELIVERY_CAR';
export const SET_DELIVERY_TIME = 'user/SET_DELIVERY_TIME'
export const RESET_DELIVERY = 'user/RESET_DELIVERY'

export const GET_ORDERS = 'user/GET_ORDERS'
export const SET_ORDERS = 'user/SET_ORDERS'
export const RESET_ORDERS = 'user/RESET_ORDERS'
export const RESET_WITHOUT_FORM_DATA = 'user/RESET_WITHOUT_FORM_DATA'

export const GET_ORDER = 'user/GET_ORDER'
export const SET_ORDER = 'user/SET_ORDER'

export const RELOAD_ORDER_INFO = 'user/RESET_ORDER_INFO'

export const IS_HAS_MORE = 'user/IS_HAS_MORE'

export const SET_SELECTED_CAR = 'user/SET_SELECTED_CAR'

export const GET_USER_PROFILE = 'user/GET_USER_PROFILE'
export const UPDATE_USER_PROFILE = 'user/UPDATE_USER_PROFILE'
export const SET_UPDATES_USER = 'user/SET_UPDATES_USER'

export const GET_SERVICE_HISTORY_FOR_CAR = 'user/GET_SERVICE_HISTORY_FOR_CAR'
export const SET_SERVICE_HISTORY_FOR_CAR = 'user/SET_SERVICE_HISTORY_FOR_CAR'

export const GET_SCHEDULED_MAINTENANCE_FOR_CAR = 'user/GET_SCHEDULED_MAINTENANCE_FOR_CAR'
export const SET_SCHEDULED_MAINTENANCE_FOR_CAR = 'user/SET_SCHEDULED_MAINTENANCE_FOR_CAR'

export const GET_INVOICE_PDF = 'user/GET_INVOICE_PDF'

export const SEND_REVIEW = 'user/SEND_REVIEW'
export const SET_REVIEW = 'user/SET_REVIEW'

export const SEND_CARD = 'user/SEND_CARD'
export const GET_CARD = 'user/GET_CARD'
export const SET_CARD = 'user/SET_CARD'
export const UPDATE_CARD = 'user/UPDATE_CARD'
export const DELETE_CARD = 'user/DELETE_CARD'

export const SET_ORDER_SUMMARY = 'user/SET_ORDER_SUMMARY'

export const SET_SAVED_SUMMARY_DATA = 'user/SET_SAVED_SUMMARY_DATA'

export const CHECK_CANCEL_STATUS = 'user/CHECK_CANCEL_STATUS'
export const SET_CANCEL_STATUS = 'user/SET_CANCEL_STATUS'

export const CHECK_RESCHEDULE_STATUS = 'user/CHECK_RESCHEDULE_STATUS'
export const SET_RESCHEDULE_STATUS = 'user/SET_RESCHEDULE_STATUS'

export const CANCEL_ORDER = 'user/CANCEL_ORDER'
export const RESCHEDULE_ORDER = 'user/RESCHEDULE_ORDER'

export const SET_CHANGED_ORDER = 'user/SET_CHANGED_ORDER'
export const SET_SECRET_REDIRECT = 'user/SET_SECRET_REDIRECT'

export const GET_DECLINED_ORDER = 'user/GET_DECLINED_ORDER'

export const SET_NOTES = 'user/SET_NOTES'

export const GET_CAR_DELIVERIES_TIME = 'user/GET_CAR_DELIVERIES_TIME'
export const SET_CAR_DELIVERIES_TIME = 'user/SET_CAR_DELIVERIES_TIME'

export const GET_AVAILABLE_DAYS = 'user/GET_AVAILABLE_DAYS'
export const SET_AVAILABLE_DAYS = 'user/SET_AVAILABLE_DAYS'

export const CREATE_TABBY_SESSION = 'user/CREATE_TABBY_SESSION'
export const SET_TABBY_SESSION = 'user/SET_TABBY_SESSION'