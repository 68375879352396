import { combineReducers } from 'redux';
import adminReducer from '../Globals/Admin/reducer';
import appReducer from '../Globals/App/reducer';
import authReducer from '../Globals/Auth/reducer';
import userReducer from '../Globals/User/reducer';
import workshopReducer from '../Globals/Workshop/reducer';

export const whiteListReducers = {
  auth: authReducer,
};

export const blackListReducers = {
  app: appReducer,
  admin: adminReducer,
  workshop: workshopReducer,
  user: userReducer
};



const rootReducer = combineReducers({
  ...blackListReducers,
  ...whiteListReducers,
});


export default rootReducer;
