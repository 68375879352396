import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddCarForm } from '../../../../Components/AddCar';
import { userActions } from '../../../../Globals/User/actions';
import { RootStateType } from '../../../../Stores';
import TabbyWomen from '../../../../Assets/Landing/tabby-women.png';
import TabbyLogo from '../../../../Assets/tabby-button-logo.svg'
import classes from './styles.module.scss';

export const TabbyPromo: React.FC = () => {
  
  return (
    <section className={classes.container} id="tabby-promo">
      <div className={classes.tabbyPromo}>
          <img className={classes.tabbyWomen} src={TabbyWomen} alt="" />
          <div className={classes.titles}>
            <h1>Book now</h1>
            <h1>Pay later with Tabby</h1>
            <img src={TabbyLogo} alt="" />
            <p>Tabby’s T&C apply <a href="https://tabby.ai/en-AE/toc" target='_blank'>Click here</a></p>
          </div>
      </div>
    </section>
  );
};
