import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { MainLayout } from '../../Components/MainLayout';
import { SettingsForm } from './Form';
import { Schedule } from './Schedule';
import { Services } from './Services';
import { Promotions } from './Promotions';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { workshopActions } from '../../Globals/Workshop/actions';
import { BankAccount } from '../../Components/BankAccount';
import { RootStateType } from '../../Stores';

const { TabPane } = Tabs;

export const WorkShopSettings: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const state: any = location.state;

  const currentUser = useSelector(
    (state: RootStateType) => state.auth.user?.user
  );

  const paymentId = useSelector(
    (state: RootStateType) => state.workshop.workshopData?.workshop_payment
  );

  const dataLayer = window.dataLayer || []
  
  useEffect(() => {
    //GTM
    //Start
    dataLayer.push({
      "path": "/workshop/settings",
      "page_type":"workshopSettingsPage",
      "event":"page_view"
    });
    //End
  }, []);

  useEffect(() => {
    dispatch(workshopActions.getWorkshopSpecialists(state?.workshopId));
  }, [dispatch, state?.workshopId]);

  return (
    <MainLayout headerTitle="Settings">
      <Tabs defaultActiveKey="1" onChange={() => {}} style={{ border: 'none' }}>
        <TabPane tab="Account" key="1">
          <SettingsForm wsId={state?.workshopId} />
        </TabPane>
        {currentUser?.role.type === 'authenticated_admin' && (
          <TabPane tab="Bank details" key="5">
            {paymentId && <BankAccount wsId={paymentId} />}
          </TabPane>
        )}
        <TabPane tab="Availability" key="2">
          <Schedule wsId={state?.workshopId} />
        </TabPane>
        <TabPane tab="Services" key="3">
          <Services wsId={state?.workshopId} />
        </TabPane>
        <TabPane tab="Promotions" key="4">
          <Promotions wsId={state?.workshopId} />
        </TabPane>
      </Tabs>
    </MainLayout>
  );
};
